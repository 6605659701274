import React from 'react';
import { useTranslation } from 'react-i18next';

export type MapCountryCode = 'pt' | 'es' | 'fr' | 'it' | 'mx' | 'br' | 'hr' | 'gr' | 'ro' | 'bu';

//Coord corresponds to where the tip of the icon should be
const LOCATION_ICON_COORDS_BY_COUNTRY: Record<MapCountryCode, { x: number; y: number }> = {
  ro: {
    x: 602.233,
    y: 109.897,
  },
  gr: {
    x: 602.284,
    y: 65.5046,
  },
  hr: {
    x: 602.21,
    y: 21.5924,
  },
  it: {
    x: 194.669,
    y: 21.8457,
  },
  fr: {
    x: 195.166,
    y: 65.668,
  },
  es: {
    x: 195.062,
    y: 109.998,
  },
  pt: {
    x: 195.635,
    y: 154.092,
  },
  mx: {
    x: 0.000915527,
    y: 216.078,
  },
  br: {
    x: 417.687,
    y: 290.145,
  },
  bu: {
    x: 602.468,
    y: 153.897,
  },
};

const LOCATION_ICON_WIDTH = 23;
const LOCATION_ICON_HEIGHT = 31;

export interface WorldwideMapProps {
  selectedCountry?: MapCountryCode | null;
  onSelectCountry: (country: MapCountryCode) => void;
  changingCountry: boolean;
}

export const WorldwideMap = ({
  selectedCountry,
  onSelectCountry,
  changingCountry,
}: WorldwideMapProps): React.ReactElement => {
  const locationIconCoord = selectedCountry && LOCATION_ICON_COORDS_BY_COUNTRY[selectedCountry];

  const { t } = useTranslation();

  return (
    <>
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html: `
            .worldwide-map--label:hover {
              opacity: 0.8;
              cursor: pointer;
            }

            .worldwide-map--label:hover + g + .worldwide-map--label-half-circle {
              opacity: 0;
            }

            .worldwide-map--location-icon {
              opacity: 1;
              transition: opacity 500ms ease;
            }

            .worldwide-map--location-icon.hidden{
              opacity: 0;
            }

            .worldwide-map--text {
              fill:white;
              font-size: 14px;
              font-family:'Montserrat', sans-serif;
              font-weight: 500;
              pointer-events: none;
              text-transform: uppercase;

            }
          `,
        }}
      ></style>
      <svg
        id="worldwide-map"
        viewBox="-12 -10 780 442"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.6">
          <path
            d="M758.288 391.735C757.005 391.735 755.957 392.782 755.957 394.065C755.957 395.348 757.005 396.395 758.288 396.395C759.582 396.395 760.63 395.348 760.63 394.065C760.63 392.782 759.582 391.735 758.288 391.735Z"
            fill="#ADADAD"
          />
          <path
            d="M751.838 385.279C750.555 385.279 749.507 386.327 749.507 387.609C749.507 388.892 750.555 389.95 751.838 389.95C753.121 389.95 754.169 388.903 754.169 387.609C754.169 386.327 753.121 385.279 751.838 385.279Z"
            fill="#ADADAD"
          />
          <path
            d="M751.838 391.736C750.555 391.736 749.507 392.783 749.507 394.066C749.507 395.348 750.555 396.396 751.838 396.396C753.121 396.396 754.169 395.348 754.169 394.066C754.169 392.783 753.121 391.736 751.838 391.736Z"
            fill="#ADADAD"
          />
          <path
            d="M751.838 398.192C750.555 398.192 749.507 399.239 749.507 400.522C749.507 401.805 750.555 402.852 751.838 402.852C753.121 402.852 754.169 401.805 754.169 400.522C754.169 399.239 753.121 398.192 751.838 398.192Z"
            fill="#ADADAD"
          />
          <path
            d="M745.379 378.834C744.095 378.834 743.047 379.881 743.047 381.164C743.047 382.446 744.095 383.494 745.379 383.494C746.662 383.494 747.71 382.446 747.71 381.164C747.71 379.881 746.662 378.834 745.379 378.834Z"
            fill="#ADADAD"
          />
          <path
            d="M745.379 385.279C744.095 385.279 743.047 386.327 743.047 387.609C743.047 388.892 744.095 389.95 745.379 389.95C746.662 389.95 747.71 388.903 747.71 387.609C747.71 386.327 746.662 385.279 745.379 385.279Z"
            fill="#ADADAD"
          />
          <path
            d="M745.379 391.736C744.095 391.736 743.047 392.783 743.047 394.066C743.047 395.348 744.095 396.396 745.379 396.396C746.662 396.396 747.71 395.348 747.71 394.066C747.71 392.783 746.662 391.736 745.379 391.736Z"
            fill="#ADADAD"
          />
          <path
            d="M745.379 398.192C744.095 398.192 743.047 399.239 743.047 400.522C743.047 401.805 744.095 402.852 745.379 402.852C746.662 402.852 747.71 401.805 747.71 400.522C747.71 399.239 746.662 398.192 745.379 398.192Z"
            fill="#ADADAD"
          />
          <path
            d="M745.379 404.636C744.095 404.636 743.047 405.684 743.047 406.966C743.047 408.26 744.095 409.307 745.379 409.307C746.662 409.307 747.71 408.26 747.71 406.966C747.71 405.684 746.662 404.636 745.379 404.636Z"
            fill="#ADADAD"
          />
          <path
            d="M738.919 404.636C737.636 404.636 736.588 405.684 736.588 406.966C736.588 408.26 737.636 409.307 738.919 409.307C740.213 409.307 741.261 408.26 741.261 406.966C741.261 405.684 740.213 404.636 738.919 404.636Z"
            fill="#ADADAD"
          />
          <path
            d="M738.919 411.092C737.636 411.092 736.588 412.14 736.588 413.422C736.588 414.705 737.636 415.753 738.919 415.753C740.213 415.753 741.261 414.705 741.261 413.422C741.261 412.14 740.213 411.092 738.919 411.092Z"
            fill="#ADADAD"
          />
          <path
            d="M732.46 346.565C731.176 346.565 730.128 347.612 730.128 348.895C730.128 350.178 731.176 351.225 732.46 351.225C733.743 351.225 734.791 350.178 734.791 348.895C734.802 347.612 733.754 346.565 732.46 346.565Z"
            fill="#ADADAD"
          />
          <path
            d="M732.46 353.021C731.176 353.021 730.128 354.069 730.128 355.362C730.128 356.645 731.176 357.692 732.46 357.692C733.743 357.692 734.791 356.645 734.791 355.362C734.802 354.069 733.754 353.021 732.46 353.021Z"
            fill="#ADADAD"
          />
          <path
            d="M732.46 404.636C731.176 404.636 730.128 405.684 730.128 406.966C730.128 408.26 731.176 409.307 732.46 409.307C733.743 409.307 734.791 408.26 734.791 406.966C734.802 405.684 733.754 404.636 732.46 404.636Z"
            fill="#ADADAD"
          />
          <path
            d="M732.46 411.092C731.176 411.092 730.128 412.14 730.128 413.423C730.128 414.705 731.176 415.753 732.46 415.753C733.743 415.753 734.791 414.705 734.791 413.423C734.802 412.14 733.754 411.092 732.46 411.092Z"
            fill="#ADADAD"
          />
          <path
            d="M726.012 346.565C724.728 346.565 723.68 347.612 723.68 348.895C723.68 350.178 724.728 351.225 726.012 351.225C727.295 351.225 728.343 350.178 728.343 348.895C728.343 347.612 727.295 346.565 726.012 346.565Z"
            fill="#ADADAD"
          />
          <path
            d="M726.012 353.021C724.728 353.021 723.68 354.069 723.68 355.362C723.68 356.645 724.728 357.692 726.012 357.692C727.295 357.692 728.343 356.645 728.343 355.362C728.343 354.069 727.295 353.021 726.012 353.021Z"
            fill="#ADADAD"
          />
          <path
            d="M713.091 320.752C711.807 320.752 710.759 321.799 710.759 323.082C710.759 324.364 711.807 325.412 713.091 325.412C714.385 325.412 715.433 324.364 715.433 323.082C715.433 321.799 714.385 320.752 713.091 320.752Z"
            fill="#ADADAD"
          />
          <path
            d="M706.631 320.752C705.348 320.752 704.3 321.799 704.3 323.082C704.3 324.364 705.348 325.412 706.631 325.412C707.925 325.412 708.973 324.364 708.973 323.082C708.973 321.799 707.925 320.752 706.631 320.752Z"
            fill="#ADADAD"
          />
          <path
            d="M700.183 314.296C698.9 314.296 697.852 315.343 697.852 316.626C697.852 317.909 698.9 318.956 700.183 318.956C701.467 318.956 702.515 317.909 702.515 316.626C702.515 315.343 701.467 314.296 700.183 314.296Z"
            fill="#ADADAD"
          />
          <path
            d="M700.183 359.477C698.9 359.477 697.852 360.524 697.852 361.807C697.852 363.09 698.9 364.137 700.183 364.137C701.467 364.137 702.515 363.09 702.515 361.807C702.515 360.514 701.467 359.477 700.183 359.477Z"
            fill="#ADADAD"
          />
          <path
            d="M700.183 365.922C698.9 365.922 697.852 366.97 697.852 368.263C697.852 369.546 698.9 370.593 700.183 370.593C701.467 370.593 702.515 369.546 702.515 368.263C702.515 366.97 701.467 365.922 700.183 365.922Z"
            fill="#ADADAD"
          />
          <path
            d="M700.183 372.378C698.9 372.378 697.852 373.425 697.852 374.708C697.852 375.991 698.9 377.038 700.183 377.038C701.467 377.038 702.515 375.991 702.515 374.708C702.515 373.425 701.467 372.378 700.183 372.378Z"
            fill="#ADADAD"
          />
          <path
            d="M693.724 314.296C692.441 314.296 691.393 315.343 691.393 316.626C691.393 317.909 692.441 318.956 693.724 318.956C695.007 318.956 696.055 317.909 696.055 316.626C696.055 315.343 695.007 314.296 693.724 314.296Z"
            fill="#ADADAD"
          />
          <path
            d="M693.724 353.021C692.441 353.021 691.393 354.069 691.393 355.362C691.393 356.645 692.441 357.692 693.724 357.692C695.007 357.692 696.055 356.645 696.055 355.362C696.055 354.069 695.007 353.021 693.724 353.021Z"
            fill="#ADADAD"
          />
          <path
            d="M693.724 359.477C692.441 359.477 691.393 360.524 691.393 361.807C691.393 363.09 692.441 364.137 693.724 364.137C695.007 364.137 696.055 363.09 696.055 361.807C696.055 360.514 695.007 359.477 693.724 359.477Z"
            fill="#ADADAD"
          />
          <path
            d="M693.724 365.922C692.441 365.922 691.393 366.97 691.393 368.263C691.393 369.546 692.441 370.593 693.724 370.593C695.007 370.593 696.055 369.546 696.055 368.263C696.055 366.97 695.007 365.922 693.724 365.922Z"
            fill="#ADADAD"
          />
          <path
            d="M693.724 372.378C692.441 372.378 691.393 373.425 691.393 374.708C691.393 375.991 692.441 377.038 693.724 377.038C695.007 377.038 696.055 375.991 696.055 374.708C696.055 373.425 695.007 372.378 693.724 372.378Z"
            fill="#ADADAD"
          />
          <path
            d="M693.724 378.834C692.441 378.834 691.393 379.881 691.393 381.164C691.393 382.446 692.441 383.494 693.724 383.494C695.007 383.494 696.055 382.446 696.055 381.164C696.055 379.881 695.007 378.834 693.724 378.834Z"
            fill="#ADADAD"
          />
          <path
            d="M693.724 385.279C692.441 385.279 691.393 386.327 691.393 387.609C691.393 388.892 692.441 389.95 693.724 389.95C695.007 389.95 696.055 388.903 696.055 387.609C696.055 386.327 695.007 385.279 693.724 385.279Z"
            fill="#ADADAD"
          />
          <path
            d="M687.274 314.295C685.99 314.295 684.942 315.343 684.942 316.626C684.942 317.908 685.99 318.956 687.274 318.956C688.557 318.956 689.605 317.908 689.605 316.626C689.605 315.343 688.557 314.295 687.274 314.295Z"
            fill="#ADADAD"
          />
          <path
            d="M687.274 320.752C685.99 320.752 684.942 321.799 684.942 323.082C684.942 324.364 685.99 325.412 687.274 325.412C688.557 325.412 689.605 324.364 689.605 323.082C689.605 321.799 688.557 320.752 687.274 320.752Z"
            fill="#ADADAD"
          />
          <path
            d="M687.274 340.11C685.99 340.11 684.942 341.157 684.942 342.44C684.942 343.723 685.99 344.77 687.274 344.77C688.557 344.77 689.605 343.723 689.605 342.44C689.605 341.157 688.557 340.11 687.274 340.11Z"
            fill="#ADADAD"
          />
          <path
            d="M687.274 346.565C685.99 346.565 684.942 347.612 684.942 348.895C684.942 350.177 685.99 351.225 687.274 351.225C688.557 351.225 689.605 350.177 689.605 348.895C689.605 347.612 688.557 346.565 687.274 346.565Z"
            fill="#ADADAD"
          />
          <path
            d="M687.274 353.021C685.99 353.021 684.942 354.068 684.942 355.362C684.942 356.644 685.99 357.692 687.274 357.692C688.557 357.692 689.605 356.644 689.605 355.362C689.605 354.068 688.557 353.021 687.274 353.021Z"
            fill="#ADADAD"
          />
          <path
            d="M687.274 359.477C685.99 359.477 684.942 360.524 684.942 361.807C684.942 363.09 685.99 364.137 687.274 364.137C688.557 364.137 689.605 363.09 689.605 361.807C689.605 360.514 688.557 359.477 687.274 359.477Z"
            fill="#ADADAD"
          />
          <path
            d="M687.274 365.923C685.99 365.923 684.942 366.97 684.942 368.264C684.942 369.546 685.99 370.594 687.274 370.594C688.557 370.594 689.605 369.546 689.605 368.264C689.605 366.97 688.557 365.923 687.274 365.923Z"
            fill="#ADADAD"
          />
          <path
            d="M687.274 372.377C685.99 372.377 684.942 373.425 684.942 374.708C684.942 375.99 685.99 377.038 687.274 377.038C688.557 377.038 689.605 375.99 689.605 374.708C689.605 373.425 688.557 372.377 687.274 372.377Z"
            fill="#ADADAD"
          />
          <path
            d="M687.274 378.834C685.99 378.834 684.942 379.881 684.942 381.164C684.942 382.446 685.99 383.494 687.274 383.494C688.557 383.494 689.605 382.446 689.605 381.164C689.605 379.881 688.557 378.834 687.274 378.834Z"
            fill="#ADADAD"
          />
          <path
            d="M687.274 385.279C685.99 385.279 684.942 386.327 684.942 387.609C684.942 388.892 685.99 389.95 687.274 389.95C688.557 389.95 689.605 388.903 689.605 387.609C689.605 386.327 688.557 385.279 687.274 385.279Z"
            fill="#ADADAD"
          />
          <path
            d="M687.274 391.736C685.99 391.736 684.942 392.783 684.942 394.066C684.942 395.348 685.99 396.396 687.274 396.396C688.557 396.396 689.605 395.348 689.605 394.066C689.605 392.783 688.557 391.736 687.274 391.736Z"
            fill="#ADADAD"
          />
          <path
            d="M687.274 398.192C685.99 398.192 684.942 399.239 684.942 400.522C684.942 401.805 685.99 402.852 687.274 402.852C688.557 402.852 689.605 401.805 689.605 400.522C689.605 399.239 688.557 398.192 687.274 398.192Z"
            fill="#ADADAD"
          />
          <path
            d="M680.814 178.785C679.531 178.785 678.483 179.833 678.483 181.115C678.483 182.398 679.531 183.445 680.814 183.445C682.098 183.445 683.146 182.398 683.146 181.115C683.146 179.833 682.098 178.785 680.814 178.785Z"
            fill="#ADADAD"
          />
          <path
            d="M680.814 185.241C679.531 185.241 678.483 186.289 678.483 187.572C678.483 188.865 679.531 189.912 680.814 189.912C682.098 189.912 683.146 188.865 683.146 187.572C683.146 186.289 682.098 185.241 680.814 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M680.814 191.698C679.531 191.698 678.483 192.745 678.483 194.028C678.483 195.31 679.531 196.358 680.814 196.358C682.098 196.358 683.146 195.31 683.146 194.028C683.146 192.745 682.098 191.698 680.814 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M680.814 198.154C679.531 198.154 678.483 199.201 678.483 200.484C678.483 201.767 679.531 202.814 680.814 202.814C682.098 202.814 683.146 201.767 683.146 200.484C683.146 199.201 682.098 198.154 680.814 198.154Z"
            fill="#ADADAD"
          />
          <path
            d="M680.814 204.598C679.531 204.598 678.483 205.646 678.483 206.928C678.483 208.211 679.531 209.258 680.814 209.258C682.098 209.258 683.146 208.211 683.146 206.928C683.146 205.646 682.098 204.598 680.814 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M680.814 314.295C679.531 314.295 678.483 315.343 678.483 316.626C678.483 317.908 679.531 318.956 680.814 318.956C682.098 318.956 683.146 317.908 683.146 316.626C683.146 315.343 682.098 314.295 680.814 314.295Z"
            fill="#ADADAD"
          />
          <path
            d="M680.814 320.752C679.531 320.752 678.483 321.799 678.483 323.082C678.483 324.364 679.531 325.412 680.814 325.412C682.098 325.412 683.146 324.364 683.146 323.082C683.146 321.799 682.098 320.752 680.814 320.752Z"
            fill="#ADADAD"
          />
          <path
            d="M680.814 333.654C679.531 333.654 678.483 334.701 678.483 335.984C678.483 337.266 679.531 338.314 680.814 338.314C682.098 338.314 683.146 337.266 683.146 335.984C683.146 334.701 682.098 333.654 680.814 333.654Z"
            fill="#ADADAD"
          />
          <path
            d="M680.814 340.11C679.531 340.11 678.483 341.157 678.483 342.44C678.483 343.723 679.531 344.77 680.814 344.77C682.098 344.77 683.146 343.723 683.146 342.44C683.146 341.157 682.098 340.11 680.814 340.11Z"
            fill="#ADADAD"
          />
          <path
            d="M680.814 346.565C679.531 346.565 678.483 347.612 678.483 348.895C678.483 350.177 679.531 351.225 680.814 351.225C682.098 351.225 683.146 350.177 683.146 348.895C683.146 347.612 682.098 346.565 680.814 346.565Z"
            fill="#ADADAD"
          />
          <path
            d="M680.814 353.021C679.531 353.021 678.483 354.068 678.483 355.362C678.483 356.644 679.531 357.692 680.814 357.692C682.098 357.692 683.146 356.644 683.146 355.362C683.146 354.068 682.098 353.021 680.814 353.021Z"
            fill="#ADADAD"
          />
          <path
            d="M680.814 359.477C679.531 359.477 678.483 360.524 678.483 361.807C678.483 363.09 679.531 364.137 680.814 364.137C682.098 364.137 683.146 363.09 683.146 361.807C683.146 360.514 682.098 359.477 680.814 359.477Z"
            fill="#ADADAD"
          />
          <path
            d="M680.814 365.923C679.531 365.923 678.483 366.97 678.483 368.264C678.483 369.546 679.531 370.594 680.814 370.594C682.098 370.594 683.146 369.546 683.146 368.264C683.146 366.97 682.098 365.923 680.814 365.923Z"
            fill="#ADADAD"
          />
          <path
            d="M680.814 372.377C679.531 372.377 678.483 373.425 678.483 374.708C678.483 375.99 679.531 377.038 680.814 377.038C682.098 377.038 683.146 375.99 683.146 374.708C683.146 373.425 682.098 372.377 680.814 372.377Z"
            fill="#ADADAD"
          />
          <path
            d="M680.814 378.834C679.531 378.834 678.483 379.881 678.483 381.164C678.483 382.446 679.531 383.494 680.814 383.494C682.098 383.494 683.146 382.446 683.146 381.164C683.146 379.881 682.098 378.834 680.814 378.834Z"
            fill="#ADADAD"
          />
          <path
            d="M680.814 385.279C679.531 385.279 678.483 386.327 678.483 387.609C678.483 388.892 679.531 389.95 680.814 389.95C682.098 389.95 683.146 388.903 683.146 387.609C683.146 386.327 682.098 385.279 680.814 385.279Z"
            fill="#ADADAD"
          />
          <path
            d="M680.814 391.736C679.531 391.736 678.483 392.783 678.483 394.066C678.483 395.348 679.531 396.396 680.814 396.396C682.098 396.396 683.146 395.348 683.146 394.066C683.146 392.783 682.098 391.736 680.814 391.736Z"
            fill="#ADADAD"
          />
          <path
            d="M674.357 172.339C673.073 172.339 672.015 173.387 672.015 174.68C672.015 175.963 673.063 177.01 674.357 177.01C675.64 177.01 676.688 175.963 676.688 174.68C676.688 173.387 675.651 172.339 674.357 172.339Z"
            fill="#ADADAD"
          />
          <path
            d="M674.357 178.785C673.073 178.785 672.015 179.833 672.015 181.115C672.015 182.398 673.063 183.445 674.357 183.445C675.64 183.445 676.688 182.398 676.688 181.115C676.688 179.833 675.651 178.785 674.357 178.785Z"
            fill="#ADADAD"
          />
          <path
            d="M674.357 185.241C673.073 185.241 672.015 186.289 672.015 187.572C672.015 188.865 673.063 189.912 674.357 189.912C675.64 189.912 676.688 188.865 676.688 187.572C676.688 186.289 675.651 185.241 674.357 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M674.357 191.698C673.073 191.698 672.015 192.745 672.015 194.028C672.015 195.31 673.063 196.358 674.357 196.358C675.64 196.358 676.688 195.31 676.688 194.028C676.688 192.745 675.651 191.698 674.357 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M674.357 198.154C673.073 198.154 672.015 199.201 672.015 200.484C672.015 201.767 673.063 202.814 674.357 202.814C675.64 202.814 676.688 201.767 676.688 200.484C676.688 199.201 675.651 198.154 674.357 198.154Z"
            fill="#ADADAD"
          />
          <path
            d="M674.357 204.598C673.073 204.598 672.015 205.646 672.015 206.928C672.015 208.211 673.063 209.258 674.357 209.258C675.64 209.258 676.688 208.211 676.688 206.928C676.688 205.646 675.651 204.598 674.357 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M674.357 211.054C673.073 211.054 672.015 212.102 672.015 213.395C672.015 214.678 673.063 215.725 674.357 215.725C675.64 215.725 676.688 214.678 676.688 213.395C676.688 212.102 675.651 211.054 674.357 211.054Z"
            fill="#ADADAD"
          />
          <path
            d="M674.357 217.51C673.073 217.51 672.015 218.558 672.015 219.841C672.015 221.123 673.063 222.181 674.357 222.181C675.64 222.181 676.688 221.134 676.688 219.841C676.688 218.558 675.651 217.51 674.357 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M674.357 223.967C673.073 223.967 672.015 225.014 672.015 226.297C672.015 227.579 673.063 228.627 674.357 228.627C675.64 228.627 676.688 227.579 676.688 226.297C676.688 225.004 675.651 223.967 674.357 223.967Z"
            fill="#ADADAD"
          />
          <path
            d="M674.357 307.851C673.073 307.851 672.015 308.899 672.015 310.192C672.015 311.475 673.063 312.522 674.357 312.522C675.64 312.522 676.688 311.475 676.688 310.192C676.688 308.899 675.651 307.851 674.357 307.851Z"
            fill="#ADADAD"
          />
          <path
            d="M674.357 314.295C673.073 314.295 672.015 315.343 672.015 316.626C672.015 317.908 673.063 318.956 674.357 318.956C675.64 318.956 676.688 317.908 676.688 316.626C676.688 315.343 675.651 314.295 674.357 314.295Z"
            fill="#ADADAD"
          />
          <path
            d="M674.357 320.752C673.073 320.752 672.015 321.799 672.015 323.082C672.015 324.364 673.063 325.412 674.357 325.412C675.64 325.412 676.688 324.364 676.688 323.082C676.688 321.799 675.651 320.752 674.357 320.752Z"
            fill="#ADADAD"
          />
          <path
            d="M674.357 340.11C673.073 340.11 672.015 341.157 672.015 342.44C672.015 343.723 673.063 344.77 674.357 344.77C675.64 344.77 676.688 343.723 676.688 342.44C676.688 341.157 675.651 340.11 674.357 340.11Z"
            fill="#ADADAD"
          />
          <path
            d="M674.357 346.565C673.073 346.565 672.015 347.612 672.015 348.895C672.015 350.177 673.063 351.225 674.357 351.225C675.64 351.225 676.688 350.177 676.688 348.895C676.688 347.612 675.651 346.565 674.357 346.565Z"
            fill="#ADADAD"
          />
          <path
            d="M674.357 353.021C673.073 353.021 672.015 354.068 672.015 355.362C672.015 356.644 673.063 357.692 674.357 357.692C675.64 357.692 676.688 356.644 676.688 355.362C676.688 354.068 675.651 353.021 674.357 353.021Z"
            fill="#ADADAD"
          />
          <path
            d="M674.357 359.477C673.073 359.477 672.015 360.524 672.015 361.807C672.015 363.09 673.063 364.137 674.357 364.137C675.64 364.137 676.688 363.09 676.688 361.807C676.688 360.514 675.651 359.477 674.357 359.477Z"
            fill="#ADADAD"
          />
          <path
            d="M674.357 365.923C673.073 365.923 672.015 366.97 672.015 368.264C672.015 369.546 673.063 370.594 674.357 370.594C675.64 370.594 676.688 369.546 676.688 368.264C676.688 366.97 675.651 365.923 674.357 365.923Z"
            fill="#ADADAD"
          />
          <path
            d="M674.357 372.377C673.073 372.377 672.015 373.425 672.015 374.708C672.015 375.99 673.063 377.038 674.357 377.038C675.64 377.038 676.688 375.99 676.688 374.708C676.688 373.425 675.651 372.377 674.357 372.377Z"
            fill="#ADADAD"
          />
          <path
            d="M674.357 378.834C673.073 378.834 672.015 379.881 672.015 381.164C672.015 382.446 673.063 383.494 674.357 383.494C675.64 383.494 676.688 382.446 676.688 381.164C676.688 379.881 675.651 378.834 674.357 378.834Z"
            fill="#ADADAD"
          />
          <path
            d="M674.357 385.279C673.073 385.279 672.015 386.327 672.015 387.609C672.015 388.892 673.063 389.95 674.357 389.95C675.64 389.95 676.688 388.903 676.688 387.609C676.688 386.327 675.651 385.279 674.357 385.279Z"
            fill="#ADADAD"
          />
          <path
            d="M667.895 165.885C666.612 165.885 665.564 166.932 665.564 168.215C665.564 169.497 666.612 170.545 667.895 170.545C669.179 170.545 670.227 169.497 670.227 168.215C670.227 166.932 669.179 165.885 667.895 165.885Z"
            fill="#ADADAD"
          />
          <path
            d="M667.895 172.339C666.612 172.339 665.564 173.387 665.564 174.68C665.564 175.963 666.612 177.01 667.895 177.01C669.179 177.01 670.227 175.963 670.227 174.68C670.227 173.387 669.179 172.339 667.895 172.339Z"
            fill="#ADADAD"
          />
          <path
            d="M667.895 178.785C666.612 178.785 665.564 179.833 665.564 181.115C665.564 182.398 666.612 183.445 667.895 183.445C669.179 183.445 670.227 182.398 670.227 181.115C670.227 179.833 669.179 178.785 667.895 178.785Z"
            fill="#ADADAD"
          />
          <path
            d="M667.895 185.241C666.612 185.241 665.564 186.289 665.564 187.572C665.564 188.865 666.612 189.912 667.895 189.912C669.179 189.912 670.227 188.865 670.227 187.572C670.227 186.289 669.179 185.241 667.895 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M667.895 191.698C666.612 191.698 665.564 192.745 665.564 194.028C665.564 195.31 666.612 196.358 667.895 196.358C669.179 196.358 670.227 195.31 670.227 194.028C670.227 192.745 669.179 191.698 667.895 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M667.895 198.154C666.612 198.154 665.564 199.201 665.564 200.484C665.564 201.767 666.612 202.814 667.895 202.814C669.179 202.814 670.227 201.767 670.227 200.484C670.227 199.201 669.179 198.154 667.895 198.154Z"
            fill="#ADADAD"
          />
          <path
            d="M667.895 217.51C666.612 217.51 665.564 218.558 665.564 219.841C665.564 221.123 666.612 222.181 667.895 222.181C669.179 222.181 670.227 221.134 670.227 219.841C670.227 218.558 669.179 217.51 667.895 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M667.895 223.967C666.612 223.967 665.564 225.014 665.564 226.297C665.564 227.579 666.612 228.627 667.895 228.627C669.179 228.627 670.227 227.579 670.227 226.297C670.227 225.004 669.179 223.967 667.895 223.967Z"
            fill="#ADADAD"
          />
          <path
            d="M667.895 307.851C666.612 307.851 665.564 308.899 665.564 310.192C665.564 311.475 666.612 312.522 667.895 312.522C669.179 312.522 670.227 311.475 670.227 310.192C670.227 308.899 669.179 307.851 667.895 307.851Z"
            fill="#ADADAD"
          />
          <path
            d="M667.895 314.295C666.612 314.295 665.564 315.343 665.564 316.626C665.564 317.908 666.612 318.956 667.895 318.956C669.179 318.956 670.227 317.908 670.227 316.626C670.227 315.343 669.179 314.295 667.895 314.295Z"
            fill="#ADADAD"
          />
          <path
            d="M667.895 320.752C666.612 320.752 665.564 321.799 665.564 323.082C665.564 324.364 666.612 325.412 667.895 325.412C669.179 325.412 670.227 324.364 670.227 323.082C670.227 321.799 669.179 320.752 667.895 320.752Z"
            fill="#ADADAD"
          />
          <path
            d="M667.895 340.11C666.612 340.11 665.564 341.157 665.564 342.44C665.564 343.723 666.612 344.77 667.895 344.77C669.179 344.77 670.227 343.723 670.227 342.44C670.227 341.157 669.179 340.11 667.895 340.11Z"
            fill="#ADADAD"
          />
          <path
            d="M667.895 346.565C666.612 346.565 665.564 347.612 665.564 348.895C665.564 350.177 666.612 351.225 667.895 351.225C669.179 351.225 670.227 350.177 670.227 348.895C670.227 347.612 669.179 346.565 667.895 346.565Z"
            fill="#ADADAD"
          />
          <path
            d="M667.895 353.021C666.612 353.021 665.564 354.068 665.564 355.362C665.564 356.644 666.612 357.692 667.895 357.692C669.179 357.692 670.227 356.644 670.227 355.362C670.227 354.068 669.179 353.021 667.895 353.021Z"
            fill="#ADADAD"
          />
          <path
            d="M667.895 359.477C666.612 359.477 665.564 360.524 665.564 361.807C665.564 363.09 666.612 364.137 667.895 364.137C669.179 364.137 670.227 363.09 670.227 361.807C670.227 360.514 669.179 359.477 667.895 359.477Z"
            fill="#ADADAD"
          />
          <path
            d="M667.895 365.923C666.612 365.923 665.564 366.97 665.564 368.264C665.564 369.546 666.612 370.594 667.895 370.594C669.179 370.594 670.227 369.546 670.227 368.264C670.227 366.97 669.179 365.923 667.895 365.923Z"
            fill="#ADADAD"
          />
          <path
            d="M667.895 372.377C666.612 372.377 665.564 373.425 665.564 374.708C665.564 375.99 666.612 377.038 667.895 377.038C669.179 377.038 670.227 375.99 670.227 374.708C670.227 373.425 669.179 372.377 667.895 372.377Z"
            fill="#ADADAD"
          />
          <path
            d="M667.895 378.834C666.612 378.834 665.564 379.881 665.564 381.164C665.564 382.446 666.612 383.494 667.895 383.494C669.179 383.494 670.227 382.446 670.227 381.164C670.227 379.881 669.179 378.834 667.895 378.834Z"
            fill="#ADADAD"
          />
          <path
            d="M661.448 165.884C660.164 165.884 659.116 166.932 659.116 168.215C659.116 169.497 660.164 170.545 661.448 170.545C662.731 170.545 663.779 169.497 663.779 168.215C663.779 166.932 662.731 165.884 661.448 165.884Z"
            fill="#ADADAD"
          />
          <path
            d="M661.448 172.34C660.164 172.34 659.116 173.388 659.116 174.681C659.116 175.963 660.164 177.011 661.448 177.011C662.731 177.011 663.779 175.963 663.779 174.681C663.779 173.388 662.731 172.34 661.448 172.34Z"
            fill="#ADADAD"
          />
          <path
            d="M661.448 178.786C660.164 178.786 659.116 179.833 659.116 181.116C659.116 182.398 660.164 183.446 661.448 183.446C662.731 183.446 663.779 182.398 663.779 181.116C663.779 179.833 662.731 178.786 661.448 178.786Z"
            fill="#ADADAD"
          />
          <path
            d="M661.448 185.241C660.164 185.241 659.116 186.289 659.116 187.571C659.116 188.865 660.164 189.912 661.448 189.912C662.731 189.912 663.779 188.865 663.779 187.571C663.779 186.289 662.731 185.241 661.448 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M661.448 191.698C660.164 191.698 659.116 192.745 659.116 194.028C659.116 195.31 660.164 196.358 661.448 196.358C662.731 196.358 663.779 195.31 663.779 194.028C663.779 192.745 662.731 191.698 661.448 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M661.448 198.153C660.164 198.153 659.116 199.201 659.116 200.483C659.116 201.766 660.164 202.813 661.448 202.813C662.731 202.813 663.779 201.766 663.779 200.483C663.779 199.201 662.731 198.153 661.448 198.153Z"
            fill="#ADADAD"
          />
          <path
            d="M661.448 204.598C660.164 204.598 659.116 205.646 659.116 206.928C659.116 208.211 660.164 209.258 661.448 209.258C662.731 209.258 663.779 208.211 663.779 206.928C663.779 205.646 662.731 204.598 661.448 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M661.448 223.966C660.164 223.966 659.116 225.014 659.116 226.297C659.116 227.579 660.164 228.627 661.448 228.627C662.731 228.627 663.779 227.579 663.779 226.297C663.779 225.003 662.731 223.966 661.448 223.966Z"
            fill="#ADADAD"
          />
          <path
            d="M661.448 307.851C660.164 307.851 659.116 308.898 659.116 310.192C659.116 311.474 660.164 312.522 661.448 312.522C662.731 312.522 663.779 311.474 663.779 310.192C663.779 308.898 662.731 307.851 661.448 307.851Z"
            fill="#ADADAD"
          />
          <path
            d="M661.448 314.296C660.164 314.296 659.116 315.343 659.116 316.626C659.116 317.909 660.164 318.956 661.448 318.956C662.731 318.956 663.779 317.909 663.779 316.626C663.779 315.343 662.731 314.296 661.448 314.296Z"
            fill="#ADADAD"
          />
          <path
            d="M661.448 320.752C660.164 320.752 659.116 321.799 659.116 323.082C659.116 324.364 660.164 325.412 661.448 325.412C662.731 325.412 663.779 324.364 663.779 323.082C663.779 321.799 662.731 320.752 661.448 320.752Z"
            fill="#ADADAD"
          />
          <path
            d="M661.448 338.324C662.735 338.324 663.779 337.281 663.779 335.994C663.779 334.707 662.735 333.664 661.448 333.664C660.16 333.664 659.116 334.707 659.116 335.994C659.116 337.281 660.16 338.324 661.448 338.324Z"
            fill="#ADADAD"
          />
          <path
            d="M661.448 340.109C660.164 340.109 659.116 341.157 659.116 342.439C659.116 343.722 660.164 344.769 661.448 344.769C662.731 344.769 663.779 343.722 663.779 342.439C663.779 341.157 662.731 340.109 661.448 340.109Z"
            fill="#ADADAD"
          />
          <path
            d="M661.448 346.565C660.164 346.565 659.116 347.612 659.116 348.895C659.116 350.178 660.164 351.225 661.448 351.225C662.731 351.225 663.779 350.178 663.779 348.895C663.779 347.612 662.731 346.565 661.448 346.565Z"
            fill="#ADADAD"
          />
          <path
            d="M661.448 353.021C660.164 353.021 659.116 354.069 659.116 355.362C659.116 356.645 660.164 357.692 661.448 357.692C662.731 357.692 663.779 356.645 663.779 355.362C663.779 354.069 662.731 353.021 661.448 353.021Z"
            fill="#ADADAD"
          />
          <path
            d="M661.448 359.477C660.164 359.477 659.116 360.524 659.116 361.807C659.116 363.09 660.164 364.137 661.448 364.137C662.731 364.137 663.779 363.09 663.779 361.807C663.779 360.514 662.731 359.477 661.448 359.477Z"
            fill="#ADADAD"
          />
          <path
            d="M661.448 365.922C660.164 365.922 659.116 366.97 659.116 368.263C659.116 369.546 660.164 370.593 661.448 370.593C662.731 370.593 663.779 369.546 663.779 368.263C663.779 366.97 662.731 365.922 661.448 365.922Z"
            fill="#ADADAD"
          />
          <path
            d="M661.448 372.378C660.164 372.378 659.116 373.425 659.116 374.708C659.116 375.991 660.164 377.038 661.448 377.038C662.731 377.038 663.779 375.991 663.779 374.708C663.779 373.425 662.731 372.378 661.448 372.378Z"
            fill="#ADADAD"
          />
          <path
            d="M661.448 383.494C662.735 383.494 663.779 382.451 663.779 381.164C663.779 379.877 662.735 378.834 661.448 378.834C660.16 378.834 659.116 379.877 659.116 381.164C659.116 382.451 660.16 383.494 661.448 383.494Z"
            fill="#ADADAD"
          />
          <path
            d="M654.988 165.884C653.694 165.884 652.646 166.932 652.646 168.215C652.646 169.497 653.694 170.545 654.988 170.545C656.271 170.545 657.319 169.497 657.319 168.215C657.319 166.932 656.271 165.884 654.988 165.884Z"
            fill="#ADADAD"
          />
          <path
            d="M654.988 172.34C653.694 172.34 652.646 173.388 652.646 174.681C652.646 175.963 653.694 177.011 654.988 177.011C656.271 177.011 657.319 175.963 657.319 174.681C657.319 173.388 656.271 172.34 654.988 172.34Z"
            fill="#ADADAD"
          />
          <path
            d="M654.988 178.786C653.694 178.786 652.646 179.833 652.646 181.116C652.646 182.398 653.694 183.446 654.988 183.446C656.271 183.446 657.319 182.398 657.319 181.116C657.319 179.833 656.271 178.786 654.988 178.786Z"
            fill="#ADADAD"
          />
          <path
            d="M654.988 185.241C653.694 185.241 652.646 186.289 652.646 187.571C652.646 188.865 653.694 189.912 654.988 189.912C656.271 189.912 657.319 188.865 657.319 187.571C657.319 186.289 656.271 185.241 654.988 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M654.988 191.698C653.694 191.698 652.646 192.745 652.646 194.028C652.646 195.31 653.694 196.358 654.988 196.358C656.271 196.358 657.319 195.31 657.319 194.028C657.319 192.745 656.271 191.698 654.988 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M654.988 198.153C653.694 198.153 652.646 199.201 652.646 200.483C652.646 201.766 653.694 202.813 654.988 202.813C656.271 202.813 657.319 201.766 657.319 200.483C657.319 199.201 656.271 198.153 654.988 198.153Z"
            fill="#ADADAD"
          />
          <path
            d="M654.988 204.598C653.694 204.598 652.646 205.646 652.646 206.928C652.646 208.211 653.694 209.258 654.988 209.258C656.271 209.258 657.319 208.211 657.319 206.928C657.319 205.646 656.271 204.598 654.988 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M654.988 223.966C653.694 223.966 652.646 225.014 652.646 226.297C652.646 227.579 653.694 228.627 654.988 228.627C656.271 228.627 657.319 227.579 657.319 226.297C657.319 225.003 656.271 223.966 654.988 223.966Z"
            fill="#ADADAD"
          />
          <path
            d="M654.988 230.411C653.694 230.411 652.646 231.459 652.646 232.752C652.646 234.035 653.694 235.082 654.988 235.082C656.271 235.082 657.319 234.035 657.319 232.752C657.319 231.459 656.271 230.411 654.988 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M654.988 236.868C653.694 236.868 652.646 237.915 652.646 239.208C652.646 240.491 653.694 241.539 654.988 241.539C656.271 241.539 657.319 240.491 657.319 239.208C657.319 237.915 656.271 236.868 654.988 236.868Z"
            fill="#ADADAD"
          />
          <path
            d="M654.988 307.851C653.694 307.851 652.646 308.898 652.646 310.192C652.646 311.474 653.694 312.522 654.988 312.522C656.271 312.522 657.319 311.474 657.319 310.192C657.319 308.898 656.271 307.851 654.988 307.851Z"
            fill="#ADADAD"
          />
          <path
            d="M654.988 320.752C653.694 320.752 652.646 321.799 652.646 323.082C652.646 324.364 653.694 325.412 654.988 325.412C656.271 325.412 657.319 324.364 657.319 323.082C657.319 321.799 656.271 320.752 654.988 320.752Z"
            fill="#ADADAD"
          />
          <path
            d="M654.988 327.208C653.694 327.208 652.646 328.255 652.646 329.538C652.646 330.821 653.694 331.868 654.988 331.868C656.271 331.868 657.319 330.821 657.319 329.538C657.319 328.255 656.271 327.208 654.988 327.208Z"
            fill="#ADADAD"
          />
          <path
            d="M654.988 333.653C653.694 333.653 652.646 334.7 652.646 335.983C652.646 337.266 653.694 338.313 654.988 338.313C656.271 338.313 657.319 337.266 657.319 335.983C657.319 334.7 656.271 333.653 654.988 333.653Z"
            fill="#ADADAD"
          />
          <path
            d="M654.988 340.109C653.694 340.109 652.646 341.157 652.646 342.439C652.646 343.722 653.694 344.769 654.988 344.769C656.271 344.769 657.319 343.722 657.319 342.439C657.319 341.157 656.271 340.109 654.988 340.109Z"
            fill="#ADADAD"
          />
          <path
            d="M654.988 346.565C653.694 346.565 652.646 347.612 652.646 348.895C652.646 350.178 653.694 351.225 654.988 351.225C656.271 351.225 657.319 350.178 657.319 348.895C657.319 347.612 656.271 346.565 654.988 346.565Z"
            fill="#ADADAD"
          />
          <path
            d="M654.988 353.021C653.694 353.021 652.646 354.069 652.646 355.362C652.646 356.645 653.694 357.692 654.988 357.692C656.271 357.692 657.319 356.645 657.319 355.362C657.319 354.069 656.271 353.021 654.988 353.021Z"
            fill="#ADADAD"
          />
          <path
            d="M654.988 359.477C653.694 359.477 652.646 360.524 652.646 361.807C652.646 363.09 653.694 364.137 654.988 364.137C656.271 364.137 657.319 363.09 657.319 361.807C657.319 360.514 656.271 359.477 654.988 359.477Z"
            fill="#ADADAD"
          />
          <path
            d="M654.988 365.922C653.694 365.922 652.646 366.97 652.646 368.263C652.646 369.546 653.694 370.593 654.988 370.593C656.271 370.593 657.319 369.546 657.319 368.263C657.319 366.97 656.271 365.922 654.988 365.922Z"
            fill="#ADADAD"
          />
          <path
            d="M654.988 372.378C653.694 372.378 652.646 373.425 652.646 374.708C652.646 375.991 653.694 377.038 654.988 377.038C656.271 377.038 657.319 375.991 657.319 374.708C657.319 373.425 656.271 372.378 654.988 372.378Z"
            fill="#ADADAD"
          />
          <path
            d="M648.529 165.884C647.246 165.884 646.197 166.932 646.197 168.215C646.197 169.497 647.246 170.545 648.529 170.545C649.812 170.545 650.86 169.497 650.86 168.215C650.86 166.932 649.823 165.884 648.529 165.884Z"
            fill="#ADADAD"
          />
          <path
            d="M648.529 172.34C647.246 172.34 646.197 173.388 646.197 174.681C646.197 175.963 647.246 177.011 648.529 177.011C649.812 177.011 650.86 175.963 650.86 174.681C650.86 173.388 649.823 172.34 648.529 172.34Z"
            fill="#ADADAD"
          />
          <path
            d="M648.529 178.786C647.246 178.786 646.197 179.833 646.197 181.116C646.197 182.398 647.246 183.446 648.529 183.446C649.812 183.446 650.86 182.398 650.86 181.116C650.86 179.833 649.823 178.786 648.529 178.786Z"
            fill="#ADADAD"
          />
          <path
            d="M648.529 185.241C647.246 185.241 646.197 186.289 646.197 187.571C646.197 188.865 647.246 189.912 648.529 189.912C649.812 189.912 650.86 188.865 650.86 187.571C650.86 186.289 649.823 185.241 648.529 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M648.529 191.698C647.246 191.698 646.197 192.745 646.197 194.028C646.197 195.31 647.246 196.358 648.529 196.358C649.812 196.358 650.86 195.31 650.86 194.028C650.86 192.745 649.823 191.698 648.529 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M648.529 198.153C647.246 198.153 646.197 199.201 646.197 200.483C646.197 201.766 647.246 202.813 648.529 202.813C649.812 202.813 650.86 201.766 650.86 200.483C650.86 199.201 649.823 198.153 648.529 198.153Z"
            fill="#ADADAD"
          />
          <path
            d="M648.529 204.598C647.246 204.598 646.197 205.646 646.197 206.928C646.197 208.211 647.246 209.258 648.529 209.258C649.812 209.258 650.86 208.211 650.86 206.928C650.86 205.646 649.823 204.598 648.529 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M648.529 211.055C647.246 211.055 646.197 212.102 646.197 213.395C646.197 214.678 647.246 215.725 648.529 215.725C649.812 215.725 650.86 214.678 650.86 213.395C650.86 212.102 649.823 211.055 648.529 211.055Z"
            fill="#ADADAD"
          />
          <path
            d="M648.529 217.51C647.246 217.51 646.197 218.558 646.197 219.84C646.197 221.123 647.246 222.181 648.529 222.181C649.812 222.181 650.86 221.134 650.86 219.84C650.86 218.558 649.823 217.51 648.529 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M648.529 223.966C647.246 223.966 646.197 225.014 646.197 226.297C646.197 227.579 647.246 228.627 648.529 228.627C649.812 228.627 650.86 227.579 650.86 226.297C650.86 225.003 649.823 223.966 648.529 223.966Z"
            fill="#ADADAD"
          />
          <path
            d="M648.529 243.313C647.246 243.313 646.197 244.36 646.197 245.654C646.197 246.936 647.246 247.984 648.529 247.984C649.812 247.984 650.86 246.936 650.86 245.654C650.86 244.36 649.823 243.313 648.529 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M648.529 301.395C647.246 301.395 646.197 302.442 646.197 303.725C646.197 305.008 647.246 306.055 648.529 306.055C649.812 306.055 650.86 305.008 650.86 303.725C650.86 302.442 649.823 301.395 648.529 301.395Z"
            fill="#ADADAD"
          />
          <path
            d="M648.529 307.851C647.246 307.851 646.197 308.898 646.197 310.192C646.197 311.474 647.246 312.522 648.529 312.522C649.812 312.522 650.86 311.474 650.86 310.192C650.86 308.898 649.823 307.851 648.529 307.851Z"
            fill="#ADADAD"
          />
          <path
            d="M648.529 333.653C647.246 333.653 646.197 334.7 646.197 335.983C646.197 337.266 647.246 338.313 648.529 338.313C649.812 338.313 650.86 337.266 650.86 335.983C650.86 334.7 649.823 333.653 648.529 333.653Z"
            fill="#ADADAD"
          />
          <path
            d="M648.529 340.109C647.246 340.109 646.197 341.157 646.197 342.439C646.197 343.722 647.246 344.769 648.529 344.769C649.812 344.769 650.86 343.722 650.86 342.439C650.86 341.157 649.823 340.109 648.529 340.109Z"
            fill="#ADADAD"
          />
          <path
            d="M648.529 346.565C647.246 346.565 646.197 347.612 646.197 348.895C646.197 350.178 647.246 351.225 648.529 351.225C649.812 351.225 650.86 350.178 650.86 348.895C650.86 347.612 649.823 346.565 648.529 346.565Z"
            fill="#ADADAD"
          />
          <path
            d="M648.529 353.021C647.246 353.021 646.197 354.069 646.197 355.362C646.197 356.645 647.246 357.692 648.529 357.692C649.812 357.692 650.86 356.645 650.86 355.362C650.86 354.069 649.823 353.021 648.529 353.021Z"
            fill="#ADADAD"
          />
          <path
            d="M648.529 359.477C647.246 359.477 646.197 360.524 646.197 361.807C646.197 363.09 647.246 364.137 648.529 364.137C649.812 364.137 650.86 363.09 650.86 361.807C650.86 360.514 649.823 359.477 648.529 359.477Z"
            fill="#ADADAD"
          />
          <path
            d="M648.529 365.922C647.246 365.922 646.197 366.97 646.197 368.263C646.197 369.546 647.246 370.593 648.529 370.593C649.812 370.593 650.86 369.546 650.86 368.263C650.86 366.97 649.823 365.922 648.529 365.922Z"
            fill="#ADADAD"
          />
          <path
            d="M648.529 372.378C647.246 372.378 646.197 373.425 646.197 374.708C646.197 375.991 647.246 377.038 648.529 377.038C649.812 377.038 650.86 375.991 650.86 374.708C650.86 373.425 649.823 372.378 648.529 372.378Z"
            fill="#ADADAD"
          />
          <path
            d="M642.07 165.885C640.786 165.885 639.738 166.932 639.738 168.215C639.738 169.497 640.786 170.545 642.07 170.545C643.353 170.545 644.401 169.497 644.401 168.215C644.412 166.932 643.364 165.885 642.07 165.885Z"
            fill="#ADADAD"
          />
          <path
            d="M642.07 172.339C640.786 172.339 639.738 173.387 639.738 174.68C639.738 175.963 640.786 177.01 642.07 177.01C643.353 177.01 644.401 175.963 644.401 174.68C644.412 173.387 643.364 172.339 642.07 172.339Z"
            fill="#ADADAD"
          />
          <path
            d="M642.07 178.785C640.786 178.785 639.738 179.833 639.738 181.115C639.738 182.398 640.786 183.445 642.07 183.445C643.353 183.445 644.401 182.398 644.401 181.115C644.412 179.833 643.364 178.785 642.07 178.785Z"
            fill="#ADADAD"
          />
          <path
            d="M642.07 185.241C640.786 185.241 639.738 186.289 639.738 187.572C639.738 188.865 640.786 189.912 642.07 189.912C643.353 189.912 644.401 188.865 644.401 187.572C644.412 186.289 643.364 185.241 642.07 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M642.07 191.698C640.786 191.698 639.738 192.745 639.738 194.028C639.738 195.31 640.786 196.358 642.07 196.358C643.353 196.358 644.401 195.31 644.401 194.028C644.412 192.745 643.364 191.698 642.07 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M642.07 198.154C640.786 198.154 639.738 199.201 639.738 200.484C639.738 201.767 640.786 202.814 642.07 202.814C643.353 202.814 644.401 201.767 644.401 200.484C644.412 199.201 643.364 198.154 642.07 198.154Z"
            fill="#ADADAD"
          />
          <path
            d="M642.07 204.598C640.786 204.598 639.738 205.646 639.738 206.928C639.738 208.211 640.786 209.258 642.07 209.258C643.353 209.258 644.401 208.211 644.401 206.928C644.412 205.646 643.364 204.598 642.07 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M642.07 211.054C640.786 211.054 639.738 212.102 639.738 213.395C639.738 214.678 640.786 215.725 642.07 215.725C643.353 215.725 644.401 214.678 644.401 213.395C644.412 212.102 643.364 211.054 642.07 211.054Z"
            fill="#ADADAD"
          />
          <path
            d="M642.07 217.51C640.786 217.51 639.738 218.558 639.738 219.841C639.738 221.123 640.786 222.181 642.07 222.181C643.353 222.181 644.401 221.134 644.401 219.841C644.412 218.558 643.364 217.51 642.07 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M642.07 275.582C640.786 275.582 639.738 276.63 639.738 277.923C639.738 279.206 640.786 280.253 642.07 280.253C643.353 280.253 644.401 279.206 644.401 277.923C644.412 276.63 643.364 275.582 642.07 275.582Z"
            fill="#ADADAD"
          />
          <path
            d="M642.07 282.038C640.786 282.038 639.738 283.086 639.738 284.368C639.738 285.651 640.786 286.699 642.07 286.699C643.353 286.699 644.401 285.651 644.401 284.368C644.412 283.086 643.364 282.038 642.07 282.038Z"
            fill="#ADADAD"
          />
          <path
            d="M642.07 288.493C640.786 288.493 639.738 289.54 639.738 290.834C639.738 292.116 640.786 293.164 642.07 293.164C643.353 293.164 644.401 292.116 644.401 290.834C644.412 289.54 643.364 288.493 642.07 288.493Z"
            fill="#ADADAD"
          />
          <path
            d="M642.07 301.395C640.786 301.395 639.738 302.442 639.738 303.725C639.738 305.008 640.786 306.055 642.07 306.055C643.353 306.055 644.401 305.008 644.401 303.725C644.412 302.442 643.364 301.395 642.07 301.395Z"
            fill="#ADADAD"
          />
          <path
            d="M642.07 307.851C640.786 307.851 639.738 308.899 639.738 310.192C639.738 311.475 640.786 312.522 642.07 312.522C643.353 312.522 644.401 311.475 644.401 310.192C644.412 308.899 643.364 307.851 642.07 307.851Z"
            fill="#ADADAD"
          />
          <path
            d="M642.07 314.295C640.786 314.295 639.738 315.343 639.738 316.626C639.738 317.908 640.786 318.956 642.07 318.956C643.353 318.956 644.401 317.908 644.401 316.626C644.412 315.343 643.364 314.295 642.07 314.295Z"
            fill="#ADADAD"
          />
          <path
            d="M642.07 320.752C640.786 320.752 639.738 321.799 639.738 323.082C639.738 324.364 640.786 325.412 642.07 325.412C643.353 325.412 644.401 324.364 644.401 323.082C644.412 321.799 643.364 320.752 642.07 320.752Z"
            fill="#ADADAD"
          />
          <path
            d="M642.07 333.654C640.786 333.654 639.738 334.701 639.738 335.984C639.738 337.266 640.786 338.314 642.07 338.314C643.353 338.314 644.401 337.266 644.401 335.984C644.412 334.701 643.364 333.654 642.07 333.654Z"
            fill="#ADADAD"
          />
          <path
            d="M642.07 340.11C640.786 340.11 639.738 341.157 639.738 342.44C639.738 343.723 640.786 344.77 642.07 344.77C643.353 344.77 644.401 343.723 644.401 342.44C644.412 341.157 643.364 340.11 642.07 340.11Z"
            fill="#ADADAD"
          />
          <path
            d="M642.07 346.565C640.786 346.565 639.738 347.612 639.738 348.895C639.738 350.177 640.786 351.225 642.07 351.225C643.353 351.225 644.401 350.177 644.401 348.895C644.412 347.612 643.364 346.565 642.07 346.565Z"
            fill="#ADADAD"
          />
          <path
            d="M642.07 353.021C640.786 353.021 639.738 354.068 639.738 355.362C639.738 356.644 640.786 357.692 642.07 357.692C643.353 357.692 644.401 356.644 644.401 355.362C644.412 354.068 643.364 353.021 642.07 353.021Z"
            fill="#ADADAD"
          />
          <path
            d="M642.07 359.477C640.786 359.477 639.738 360.524 639.738 361.807C639.738 363.09 640.786 364.137 642.07 364.137C643.353 364.137 644.401 363.09 644.401 361.807C644.412 360.514 643.364 359.477 642.07 359.477Z"
            fill="#ADADAD"
          />
          <path
            d="M642.07 365.923C640.786 365.923 639.738 366.97 639.738 368.264C639.738 369.546 640.786 370.594 642.07 370.594C643.353 370.594 644.401 369.546 644.401 368.264C644.412 366.97 643.364 365.923 642.07 365.923Z"
            fill="#ADADAD"
          />
          <path
            d="M642.07 372.377C640.786 372.377 639.738 373.425 639.738 374.708C639.738 375.99 640.786 377.038 642.07 377.038C643.353 377.038 644.401 375.99 644.401 374.708C644.412 373.425 643.364 372.377 642.07 372.377Z"
            fill="#ADADAD"
          />
          <path
            d="M635.619 165.885C634.336 165.885 633.288 166.932 633.288 168.215C633.288 169.497 634.336 170.545 635.619 170.545C636.903 170.545 637.951 169.497 637.951 168.215C637.951 166.932 636.903 165.885 635.619 165.885Z"
            fill="#ADADAD"
          />
          <path
            d="M635.619 172.339C634.336 172.339 633.288 173.387 633.288 174.68C633.288 175.963 634.336 177.01 635.619 177.01C636.903 177.01 637.951 175.963 637.951 174.68C637.951 173.387 636.903 172.339 635.619 172.339Z"
            fill="#ADADAD"
          />
          <path
            d="M635.619 178.785C634.336 178.785 633.288 179.833 633.288 181.115C633.288 182.398 634.336 183.445 635.619 183.445C636.903 183.445 637.951 182.398 637.951 181.115C637.951 179.833 636.903 178.785 635.619 178.785Z"
            fill="#ADADAD"
          />
          <path
            d="M635.619 185.241C634.336 185.241 633.288 186.289 633.288 187.572C633.288 188.865 634.336 189.912 635.619 189.912C636.903 189.912 637.951 188.865 637.951 187.572C637.951 186.289 636.903 185.241 635.619 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M635.619 191.698C634.336 191.698 633.288 192.745 633.288 194.028C633.288 195.31 634.336 196.358 635.619 196.358C636.903 196.358 637.951 195.31 637.951 194.028C637.951 192.745 636.903 191.698 635.619 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M635.619 198.154C634.336 198.154 633.288 199.201 633.288 200.484C633.288 201.767 634.336 202.814 635.619 202.814C636.903 202.814 637.951 201.767 637.951 200.484C637.951 199.201 636.903 198.154 635.619 198.154Z"
            fill="#ADADAD"
          />
          <path
            d="M635.619 204.598C634.336 204.598 633.288 205.646 633.288 206.928C633.288 208.211 634.336 209.258 635.619 209.258C636.903 209.258 637.951 208.211 637.951 206.928C637.951 205.646 636.903 204.598 635.619 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M635.619 211.054C634.336 211.054 633.288 212.102 633.288 213.395C633.288 214.678 634.336 215.725 635.619 215.725C636.903 215.725 637.951 214.678 637.951 213.395C637.951 212.102 636.903 211.054 635.619 211.054Z"
            fill="#ADADAD"
          />
          <path
            d="M635.619 262.68C634.336 262.68 633.288 263.728 633.288 265.01C633.288 266.293 634.336 267.34 635.619 267.34C636.903 267.34 637.951 266.293 637.951 265.01C637.951 263.728 636.903 262.68 635.619 262.68Z"
            fill="#ADADAD"
          />
          <path
            d="M635.619 269.126C634.336 269.126 633.288 270.173 633.288 271.456C633.288 272.739 634.336 273.786 635.619 273.786C636.903 273.786 637.951 272.739 637.951 271.456C637.951 270.173 636.903 269.126 635.619 269.126Z"
            fill="#ADADAD"
          />
          <path
            d="M635.619 275.582C634.336 275.582 633.288 276.63 633.288 277.923C633.288 279.206 634.336 280.253 635.619 280.253C636.903 280.253 637.951 279.206 637.951 277.923C637.951 276.63 636.903 275.582 635.619 275.582Z"
            fill="#ADADAD"
          />
          <path
            d="M635.619 282.038C634.336 282.038 633.288 283.086 633.288 284.368C633.288 285.651 634.336 286.699 635.619 286.699C636.903 286.699 637.951 285.651 637.951 284.368C637.951 283.086 636.903 282.038 635.619 282.038Z"
            fill="#ADADAD"
          />
          <path
            d="M635.619 288.493C634.336 288.493 633.288 289.54 633.288 290.834C633.288 292.116 634.336 293.164 635.619 293.164C636.903 293.164 637.951 292.116 637.951 290.834C637.951 289.54 636.903 288.493 635.619 288.493Z"
            fill="#ADADAD"
          />
          <path
            d="M635.619 306.055C636.907 306.055 637.951 305.012 637.951 303.725C637.951 302.438 636.907 301.395 635.619 301.395C634.332 301.395 633.288 302.438 633.288 303.725C633.288 305.012 634.332 306.055 635.619 306.055Z"
            fill="#ADADAD"
          />
          <path
            d="M635.619 307.851C634.336 307.851 633.288 308.899 633.288 310.192C633.288 311.475 634.336 312.522 635.619 312.522C636.903 312.522 637.951 311.475 637.951 310.192C637.951 308.899 636.903 307.851 635.619 307.851Z"
            fill="#ADADAD"
          />
          <path
            d="M635.619 314.295C634.336 314.295 633.288 315.343 633.288 316.626C633.288 317.908 634.336 318.956 635.619 318.956C636.903 318.956 637.951 317.908 637.951 316.626C637.951 315.343 636.903 314.295 635.619 314.295Z"
            fill="#ADADAD"
          />
          <path
            d="M635.619 320.752C634.336 320.752 633.288 321.799 633.288 323.082C633.288 324.364 634.336 325.412 635.619 325.412C636.903 325.412 637.951 324.364 637.951 323.082C637.951 321.799 636.903 320.752 635.619 320.752Z"
            fill="#ADADAD"
          />
          <path
            d="M635.619 327.208C634.336 327.208 633.288 328.255 633.288 329.538C633.288 330.821 634.336 331.868 635.619 331.868C636.903 331.868 637.951 330.821 637.951 329.538C637.951 328.255 636.903 327.208 635.619 327.208Z"
            fill="#ADADAD"
          />
          <path
            d="M635.619 340.11C634.336 340.11 633.288 341.157 633.288 342.44C633.288 343.723 634.336 344.77 635.619 344.77C636.903 344.77 637.951 343.723 637.951 342.44C637.951 341.157 636.903 340.11 635.619 340.11Z"
            fill="#ADADAD"
          />
          <path
            d="M635.619 346.565C634.336 346.565 633.288 347.612 633.288 348.895C633.288 350.177 634.336 351.225 635.619 351.225C636.903 351.225 637.951 350.177 637.951 348.895C637.951 347.612 636.903 346.565 635.619 346.565Z"
            fill="#ADADAD"
          />
          <path
            d="M635.619 353.021C634.336 353.021 633.288 354.068 633.288 355.362C633.288 356.644 634.336 357.692 635.619 357.692C636.903 357.692 637.951 356.644 637.951 355.362C637.951 354.068 636.903 353.021 635.619 353.021Z"
            fill="#ADADAD"
          />
          <path
            d="M635.619 359.477C634.336 359.477 633.288 360.524 633.288 361.807C633.288 363.09 634.336 364.137 635.619 364.137C636.903 364.137 637.951 363.09 637.951 361.807C637.951 360.514 636.903 359.477 635.619 359.477Z"
            fill="#ADADAD"
          />
          <path
            d="M635.619 365.923C634.336 365.923 633.288 366.97 633.288 368.264C633.288 369.546 634.336 370.594 635.619 370.594C636.903 370.594 637.951 369.546 637.951 368.264C637.951 366.97 636.903 365.923 635.619 365.923Z"
            fill="#ADADAD"
          />
          <path
            d="M635.619 372.377C634.336 372.377 633.288 373.425 633.288 374.708C633.288 375.99 634.336 377.038 635.619 377.038C636.903 377.038 637.951 375.99 637.951 374.708C637.951 373.425 636.903 372.377 635.619 372.377Z"
            fill="#ADADAD"
          />
          <path
            d="M635.619 383.494C636.907 383.494 637.951 382.451 637.951 381.164C637.951 379.877 636.907 378.834 635.619 378.834C634.332 378.834 633.288 379.877 633.288 381.164C633.288 382.451 634.332 383.494 635.619 383.494Z"
            fill="#ADADAD"
          />
          <path
            d="M629.16 165.884C627.877 165.884 626.828 166.932 626.828 168.215C626.828 169.497 627.877 170.545 629.16 170.545C630.443 170.545 631.502 169.497 631.502 168.215C631.491 166.932 630.443 165.884 629.16 165.884Z"
            fill="#ADADAD"
          />
          <path
            d="M629.16 172.34C627.877 172.34 626.828 173.388 626.828 174.681C626.828 175.963 627.877 177.011 629.16 177.011C630.443 177.011 631.502 175.963 631.502 174.681C631.491 173.388 630.443 172.34 629.16 172.34Z"
            fill="#ADADAD"
          />
          <path
            d="M629.16 178.786C627.877 178.786 626.828 179.833 626.828 181.116C626.828 182.398 627.877 183.446 629.16 183.446C630.443 183.446 631.502 182.398 631.502 181.116C631.491 179.833 630.443 178.786 629.16 178.786Z"
            fill="#ADADAD"
          />
          <path
            d="M629.16 185.241C627.877 185.241 626.828 186.289 626.828 187.571C626.828 188.865 627.877 189.912 629.16 189.912C630.443 189.912 631.502 188.865 631.502 187.571C631.491 186.289 630.443 185.241 629.16 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M629.16 191.698C627.877 191.698 626.828 192.745 626.828 194.028C626.828 195.31 627.877 196.358 629.16 196.358C630.443 196.358 631.502 195.31 631.502 194.028C631.491 192.745 630.443 191.698 629.16 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M629.16 198.153C627.877 198.153 626.828 199.201 626.828 200.483C626.828 201.766 627.877 202.813 629.16 202.813C630.443 202.813 631.502 201.766 631.502 200.483C631.491 199.201 630.443 198.153 629.16 198.153Z"
            fill="#ADADAD"
          />
          <path
            d="M629.16 204.598C627.877 204.598 626.828 205.646 626.828 206.928C626.828 208.211 627.877 209.258 629.16 209.258C630.443 209.258 631.502 208.211 631.502 206.928C631.491 205.646 630.443 204.598 629.16 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M629.16 211.055C627.877 211.055 626.828 212.102 626.828 213.395C626.828 214.678 627.877 215.725 629.16 215.725C630.443 215.725 631.502 214.678 631.502 213.395C631.491 212.102 630.443 211.055 629.16 211.055Z"
            fill="#ADADAD"
          />
          <path
            d="M629.16 217.51C627.877 217.51 626.828 218.558 626.828 219.84C626.828 221.123 627.877 222.181 629.16 222.181C630.443 222.181 631.502 221.134 631.502 219.84C631.491 218.558 630.443 217.51 629.16 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M629.16 223.966C627.877 223.966 626.828 225.014 626.828 226.297C626.828 227.579 627.877 228.627 629.16 228.627C630.443 228.627 631.502 227.579 631.502 226.297C631.491 225.003 630.443 223.966 629.16 223.966Z"
            fill="#ADADAD"
          />
          <path
            d="M629.16 230.411C627.877 230.411 626.828 231.459 626.828 232.752C626.828 234.035 627.877 235.082 629.16 235.082C630.443 235.082 631.502 234.035 631.502 232.752C631.491 231.459 630.443 230.411 629.16 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M629.16 236.868C627.877 236.868 626.828 237.915 626.828 239.208C626.828 240.491 627.877 241.539 629.16 241.539C630.443 241.539 631.502 240.491 631.502 239.208C631.491 237.915 630.443 236.868 629.16 236.868Z"
            fill="#ADADAD"
          />
          <path
            d="M629.16 243.313C627.877 243.313 626.828 244.36 626.828 245.654C626.828 246.936 627.877 247.984 629.16 247.984C630.443 247.984 631.502 246.936 631.502 245.654C631.491 244.36 630.443 243.313 629.16 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M629.16 249.768C627.877 249.768 626.828 250.816 626.828 252.098C626.828 253.381 627.877 254.429 629.16 254.429C630.443 254.429 631.502 253.381 631.502 252.098C631.491 250.816 630.443 249.768 629.16 249.768Z"
            fill="#ADADAD"
          />
          <path
            d="M629.16 262.68C627.877 262.68 626.828 263.728 626.828 265.01C626.828 266.293 627.877 267.341 629.16 267.341C630.443 267.341 631.502 266.293 631.502 265.01C631.491 263.728 630.443 262.68 629.16 262.68Z"
            fill="#ADADAD"
          />
          <path
            d="M629.16 269.126C627.877 269.126 626.828 270.173 626.828 271.456C626.828 272.739 627.877 273.786 629.16 273.786C630.443 273.786 631.502 272.739 631.502 271.456C631.502 270.173 630.443 269.126 629.16 269.126Z"
            fill="#ADADAD"
          />
          <path
            d="M629.16 275.582C627.877 275.582 626.828 276.629 626.828 277.922C626.828 279.205 627.877 280.253 629.16 280.253C630.443 280.253 631.502 279.205 631.502 277.922C631.491 276.629 630.443 275.582 629.16 275.582Z"
            fill="#ADADAD"
          />
          <path
            d="M629.16 301.395C627.877 301.395 626.828 302.442 626.828 303.725C626.828 305.008 627.877 306.055 629.16 306.055C630.443 306.055 631.502 305.008 631.502 303.725C631.491 302.442 630.443 301.395 629.16 301.395Z"
            fill="#ADADAD"
          />
          <path
            d="M629.16 307.851C627.877 307.851 626.828 308.898 626.828 310.192C626.828 311.474 627.877 312.522 629.16 312.522C630.443 312.522 631.502 311.474 631.502 310.192C631.491 308.898 630.443 307.851 629.16 307.851Z"
            fill="#ADADAD"
          />
          <path
            d="M629.16 314.296C627.877 314.296 626.828 315.343 626.828 316.626C626.828 317.909 627.877 318.956 629.16 318.956C630.443 318.956 631.502 317.909 631.502 316.626C631.491 315.343 630.443 314.296 629.16 314.296Z"
            fill="#ADADAD"
          />
          <path
            d="M629.16 320.752C627.877 320.752 626.828 321.799 626.828 323.082C626.828 324.364 627.877 325.412 629.16 325.412C630.443 325.412 631.502 324.364 631.502 323.082C631.491 321.799 630.443 320.752 629.16 320.752Z"
            fill="#ADADAD"
          />
          <path
            d="M629.16 327.208C627.877 327.208 626.828 328.255 626.828 329.538C626.828 330.821 627.877 331.868 629.16 331.868C630.443 331.868 631.502 330.821 631.502 329.538C631.491 328.255 630.443 327.208 629.16 327.208Z"
            fill="#ADADAD"
          />
          <path
            d="M629.16 340.109C627.877 340.109 626.828 341.157 626.828 342.439C626.828 343.722 627.877 344.769 629.16 344.769C630.443 344.769 631.502 343.722 631.502 342.439C631.491 341.157 630.443 340.109 629.16 340.109Z"
            fill="#ADADAD"
          />
          <path
            d="M629.16 346.565C627.877 346.565 626.828 347.612 626.828 348.895C626.828 350.178 627.877 351.225 629.16 351.225C630.443 351.225 631.502 350.178 631.502 348.895C631.491 347.612 630.443 346.565 629.16 346.565Z"
            fill="#ADADAD"
          />
          <path
            d="M629.16 353.021C627.877 353.021 626.828 354.069 626.828 355.362C626.828 356.645 627.877 357.692 629.16 357.692C630.443 357.692 631.502 356.645 631.502 355.362C631.491 354.069 630.443 353.021 629.16 353.021Z"
            fill="#ADADAD"
          />
          <path
            d="M629.16 359.477C627.877 359.477 626.828 360.524 626.828 361.807C626.828 363.09 627.877 364.137 629.16 364.137C630.443 364.137 631.502 363.09 631.502 361.807C631.491 360.514 630.443 359.477 629.16 359.477Z"
            fill="#ADADAD"
          />
          <path
            d="M629.16 365.922C627.877 365.922 626.828 366.97 626.828 368.263C626.828 369.546 627.877 370.593 629.16 370.593C630.443 370.593 631.502 369.546 631.502 368.263C631.491 366.97 630.443 365.922 629.16 365.922Z"
            fill="#ADADAD"
          />
          <path
            d="M629.16 372.378C627.877 372.378 626.828 373.425 626.828 374.708C626.828 375.991 627.877 377.038 629.16 377.038C630.443 377.038 631.502 375.991 631.502 374.708C631.491 373.425 630.443 372.378 629.16 372.378Z"
            fill="#ADADAD"
          />
          <path
            d="M629.16 378.834C627.877 378.834 626.828 379.881 626.828 381.164C626.828 382.446 627.877 383.494 629.16 383.494C630.443 383.494 631.502 382.446 631.502 381.164C631.502 379.881 630.443 378.834 629.16 378.834Z"
            fill="#ADADAD"
          />
          <path
            d="M622.701 165.884C621.417 165.884 620.369 166.932 620.369 168.215C620.369 169.497 621.417 170.545 622.701 170.545C623.995 170.545 625.043 169.497 625.043 168.215C625.043 166.932 623.995 165.884 622.701 165.884Z"
            fill="#ADADAD"
          />
          <path
            d="M622.701 172.34C621.417 172.34 620.369 173.388 620.369 174.681C620.369 175.963 621.417 177.011 622.701 177.011C623.995 177.011 625.043 175.963 625.043 174.681C625.043 173.388 623.995 172.34 622.701 172.34Z"
            fill="#ADADAD"
          />
          <path
            d="M622.701 178.786C621.417 178.786 620.369 179.833 620.369 181.116C620.369 182.398 621.417 183.446 622.701 183.446C623.995 183.446 625.043 182.398 625.043 181.116C625.043 179.833 623.995 178.786 622.701 178.786Z"
            fill="#ADADAD"
          />
          <path
            d="M622.701 185.241C621.417 185.241 620.369 186.289 620.369 187.571C620.369 188.865 621.417 189.912 622.701 189.912C623.995 189.912 625.043 188.865 625.043 187.571C625.043 186.289 623.995 185.241 622.701 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M622.701 191.698C621.417 191.698 620.369 192.745 620.369 194.028C620.369 195.31 621.417 196.358 622.701 196.358C623.995 196.358 625.043 195.31 625.043 194.028C625.043 192.745 623.995 191.698 622.701 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M622.701 198.153C621.417 198.153 620.369 199.201 620.369 200.483C620.369 201.766 621.417 202.813 622.701 202.813C623.995 202.813 625.043 201.766 625.043 200.483C625.043 199.201 623.995 198.153 622.701 198.153Z"
            fill="#ADADAD"
          />
          <path
            d="M622.701 204.598C621.417 204.598 620.369 205.646 620.369 206.928C620.369 208.211 621.417 209.258 622.701 209.258C623.995 209.258 625.043 208.211 625.043 206.928C625.043 205.646 623.995 204.598 622.701 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M622.701 211.055C621.417 211.055 620.369 212.102 620.369 213.395C620.369 214.678 621.417 215.725 622.701 215.725C623.995 215.725 625.043 214.678 625.043 213.395C625.043 212.102 623.995 211.055 622.701 211.055Z"
            fill="#ADADAD"
          />
          <path
            d="M622.701 217.51C621.417 217.51 620.369 218.558 620.369 219.84C620.369 221.123 621.417 222.181 622.701 222.181C623.995 222.181 625.043 221.134 625.043 219.84C625.043 218.558 623.995 217.51 622.701 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M622.701 223.966C621.417 223.966 620.369 225.014 620.369 226.297C620.369 227.579 621.417 228.627 622.701 228.627C623.995 228.627 625.043 227.579 625.043 226.297C625.043 225.003 623.995 223.966 622.701 223.966Z"
            fill="#ADADAD"
          />
          <path
            d="M622.701 230.411C621.417 230.411 620.369 231.459 620.369 232.752C620.369 234.035 621.417 235.082 622.701 235.082C623.995 235.082 625.043 234.035 625.043 232.752C625.043 231.459 623.995 230.411 622.701 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M622.701 236.868C621.417 236.868 620.369 237.915 620.369 239.208C620.369 240.491 621.417 241.539 622.701 241.539C623.995 241.539 625.043 240.491 625.043 239.208C625.043 237.915 623.995 236.868 622.701 236.868Z"
            fill="#ADADAD"
          />
          <path
            d="M622.701 243.313C621.417 243.313 620.369 244.36 620.369 245.654C620.369 246.936 621.417 247.984 622.701 247.984C623.995 247.984 625.043 246.936 625.043 245.654C625.043 244.36 623.995 243.313 622.701 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M622.701 249.768C621.417 249.768 620.369 250.816 620.369 252.098C620.369 253.381 621.417 254.429 622.701 254.429C623.995 254.429 625.043 253.381 625.043 252.098C625.043 250.816 623.995 249.768 622.701 249.768Z"
            fill="#ADADAD"
          />
          <path
            d="M622.701 288.493C621.417 288.493 620.369 289.541 620.369 290.834C620.369 292.117 621.417 293.164 622.701 293.164C623.995 293.164 625.043 292.117 625.043 290.834C625.043 289.541 623.995 288.493 622.701 288.493Z"
            fill="#ADADAD"
          />
          <path
            d="M622.701 294.939C621.417 294.939 620.369 295.987 620.369 297.28C620.369 298.563 621.417 299.61 622.701 299.61C623.995 299.61 625.043 298.563 625.043 297.28C625.043 295.987 623.995 294.939 622.701 294.939Z"
            fill="#ADADAD"
          />
          <path
            d="M622.701 301.395C621.417 301.395 620.369 302.442 620.369 303.725C620.369 305.008 621.417 306.055 622.701 306.055C623.995 306.055 625.043 305.008 625.043 303.725C625.043 302.442 623.995 301.395 622.701 301.395Z"
            fill="#ADADAD"
          />
          <path
            d="M622.701 307.851C621.417 307.851 620.369 308.898 620.369 310.192C620.369 311.474 621.417 312.522 622.701 312.522C623.995 312.522 625.043 311.474 625.043 310.192C625.043 308.898 623.995 307.851 622.701 307.851Z"
            fill="#ADADAD"
          />
          <path
            d="M622.701 320.752C621.417 320.752 620.369 321.799 620.369 323.082C620.369 324.364 621.417 325.412 622.701 325.412C623.995 325.412 625.043 324.364 625.043 323.082C625.043 321.799 623.995 320.752 622.701 320.752Z"
            fill="#ADADAD"
          />
          <path
            d="M622.701 353.021C621.417 353.021 620.369 354.069 620.369 355.362C620.369 356.645 621.417 357.692 622.701 357.692C623.995 357.692 625.043 356.645 625.043 355.362C625.043 354.069 623.995 353.021 622.701 353.021Z"
            fill="#ADADAD"
          />
          <path
            d="M622.701 359.477C621.417 359.477 620.369 360.524 620.369 361.807C620.369 363.09 621.417 364.137 622.701 364.137C623.995 364.137 625.043 363.09 625.043 361.807C625.043 360.514 623.995 359.477 622.701 359.477Z"
            fill="#ADADAD"
          />
          <path
            d="M622.701 365.922C621.417 365.922 620.369 366.97 620.369 368.263C620.369 369.546 621.417 370.593 622.701 370.593C623.995 370.593 625.043 369.546 625.043 368.263C625.043 366.97 623.995 365.922 622.701 365.922Z"
            fill="#ADADAD"
          />
          <path
            d="M622.701 372.378C621.417 372.378 620.369 373.425 620.369 374.708C620.369 375.991 621.417 377.038 622.701 377.038C623.995 377.038 625.043 375.991 625.043 374.708C625.043 373.425 623.995 372.378 622.701 372.378Z"
            fill="#ADADAD"
          />
          <path
            d="M622.701 378.834C621.417 378.834 620.369 379.881 620.369 381.164C620.369 382.446 621.417 383.494 622.701 383.494C623.995 383.494 625.043 382.446 625.043 381.164C625.043 379.881 623.995 378.834 622.701 378.834Z"
            fill="#ADADAD"
          />
          <path
            d="M616.25 165.885C614.967 165.885 613.919 166.932 613.919 168.215C613.919 169.497 614.967 170.545 616.25 170.545C617.533 170.545 618.581 169.497 618.581 168.215C618.581 166.932 617.533 165.885 616.25 165.885Z"
            fill="#ADADAD"
          />
          <path
            d="M616.25 172.339C614.967 172.339 613.919 173.387 613.919 174.68C613.919 175.963 614.967 177.01 616.25 177.01C617.533 177.01 618.581 175.963 618.581 174.68C618.581 173.387 617.533 172.339 616.25 172.339Z"
            fill="#ADADAD"
          />
          <path
            d="M616.25 178.785C614.967 178.785 613.919 179.833 613.919 181.115C613.919 182.398 614.967 183.445 616.25 183.445C617.533 183.445 618.581 182.398 618.581 181.115C618.581 179.833 617.533 178.785 616.25 178.785Z"
            fill="#ADADAD"
          />
          <path
            d="M616.25 185.241C614.967 185.241 613.919 186.289 613.919 187.572C613.919 188.865 614.967 189.912 616.25 189.912C617.533 189.912 618.581 188.865 618.581 187.572C618.581 186.289 617.533 185.241 616.25 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M616.25 191.698C614.967 191.698 613.919 192.745 613.919 194.028C613.919 195.31 614.967 196.358 616.25 196.358C617.533 196.358 618.581 195.31 618.581 194.028C618.581 192.745 617.533 191.698 616.25 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M616.25 198.154C614.967 198.154 613.919 199.201 613.919 200.484C613.919 201.767 614.967 202.814 616.25 202.814C617.533 202.814 618.581 201.767 618.581 200.484C618.581 199.201 617.533 198.154 616.25 198.154Z"
            fill="#ADADAD"
          />
          <path
            d="M616.25 204.598C614.967 204.598 613.919 205.646 613.919 206.928C613.919 208.211 614.967 209.258 616.25 209.258C617.533 209.258 618.581 208.211 618.581 206.928C618.581 205.646 617.533 204.598 616.25 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M616.25 211.054C614.967 211.054 613.919 212.102 613.919 213.395C613.919 214.678 614.967 215.725 616.25 215.725C617.533 215.725 618.581 214.678 618.581 213.395C618.581 212.102 617.533 211.054 616.25 211.054Z"
            fill="#ADADAD"
          />
          <path
            d="M616.25 217.51C614.967 217.51 613.919 218.558 613.919 219.841C613.919 221.123 614.967 222.181 616.25 222.181C617.533 222.181 618.581 221.134 618.581 219.841C618.581 218.558 617.533 217.51 616.25 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M616.25 223.967C614.967 223.967 613.919 225.014 613.919 226.297C613.919 227.579 614.967 228.627 616.25 228.627C617.533 228.627 618.581 227.579 618.581 226.297C618.581 225.004 617.533 223.967 616.25 223.967Z"
            fill="#ADADAD"
          />
          <path
            d="M616.25 230.411C614.967 230.411 613.919 231.458 613.919 232.752C613.919 234.034 614.967 235.082 616.25 235.082C617.533 235.082 618.581 234.034 618.581 232.752C618.581 231.458 617.533 230.411 616.25 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M616.25 236.867C614.967 236.867 613.919 237.915 613.919 239.208C613.919 240.491 614.967 241.538 616.25 241.538C617.533 241.538 618.581 240.491 618.581 239.208C618.581 237.915 617.533 236.867 616.25 236.867Z"
            fill="#ADADAD"
          />
          <path
            d="M616.25 243.313C614.967 243.313 613.919 244.36 613.919 245.654C613.919 246.936 614.967 247.984 616.25 247.984C617.533 247.984 618.581 246.936 618.581 245.654C618.581 244.36 617.533 243.313 616.25 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M616.25 249.768C614.967 249.768 613.919 250.815 613.919 252.098C613.919 253.38 614.967 254.428 616.25 254.428C617.533 254.428 618.581 253.38 618.581 252.098C618.581 250.815 617.533 249.768 616.25 249.768Z"
            fill="#ADADAD"
          />
          <path
            d="M616.25 294.939C614.967 294.939 613.919 295.986 613.919 297.28C613.919 298.562 614.967 299.61 616.25 299.61C617.533 299.61 618.581 298.562 618.581 297.28C618.581 295.986 617.533 294.939 616.25 294.939Z"
            fill="#ADADAD"
          />
          <path
            d="M616.25 306.055C617.538 306.055 618.581 305.012 618.581 303.725C618.581 302.438 617.538 301.395 616.25 301.395C614.962 301.395 613.919 302.438 613.919 303.725C613.919 305.012 614.962 306.055 616.25 306.055Z"
            fill="#ADADAD"
          />
          <path
            d="M616.25 307.851C614.967 307.851 613.919 308.899 613.919 310.192C613.919 311.475 614.967 312.522 616.25 312.522C617.533 312.522 618.581 311.475 618.581 310.192C618.581 308.899 617.533 307.851 616.25 307.851Z"
            fill="#ADADAD"
          />
          <path
            d="M616.25 320.752C614.967 320.752 613.919 321.799 613.919 323.082C613.919 324.364 614.967 325.412 616.25 325.412C617.533 325.412 618.581 324.364 618.581 323.082C618.581 321.799 617.533 320.752 616.25 320.752Z"
            fill="#ADADAD"
          />
          <path
            d="M616.25 353.021C614.967 353.021 613.919 354.068 613.919 355.362C613.919 356.644 614.967 357.692 616.25 357.692C617.533 357.692 618.581 356.644 618.581 355.362C618.581 354.068 617.533 353.021 616.25 353.021Z"
            fill="#ADADAD"
          />
          <path
            d="M616.25 359.477C614.967 359.477 613.919 360.524 613.919 361.807C613.919 363.09 614.967 364.137 616.25 364.137C617.533 364.137 618.581 363.09 618.581 361.807C618.581 360.514 617.533 359.477 616.25 359.477Z"
            fill="#ADADAD"
          />
          <path
            d="M616.25 365.923C614.967 365.923 613.919 366.97 613.919 368.264C613.919 369.546 614.967 370.594 616.25 370.594C617.533 370.594 618.581 369.546 618.581 368.264C618.581 366.97 617.533 365.923 616.25 365.923Z"
            fill="#ADADAD"
          />
          <path
            d="M609.791 165.885C608.507 165.885 607.459 166.932 607.459 168.215C607.459 169.497 608.507 170.545 609.791 170.545C611.074 170.545 612.122 169.497 612.122 168.215C612.122 166.932 611.074 165.885 609.791 165.885Z"
            fill="#ADADAD"
          />
          <path
            d="M609.791 172.339C608.507 172.339 607.459 173.387 607.459 174.68C607.459 175.963 608.507 177.01 609.791 177.01C611.074 177.01 612.122 175.963 612.122 174.68C612.122 173.387 611.074 172.339 609.791 172.339Z"
            fill="#ADADAD"
          />
          <path
            d="M609.791 178.785C608.507 178.785 607.459 179.833 607.459 181.115C607.459 182.398 608.507 183.445 609.791 183.445C611.074 183.445 612.122 182.398 612.122 181.115C612.122 179.833 611.074 178.785 609.791 178.785Z"
            fill="#ADADAD"
          />
          <path
            d="M609.791 185.241C608.507 185.241 607.459 186.289 607.459 187.572C607.459 188.865 608.507 189.912 609.791 189.912C611.074 189.912 612.122 188.865 612.122 187.572C612.122 186.289 611.074 185.241 609.791 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M609.791 191.698C608.507 191.698 607.459 192.745 607.459 194.028C607.459 195.31 608.507 196.358 609.791 196.358C611.074 196.358 612.122 195.31 612.122 194.028C612.122 192.745 611.074 191.698 609.791 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M609.791 198.154C608.507 198.154 607.459 199.201 607.459 200.484C607.459 201.767 608.507 202.814 609.791 202.814C611.074 202.814 612.122 201.767 612.122 200.484C612.122 199.201 611.074 198.154 609.791 198.154Z"
            fill="#ADADAD"
          />
          <path
            d="M609.791 204.598C608.507 204.598 607.459 205.646 607.459 206.928C607.459 208.211 608.507 209.258 609.791 209.258C611.074 209.258 612.122 208.211 612.122 206.928C612.122 205.646 611.074 204.598 609.791 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M609.791 211.054C608.507 211.054 607.459 212.102 607.459 213.395C607.459 214.678 608.507 215.725 609.791 215.725C611.074 215.725 612.122 214.678 612.122 213.395C612.122 212.102 611.074 211.054 609.791 211.054Z"
            fill="#ADADAD"
          />
          <path
            d="M609.791 217.51C608.507 217.51 607.459 218.558 607.459 219.841C607.459 221.123 608.507 222.181 609.791 222.181C611.074 222.181 612.122 221.134 612.122 219.841C612.122 218.558 611.074 217.51 609.791 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M609.791 223.967C608.507 223.967 607.459 225.014 607.459 226.297C607.459 227.579 608.507 228.627 609.791 228.627C611.074 228.627 612.122 227.579 612.122 226.297C612.122 225.004 611.074 223.967 609.791 223.967Z"
            fill="#ADADAD"
          />
          <path
            d="M609.791 230.411C608.507 230.411 607.459 231.458 607.459 232.752C607.459 234.034 608.507 235.082 609.791 235.082C611.074 235.082 612.122 234.034 612.122 232.752C612.122 231.458 611.074 230.411 609.791 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M609.791 236.867C608.507 236.867 607.459 237.915 607.459 239.208C607.459 240.491 608.507 241.538 609.791 241.538C611.074 241.538 612.122 240.491 612.122 239.208C612.122 237.915 611.074 236.867 609.791 236.867Z"
            fill="#ADADAD"
          />
          <path
            d="M609.791 243.313C608.507 243.313 607.459 244.36 607.459 245.654C607.459 246.936 608.507 247.984 609.791 247.984C611.074 247.984 612.122 246.936 612.122 245.654C612.122 244.36 611.074 243.313 609.791 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M609.791 249.768C608.507 249.768 607.459 250.815 607.459 252.098C607.459 253.38 608.507 254.428 609.791 254.428C611.074 254.428 612.122 253.38 612.122 252.098C612.122 250.815 611.074 249.768 609.791 249.768Z"
            fill="#ADADAD"
          />
          <path
            d="M609.791 256.225C608.507 256.225 607.459 257.273 607.459 258.566C607.459 259.849 608.507 260.896 609.791 260.896C611.074 260.896 612.122 259.849 612.122 258.566C612.122 257.273 611.074 256.225 609.791 256.225Z"
            fill="#ADADAD"
          />
          <path
            d="M609.791 262.68C608.507 262.68 607.459 263.728 607.459 265.01C607.459 266.293 608.507 267.34 609.791 267.34C611.074 267.34 612.122 266.293 612.122 265.01C612.122 263.728 611.074 262.68 609.791 262.68Z"
            fill="#ADADAD"
          />
          <path
            d="M609.791 301.395C608.507 301.395 607.459 302.442 607.459 303.725C607.459 305.008 608.507 306.055 609.791 306.055C611.074 306.055 612.122 305.008 612.122 303.725C612.122 302.442 611.074 301.395 609.791 301.395Z"
            fill="#ADADAD"
          />
          <path
            d="M609.791 307.851C608.507 307.851 607.459 308.899 607.459 310.192C607.459 311.475 608.507 312.522 609.791 312.522C611.074 312.522 612.122 311.475 612.122 310.192C612.122 308.899 611.074 307.851 609.791 307.851Z"
            fill="#ADADAD"
          />
          <path
            d="M609.791 320.752C608.507 320.752 607.459 321.799 607.459 323.082C607.459 324.364 608.507 325.412 609.791 325.412C611.074 325.412 612.122 324.364 612.122 323.082C612.122 321.799 611.074 320.752 609.791 320.752Z"
            fill="#ADADAD"
          />
          <path
            d="M603.334 165.885C602.051 165.885 601.003 166.932 601.003 168.215C601.003 169.497 602.051 170.545 603.334 170.545C604.617 170.545 605.666 169.497 605.666 168.215C605.676 166.932 604.628 165.885 603.334 165.885Z"
            fill="#ADADAD"
          />
          <path
            d="M603.334 172.339C602.051 172.339 601.003 173.387 601.003 174.68C601.003 175.963 602.051 177.01 603.334 177.01C604.617 177.01 605.666 175.963 605.666 174.68C605.676 173.387 604.628 172.339 603.334 172.339Z"
            fill="#ADADAD"
          />
          <path
            d="M603.334 178.785C602.051 178.785 601.003 179.833 601.003 181.115C601.003 182.398 602.051 183.445 603.334 183.445C604.617 183.445 605.666 182.398 605.666 181.115C605.676 179.833 604.628 178.785 603.334 178.785Z"
            fill="#ADADAD"
          />
          <path
            d="M603.334 185.241C602.051 185.241 601.003 186.289 601.003 187.572C601.003 188.865 602.051 189.912 603.334 189.912C604.617 189.912 605.666 188.865 605.666 187.572C605.676 186.289 604.628 185.241 603.334 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M603.334 191.698C602.051 191.698 601.003 192.745 601.003 194.028C601.003 195.31 602.051 196.358 603.334 196.358C604.617 196.358 605.666 195.31 605.666 194.028C605.676 192.745 604.628 191.698 603.334 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M603.334 198.154C602.051 198.154 601.003 199.201 601.003 200.484C601.003 201.767 602.051 202.814 603.334 202.814C604.617 202.814 605.666 201.767 605.666 200.484C605.676 199.201 604.628 198.154 603.334 198.154Z"
            fill="#ADADAD"
          />
          <path
            d="M603.334 204.598C602.051 204.598 601.003 205.646 601.003 206.928C601.003 208.211 602.051 209.258 603.334 209.258C604.617 209.258 605.666 208.211 605.666 206.928C605.676 205.646 604.628 204.598 603.334 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M603.334 211.054C602.051 211.054 601.003 212.102 601.003 213.395C601.003 214.678 602.051 215.725 603.334 215.725C604.617 215.725 605.666 214.678 605.666 213.395C605.676 212.102 604.628 211.054 603.334 211.054Z"
            fill="#ADADAD"
          />
          <path
            d="M603.334 217.51C602.051 217.51 601.003 218.558 601.003 219.841C601.003 221.123 602.051 222.181 603.334 222.181C604.617 222.181 605.666 221.134 605.666 219.841C605.676 218.558 604.628 217.51 603.334 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M603.334 223.967C602.051 223.967 601.003 225.014 601.003 226.297C601.003 227.579 602.051 228.627 603.334 228.627C604.617 228.627 605.666 227.579 605.666 226.297C605.676 225.004 604.628 223.967 603.334 223.967Z"
            fill="#ADADAD"
          />
          <path
            d="M603.334 230.411C602.051 230.411 601.003 231.458 601.003 232.752C601.003 234.034 602.051 235.082 603.334 235.082C604.617 235.082 605.666 234.034 605.666 232.752C605.676 231.458 604.628 230.411 603.334 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M603.334 236.867C602.051 236.867 601.003 237.915 601.003 239.208C601.003 240.491 602.051 241.538 603.334 241.538C604.617 241.538 605.666 240.491 605.666 239.208C605.676 237.915 604.628 236.867 603.334 236.867Z"
            fill="#ADADAD"
          />
          <path
            d="M603.334 243.313C602.051 243.313 601.003 244.36 601.003 245.654C601.003 246.936 602.051 247.984 603.334 247.984C604.617 247.984 605.666 246.936 605.666 245.654C605.676 244.36 604.628 243.313 603.334 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M603.334 249.768C602.051 249.768 601.003 250.815 601.003 252.098C601.003 253.38 602.051 254.428 603.334 254.428C604.617 254.428 605.666 253.38 605.666 252.098C605.676 250.815 604.628 249.768 603.334 249.768Z"
            fill="#ADADAD"
          />
          <path
            d="M603.334 256.225C602.051 256.225 601.003 257.273 601.003 258.566C601.003 259.849 602.051 260.896 603.334 260.896C604.617 260.896 605.666 259.849 605.666 258.566C605.676 257.273 604.628 256.225 603.334 256.225Z"
            fill="#ADADAD"
          />
          <path
            d="M603.334 269.126C602.051 269.126 601.003 270.173 601.003 271.456C601.003 272.739 602.051 273.786 603.334 273.786C604.617 273.786 605.666 272.739 605.666 271.456C605.666 270.173 604.628 269.126 603.334 269.126Z"
            fill="#ADADAD"
          />
          <path
            d="M603.334 275.582C602.051 275.582 601.003 276.63 601.003 277.923C601.003 279.206 602.051 280.253 603.334 280.253C604.617 280.253 605.666 279.206 605.666 277.923C605.676 276.63 604.628 275.582 603.334 275.582Z"
            fill="#ADADAD"
          />
          <path
            d="M603.334 314.295C602.051 314.295 601.003 315.343 601.003 316.626C601.003 317.908 602.051 318.956 603.334 318.956C604.617 318.956 605.666 317.908 605.666 316.626C605.676 315.343 604.628 314.295 603.334 314.295Z"
            fill="#ADADAD"
          />
          <path
            d="M603.334 320.752C602.051 320.752 601.003 321.799 601.003 323.082C601.003 324.364 602.051 325.412 603.334 325.412C604.617 325.412 605.666 324.364 605.666 323.082C605.676 321.799 604.628 320.752 603.334 320.752Z"
            fill="#ADADAD"
          />
          <path
            d="M596.875 165.885C595.591 165.885 594.543 166.932 594.543 168.215C594.543 169.497 595.591 170.545 596.875 170.545C598.158 170.545 599.206 169.497 599.206 168.215C599.217 166.932 598.169 165.885 596.875 165.885Z"
            fill="#ADADAD"
          />
          <path
            d="M596.875 172.339C595.591 172.339 594.543 173.387 594.543 174.68C594.543 175.963 595.591 177.01 596.875 177.01C598.158 177.01 599.206 175.963 599.206 174.68C599.217 173.387 598.169 172.339 596.875 172.339Z"
            fill="#ADADAD"
          />
          <path
            d="M596.875 178.785C595.591 178.785 594.543 179.833 594.543 181.115C594.543 182.398 595.591 183.445 596.875 183.445C598.158 183.445 599.206 182.398 599.206 181.115C599.217 179.833 598.169 178.785 596.875 178.785Z"
            fill="#ADADAD"
          />
          <path
            d="M596.875 185.241C595.591 185.241 594.543 186.289 594.543 187.572C594.543 188.865 595.591 189.912 596.875 189.912C598.158 189.912 599.206 188.865 599.206 187.572C599.217 186.289 598.169 185.241 596.875 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M596.875 191.698C595.591 191.698 594.543 192.745 594.543 194.028C594.543 195.31 595.591 196.358 596.875 196.358C598.158 196.358 599.206 195.31 599.206 194.028C599.217 192.745 598.169 191.698 596.875 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M596.875 198.154C595.591 198.154 594.543 199.201 594.543 200.484C594.543 201.767 595.591 202.814 596.875 202.814C598.158 202.814 599.206 201.767 599.206 200.484C599.217 199.201 598.169 198.154 596.875 198.154Z"
            fill="#ADADAD"
          />
          <path
            d="M596.875 204.598C595.591 204.598 594.543 205.646 594.543 206.928C594.543 208.211 595.591 209.258 596.875 209.258C598.158 209.258 599.206 208.211 599.206 206.928C599.217 205.646 598.169 204.598 596.875 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M596.875 211.054C595.591 211.054 594.543 212.102 594.543 213.395C594.543 214.678 595.591 215.725 596.875 215.725C598.158 215.725 599.206 214.678 599.206 213.395C599.217 212.102 598.169 211.054 596.875 211.054Z"
            fill="#ADADAD"
          />
          <path
            d="M596.875 217.51C595.591 217.51 594.543 218.558 594.543 219.841C594.543 221.123 595.591 222.181 596.875 222.181C598.158 222.181 599.206 221.134 599.206 219.841C599.217 218.558 598.169 217.51 596.875 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M596.875 223.967C595.591 223.967 594.543 225.014 594.543 226.297C594.543 227.579 595.591 228.627 596.875 228.627C598.158 228.627 599.206 227.579 599.206 226.297C599.217 225.004 598.169 223.967 596.875 223.967Z"
            fill="#ADADAD"
          />
          <path
            d="M596.875 230.411C595.591 230.411 594.543 231.458 594.543 232.752C594.543 234.034 595.591 235.082 596.875 235.082C598.158 235.082 599.206 234.034 599.206 232.752C599.217 231.458 598.169 230.411 596.875 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M596.875 236.867C595.591 236.867 594.543 237.915 594.543 239.208C594.543 240.491 595.591 241.538 596.875 241.538C598.158 241.538 599.206 240.491 599.206 239.208C599.217 237.915 598.169 236.867 596.875 236.867Z"
            fill="#ADADAD"
          />
          <path
            d="M596.875 243.313C595.591 243.313 594.543 244.36 594.543 245.654C594.543 246.936 595.591 247.984 596.875 247.984C598.158 247.984 599.206 246.936 599.206 245.654C599.217 244.36 598.169 243.313 596.875 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M596.875 249.768C595.591 249.768 594.543 250.815 594.543 252.098C594.543 253.38 595.591 254.428 596.875 254.428C598.158 254.428 599.206 253.38 599.206 252.098C599.217 250.815 598.169 249.768 596.875 249.768Z"
            fill="#ADADAD"
          />
          <path
            d="M596.875 256.225C595.591 256.225 594.543 257.273 594.543 258.566C594.543 259.849 595.591 260.896 596.875 260.896C598.158 260.896 599.206 259.849 599.206 258.566C599.217 257.273 598.169 256.225 596.875 256.225Z"
            fill="#ADADAD"
          />
          <path
            d="M596.875 262.68C595.591 262.68 594.543 263.728 594.543 265.01C594.543 266.293 595.591 267.34 596.875 267.34C598.158 267.34 599.206 266.293 599.206 265.01C599.217 263.728 598.169 262.68 596.875 262.68Z"
            fill="#ADADAD"
          />
          <path
            d="M596.875 269.126C595.591 269.126 594.543 270.173 594.543 271.456C594.543 272.739 595.591 273.786 596.875 273.786C598.158 273.786 599.206 272.739 599.206 271.456C599.206 270.173 598.169 269.126 596.875 269.126Z"
            fill="#ADADAD"
          />
          <path
            d="M596.875 275.582C595.591 275.582 594.543 276.63 594.543 277.923C594.543 279.206 595.591 280.253 596.875 280.253C598.158 280.253 599.206 279.206 599.206 277.923C599.217 276.63 598.169 275.582 596.875 275.582Z"
            fill="#ADADAD"
          />
          <path
            d="M596.875 282.038C595.591 282.038 594.543 283.086 594.543 284.368C594.543 285.651 595.591 286.699 596.875 286.699C598.158 286.699 599.206 285.651 599.206 284.368C599.217 283.086 598.169 282.038 596.875 282.038Z"
            fill="#ADADAD"
          />
          <path
            d="M596.875 294.939C595.591 294.939 594.543 295.986 594.543 297.28C594.543 298.562 595.591 299.61 596.875 299.61C598.158 299.61 599.206 298.562 599.206 297.28C599.217 295.986 598.169 294.939 596.875 294.939Z"
            fill="#ADADAD"
          />
          <path
            d="M596.875 301.395C595.591 301.395 594.543 302.442 594.543 303.725C594.543 305.008 595.591 306.055 596.875 306.055C598.158 306.055 599.206 305.008 599.206 303.725C599.217 302.442 598.169 301.395 596.875 301.395Z"
            fill="#ADADAD"
          />
          <path
            d="M596.875 307.851C595.591 307.851 594.543 308.899 594.543 310.192C594.543 311.475 595.591 312.522 596.875 312.522C598.158 312.522 599.206 311.475 599.206 310.192C599.217 308.899 598.169 307.851 596.875 307.851Z"
            fill="#ADADAD"
          />
          <path
            d="M596.875 314.295C595.591 314.295 594.543 315.343 594.543 316.626C594.543 317.908 595.591 318.956 596.875 318.956C598.158 318.956 599.206 317.908 599.206 316.626C599.217 315.343 598.169 314.295 596.875 314.295Z"
            fill="#ADADAD"
          />
          <path
            d="M590.424 165.884C589.141 165.884 588.093 166.932 588.093 168.215C588.093 169.497 589.141 170.545 590.424 170.545C591.708 170.545 592.756 169.497 592.756 168.215C592.756 166.932 591.708 165.884 590.424 165.884Z"
            fill="#ADADAD"
          />
          <path
            d="M590.424 172.34C589.141 172.34 588.093 173.388 588.093 174.681C588.093 175.963 589.141 177.011 590.424 177.011C591.708 177.011 592.756 175.963 592.756 174.681C592.756 173.388 591.708 172.34 590.424 172.34Z"
            fill="#ADADAD"
          />
          <path
            d="M590.424 178.786C589.141 178.786 588.093 179.833 588.093 181.116C588.093 182.398 589.141 183.446 590.424 183.446C591.708 183.446 592.756 182.398 592.756 181.116C592.756 179.833 591.708 178.786 590.424 178.786Z"
            fill="#ADADAD"
          />
          <path
            d="M590.424 185.241C589.141 185.241 588.093 186.289 588.093 187.571C588.093 188.865 589.141 189.912 590.424 189.912C591.708 189.912 592.756 188.865 592.756 187.571C592.756 186.289 591.708 185.241 590.424 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M590.424 191.698C589.141 191.698 588.093 192.745 588.093 194.028C588.093 195.31 589.141 196.358 590.424 196.358C591.708 196.358 592.756 195.31 592.756 194.028C592.756 192.745 591.708 191.698 590.424 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M590.424 198.153C589.141 198.153 588.093 199.201 588.093 200.483C588.093 201.766 589.141 202.813 590.424 202.813C591.708 202.813 592.756 201.766 592.756 200.483C592.756 199.201 591.708 198.153 590.424 198.153Z"
            fill="#ADADAD"
          />
          <path
            d="M590.424 204.598C589.141 204.598 588.093 205.646 588.093 206.928C588.093 208.211 589.141 209.258 590.424 209.258C591.708 209.258 592.756 208.211 592.756 206.928C592.756 205.646 591.708 204.598 590.424 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M590.424 211.055C589.141 211.055 588.093 212.102 588.093 213.395C588.093 214.678 589.141 215.725 590.424 215.725C591.708 215.725 592.756 214.678 592.756 213.395C592.756 212.102 591.708 211.055 590.424 211.055Z"
            fill="#ADADAD"
          />
          <path
            d="M590.424 217.51C589.141 217.51 588.093 218.558 588.093 219.84C588.093 221.123 589.141 222.181 590.424 222.181C591.708 222.181 592.756 221.134 592.756 219.84C592.756 218.558 591.708 217.51 590.424 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M590.424 223.966C589.141 223.966 588.093 225.014 588.093 226.297C588.093 227.579 589.141 228.627 590.424 228.627C591.708 228.627 592.756 227.579 592.756 226.297C592.756 225.003 591.708 223.966 590.424 223.966Z"
            fill="#ADADAD"
          />
          <path
            d="M590.424 230.411C589.141 230.411 588.093 231.459 588.093 232.752C588.093 234.035 589.141 235.082 590.424 235.082C591.708 235.082 592.756 234.035 592.756 232.752C592.756 231.459 591.708 230.411 590.424 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M590.424 236.868C589.141 236.868 588.093 237.915 588.093 239.208C588.093 240.491 589.141 241.539 590.424 241.539C591.708 241.539 592.756 240.491 592.756 239.208C592.756 237.915 591.708 236.868 590.424 236.868Z"
            fill="#ADADAD"
          />
          <path
            d="M590.424 243.313C589.141 243.313 588.093 244.36 588.093 245.654C588.093 246.936 589.141 247.984 590.424 247.984C591.708 247.984 592.756 246.936 592.756 245.654C592.756 244.36 591.708 243.313 590.424 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M590.424 249.768C589.141 249.768 588.093 250.816 588.093 252.098C588.093 253.381 589.141 254.429 590.424 254.429C591.708 254.429 592.756 253.381 592.756 252.098C592.756 250.816 591.708 249.768 590.424 249.768Z"
            fill="#ADADAD"
          />
          <path
            d="M590.424 256.225C589.141 256.225 588.093 257.272 588.093 258.565C588.093 259.848 589.141 260.896 590.424 260.896C591.708 260.896 592.756 259.848 592.756 258.565C592.756 257.272 591.708 256.225 590.424 256.225Z"
            fill="#ADADAD"
          />
          <path
            d="M590.424 262.68C589.141 262.68 588.093 263.728 588.093 265.01C588.093 266.293 589.141 267.341 590.424 267.341C591.708 267.341 592.756 266.293 592.756 265.01C592.756 263.728 591.708 262.68 590.424 262.68Z"
            fill="#ADADAD"
          />
          <path
            d="M590.424 269.126C589.141 269.126 588.093 270.173 588.093 271.456C588.093 272.739 589.141 273.786 590.424 273.786C591.708 273.786 592.756 272.739 592.756 271.456C592.756 270.173 591.708 269.126 590.424 269.126Z"
            fill="#ADADAD"
          />
          <path
            d="M590.424 275.582C589.141 275.582 588.093 276.629 588.093 277.922C588.093 279.205 589.141 280.253 590.424 280.253C591.708 280.253 592.756 279.205 592.756 277.922C592.756 276.629 591.708 275.582 590.424 275.582Z"
            fill="#ADADAD"
          />
          <path
            d="M590.424 288.493C589.141 288.493 588.093 289.541 588.093 290.834C588.093 292.117 589.141 293.164 590.424 293.164C591.708 293.164 592.756 292.117 592.756 290.834C592.756 289.541 591.708 288.493 590.424 288.493Z"
            fill="#ADADAD"
          />
          <path
            d="M590.424 294.939C589.141 294.939 588.093 295.987 588.093 297.28C588.093 298.563 589.141 299.61 590.424 299.61C591.708 299.61 592.756 298.563 592.756 297.28C592.756 295.987 591.708 294.939 590.424 294.939Z"
            fill="#ADADAD"
          />
          <path
            d="M590.424 301.395C589.141 301.395 588.093 302.442 588.093 303.725C588.093 305.008 589.141 306.055 590.424 306.055C591.708 306.055 592.756 305.008 592.756 303.725C592.756 302.442 591.708 301.395 590.424 301.395Z"
            fill="#ADADAD"
          />
          <path
            d="M590.424 307.851C589.141 307.851 588.093 308.898 588.093 310.192C588.093 311.474 589.141 312.522 590.424 312.522C591.708 312.522 592.756 311.474 592.756 310.192C592.756 308.898 591.708 307.851 590.424 307.851Z"
            fill="#ADADAD"
          />
          <path
            d="M583.965 165.884C582.681 165.884 581.633 166.932 581.633 168.215C581.633 169.497 582.681 170.545 583.965 170.545C585.248 170.545 586.296 169.497 586.296 168.215C586.307 166.932 585.259 165.884 583.965 165.884Z"
            fill="#ADADAD"
          />
          <path
            d="M583.965 172.34C582.681 172.34 581.633 173.388 581.633 174.681C581.633 175.963 582.681 177.011 583.965 177.011C585.248 177.011 586.296 175.963 586.296 174.681C586.307 173.388 585.259 172.34 583.965 172.34Z"
            fill="#ADADAD"
          />
          <path
            d="M583.965 178.786C582.681 178.786 581.633 179.833 581.633 181.116C581.633 182.398 582.681 183.446 583.965 183.446C585.248 183.446 586.296 182.398 586.296 181.116C586.307 179.833 585.259 178.786 583.965 178.786Z"
            fill="#ADADAD"
          />
          <path
            d="M583.965 185.241C582.681 185.241 581.633 186.289 581.633 187.571C581.633 188.865 582.681 189.912 583.965 189.912C585.248 189.912 586.296 188.865 586.296 187.571C586.307 186.289 585.259 185.241 583.965 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M583.965 191.698C582.681 191.698 581.633 192.745 581.633 194.028C581.633 195.31 582.681 196.358 583.965 196.358C585.248 196.358 586.296 195.31 586.296 194.028C586.307 192.745 585.259 191.698 583.965 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M583.965 198.153C582.681 198.153 581.633 199.201 581.633 200.483C581.633 201.766 582.681 202.813 583.965 202.813C585.248 202.813 586.296 201.766 586.296 200.483C586.307 199.201 585.259 198.153 583.965 198.153Z"
            fill="#ADADAD"
          />
          <path
            d="M583.965 204.598C582.681 204.598 581.633 205.646 581.633 206.928C581.633 208.211 582.681 209.258 583.965 209.258C585.248 209.258 586.296 208.211 586.296 206.928C586.307 205.646 585.259 204.598 583.965 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M583.965 211.055C582.681 211.055 581.633 212.102 581.633 213.395C581.633 214.678 582.681 215.725 583.965 215.725C585.248 215.725 586.296 214.678 586.296 213.395C586.307 212.102 585.259 211.055 583.965 211.055Z"
            fill="#ADADAD"
          />
          <path
            d="M583.965 217.51C582.681 217.51 581.633 218.558 581.633 219.84C581.633 221.123 582.681 222.181 583.965 222.181C585.248 222.181 586.296 221.134 586.296 219.84C586.307 218.558 585.259 217.51 583.965 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M583.965 223.966C582.681 223.966 581.633 225.014 581.633 226.297C581.633 227.579 582.681 228.627 583.965 228.627C585.248 228.627 586.296 227.579 586.296 226.297C586.307 225.003 585.259 223.966 583.965 223.966Z"
            fill="#ADADAD"
          />
          <path
            d="M583.965 230.411C582.681 230.411 581.633 231.459 581.633 232.752C581.633 234.035 582.681 235.082 583.965 235.082C585.248 235.082 586.296 234.035 586.296 232.752C586.307 231.459 585.259 230.411 583.965 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M583.965 236.868C582.681 236.868 581.633 237.915 581.633 239.208C581.633 240.491 582.681 241.539 583.965 241.539C585.248 241.539 586.296 240.491 586.296 239.208C586.307 237.915 585.259 236.868 583.965 236.868Z"
            fill="#ADADAD"
          />
          <path
            d="M583.965 243.313C582.681 243.313 581.633 244.36 581.633 245.654C581.633 246.936 582.681 247.984 583.965 247.984C585.248 247.984 586.296 246.936 586.296 245.654C586.307 244.36 585.259 243.313 583.965 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M583.965 249.768C582.681 249.768 581.633 250.816 581.633 252.098C581.633 253.381 582.681 254.429 583.965 254.429C585.248 254.429 586.296 253.381 586.296 252.098C586.307 250.816 585.259 249.768 583.965 249.768Z"
            fill="#ADADAD"
          />
          <path
            d="M583.965 256.225C582.681 256.225 581.633 257.272 581.633 258.565C581.633 259.848 582.681 260.896 583.965 260.896C585.248 260.896 586.296 259.848 586.296 258.565C586.307 257.272 585.259 256.225 583.965 256.225Z"
            fill="#ADADAD"
          />
          <path
            d="M583.965 262.68C582.681 262.68 581.633 263.728 581.633 265.01C581.633 266.293 582.681 267.341 583.965 267.341C585.248 267.341 586.296 266.293 586.296 265.01C586.307 263.728 585.259 262.68 583.965 262.68Z"
            fill="#ADADAD"
          />
          <path
            d="M583.965 269.126C582.681 269.126 581.633 270.173 581.633 271.456C581.633 272.739 582.681 273.786 583.965 273.786C585.248 273.786 586.296 272.739 586.296 271.456C586.296 270.173 585.259 269.126 583.965 269.126Z"
            fill="#ADADAD"
          />
          <path
            d="M583.965 275.582C582.681 275.582 581.633 276.629 581.633 277.922C581.633 279.205 582.681 280.253 583.965 280.253C585.248 280.253 586.296 279.205 586.296 277.922C586.307 276.629 585.259 275.582 583.965 275.582Z"
            fill="#ADADAD"
          />
          <path
            d="M583.965 282.038C582.681 282.038 581.633 283.085 581.633 284.368C581.633 285.651 582.681 286.698 583.965 286.698C585.248 286.698 586.296 285.651 586.296 284.368C586.307 283.085 585.259 282.038 583.965 282.038Z"
            fill="#ADADAD"
          />
          <path
            d="M583.965 288.493C582.681 288.493 581.633 289.541 581.633 290.834C581.633 292.117 582.681 293.164 583.965 293.164C585.248 293.164 586.296 292.117 586.296 290.834C586.307 289.541 585.259 288.493 583.965 288.493Z"
            fill="#ADADAD"
          />
          <path
            d="M583.965 294.939C582.681 294.939 581.633 295.987 581.633 297.28C581.633 298.563 582.681 299.61 583.965 299.61C585.248 299.61 586.296 298.563 586.296 297.28C586.307 295.987 585.259 294.939 583.965 294.939Z"
            fill="#ADADAD"
          />
          <path
            d="M583.965 301.395C582.681 301.395 581.633 302.442 581.633 303.725C581.633 305.008 582.681 306.055 583.965 306.055C585.248 306.055 586.296 305.008 586.296 303.725C586.307 302.442 585.259 301.395 583.965 301.395Z"
            fill="#ADADAD"
          />
          <path
            d="M577.514 165.885C576.231 165.885 575.183 166.932 575.183 168.215C575.183 169.497 576.231 170.545 577.514 170.545C578.798 170.545 579.846 169.497 579.846 168.215C579.835 166.932 578.798 165.885 577.514 165.885Z"
            fill="#ADADAD"
          />
          <path
            d="M577.514 172.339C576.231 172.339 575.183 173.387 575.183 174.68C575.183 175.963 576.231 177.01 577.514 177.01C578.798 177.01 579.846 175.963 579.846 174.68C579.835 173.387 578.798 172.339 577.514 172.339Z"
            fill="#ADADAD"
          />
          <path
            d="M577.514 178.785C576.231 178.785 575.183 179.833 575.183 181.115C575.183 182.398 576.231 183.445 577.514 183.445C578.798 183.445 579.846 182.398 579.846 181.115C579.835 179.833 578.798 178.785 577.514 178.785Z"
            fill="#ADADAD"
          />
          <path
            d="M577.514 185.241C576.231 185.241 575.183 186.289 575.183 187.572C575.183 188.865 576.231 189.912 577.514 189.912C578.798 189.912 579.846 188.865 579.846 187.572C579.835 186.289 578.798 185.241 577.514 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M577.514 191.698C576.231 191.698 575.183 192.745 575.183 194.028C575.183 195.31 576.231 196.358 577.514 196.358C578.798 196.358 579.846 195.31 579.846 194.028C579.835 192.745 578.798 191.698 577.514 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M577.514 198.154C576.231 198.154 575.183 199.201 575.183 200.484C575.183 201.767 576.231 202.814 577.514 202.814C578.798 202.814 579.846 201.767 579.846 200.484C579.835 199.201 578.798 198.154 577.514 198.154Z"
            fill="#ADADAD"
          />
          <path
            d="M577.514 204.598C576.231 204.598 575.183 205.646 575.183 206.928C575.183 208.211 576.231 209.258 577.514 209.258C578.798 209.258 579.846 208.211 579.846 206.928C579.835 205.646 578.798 204.598 577.514 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M577.514 211.054C576.231 211.054 575.183 212.102 575.183 213.395C575.183 214.678 576.231 215.725 577.514 215.725C578.798 215.725 579.846 214.678 579.846 213.395C579.835 212.102 578.798 211.054 577.514 211.054Z"
            fill="#ADADAD"
          />
          <path
            d="M577.514 217.51C576.231 217.51 575.183 218.558 575.183 219.841C575.183 221.123 576.231 222.181 577.514 222.181C578.798 222.181 579.846 221.134 579.846 219.841C579.835 218.558 578.798 217.51 577.514 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M577.514 223.967C576.231 223.967 575.183 225.014 575.183 226.297C575.183 227.579 576.231 228.627 577.514 228.627C578.798 228.627 579.846 227.579 579.846 226.297C579.835 225.004 578.798 223.967 577.514 223.967Z"
            fill="#ADADAD"
          />
          <path
            d="M577.514 230.411C576.231 230.411 575.183 231.458 575.183 232.752C575.183 234.034 576.231 235.082 577.514 235.082C578.798 235.082 579.846 234.034 579.846 232.752C579.835 231.458 578.798 230.411 577.514 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M577.514 236.867C576.231 236.867 575.183 237.915 575.183 239.208C575.183 240.491 576.231 241.538 577.514 241.538C578.798 241.538 579.846 240.491 579.846 239.208C579.835 237.915 578.798 236.867 577.514 236.867Z"
            fill="#ADADAD"
          />
          <path
            d="M577.514 243.313C576.231 243.313 575.183 244.36 575.183 245.654C575.183 246.936 576.231 247.984 577.514 247.984C578.798 247.984 579.846 246.936 579.846 245.654C579.835 244.36 578.798 243.313 577.514 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M577.514 249.768C576.231 249.768 575.183 250.815 575.183 252.098C575.183 253.38 576.231 254.428 577.514 254.428C578.798 254.428 579.846 253.38 579.846 252.098C579.835 250.815 578.798 249.768 577.514 249.768Z"
            fill="#ADADAD"
          />
          <path
            d="M577.514 256.225C576.231 256.225 575.183 257.273 575.183 258.566C575.183 259.849 576.231 260.896 577.514 260.896C578.798 260.896 579.846 259.849 579.846 258.566C579.835 257.273 578.798 256.225 577.514 256.225Z"
            fill="#ADADAD"
          />
          <path
            d="M577.514 262.68C576.231 262.68 575.183 263.728 575.183 265.01C575.183 266.293 576.231 267.34 577.514 267.34C578.798 267.34 579.846 266.293 579.846 265.01C579.835 263.728 578.798 262.68 577.514 262.68Z"
            fill="#ADADAD"
          />
          <path
            d="M577.514 294.939C576.231 294.939 575.183 295.986 575.183 297.28C575.183 298.562 576.231 299.61 577.514 299.61C578.798 299.61 579.846 298.562 579.846 297.28C579.835 295.986 578.798 294.939 577.514 294.939Z"
            fill="#ADADAD"
          />
          <path
            d="M571.057 165.885C569.763 165.885 568.715 166.932 568.715 168.215C568.715 169.497 569.763 170.545 571.057 170.545C572.34 170.545 573.388 169.497 573.388 168.215C573.388 166.932 572.34 165.885 571.057 165.885Z"
            fill="#ADADAD"
          />
          <path
            d="M571.057 172.339C569.763 172.339 568.715 173.387 568.715 174.68C568.715 175.963 569.763 177.01 571.057 177.01C572.34 177.01 573.388 175.963 573.388 174.68C573.388 173.387 572.34 172.339 571.057 172.339Z"
            fill="#ADADAD"
          />
          <path
            d="M571.057 178.785C569.763 178.785 568.715 179.833 568.715 181.115C568.715 182.398 569.763 183.445 571.057 183.445C572.34 183.445 573.388 182.398 573.388 181.115C573.388 179.833 572.34 178.785 571.057 178.785Z"
            fill="#ADADAD"
          />
          <path
            d="M571.057 185.241C569.763 185.241 568.715 186.289 568.715 187.572C568.715 188.865 569.763 189.912 571.057 189.912C572.34 189.912 573.388 188.865 573.388 187.572C573.388 186.289 572.34 185.241 571.057 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M571.057 191.698C569.763 191.698 568.715 192.745 568.715 194.028C568.715 195.31 569.763 196.358 571.057 196.358C572.34 196.358 573.388 195.31 573.388 194.028C573.388 192.745 572.34 191.698 571.057 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M571.057 198.154C569.763 198.154 568.715 199.201 568.715 200.484C568.715 201.767 569.763 202.814 571.057 202.814C572.34 202.814 573.388 201.767 573.388 200.484C573.388 199.201 572.34 198.154 571.057 198.154Z"
            fill="#ADADAD"
          />
          <path
            d="M571.057 204.598C569.763 204.598 568.715 205.646 568.715 206.928C568.715 208.211 569.763 209.258 571.057 209.258C572.34 209.258 573.388 208.211 573.388 206.928C573.388 205.646 572.34 204.598 571.057 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M571.057 211.054C569.763 211.054 568.715 212.102 568.715 213.395C568.715 214.678 569.763 215.725 571.057 215.725C572.34 215.725 573.388 214.678 573.388 213.395C573.388 212.102 572.34 211.054 571.057 211.054Z"
            fill="#ADADAD"
          />
          <path
            d="M571.057 217.51C569.763 217.51 568.715 218.558 568.715 219.841C568.715 221.123 569.763 222.181 571.057 222.181C572.34 222.181 573.388 221.134 573.388 219.841C573.388 218.558 572.34 217.51 571.057 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M571.057 223.967C569.763 223.967 568.715 225.014 568.715 226.297C568.715 227.579 569.763 228.627 571.057 228.627C572.34 228.627 573.388 227.579 573.388 226.297C573.388 225.004 572.34 223.967 571.057 223.967Z"
            fill="#ADADAD"
          />
          <path
            d="M571.057 230.411C569.763 230.411 568.715 231.458 568.715 232.752C568.715 234.034 569.763 235.082 571.057 235.082C572.34 235.082 573.388 234.034 573.388 232.752C573.388 231.458 572.34 230.411 571.057 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M571.057 236.867C569.763 236.867 568.715 237.915 568.715 239.208C568.715 240.491 569.763 241.538 571.057 241.538C572.34 241.538 573.388 240.491 573.388 239.208C573.388 237.915 572.34 236.867 571.057 236.867Z"
            fill="#ADADAD"
          />
          <path
            d="M571.057 243.313C569.763 243.313 568.715 244.36 568.715 245.654C568.715 246.936 569.763 247.984 571.057 247.984C572.34 247.984 573.388 246.936 573.388 245.654C573.388 244.36 572.34 243.313 571.057 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M571.057 249.768C569.763 249.768 568.715 250.815 568.715 252.098C568.715 253.38 569.763 254.428 571.057 254.428C572.34 254.428 573.388 253.38 573.388 252.098C573.388 250.815 572.34 249.768 571.057 249.768Z"
            fill="#ADADAD"
          />
          <path
            d="M571.057 256.225C569.763 256.225 568.715 257.273 568.715 258.566C568.715 259.849 569.763 260.896 571.057 260.896C572.34 260.896 573.388 259.849 573.388 258.566C573.388 257.273 572.34 256.225 571.057 256.225Z"
            fill="#ADADAD"
          />
          <path
            d="M564.596 165.885C563.312 165.885 562.264 166.932 562.264 168.215C562.264 169.497 563.312 170.545 564.596 170.545C565.879 170.545 566.927 169.497 566.927 168.215C566.927 166.932 565.879 165.885 564.596 165.885Z"
            fill="#ADADAD"
          />
          <path
            d="M564.596 172.339C563.312 172.339 562.264 173.387 562.264 174.68C562.264 175.963 563.312 177.01 564.596 177.01C565.879 177.01 566.927 175.963 566.927 174.68C566.927 173.387 565.879 172.339 564.596 172.339Z"
            fill="#ADADAD"
          />
          <path
            d="M564.596 178.785C563.312 178.785 562.264 179.833 562.264 181.115C562.264 182.398 563.312 183.445 564.596 183.445C565.879 183.445 566.927 182.398 566.927 181.115C566.927 179.833 565.879 178.785 564.596 178.785Z"
            fill="#ADADAD"
          />
          <path
            d="M564.596 185.241C563.312 185.241 562.264 186.289 562.264 187.572C562.264 188.865 563.312 189.912 564.596 189.912C565.879 189.912 566.927 188.865 566.927 187.572C566.927 186.289 565.879 185.241 564.596 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M564.596 191.698C563.312 191.698 562.264 192.745 562.264 194.028C562.264 195.31 563.312 196.358 564.596 196.358C565.879 196.358 566.927 195.31 566.927 194.028C566.927 192.745 565.879 191.698 564.596 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M564.596 198.154C563.312 198.154 562.264 199.201 562.264 200.484C562.264 201.767 563.312 202.814 564.596 202.814C565.879 202.814 566.927 201.767 566.927 200.484C566.927 199.201 565.879 198.154 564.596 198.154Z"
            fill="#ADADAD"
          />
          <path
            d="M564.596 204.598C563.312 204.598 562.264 205.646 562.264 206.928C562.264 208.211 563.312 209.258 564.596 209.258C565.879 209.258 566.927 208.211 566.927 206.928C566.927 205.646 565.879 204.598 564.596 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M564.596 211.054C563.312 211.054 562.264 212.102 562.264 213.395C562.264 214.678 563.312 215.725 564.596 215.725C565.879 215.725 566.927 214.678 566.927 213.395C566.927 212.102 565.879 211.054 564.596 211.054Z"
            fill="#ADADAD"
          />
          <path
            d="M564.596 217.51C563.312 217.51 562.264 218.558 562.264 219.841C562.264 221.123 563.312 222.181 564.596 222.181C565.879 222.181 566.927 221.134 566.927 219.841C566.927 218.558 565.879 217.51 564.596 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M564.596 223.967C563.312 223.967 562.264 225.014 562.264 226.297C562.264 227.579 563.312 228.627 564.596 228.627C565.879 228.627 566.927 227.579 566.927 226.297C566.927 225.004 565.879 223.967 564.596 223.967Z"
            fill="#ADADAD"
          />
          <path
            d="M564.596 230.411C563.312 230.411 562.264 231.458 562.264 232.752C562.264 234.034 563.312 235.082 564.596 235.082C565.879 235.082 566.927 234.034 566.927 232.752C566.927 231.458 565.879 230.411 564.596 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M564.596 236.867C563.312 236.867 562.264 237.915 562.264 239.208C562.264 240.491 563.312 241.538 564.596 241.538C565.879 241.538 566.927 240.491 566.927 239.208C566.927 237.915 565.879 236.867 564.596 236.867Z"
            fill="#ADADAD"
          />
          <path
            d="M564.596 243.313C563.312 243.313 562.264 244.36 562.264 245.654C562.264 246.936 563.312 247.984 564.596 247.984C565.879 247.984 566.927 246.936 566.927 245.654C566.927 244.36 565.879 243.313 564.596 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M564.596 249.768C563.312 249.768 562.264 250.815 562.264 252.098C562.264 253.38 563.312 254.428 564.596 254.428C565.879 254.428 566.927 253.38 566.927 252.098C566.927 250.815 565.879 249.768 564.596 249.768Z"
            fill="#ADADAD"
          />
          <path
            d="M564.596 256.225C563.312 256.225 562.264 257.273 562.264 258.566C562.264 259.849 563.312 260.896 564.596 260.896C565.879 260.896 566.927 259.849 566.927 258.566C566.927 257.273 565.879 256.225 564.596 256.225Z"
            fill="#ADADAD"
          />
          <path
            d="M558.136 165.884C556.853 165.884 555.805 166.932 555.805 168.215C555.805 169.497 556.853 170.545 558.136 170.545C559.42 170.545 560.468 169.497 560.468 168.215C560.479 166.932 559.43 165.884 558.136 165.884Z"
            fill="#ADADAD"
          />
          <path
            d="M558.136 172.34C556.853 172.34 555.805 173.388 555.805 174.681C555.805 175.963 556.853 177.011 558.136 177.011C559.42 177.011 560.468 175.963 560.468 174.681C560.479 173.388 559.43 172.34 558.136 172.34Z"
            fill="#ADADAD"
          />
          <path
            d="M558.136 178.786C556.853 178.786 555.805 179.833 555.805 181.116C555.805 182.398 556.853 183.446 558.136 183.446C559.42 183.446 560.468 182.398 560.468 181.116C560.479 179.833 559.43 178.786 558.136 178.786Z"
            fill="#ADADAD"
          />
          <path
            d="M558.136 185.241C556.853 185.241 555.805 186.289 555.805 187.571C555.805 188.865 556.853 189.912 558.136 189.912C559.42 189.912 560.468 188.865 560.468 187.571C560.479 186.289 559.43 185.241 558.136 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M558.136 191.698C556.853 191.698 555.805 192.745 555.805 194.028C555.805 195.31 556.853 196.358 558.136 196.358C559.42 196.358 560.468 195.31 560.468 194.028C560.479 192.745 559.43 191.698 558.136 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M558.136 198.153C556.853 198.153 555.805 199.201 555.805 200.483C555.805 201.766 556.853 202.813 558.136 202.813C559.42 202.813 560.468 201.766 560.468 200.483C560.479 199.201 559.43 198.153 558.136 198.153Z"
            fill="#ADADAD"
          />
          <path
            d="M558.136 204.598C556.853 204.598 555.805 205.646 555.805 206.928C555.805 208.211 556.853 209.258 558.136 209.258C559.42 209.258 560.468 208.211 560.468 206.928C560.479 205.646 559.43 204.598 558.136 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M558.136 211.055C556.853 211.055 555.805 212.102 555.805 213.395C555.805 214.678 556.853 215.725 558.136 215.725C559.42 215.725 560.468 214.678 560.468 213.395C560.479 212.102 559.43 211.055 558.136 211.055Z"
            fill="#ADADAD"
          />
          <path
            d="M558.136 217.51C556.853 217.51 555.805 218.558 555.805 219.84C555.805 221.123 556.853 222.181 558.136 222.181C559.42 222.181 560.468 221.134 560.468 219.84C560.479 218.558 559.43 217.51 558.136 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M558.136 223.966C556.853 223.966 555.805 225.014 555.805 226.297C555.805 227.579 556.853 228.627 558.136 228.627C559.42 228.627 560.468 227.579 560.468 226.297C560.479 225.003 559.43 223.966 558.136 223.966Z"
            fill="#ADADAD"
          />
          <path
            d="M558.136 230.411C556.853 230.411 555.805 231.459 555.805 232.752C555.805 234.035 556.853 235.082 558.136 235.082C559.42 235.082 560.468 234.035 560.468 232.752C560.479 231.459 559.43 230.411 558.136 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M558.136 236.868C556.853 236.868 555.805 237.915 555.805 239.208C555.805 240.491 556.853 241.539 558.136 241.539C559.42 241.539 560.468 240.491 560.468 239.208C560.479 237.915 559.43 236.868 558.136 236.868Z"
            fill="#ADADAD"
          />
          <path
            d="M558.136 243.313C556.853 243.313 555.805 244.36 555.805 245.654C555.805 246.936 556.853 247.984 558.136 247.984C559.42 247.984 560.468 246.936 560.468 245.654C560.479 244.36 559.43 243.313 558.136 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M558.136 249.768C556.853 249.768 555.805 250.816 555.805 252.098C555.805 253.381 556.853 254.429 558.136 254.429C559.42 254.429 560.468 253.381 560.468 252.098C560.479 250.816 559.43 249.768 558.136 249.768Z"
            fill="#ADADAD"
          />
          <path
            d="M558.136 256.225C556.853 256.225 555.805 257.272 555.805 258.565C555.805 259.848 556.853 260.896 558.136 260.896C559.42 260.896 560.468 259.848 560.468 258.565C560.479 257.272 559.43 256.225 558.136 256.225Z"
            fill="#ADADAD"
          />
          <path
            d="M551.677 165.884C550.394 165.884 549.346 166.932 549.346 168.215C549.346 169.497 550.394 170.545 551.677 170.545C552.96 170.545 554.009 169.497 554.009 168.215C554.019 166.932 552.971 165.884 551.677 165.884Z"
            fill="#ADADAD"
          />
          <path
            d="M551.677 172.34C550.394 172.34 549.346 173.388 549.346 174.681C549.346 175.963 550.394 177.011 551.677 177.011C552.96 177.011 554.009 175.963 554.009 174.681C554.019 173.388 552.971 172.34 551.677 172.34Z"
            fill="#ADADAD"
          />
          <path
            d="M551.677 178.786C550.394 178.786 549.346 179.833 549.346 181.116C549.346 182.398 550.394 183.446 551.677 183.446C552.96 183.446 554.009 182.398 554.009 181.116C554.019 179.833 552.971 178.786 551.677 178.786Z"
            fill="#ADADAD"
          />
          <path
            d="M551.677 185.241C550.394 185.241 549.346 186.289 549.346 187.571C549.346 188.865 550.394 189.912 551.677 189.912C552.96 189.912 554.009 188.865 554.009 187.571C554.019 186.289 552.971 185.241 551.677 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M551.677 191.698C550.394 191.698 549.346 192.745 549.346 194.028C549.346 195.31 550.394 196.358 551.677 196.358C552.96 196.358 554.009 195.31 554.009 194.028C554.019 192.745 552.971 191.698 551.677 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M551.677 198.153C550.394 198.153 549.346 199.201 549.346 200.483C549.346 201.766 550.394 202.813 551.677 202.813C552.96 202.813 554.009 201.766 554.009 200.483C554.019 199.201 552.971 198.153 551.677 198.153Z"
            fill="#ADADAD"
          />
          <path
            d="M551.677 204.598C550.394 204.598 549.346 205.646 549.346 206.928C549.346 208.211 550.394 209.258 551.677 209.258C552.96 209.258 554.009 208.211 554.009 206.928C554.019 205.646 552.971 204.598 551.677 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M551.677 211.055C550.394 211.055 549.346 212.102 549.346 213.395C549.346 214.678 550.394 215.725 551.677 215.725C552.96 215.725 554.009 214.678 554.009 213.395C554.019 212.102 552.971 211.055 551.677 211.055Z"
            fill="#ADADAD"
          />
          <path
            d="M551.677 217.51C550.394 217.51 549.346 218.558 549.346 219.84C549.346 221.123 550.394 222.181 551.677 222.181C552.96 222.181 554.009 221.134 554.009 219.84C554.019 218.558 552.971 217.51 551.677 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M551.677 223.966C550.394 223.966 549.346 225.014 549.346 226.297C549.346 227.579 550.394 228.627 551.677 228.627C552.96 228.627 554.009 227.579 554.009 226.297C554.019 225.003 552.971 223.966 551.677 223.966Z"
            fill="#ADADAD"
          />
          <path
            d="M551.677 230.411C550.394 230.411 549.346 231.459 549.346 232.752C549.346 234.035 550.394 235.082 551.677 235.082C552.96 235.082 554.009 234.035 554.009 232.752C554.019 231.459 552.971 230.411 551.677 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M551.677 236.868C550.394 236.868 549.346 237.915 549.346 239.208C549.346 240.491 550.394 241.539 551.677 241.539C552.96 241.539 554.009 240.491 554.009 239.208C554.019 237.915 552.971 236.868 551.677 236.868Z"
            fill="#ADADAD"
          />
          <path
            d="M551.677 243.313C550.394 243.313 549.346 244.36 549.346 245.654C549.346 246.936 550.394 247.984 551.677 247.984C552.96 247.984 554.009 246.936 554.009 245.654C554.019 244.36 552.971 243.313 551.677 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M551.677 249.768C550.394 249.768 549.346 250.816 549.346 252.098C549.346 253.381 550.394 254.429 551.677 254.429C552.96 254.429 554.009 253.381 554.009 252.098C554.019 250.816 552.971 249.768 551.677 249.768Z"
            fill="#ADADAD"
          />
          <path
            d="M551.677 256.225C550.394 256.225 549.346 257.272 549.346 258.565C549.346 259.848 550.394 260.896 551.677 260.896C552.96 260.896 554.009 259.848 554.009 258.565C554.019 257.272 552.971 256.225 551.677 256.225Z"
            fill="#ADADAD"
          />
          <path
            d="M551.677 262.68C550.394 262.68 549.346 263.728 549.346 265.01C549.346 266.293 550.394 267.341 551.677 267.341C552.96 267.341 554.009 266.293 554.009 265.01C554.019 263.728 552.971 262.68 551.677 262.68Z"
            fill="#ADADAD"
          />
          <path
            d="M545.229 165.885C543.946 165.885 542.898 166.932 542.898 168.215C542.898 169.497 543.946 170.545 545.229 170.545C546.513 170.545 547.561 169.497 547.561 168.215C547.561 166.932 546.513 165.885 545.229 165.885Z"
            fill="#ADADAD"
          />
          <path
            d="M545.229 172.339C543.946 172.339 542.898 173.387 542.898 174.68C542.898 175.963 543.946 177.01 545.229 177.01C546.513 177.01 547.561 175.963 547.561 174.68C547.561 173.387 546.513 172.339 545.229 172.339Z"
            fill="#ADADAD"
          />
          <path
            d="M545.229 178.785C543.946 178.785 542.898 179.833 542.898 181.115C542.898 182.398 543.946 183.445 545.229 183.445C546.513 183.445 547.561 182.398 547.561 181.115C547.561 179.833 546.513 178.785 545.229 178.785Z"
            fill="#ADADAD"
          />
          <path
            d="M545.229 185.241C543.946 185.241 542.898 186.289 542.898 187.572C542.898 188.865 543.946 189.912 545.229 189.912C546.513 189.912 547.561 188.865 547.561 187.572C547.561 186.289 546.513 185.241 545.229 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M545.229 191.698C543.946 191.698 542.898 192.745 542.898 194.028C542.898 195.31 543.946 196.358 545.229 196.358C546.513 196.358 547.561 195.31 547.561 194.028C547.561 192.745 546.513 191.698 545.229 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M545.229 198.154C543.946 198.154 542.898 199.201 542.898 200.484C542.898 201.767 543.946 202.814 545.229 202.814C546.513 202.814 547.561 201.767 547.561 200.484C547.561 199.201 546.513 198.154 545.229 198.154Z"
            fill="#ADADAD"
          />
          <path
            d="M545.229 204.598C543.946 204.598 542.898 205.646 542.898 206.928C542.898 208.211 543.946 209.258 545.229 209.258C546.513 209.258 547.561 208.211 547.561 206.928C547.561 205.646 546.513 204.598 545.229 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M545.229 211.054C543.946 211.054 542.898 212.102 542.898 213.395C542.898 214.678 543.946 215.725 545.229 215.725C546.513 215.725 547.561 214.678 547.561 213.395C547.561 212.102 546.513 211.054 545.229 211.054Z"
            fill="#ADADAD"
          />
          <path
            d="M545.229 217.51C543.946 217.51 542.898 218.558 542.898 219.841C542.898 221.123 543.946 222.181 545.229 222.181C546.513 222.181 547.561 221.134 547.561 219.841C547.561 218.558 546.513 217.51 545.229 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M545.229 223.967C543.946 223.967 542.898 225.014 542.898 226.297C542.898 227.579 543.946 228.627 545.229 228.627C546.513 228.627 547.561 227.579 547.561 226.297C547.561 225.004 546.513 223.967 545.229 223.967Z"
            fill="#ADADAD"
          />
          <path
            d="M545.229 230.411C543.946 230.411 542.898 231.458 542.898 232.752C542.898 234.034 543.946 235.082 545.229 235.082C546.513 235.082 547.561 234.034 547.561 232.752C547.561 231.458 546.513 230.411 545.229 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M545.229 236.867C543.946 236.867 542.898 237.915 542.898 239.208C542.898 240.491 543.946 241.538 545.229 241.538C546.513 241.538 547.561 240.491 547.561 239.208C547.561 237.915 546.513 236.867 545.229 236.867Z"
            fill="#ADADAD"
          />
          <path
            d="M545.229 243.313C543.946 243.313 542.898 244.36 542.898 245.654C542.898 246.936 543.946 247.984 545.229 247.984C546.513 247.984 547.561 246.936 547.561 245.654C547.561 244.36 546.513 243.313 545.229 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M545.229 249.768C543.946 249.768 542.898 250.815 542.898 252.098C542.898 253.38 543.946 254.428 545.229 254.428C546.513 254.428 547.561 253.38 547.561 252.098C547.561 250.815 546.513 249.768 545.229 249.768Z"
            fill="#ADADAD"
          />
          <path
            d="M545.229 256.225C543.946 256.225 542.898 257.273 542.898 258.566C542.898 259.849 543.946 260.896 545.229 260.896C546.513 260.896 547.561 259.849 547.561 258.566C547.561 257.273 546.513 256.225 545.229 256.225Z"
            fill="#ADADAD"
          />
          <path
            d="M545.229 262.68C543.946 262.68 542.898 263.728 542.898 265.01C542.898 266.293 543.946 267.34 545.229 267.34C546.513 267.34 547.561 266.293 547.561 265.01C547.561 263.728 546.513 262.68 545.229 262.68Z"
            fill="#ADADAD"
          />
          <path
            d="M545.229 269.126C543.946 269.126 542.898 270.173 542.898 271.456C542.898 272.739 543.946 273.786 545.229 273.786C546.513 273.786 547.561 272.739 547.561 271.456C547.561 270.173 546.513 269.126 545.229 269.126Z"
            fill="#ADADAD"
          />
          <path
            d="M545.229 275.582C543.946 275.582 542.898 276.63 542.898 277.923C542.898 279.206 543.946 280.253 545.229 280.253C546.513 280.253 547.561 279.206 547.561 277.923C547.561 276.63 546.513 275.582 545.229 275.582Z"
            fill="#ADADAD"
          />
          <path
            d="M538.77 165.885C537.486 165.885 536.438 166.932 536.438 168.215C536.438 169.497 537.486 170.545 538.77 170.545C540.053 170.545 541.112 169.497 541.112 168.215C541.112 166.932 540.064 165.885 538.77 165.885Z"
            fill="#ADADAD"
          />
          <path
            d="M538.77 172.339C537.486 172.339 536.438 173.387 536.438 174.68C536.438 175.963 537.486 177.01 538.77 177.01C540.053 177.01 541.112 175.963 541.112 174.68C541.112 173.387 540.064 172.339 538.77 172.339Z"
            fill="#ADADAD"
          />
          <path
            d="M538.77 178.785C537.486 178.785 536.438 179.833 536.438 181.115C536.438 182.398 537.486 183.445 538.77 183.445C540.053 183.445 541.112 182.398 541.112 181.115C541.112 179.833 540.064 178.785 538.77 178.785Z"
            fill="#ADADAD"
          />
          <path
            d="M538.77 185.241C537.486 185.241 536.438 186.289 536.438 187.572C536.438 188.865 537.486 189.912 538.77 189.912C540.053 189.912 541.112 188.865 541.112 187.572C541.112 186.289 540.064 185.241 538.77 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M538.77 191.698C537.486 191.698 536.438 192.745 536.438 194.028C536.438 195.31 537.486 196.358 538.77 196.358C540.053 196.358 541.112 195.31 541.112 194.028C541.112 192.745 540.064 191.698 538.77 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M538.77 198.154C537.486 198.154 536.438 199.201 536.438 200.484C536.438 201.767 537.486 202.814 538.77 202.814C540.053 202.814 541.112 201.767 541.112 200.484C541.112 199.201 540.064 198.154 538.77 198.154Z"
            fill="#ADADAD"
          />
          <path
            d="M538.77 204.598C537.486 204.598 536.438 205.646 536.438 206.928C536.438 208.211 537.486 209.258 538.77 209.258C540.053 209.258 541.112 208.211 541.112 206.928C541.112 205.646 540.064 204.598 538.77 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M538.77 211.054C537.486 211.054 536.438 212.102 536.438 213.395C536.438 214.678 537.486 215.725 538.77 215.725C540.053 215.725 541.112 214.678 541.112 213.395C541.112 212.102 540.064 211.054 538.77 211.054Z"
            fill="#ADADAD"
          />
          <path
            d="M538.77 217.51C537.486 217.51 536.438 218.558 536.438 219.841C536.438 221.123 537.486 222.181 538.77 222.181C540.053 222.181 541.112 221.134 541.112 219.841C541.112 218.558 540.064 217.51 538.77 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M538.77 223.967C537.486 223.967 536.438 225.014 536.438 226.297C536.438 227.579 537.486 228.627 538.77 228.627C540.053 228.627 541.112 227.579 541.112 226.297C541.112 225.004 540.064 223.967 538.77 223.967Z"
            fill="#ADADAD"
          />
          <path
            d="M538.77 230.411C537.486 230.411 536.438 231.458 536.438 232.752C536.438 234.034 537.486 235.082 538.77 235.082C540.053 235.082 541.112 234.034 541.112 232.752C541.112 231.458 540.064 230.411 538.77 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M538.77 236.867C537.486 236.867 536.438 237.915 536.438 239.208C536.438 240.491 537.486 241.538 538.77 241.538C540.053 241.538 541.112 240.491 541.112 239.208C541.112 237.915 540.064 236.867 538.77 236.867Z"
            fill="#ADADAD"
          />
          <path
            d="M538.77 243.313C537.486 243.313 536.438 244.36 536.438 245.654C536.438 246.936 537.486 247.984 538.77 247.984C540.053 247.984 541.112 246.936 541.112 245.654C541.112 244.36 540.064 243.313 538.77 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M538.77 249.768C537.486 249.768 536.438 250.815 536.438 252.098C536.438 253.38 537.486 254.428 538.77 254.428C540.053 254.428 541.112 253.38 541.112 252.098C541.112 250.815 540.064 249.768 538.77 249.768Z"
            fill="#ADADAD"
          />
          <path
            d="M538.77 256.225C537.486 256.225 536.438 257.273 536.438 258.566C536.438 259.849 537.486 260.896 538.77 260.896C540.053 260.896 541.112 259.849 541.112 258.566C541.112 257.273 540.064 256.225 538.77 256.225Z"
            fill="#ADADAD"
          />
          <path
            d="M538.77 262.68C537.486 262.68 536.438 263.728 536.438 265.01C536.438 266.293 537.486 267.34 538.77 267.34C540.053 267.34 541.112 266.293 541.112 265.01C541.112 263.728 540.064 262.68 538.77 262.68Z"
            fill="#ADADAD"
          />
          <path
            d="M538.77 269.126C537.486 269.126 536.438 270.173 536.438 271.456C536.438 272.739 537.486 273.786 538.77 273.786C540.053 273.786 541.112 272.739 541.112 271.456C541.112 270.173 540.064 269.126 538.77 269.126Z"
            fill="#ADADAD"
          />
          <path
            d="M538.77 275.582C537.486 275.582 536.438 276.63 536.438 277.923C536.438 279.206 537.486 280.253 538.77 280.253C540.053 280.253 541.112 279.206 541.112 277.923C541.112 276.63 540.064 275.582 538.77 275.582Z"
            fill="#ADADAD"
          />
          <path
            d="M538.77 282.038C537.486 282.038 536.438 283.086 536.438 284.368C536.438 285.651 537.486 286.699 538.77 286.699C540.053 286.699 541.112 285.651 541.112 284.368C541.112 283.086 540.064 282.038 538.77 282.038Z"
            fill="#ADADAD"
          />
          <path
            d="M532.319 185.241C531.036 185.241 529.988 186.289 529.988 187.572C529.988 188.865 531.036 189.912 532.319 189.912C533.603 189.912 534.651 188.865 534.651 187.572C534.651 186.289 533.603 185.241 532.319 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M532.319 191.698C531.036 191.698 529.988 192.745 529.988 194.028C529.988 195.31 531.036 196.358 532.319 196.358C533.603 196.358 534.651 195.31 534.651 194.028C534.651 192.745 533.603 191.698 532.319 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M532.319 198.154C531.036 198.154 529.988 199.201 529.988 200.484C529.988 201.767 531.036 202.814 532.319 202.814C533.603 202.814 534.651 201.767 534.651 200.484C534.651 199.201 533.603 198.154 532.319 198.154Z"
            fill="#ADADAD"
          />
          <path
            d="M532.319 204.598C531.036 204.598 529.988 205.646 529.988 206.928C529.988 208.211 531.036 209.258 532.319 209.258C533.603 209.258 534.651 208.211 534.651 206.928C534.651 205.646 533.603 204.598 532.319 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M532.319 211.054C531.036 211.054 529.988 212.102 529.988 213.395C529.988 214.678 531.036 215.725 532.319 215.725C533.603 215.725 534.651 214.678 534.651 213.395C534.651 212.102 533.603 211.054 532.319 211.054Z"
            fill="#ADADAD"
          />
          <path
            d="M532.319 217.51C531.036 217.51 529.988 218.558 529.988 219.841C529.988 221.123 531.036 222.181 532.319 222.181C533.603 222.181 534.651 221.134 534.651 219.841C534.651 218.558 533.603 217.51 532.319 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M532.319 223.967C531.036 223.967 529.988 225.014 529.988 226.297C529.988 227.579 531.036 228.627 532.319 228.627C533.603 228.627 534.651 227.579 534.651 226.297C534.651 225.004 533.603 223.967 532.319 223.967Z"
            fill="#ADADAD"
          />
          <path
            d="M532.319 230.411C531.036 230.411 529.988 231.458 529.988 232.752C529.988 234.034 531.036 235.082 532.319 235.082C533.603 235.082 534.651 234.034 534.651 232.752C534.651 231.458 533.603 230.411 532.319 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M532.319 236.867C531.036 236.867 529.988 237.915 529.988 239.208C529.988 240.491 531.036 241.538 532.319 241.538C533.603 241.538 534.651 240.491 534.651 239.208C534.651 237.915 533.603 236.867 532.319 236.867Z"
            fill="#ADADAD"
          />
          <path
            d="M532.319 243.313C531.036 243.313 529.988 244.36 529.988 245.654C529.988 246.936 531.036 247.984 532.319 247.984C533.603 247.984 534.651 246.936 534.651 245.654C534.651 244.36 533.603 243.313 532.319 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M532.319 249.768C531.036 249.768 529.988 250.815 529.988 252.098C529.988 253.38 531.036 254.428 532.319 254.428C533.603 254.428 534.651 253.38 534.651 252.098C534.651 250.815 533.603 249.768 532.319 249.768Z"
            fill="#ADADAD"
          />
          <path
            d="M532.319 256.225C531.036 256.225 529.988 257.273 529.988 258.566C529.988 259.849 531.036 260.896 532.319 260.896C533.603 260.896 534.651 259.849 534.651 258.566C534.651 257.273 533.603 256.225 532.319 256.225Z"
            fill="#ADADAD"
          />
          <path
            d="M532.319 262.68C531.036 262.68 529.988 263.728 529.988 265.01C529.988 266.293 531.036 267.34 532.319 267.34C533.603 267.34 534.651 266.293 534.651 265.01C534.651 263.728 533.603 262.68 532.319 262.68Z"
            fill="#ADADAD"
          />
          <path
            d="M532.319 269.126C531.036 269.126 529.988 270.173 529.988 271.456C529.988 272.739 531.036 273.786 532.319 273.786C533.603 273.786 534.651 272.739 534.651 271.456C534.651 270.173 533.603 269.126 532.319 269.126Z"
            fill="#ADADAD"
          />
          <path
            d="M532.319 275.582C531.036 275.582 529.988 276.63 529.988 277.923C529.988 279.206 531.036 280.253 532.319 280.253C533.603 280.253 534.651 279.206 534.651 277.923C534.651 276.63 533.603 275.582 532.319 275.582Z"
            fill="#ADADAD"
          />
          <path
            d="M525.851 185.241C524.568 185.241 523.52 186.289 523.52 187.572C523.52 188.865 524.568 189.912 525.851 189.912C527.145 189.912 528.193 188.865 528.193 187.572C528.193 186.289 527.145 185.241 525.851 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M525.851 191.698C524.568 191.698 523.52 192.745 523.52 194.028C523.52 195.31 524.568 196.358 525.851 196.358C527.145 196.358 528.193 195.31 528.193 194.028C528.193 192.745 527.145 191.698 525.851 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M525.851 198.154C524.568 198.154 523.52 199.201 523.52 200.484C523.52 201.767 524.568 202.814 525.851 202.814C527.145 202.814 528.193 201.767 528.193 200.484C528.193 199.201 527.145 198.154 525.851 198.154Z"
            fill="#ADADAD"
          />
          <path
            d="M525.851 204.598C524.568 204.598 523.52 205.646 523.52 206.928C523.52 208.211 524.568 209.258 525.851 209.258C527.145 209.258 528.193 208.211 528.193 206.928C528.193 205.646 527.145 204.598 525.851 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M525.851 211.054C524.568 211.054 523.52 212.102 523.52 213.395C523.52 214.678 524.568 215.725 525.851 215.725C527.145 215.725 528.193 214.678 528.193 213.395C528.193 212.102 527.145 211.054 525.851 211.054Z"
            fill="#ADADAD"
          />
          <path
            d="M525.851 217.51C524.568 217.51 523.52 218.558 523.52 219.841C523.52 221.123 524.568 222.181 525.851 222.181C527.145 222.181 528.193 221.134 528.193 219.841C528.193 218.558 527.145 217.51 525.851 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M525.851 223.967C524.568 223.967 523.52 225.014 523.52 226.297C523.52 227.579 524.568 228.627 525.851 228.627C527.145 228.627 528.193 227.579 528.193 226.297C528.193 225.004 527.145 223.967 525.851 223.967Z"
            fill="#ADADAD"
          />
          <path
            d="M525.851 230.411C524.568 230.411 523.52 231.458 523.52 232.752C523.52 234.034 524.568 235.082 525.851 235.082C527.145 235.082 528.193 234.034 528.193 232.752C528.193 231.458 527.145 230.411 525.851 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M525.851 236.867C524.568 236.867 523.52 237.915 523.52 239.208C523.52 240.491 524.568 241.538 525.851 241.538C527.145 241.538 528.193 240.491 528.193 239.208C528.193 237.915 527.145 236.867 525.851 236.867Z"
            fill="#ADADAD"
          />
          <path
            d="M525.851 243.313C524.568 243.313 523.52 244.36 523.52 245.654C523.52 246.936 524.568 247.984 525.851 247.984C527.145 247.984 528.193 246.936 528.193 245.654C528.193 244.36 527.145 243.313 525.851 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M525.851 249.768C524.568 249.768 523.52 250.815 523.52 252.098C523.52 253.38 524.568 254.428 525.851 254.428C527.145 254.428 528.193 253.38 528.193 252.098C528.193 250.815 527.145 249.768 525.851 249.768Z"
            fill="#ADADAD"
          />
          <path
            d="M525.851 256.225C524.568 256.225 523.52 257.273 523.52 258.566C523.52 259.849 524.568 260.896 525.851 260.896C527.145 260.896 528.193 259.849 528.193 258.566C528.193 257.273 527.145 256.225 525.851 256.225Z"
            fill="#ADADAD"
          />
          <path
            d="M519.401 185.241C518.117 185.241 517.069 186.289 517.069 187.571C517.069 188.865 518.117 189.912 519.401 189.912C520.684 189.912 521.732 188.865 521.732 187.571C521.732 186.289 520.684 185.241 519.401 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M519.401 191.698C518.117 191.698 517.069 192.745 517.069 194.028C517.069 195.31 518.117 196.358 519.401 196.358C520.684 196.358 521.732 195.31 521.732 194.028C521.732 192.745 520.684 191.698 519.401 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M519.401 198.153C518.117 198.153 517.069 199.201 517.069 200.483C517.069 201.766 518.117 202.813 519.401 202.813C520.684 202.813 521.732 201.766 521.732 200.483C521.732 199.201 520.684 198.153 519.401 198.153Z"
            fill="#ADADAD"
          />
          <path
            d="M519.401 204.598C518.117 204.598 517.069 205.646 517.069 206.928C517.069 208.211 518.117 209.258 519.401 209.258C520.684 209.258 521.732 208.211 521.732 206.928C521.732 205.646 520.684 204.598 519.401 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M519.401 211.055C518.117 211.055 517.069 212.102 517.069 213.395C517.069 214.678 518.117 215.725 519.401 215.725C520.684 215.725 521.732 214.678 521.732 213.395C521.732 212.102 520.684 211.055 519.401 211.055Z"
            fill="#ADADAD"
          />
          <path
            d="M519.401 217.51C518.117 217.51 517.069 218.558 517.069 219.84C517.069 221.123 518.117 222.181 519.401 222.181C520.684 222.181 521.732 221.134 521.732 219.84C521.732 218.558 520.684 217.51 519.401 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M519.401 223.966C518.117 223.966 517.069 225.014 517.069 226.297C517.069 227.579 518.117 228.627 519.401 228.627C520.684 228.627 521.732 227.579 521.732 226.297C521.732 225.003 520.684 223.966 519.401 223.966Z"
            fill="#ADADAD"
          />
          <path
            d="M519.401 230.411C518.117 230.411 517.069 231.459 517.069 232.752C517.069 234.035 518.117 235.082 519.401 235.082C520.684 235.082 521.732 234.035 521.732 232.752C521.732 231.459 520.684 230.411 519.401 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M519.401 236.868C518.117 236.868 517.069 237.915 517.069 239.208C517.069 240.491 518.117 241.539 519.401 241.539C520.684 241.539 521.732 240.491 521.732 239.208C521.732 237.915 520.684 236.868 519.401 236.868Z"
            fill="#ADADAD"
          />
          <path
            d="M519.401 243.313C518.117 243.313 517.069 244.36 517.069 245.654C517.069 246.936 518.117 247.984 519.401 247.984C520.684 247.984 521.732 246.936 521.732 245.654C521.732 244.36 520.684 243.313 519.401 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M519.401 249.768C518.117 249.768 517.069 250.816 517.069 252.098C517.069 253.381 518.117 254.429 519.401 254.429C520.684 254.429 521.732 253.381 521.732 252.098C521.732 250.816 520.684 249.768 519.401 249.768Z"
            fill="#ADADAD"
          />
          <path
            d="M519.401 256.225C518.117 256.225 517.069 257.272 517.069 258.565C517.069 259.848 518.117 260.896 519.401 260.896C520.684 260.896 521.732 259.848 521.732 258.565C521.732 257.272 520.684 256.225 519.401 256.225Z"
            fill="#ADADAD"
          />
          <path
            d="M512.941 185.241C511.658 185.241 510.61 186.289 510.61 187.571C510.61 188.865 511.658 189.912 512.941 189.912C514.225 189.912 515.273 188.865 515.273 187.571C515.273 186.289 514.235 185.241 512.941 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M512.941 191.698C511.658 191.698 510.61 192.745 510.61 194.028C510.61 195.31 511.658 196.358 512.941 196.358C514.225 196.358 515.273 195.31 515.273 194.028C515.273 192.745 514.235 191.698 512.941 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M512.941 198.153C511.658 198.153 510.61 199.201 510.61 200.483C510.61 201.766 511.658 202.813 512.941 202.813C514.225 202.813 515.273 201.766 515.273 200.483C515.273 199.201 514.235 198.153 512.941 198.153Z"
            fill="#ADADAD"
          />
          <path
            d="M506.493 185.241C505.209 185.241 504.151 186.289 504.151 187.571C504.151 188.865 505.199 189.912 506.493 189.912C507.776 189.912 508.824 188.865 508.824 187.571C508.824 186.289 507.776 185.241 506.493 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M506.493 191.698C505.209 191.698 504.151 192.745 504.151 194.028C504.151 195.31 505.199 196.358 506.493 196.358C507.776 196.358 508.824 195.31 508.824 194.028C508.824 192.745 507.776 191.698 506.493 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M506.493 198.153C505.209 198.153 504.151 199.201 504.151 200.483C504.151 201.766 505.199 202.813 506.493 202.813C507.776 202.813 508.824 201.766 508.824 200.483C508.824 199.201 507.776 198.153 506.493 198.153Z"
            fill="#ADADAD"
          />
          <path
            d="M454.837 340.109C453.553 340.109 452.505 341.157 452.505 342.439C452.505 343.722 453.553 344.769 454.837 344.769C456.131 344.769 457.179 343.722 457.179 342.439C457.168 341.157 456.12 340.109 454.837 340.109Z"
            fill="#ADADAD"
          />
          <path
            d="M448.377 340.109C447.094 340.109 446.046 341.157 446.046 342.439C446.046 343.722 447.094 344.769 448.377 344.769C449.661 344.769 450.709 343.722 450.709 342.439C450.709 341.157 449.661 340.109 448.377 340.109Z"
            fill="#ADADAD"
          />
          <path
            d="M448.377 346.565C447.094 346.565 446.046 347.612 446.046 348.895C446.046 350.178 447.094 351.225 448.377 351.225C449.661 351.225 450.709 350.178 450.709 348.895C450.709 347.612 449.661 346.565 448.377 346.565Z"
            fill="#ADADAD"
          />
          <path
            d="M448.377 353.021C447.094 353.021 446.046 354.069 446.046 355.362C446.046 356.645 447.094 357.692 448.377 357.692C449.661 357.692 450.709 356.645 450.709 355.362C450.709 354.069 449.661 353.021 448.377 353.021Z"
            fill="#ADADAD"
          />
          <path
            d="M448.377 359.477C447.094 359.477 446.046 360.524 446.046 361.807C446.046 363.09 447.094 364.137 448.377 364.137C449.661 364.137 450.709 363.09 450.709 361.807C450.709 360.514 449.661 359.477 448.377 359.477Z"
            fill="#ADADAD"
          />
          <path
            d="M441.929 340.109C440.635 340.109 439.586 341.157 439.586 342.439C439.586 343.722 440.635 344.769 441.929 344.769C443.212 344.769 444.26 343.722 444.26 342.439C444.26 341.157 443.212 340.109 441.929 340.109Z"
            fill="#ADADAD"
          />
          <path
            d="M441.929 346.565C440.635 346.565 439.586 347.612 439.586 348.895C439.586 350.178 440.635 351.225 441.929 351.225C443.212 351.225 444.26 350.178 444.26 348.895C444.26 347.612 443.212 346.565 441.929 346.565Z"
            fill="#ADADAD"
          />
          <path
            d="M441.929 353.021C440.635 353.021 439.586 354.069 439.586 355.362C439.586 356.645 440.635 357.692 441.929 357.692C443.212 357.692 444.26 356.645 444.26 355.362C444.26 354.069 443.212 353.021 441.929 353.021Z"
            fill="#ADADAD"
          />
          <path
            d="M441.929 359.477C440.635 359.477 439.586 360.524 439.586 361.807C439.586 363.09 440.635 364.137 441.929 364.137C443.212 364.137 444.26 363.09 444.26 361.807C444.26 360.514 443.212 359.477 441.929 359.477Z"
            fill="#ADADAD"
          />
          <path
            d="M435.47 340.11C434.186 340.11 433.138 341.157 433.138 342.44C433.138 343.723 434.186 344.77 435.47 344.77C436.753 344.77 437.801 343.723 437.801 342.44C437.801 341.157 436.753 340.11 435.47 340.11Z"
            fill="#ADADAD"
          />
          <path
            d="M435.47 346.565C434.186 346.565 433.138 347.612 433.138 348.895C433.138 350.177 434.186 351.225 435.47 351.225C436.753 351.225 437.801 350.177 437.801 348.895C437.801 347.612 436.753 346.565 435.47 346.565Z"
            fill="#ADADAD"
          />
          <path
            d="M435.47 353.021C434.186 353.021 433.138 354.068 433.138 355.362C433.138 356.644 434.186 357.692 435.47 357.692C436.753 357.692 437.801 356.644 437.801 355.362C437.801 354.068 436.753 353.021 435.47 353.021Z"
            fill="#ADADAD"
          />
          <path
            d="M435.47 359.477C434.186 359.477 433.138 360.524 433.138 361.807C433.138 363.09 434.186 364.137 435.47 364.137C436.753 364.137 437.801 363.09 437.801 361.807C437.801 360.514 436.753 359.477 435.47 359.477Z"
            fill="#ADADAD"
          />
          <path
            d="M435.47 365.923C434.186 365.923 433.138 366.97 433.138 368.264C433.138 369.546 434.186 370.594 435.47 370.594C436.753 370.594 437.801 369.546 437.801 368.264C437.801 366.97 436.753 365.923 435.47 365.923Z"
            fill="#ADADAD"
          />
          <path
            d="M435.47 372.377C434.186 372.377 433.138 373.425 433.138 374.708C433.138 375.99 434.186 377.038 435.47 377.038C436.753 377.038 437.801 375.99 437.801 374.708C437.801 373.425 436.753 372.377 435.47 372.377Z"
            fill="#ADADAD"
          />
          <path
            d="M429.01 353.021C427.727 353.021 426.679 354.068 426.679 355.362C426.679 356.644 427.727 357.692 429.01 357.692C430.294 357.692 431.342 356.644 431.342 355.362C431.342 354.068 430.294 353.021 429.01 353.021Z"
            fill="#ADADAD"
          />
          <path
            d="M429.01 359.477C427.727 359.477 426.679 360.524 426.679 361.807C426.679 363.09 427.727 364.137 429.01 364.137C430.294 364.137 431.342 363.09 431.342 361.807C431.342 360.514 430.294 359.477 429.01 359.477Z"
            fill="#ADADAD"
          />
          <path
            d="M429.01 365.923C427.727 365.923 426.679 366.97 426.679 368.264C426.679 369.546 427.727 370.594 429.01 370.594C430.294 370.594 431.342 369.546 431.342 368.264C431.342 366.97 430.294 365.923 429.01 365.923Z"
            fill="#ADADAD"
          />
          <path
            d="M429.01 372.377C427.727 372.377 426.679 373.425 426.679 374.708C426.679 375.99 427.727 377.038 429.01 377.038C430.294 377.038 431.342 375.99 431.342 374.708C431.342 373.425 430.294 372.377 429.01 372.377Z"
            fill="#ADADAD"
          />
          <path
            d="M429.01 383.494C430.298 383.494 431.342 382.451 431.342 381.164C431.342 379.877 430.298 378.834 429.01 378.834C427.723 378.834 426.679 379.877 426.679 381.164C426.679 382.451 427.723 383.494 429.01 383.494Z"
            fill="#ADADAD"
          />
          <path
            d="M422.551 353.021C421.268 353.021 420.22 354.068 420.22 355.362C420.22 356.644 421.268 357.692 422.551 357.692C423.845 357.692 424.893 356.644 424.893 355.362C424.893 354.068 423.845 353.021 422.551 353.021Z"
            fill="#ADADAD"
          />
          <path
            d="M422.551 359.477C421.268 359.477 420.22 360.524 420.22 361.807C420.22 363.09 421.268 364.137 422.551 364.137C423.845 364.137 424.893 363.09 424.893 361.807C424.893 360.514 423.845 359.477 422.551 359.477Z"
            fill="#ADADAD"
          />
          <path
            d="M422.551 365.923C421.268 365.923 420.22 366.97 420.22 368.264C420.22 369.546 421.268 370.594 422.551 370.594C423.845 370.594 424.893 369.546 424.893 368.264C424.893 366.97 423.845 365.923 422.551 365.923Z"
            fill="#ADADAD"
          />
          <path
            d="M422.551 372.377C421.268 372.377 420.22 373.425 420.22 374.708C420.22 375.99 421.268 377.038 422.551 377.038C423.845 377.038 424.893 375.99 424.893 374.708C424.893 373.425 423.845 372.377 422.551 372.377Z"
            fill="#ADADAD"
          />
          <path
            d="M422.551 378.834C421.268 378.834 420.22 379.881 420.22 381.164C420.22 382.446 421.268 383.494 422.551 383.494C423.845 383.494 424.893 382.446 424.893 381.164C424.893 379.881 423.845 378.834 422.551 378.834Z"
            fill="#ADADAD"
          />
          <path
            d="M416.089 353.021C414.805 353.021 413.757 354.068 413.757 355.362C413.757 356.644 414.805 357.692 416.089 357.692C417.372 357.692 418.42 356.644 418.42 355.362C418.431 354.068 417.383 353.021 416.089 353.021Z"
            fill="#ADADAD"
          />
          <path
            d="M416.089 359.477C414.805 359.477 413.757 360.524 413.757 361.807C413.757 363.09 414.805 364.137 416.089 364.137C417.372 364.137 418.42 363.09 418.42 361.807C418.431 360.514 417.383 359.477 416.089 359.477Z"
            fill="#ADADAD"
          />
          <path
            d="M416.089 365.923C414.805 365.923 413.757 366.97 413.757 368.264C413.757 369.546 414.805 370.594 416.089 370.594C417.372 370.594 418.42 369.546 418.42 368.264C418.431 366.97 417.383 365.923 416.089 365.923Z"
            fill="#ADADAD"
          />
          <path
            d="M416.089 372.377C414.805 372.377 413.757 373.425 413.757 374.708C413.757 375.99 414.805 377.038 416.089 377.038C417.372 377.038 418.42 375.99 418.42 374.708C418.431 373.425 417.383 372.377 416.089 372.377Z"
            fill="#ADADAD"
          />
          <path
            d="M416.089 378.834C414.805 378.834 413.757 379.881 413.757 381.164C413.757 382.446 414.805 383.494 416.089 383.494C417.372 383.494 418.42 382.446 418.42 381.164C418.42 379.881 417.383 378.834 416.089 378.834Z"
            fill="#ADADAD"
          />
          <path
            d="M409.64 353.021C408.357 353.021 407.298 354.068 407.298 355.362C407.298 356.644 408.346 357.692 409.64 357.692C410.923 357.692 411.971 356.644 411.971 355.362C411.971 354.068 410.923 353.021 409.64 353.021Z"
            fill="#ADADAD"
          />
          <path
            d="M409.64 359.477C408.357 359.477 407.298 360.524 407.298 361.807C407.298 363.09 408.346 364.137 409.64 364.137C410.923 364.137 411.971 363.09 411.971 361.807C411.971 360.514 410.923 359.477 409.64 359.477Z"
            fill="#ADADAD"
          />
          <path
            d="M409.64 365.923C408.357 365.923 407.298 366.97 407.298 368.264C407.298 369.546 408.346 370.594 409.64 370.594C410.923 370.594 411.971 369.546 411.971 368.264C411.971 366.97 410.923 365.923 409.64 365.923Z"
            fill="#ADADAD"
          />
          <path
            d="M409.64 372.377C408.357 372.377 407.298 373.425 407.298 374.708C407.298 375.99 408.346 377.038 409.64 377.038C410.923 377.038 411.971 375.99 411.971 374.708C411.971 373.425 410.923 372.377 409.64 372.377Z"
            fill="#ADADAD"
          />
          <path
            d="M409.64 378.834C408.357 378.834 407.298 379.881 407.298 381.164C407.298 382.446 408.346 383.494 409.64 383.494C410.923 383.494 411.971 382.446 411.971 381.164C411.971 379.881 410.923 378.834 409.64 378.834Z"
            fill="#ADADAD"
          />
          <path
            d="M403.182 353.021C401.899 353.021 400.851 354.069 400.851 355.362C400.851 356.645 401.899 357.692 403.182 357.692C404.466 357.692 405.514 356.645 405.514 355.362C405.514 354.069 404.466 353.021 403.182 353.021Z"
            fill="#ADADAD"
          />
          <path
            d="M403.182 359.477C401.899 359.477 400.851 360.524 400.851 361.807C400.851 363.09 401.899 364.137 403.182 364.137C404.466 364.137 405.514 363.09 405.514 361.807C405.514 360.514 404.466 359.477 403.182 359.477Z"
            fill="#ADADAD"
          />
          <path
            d="M441.929 198.153C440.635 198.153 439.586 199.201 439.586 200.483C439.586 201.766 440.635 202.813 441.929 202.813C443.212 202.813 444.26 201.766 444.26 200.483C444.26 199.201 443.212 198.153 441.929 198.153Z"
            fill="#ADADAD"
          />
          <path
            d="M435.47 191.698C434.186 191.698 433.138 192.745 433.138 194.028C433.138 195.31 434.186 196.358 435.47 196.358C436.753 196.358 437.801 195.31 437.801 194.028C437.801 192.745 436.753 191.698 435.47 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M435.47 198.154C434.186 198.154 433.138 199.201 433.138 200.484C433.138 201.767 434.186 202.814 435.47 202.814C436.753 202.814 437.801 201.767 437.801 200.484C437.801 199.201 436.753 198.154 435.47 198.154Z"
            fill="#ADADAD"
          />
          <path
            d="M429.01 191.698C427.727 191.698 426.679 192.745 426.679 194.028C426.679 195.31 427.727 196.358 429.01 196.358C430.294 196.358 431.342 195.31 431.342 194.028C431.342 192.745 430.294 191.698 429.01 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M429.01 198.154C427.727 198.154 426.679 199.201 426.679 200.484C426.679 201.767 427.727 202.814 429.01 202.814C430.294 202.814 431.342 201.767 431.342 200.484C431.342 199.201 430.294 198.154 429.01 198.154Z"
            fill="#ADADAD"
          />
          <path
            d="M429.01 204.598C427.727 204.598 426.679 205.646 426.679 206.928C426.679 208.211 427.727 209.258 429.01 209.258C430.294 209.258 431.342 208.211 431.342 206.928C431.342 205.646 430.294 204.598 429.01 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M429.01 211.054C427.727 211.054 426.679 212.102 426.679 213.395C426.679 214.678 427.727 215.725 429.01 215.725C430.294 215.725 431.342 214.678 431.342 213.395C431.342 212.102 430.294 211.054 429.01 211.054Z"
            fill="#ADADAD"
          />
          <path
            d="M429.01 217.51C427.727 217.51 426.679 218.558 426.679 219.841C426.679 221.123 427.727 222.181 429.01 222.181C430.294 222.181 431.342 221.134 431.342 219.841C431.342 218.558 430.294 217.51 429.01 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M422.551 191.698C421.268 191.698 420.22 192.745 420.22 194.028C420.22 195.31 421.268 196.358 422.551 196.358C423.845 196.358 424.893 195.31 424.893 194.028C424.893 192.745 423.845 191.698 422.551 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M422.551 198.154C421.268 198.154 420.22 199.201 420.22 200.484C420.22 201.767 421.268 202.814 422.551 202.814C423.845 202.814 424.893 201.767 424.893 200.484C424.893 199.201 423.845 198.154 422.551 198.154Z"
            fill="#ADADAD"
          />
          <path
            d="M422.551 204.598C421.268 204.598 420.22 205.646 420.22 206.928C420.22 208.211 421.268 209.258 422.551 209.258C423.845 209.258 424.893 208.211 424.893 206.928C424.893 205.646 423.845 204.598 422.551 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M422.551 211.054C421.268 211.054 420.22 212.102 420.22 213.395C420.22 214.678 421.268 215.725 422.551 215.725C423.845 215.725 424.893 214.678 424.893 213.395C424.893 212.102 423.845 211.054 422.551 211.054Z"
            fill="#ADADAD"
          />
          <path
            d="M422.551 217.51C421.268 217.51 420.22 218.558 420.22 219.841C420.22 221.123 421.268 222.181 422.551 222.181C423.845 222.181 424.893 221.134 424.893 219.841C424.893 218.558 423.845 217.51 422.551 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M416.089 191.698C414.805 191.698 413.757 192.745 413.757 194.028C413.757 195.31 414.805 196.358 416.089 196.358C417.372 196.358 418.42 195.31 418.42 194.028C418.431 192.745 417.383 191.698 416.089 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M416.089 198.154C414.805 198.154 413.757 199.201 413.757 200.484C413.757 201.767 414.805 202.814 416.089 202.814C417.372 202.814 418.42 201.767 418.42 200.484C418.431 199.201 417.383 198.154 416.089 198.154Z"
            fill="#ADADAD"
          />
          <path
            d="M416.089 204.598C414.805 204.598 413.757 205.646 413.757 206.928C413.757 208.211 414.805 209.258 416.089 209.258C417.372 209.258 418.42 208.211 418.42 206.928C418.431 205.646 417.383 204.598 416.089 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M416.089 211.054C414.805 211.054 413.757 212.102 413.757 213.395C413.757 214.678 414.805 215.725 416.089 215.725C417.372 215.725 418.42 214.678 418.42 213.395C418.431 212.102 417.383 211.054 416.089 211.054Z"
            fill="#ADADAD"
          />
          <path
            d="M416.089 217.51C414.805 217.51 413.757 218.558 413.757 219.841C413.757 221.123 414.805 222.181 416.089 222.181C417.372 222.181 418.42 221.134 418.42 219.841C418.431 218.558 417.383 217.51 416.089 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M409.64 191.698C408.357 191.698 407.298 192.745 407.298 194.028C407.298 195.31 408.346 196.358 409.64 196.358C410.923 196.358 411.971 195.31 411.971 194.028C411.971 192.745 410.923 191.698 409.64 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M409.64 198.154C408.357 198.154 407.298 199.201 407.298 200.484C407.298 201.767 408.346 202.814 409.64 202.814C410.923 202.814 411.971 201.767 411.971 200.484C411.971 199.201 410.923 198.154 409.64 198.154Z"
            fill="#ADADAD"
          />
          <path
            d="M409.64 204.598C408.357 204.598 407.298 205.646 407.298 206.928C407.298 208.211 408.346 209.258 409.64 209.258C410.923 209.258 411.971 208.211 411.971 206.928C411.971 205.646 410.923 204.598 409.64 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M403.182 191.698C401.899 191.698 400.851 192.745 400.851 194.028C400.851 195.31 401.899 196.358 403.182 196.358C404.466 196.358 405.514 195.31 405.514 194.028C405.514 192.745 404.466 191.698 403.182 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M403.182 198.153C401.899 198.153 400.851 199.201 400.851 200.483C400.851 201.766 401.899 202.813 403.182 202.813C404.466 202.813 405.514 201.766 405.514 200.483C405.514 199.201 404.466 198.153 403.182 198.153Z"
            fill="#ADADAD"
          />
          <path
            d="M403.182 211.055C401.899 211.055 400.851 212.102 400.851 213.395C400.851 214.678 401.899 215.725 403.182 215.725C404.466 215.725 405.514 214.678 405.514 213.395C405.514 212.102 404.466 211.055 403.182 211.055Z"
            fill="#ADADAD"
          />
          <path
            d="M403.182 217.51C401.899 217.51 400.851 218.558 400.851 219.84C400.851 221.123 401.899 222.181 403.182 222.181C404.466 222.181 405.514 221.134 405.514 219.84C405.514 218.558 404.466 217.51 403.182 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M396.721 191.698C395.438 191.698 394.379 192.745 394.379 194.028C394.379 195.31 395.427 196.358 396.721 196.358C398.004 196.358 399.052 195.31 399.052 194.028C399.063 192.745 398.015 191.698 396.721 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M396.721 198.154C395.438 198.154 394.379 199.201 394.379 200.484C394.379 201.767 395.427 202.814 396.721 202.814C398.004 202.814 399.052 201.767 399.052 200.484C399.063 199.201 398.015 198.154 396.721 198.154Z"
            fill="#ADADAD"
          />
          <path
            d="M396.721 204.598C395.438 204.598 394.379 205.646 394.379 206.928C394.379 208.211 395.427 209.258 396.721 209.258C398.004 209.258 399.052 208.211 399.052 206.928C399.063 205.646 398.015 204.598 396.721 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M396.721 217.51C395.438 217.51 394.379 218.558 394.379 219.841C394.379 221.123 395.427 222.181 396.721 222.181C398.004 222.181 399.052 221.134 399.052 219.841C399.063 218.558 398.015 217.51 396.721 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M390.275 198.154C388.991 198.154 387.943 199.201 387.943 200.484C387.943 201.767 388.991 202.814 390.275 202.814C391.558 202.814 392.606 201.767 392.606 200.484C392.606 199.201 391.558 198.154 390.275 198.154Z"
            fill="#ADADAD"
          />
          <path
            d="M390.275 204.598C388.991 204.598 387.943 205.646 387.943 206.928C387.943 208.211 388.991 209.258 390.275 209.258C391.558 209.258 392.606 208.211 392.606 206.928C392.606 205.646 391.558 204.598 390.275 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M390.275 211.054C388.991 211.054 387.943 212.102 387.943 213.395C387.943 214.678 388.991 215.725 390.275 215.725C391.558 215.725 392.606 214.678 392.606 213.395C392.606 212.102 391.558 211.054 390.275 211.054Z"
            fill="#ADADAD"
          />
          <path
            d="M383.813 204.598C382.53 204.598 381.482 205.646 381.482 206.928C381.482 208.211 382.53 209.258 383.813 209.258C385.107 209.258 386.155 208.211 386.155 206.928C386.155 205.646 385.096 204.598 383.813 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M500.034 223.967C498.751 223.967 497.703 225.014 497.703 226.297C497.703 227.579 498.751 228.627 500.034 228.627C501.328 228.627 502.376 227.579 502.376 226.297C502.366 225.004 501.317 223.967 500.034 223.967Z"
            fill="#ADADAD"
          />
          <path
            d="M500.034 230.411C498.751 230.411 497.703 231.458 497.703 232.752C497.703 234.034 498.751 235.082 500.034 235.082C501.328 235.082 502.376 234.034 502.376 232.752C502.366 231.458 501.317 230.411 500.034 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M500.034 236.867C498.751 236.867 497.703 237.915 497.703 239.208C497.703 240.491 498.751 241.538 500.034 241.538C501.328 241.538 502.376 240.491 502.376 239.208C502.366 237.915 501.317 236.867 500.034 236.867Z"
            fill="#ADADAD"
          />
          <path
            d="M500.034 243.313C498.751 243.313 497.703 244.36 497.703 245.654C497.703 246.936 498.751 247.984 500.034 247.984C501.328 247.984 502.376 246.936 502.376 245.654C502.366 244.36 501.317 243.313 500.034 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M493.575 204.598C492.291 204.598 491.243 205.646 491.243 206.928C491.243 208.211 492.291 209.258 493.575 209.258C494.869 209.258 495.917 208.211 495.917 206.928C495.917 205.646 494.858 204.598 493.575 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M493.575 211.054C492.291 211.054 491.243 212.102 491.243 213.395C491.243 214.678 492.291 215.725 493.575 215.725C494.869 215.725 495.917 214.678 495.917 213.395C495.917 212.102 494.858 211.054 493.575 211.054Z"
            fill="#ADADAD"
          />
          <path
            d="M493.575 217.51C492.291 217.51 491.243 218.558 491.243 219.841C491.243 221.123 492.291 222.181 493.575 222.181C494.869 222.181 495.917 221.134 495.917 219.841C495.917 218.558 494.858 217.51 493.575 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M493.575 223.967C492.291 223.967 491.243 225.014 491.243 226.297C491.243 227.579 492.291 228.627 493.575 228.627C494.869 228.627 495.917 227.579 495.917 226.297C495.917 225.004 494.858 223.967 493.575 223.967Z"
            fill="#ADADAD"
          />
          <path
            d="M493.575 230.411C492.291 230.411 491.243 231.458 491.243 232.752C491.243 234.034 492.291 235.082 493.575 235.082C494.869 235.082 495.917 234.034 495.917 232.752C495.917 231.458 494.858 230.411 493.575 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M493.575 236.867C492.291 236.867 491.243 237.915 491.243 239.208C491.243 240.491 492.291 241.538 493.575 241.538C494.869 241.538 495.917 240.491 495.917 239.208C495.917 237.915 494.858 236.867 493.575 236.867Z"
            fill="#ADADAD"
          />
          <path
            d="M493.575 243.313C492.291 243.313 491.243 244.36 491.243 245.654C491.243 246.936 492.291 247.984 493.575 247.984C494.869 247.984 495.917 246.936 495.917 245.654C495.917 244.36 494.858 243.313 493.575 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M487.124 204.598C485.841 204.598 484.793 205.646 484.793 206.928C484.793 208.211 485.841 209.258 487.124 209.258C488.408 209.258 489.456 208.211 489.456 206.928C489.456 205.646 488.408 204.598 487.124 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M487.124 211.055C485.841 211.055 484.793 212.102 484.793 213.395C484.793 214.678 485.841 215.725 487.124 215.725C488.408 215.725 489.456 214.678 489.456 213.395C489.456 212.102 488.408 211.055 487.124 211.055Z"
            fill="#ADADAD"
          />
          <path
            d="M487.124 217.51C485.841 217.51 484.793 218.558 484.793 219.84C484.793 221.123 485.841 222.181 487.124 222.181C488.408 222.181 489.456 221.134 489.456 219.84C489.456 218.558 488.408 217.51 487.124 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M487.124 223.966C485.841 223.966 484.793 225.014 484.793 226.297C484.793 227.579 485.841 228.627 487.124 228.627C488.408 228.627 489.456 227.579 489.456 226.297C489.456 225.003 488.408 223.966 487.124 223.966Z"
            fill="#ADADAD"
          />
          <path
            d="M487.124 230.411C485.841 230.411 484.793 231.459 484.793 232.752C484.793 234.035 485.841 235.082 487.124 235.082C488.408 235.082 489.456 234.035 489.456 232.752C489.456 231.459 488.408 230.411 487.124 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M487.124 236.868C485.841 236.868 484.793 237.915 484.793 239.208C484.793 240.491 485.841 241.539 487.124 241.539C488.408 241.539 489.456 240.491 489.456 239.208C489.456 237.915 488.408 236.868 487.124 236.868Z"
            fill="#ADADAD"
          />
          <path
            d="M487.124 243.313C485.841 243.313 484.793 244.36 484.793 245.654C484.793 246.936 485.841 247.984 487.124 247.984C488.408 247.984 489.456 246.936 489.456 245.654C489.456 244.36 488.408 243.313 487.124 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M480.654 204.598C479.37 204.598 478.322 205.646 478.322 206.928C478.322 208.211 479.37 209.258 480.654 209.258C481.937 209.258 482.996 208.211 482.996 206.928C482.996 205.646 481.948 204.598 480.654 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M480.654 211.055C479.37 211.055 478.322 212.102 478.322 213.395C478.322 214.678 479.37 215.725 480.654 215.725C481.937 215.725 482.996 214.678 482.996 213.395C482.996 212.102 481.948 211.055 480.654 211.055Z"
            fill="#ADADAD"
          />
          <path
            d="M480.654 217.51C479.37 217.51 478.322 218.558 478.322 219.84C478.322 221.123 479.37 222.181 480.654 222.181C481.937 222.181 482.996 221.134 482.996 219.84C482.996 218.558 481.948 217.51 480.654 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M480.654 223.966C479.37 223.966 478.322 225.014 478.322 226.297C478.322 227.579 479.37 228.627 480.654 228.627C481.937 228.627 482.996 227.579 482.996 226.297C482.996 225.003 481.948 223.966 480.654 223.966Z"
            fill="#ADADAD"
          />
          <path
            d="M480.654 230.411C479.37 230.411 478.322 231.459 478.322 232.752C478.322 234.035 479.37 235.082 480.654 235.082C481.937 235.082 482.996 234.035 482.996 232.752C482.996 231.459 481.948 230.411 480.654 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M480.654 236.868C479.37 236.868 478.322 237.915 478.322 239.208C478.322 240.491 479.37 241.539 480.654 241.539C481.937 241.539 482.996 240.491 482.996 239.208C482.996 237.915 481.948 236.868 480.654 236.868Z"
            fill="#ADADAD"
          />
          <path
            d="M474.206 217.51C472.922 217.51 471.874 218.558 471.874 219.84C471.874 221.123 472.922 222.181 474.206 222.181C475.489 222.181 476.537 221.134 476.537 219.84C476.537 218.558 475.489 217.51 474.206 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M474.206 223.966C472.922 223.966 471.874 225.014 471.874 226.297C471.874 227.579 472.922 228.627 474.206 228.627C475.489 228.627 476.537 227.579 476.537 226.297C476.537 225.003 475.489 223.966 474.206 223.966Z"
            fill="#ADADAD"
          />
          <path
            d="M474.206 230.411C472.922 230.411 471.874 231.459 471.874 232.752C471.874 234.035 472.922 235.082 474.206 235.082C475.489 235.082 476.537 234.035 476.537 232.752C476.537 231.459 475.489 230.411 474.206 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M467.746 217.51C466.463 217.51 465.415 218.558 465.415 219.841C465.415 221.123 466.463 222.181 467.746 222.181C469.029 222.181 470.077 221.134 470.077 219.841C470.088 218.558 469.04 217.51 467.746 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M467.746 223.967C466.463 223.967 465.415 225.014 465.415 226.297C465.415 227.579 466.463 228.627 467.746 228.627C469.029 228.627 470.077 227.579 470.077 226.297C470.088 225.004 469.04 223.967 467.746 223.967Z"
            fill="#ADADAD"
          />
          <path
            d="M467.746 230.411C466.463 230.411 465.415 231.458 465.415 232.752C465.415 234.034 466.463 235.082 467.746 235.082C469.029 235.082 470.077 234.034 470.077 232.752C470.088 231.458 469.04 230.411 467.746 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M461.297 217.51C460.014 217.51 458.955 218.558 458.955 219.841C458.955 221.123 460.003 222.181 461.297 222.181C462.581 222.181 463.629 221.134 463.629 219.841C463.629 218.558 462.581 217.51 461.297 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M461.297 223.967C460.014 223.967 458.955 225.014 458.955 226.297C458.955 227.579 460.003 228.627 461.297 228.627C462.581 228.627 463.629 227.579 463.629 226.297C463.629 225.004 462.581 223.967 461.297 223.967Z"
            fill="#ADADAD"
          />
          <path
            d="M480.654 282.038C479.37 282.038 478.322 283.085 478.322 284.368C478.322 285.651 479.37 286.698 480.654 286.698C481.937 286.698 482.996 285.651 482.996 284.368C482.996 283.085 481.948 282.038 480.654 282.038Z"
            fill="#ADADAD"
          />
          <path
            d="M480.654 288.493C479.37 288.493 478.322 289.541 478.322 290.834C478.322 292.117 479.37 293.164 480.654 293.164C481.937 293.164 482.996 292.117 482.996 290.834C482.996 289.541 481.948 288.493 480.654 288.493Z"
            fill="#ADADAD"
          />
          <path
            d="M474.206 282.038C472.922 282.038 471.874 283.085 471.874 284.368C471.874 285.651 472.922 286.698 474.206 286.698C475.489 286.698 476.537 285.651 476.537 284.368C476.537 283.085 475.489 282.038 474.206 282.038Z"
            fill="#ADADAD"
          />
          <path
            d="M474.206 288.493C472.922 288.493 471.874 289.541 471.874 290.834C471.874 292.117 472.922 293.164 474.206 293.164C475.489 293.164 476.537 292.117 476.537 290.834C476.537 289.541 475.489 288.493 474.206 288.493Z"
            fill="#ADADAD"
          />
          <path
            d="M467.746 275.582C466.463 275.582 465.415 276.63 465.415 277.923C465.415 279.206 466.463 280.253 467.746 280.253C469.029 280.253 470.077 279.206 470.077 277.923C470.088 276.63 469.04 275.582 467.746 275.582Z"
            fill="#ADADAD"
          />
          <path
            d="M467.746 282.038C466.463 282.038 465.415 283.086 465.415 284.368C465.415 285.651 466.463 286.699 467.746 286.699C469.029 286.699 470.077 285.651 470.077 284.368C470.088 283.086 469.04 282.038 467.746 282.038Z"
            fill="#ADADAD"
          />
          <path
            d="M467.746 288.493C466.463 288.493 465.415 289.54 465.415 290.834C465.415 292.116 466.463 293.164 467.746 293.164C469.029 293.164 470.077 292.116 470.077 290.834C470.088 289.54 469.04 288.493 467.746 288.493Z"
            fill="#ADADAD"
          />
          <path
            d="M467.746 294.939C466.463 294.939 465.415 295.986 465.415 297.28C465.415 298.562 466.463 299.61 467.746 299.61C469.029 299.61 470.077 298.562 470.077 297.28C470.088 295.986 469.04 294.939 467.746 294.939Z"
            fill="#ADADAD"
          />
          <path
            d="M461.297 275.582C460.014 275.582 458.955 276.63 458.955 277.923C458.955 279.206 460.003 280.253 461.297 280.253C462.581 280.253 463.629 279.206 463.629 277.923C463.629 276.63 462.581 275.582 461.297 275.582Z"
            fill="#ADADAD"
          />
          <path
            d="M461.297 282.038C460.014 282.038 458.955 283.086 458.955 284.368C458.955 285.651 460.003 286.699 461.297 286.699C462.581 286.699 463.629 285.651 463.629 284.368C463.629 283.086 462.581 282.038 461.297 282.038Z"
            fill="#ADADAD"
          />
          <path
            d="M461.297 288.493C460.014 288.493 458.955 289.54 458.955 290.834C458.955 292.116 460.003 293.164 461.297 293.164C462.581 293.164 463.629 292.116 463.629 290.834C463.629 289.54 462.581 288.493 461.297 288.493Z"
            fill="#ADADAD"
          />
          <path
            d="M461.297 294.939C460.014 294.939 458.955 295.986 458.955 297.28C458.955 298.562 460.003 299.61 461.297 299.61C462.581 299.61 463.629 298.562 463.629 297.28C463.629 295.986 462.581 294.939 461.297 294.939Z"
            fill="#ADADAD"
          />
          <path
            d="M461.297 301.395C460.014 301.395 458.955 302.442 458.955 303.725C458.955 305.008 460.003 306.055 461.297 306.055C462.581 306.055 463.629 305.008 463.629 303.725C463.629 302.442 462.581 301.395 461.297 301.395Z"
            fill="#ADADAD"
          />
          <path
            d="M454.837 275.582C453.553 275.582 452.505 276.629 452.505 277.922C452.505 279.205 453.553 280.253 454.837 280.253C456.131 280.253 457.179 279.205 457.179 277.922C457.168 276.629 456.12 275.582 454.837 275.582Z"
            fill="#ADADAD"
          />
          <path
            d="M454.837 282.038C453.553 282.038 452.505 283.085 452.505 284.368C452.505 285.651 453.553 286.698 454.837 286.698C456.131 286.698 457.179 285.651 457.179 284.368C457.168 283.085 456.12 282.038 454.837 282.038Z"
            fill="#ADADAD"
          />
          <path
            d="M454.837 288.493C453.553 288.493 452.505 289.541 452.505 290.834C452.505 292.117 453.553 293.164 454.837 293.164C456.131 293.164 457.179 292.117 457.179 290.834C457.168 289.541 456.12 288.493 454.837 288.493Z"
            fill="#ADADAD"
          />
          <path
            d="M454.837 294.939C453.553 294.939 452.505 295.987 452.505 297.28C452.505 298.563 453.553 299.61 454.837 299.61C456.131 299.61 457.179 298.563 457.179 297.28C457.168 295.987 456.12 294.939 454.837 294.939Z"
            fill="#ADADAD"
          />
          <path
            d="M454.837 301.395C453.553 301.395 452.505 302.442 452.505 303.725C452.505 305.008 453.553 306.055 454.837 306.055C456.131 306.055 457.179 305.008 457.179 303.725C457.168 302.442 456.12 301.395 454.837 301.395Z"
            fill="#ADADAD"
          />
          <path
            d="M454.837 307.851C453.553 307.851 452.505 308.898 452.505 310.192C452.505 311.474 453.553 312.522 454.837 312.522C456.131 312.522 457.179 311.474 457.179 310.192C457.168 308.898 456.12 307.851 454.837 307.851Z"
            fill="#ADADAD"
          />
          <path
            d="M454.837 314.296C453.553 314.296 452.505 315.343 452.505 316.626C452.505 317.909 453.553 318.956 454.837 318.956C456.131 318.956 457.179 317.909 457.179 316.626C457.168 315.343 456.12 314.296 454.837 314.296Z"
            fill="#ADADAD"
          />
          <path
            d="M454.837 320.752C453.553 320.752 452.505 321.799 452.505 323.082C452.505 324.364 453.553 325.412 454.837 325.412C456.131 325.412 457.179 324.364 457.179 323.082C457.168 321.799 456.12 320.752 454.837 320.752Z"
            fill="#ADADAD"
          />
          <path
            d="M454.837 327.208C453.553 327.208 452.505 328.255 452.505 329.538C452.505 330.821 453.553 331.868 454.837 331.868C456.131 331.868 457.179 330.821 457.179 329.538C457.168 328.255 456.12 327.208 454.837 327.208Z"
            fill="#ADADAD"
          />
          <path
            d="M454.837 333.653C453.553 333.653 452.505 334.7 452.505 335.983C452.505 337.266 453.553 338.313 454.837 338.313C456.131 338.313 457.179 337.266 457.179 335.983C457.168 334.7 456.12 333.653 454.837 333.653Z"
            fill="#ADADAD"
          />
          <path
            d="M448.377 275.582C447.094 275.582 446.046 276.629 446.046 277.922C446.046 279.205 447.094 280.253 448.377 280.253C449.661 280.253 450.709 279.205 450.709 277.922C450.709 276.629 449.661 275.582 448.377 275.582Z"
            fill="#ADADAD"
          />
          <path
            d="M448.377 282.038C447.094 282.038 446.046 283.085 446.046 284.368C446.046 285.651 447.094 286.698 448.377 286.698C449.661 286.698 450.709 285.651 450.709 284.368C450.709 283.085 449.661 282.038 448.377 282.038Z"
            fill="#ADADAD"
          />
          <path
            d="M448.377 288.493C447.094 288.493 446.046 289.541 446.046 290.834C446.046 292.117 447.094 293.164 448.377 293.164C449.661 293.164 450.709 292.117 450.709 290.834C450.709 289.541 449.661 288.493 448.377 288.493Z"
            fill="#ADADAD"
          />
          <path
            d="M448.377 294.939C447.094 294.939 446.046 295.987 446.046 297.28C446.046 298.563 447.094 299.61 448.377 299.61C449.661 299.61 450.709 298.563 450.709 297.28C450.709 295.987 449.661 294.939 448.377 294.939Z"
            fill="#ADADAD"
          />
          <path
            d="M448.377 301.395C447.094 301.395 446.046 302.442 446.046 303.725C446.046 305.008 447.094 306.055 448.377 306.055C449.661 306.055 450.709 305.008 450.709 303.725C450.709 302.442 449.661 301.395 448.377 301.395Z"
            fill="#ADADAD"
          />
          <path
            d="M448.377 307.851C447.094 307.851 446.046 308.898 446.046 310.192C446.046 311.474 447.094 312.522 448.377 312.522C449.661 312.522 450.709 311.474 450.709 310.192C450.709 308.898 449.661 307.851 448.377 307.851Z"
            fill="#ADADAD"
          />
          <path
            d="M448.377 314.296C447.094 314.296 446.046 315.343 446.046 316.626C446.046 317.909 447.094 318.956 448.377 318.956C449.661 318.956 450.709 317.909 450.709 316.626C450.709 315.343 449.661 314.296 448.377 314.296Z"
            fill="#ADADAD"
          />
          <path
            d="M448.377 320.752C447.094 320.752 446.046 321.799 446.046 323.082C446.046 324.364 447.094 325.412 448.377 325.412C449.661 325.412 450.709 324.364 450.709 323.082C450.709 321.799 449.661 320.752 448.377 320.752Z"
            fill="#ADADAD"
          />
          <path
            d="M448.377 327.208C447.094 327.208 446.046 328.255 446.046 329.538C446.046 330.821 447.094 331.868 448.377 331.868C449.661 331.868 450.709 330.821 450.709 329.538C450.709 328.255 449.661 327.208 448.377 327.208Z"
            fill="#ADADAD"
          />
          <path
            d="M448.377 333.653C447.094 333.653 446.046 334.7 446.046 335.983C446.046 337.266 447.094 338.313 448.377 338.313C449.661 338.313 450.709 337.266 450.709 335.983C450.709 334.7 449.661 333.653 448.377 333.653Z"
            fill="#ADADAD"
          />
          <path
            d="M441.929 275.582C440.635 275.582 439.586 276.629 439.586 277.922C439.586 279.205 440.635 280.253 441.929 280.253C443.212 280.253 444.26 279.205 444.26 277.922C444.26 276.629 443.212 275.582 441.929 275.582Z"
            fill="#ADADAD"
          />
          <path
            d="M441.929 282.038C440.635 282.038 439.586 283.085 439.586 284.368C439.586 285.651 440.635 286.698 441.929 286.698C443.212 286.698 444.26 285.651 444.26 284.368C444.26 283.085 443.212 282.038 441.929 282.038Z"
            fill="#ADADAD"
          />
          <path
            d="M441.929 288.493C440.635 288.493 439.586 289.541 439.586 290.834C439.586 292.117 440.635 293.164 441.929 293.164C443.212 293.164 444.26 292.117 444.26 290.834C444.26 289.541 443.212 288.493 441.929 288.493Z"
            fill="#ADADAD"
          />
          <path
            d="M441.929 294.939C440.635 294.939 439.586 295.987 439.586 297.28C439.586 298.563 440.635 299.61 441.929 299.61C443.212 299.61 444.26 298.563 444.26 297.28C444.26 295.987 443.212 294.939 441.929 294.939Z"
            fill="#ADADAD"
          />
          <path
            d="M441.929 301.395C440.635 301.395 439.586 302.442 439.586 303.725C439.586 305.008 440.635 306.055 441.929 306.055C443.212 306.055 444.26 305.008 444.26 303.725C444.26 302.442 443.212 301.395 441.929 301.395Z"
            fill="#ADADAD"
          />
          <path
            d="M441.929 307.851C440.635 307.851 439.586 308.898 439.586 310.192C439.586 311.474 440.635 312.522 441.929 312.522C443.212 312.522 444.26 311.474 444.26 310.192C444.26 308.898 443.212 307.851 441.929 307.851Z"
            fill="#ADADAD"
          />
          <path
            d="M441.929 314.296C440.635 314.296 439.586 315.343 439.586 316.626C439.586 317.909 440.635 318.956 441.929 318.956C443.212 318.956 444.26 317.909 444.26 316.626C444.26 315.343 443.212 314.296 441.929 314.296Z"
            fill="#ADADAD"
          />
          <path
            d="M441.929 320.752C440.635 320.752 439.586 321.799 439.586 323.082C439.586 324.364 440.635 325.412 441.929 325.412C443.212 325.412 444.26 324.364 444.26 323.082C444.26 321.799 443.212 320.752 441.929 320.752Z"
            fill="#ADADAD"
          />
          <path
            d="M441.929 327.208C440.635 327.208 439.586 328.255 439.586 329.538C439.586 330.821 440.635 331.868 441.929 331.868C443.212 331.868 444.26 330.821 444.26 329.538C444.26 328.255 443.212 327.208 441.929 327.208Z"
            fill="#ADADAD"
          />
          <path
            d="M441.929 333.653C440.635 333.653 439.586 334.7 439.586 335.983C439.586 337.266 440.635 338.313 441.929 338.313C443.212 338.313 444.26 337.266 444.26 335.983C444.26 334.7 443.212 333.653 441.929 333.653Z"
            fill="#ADADAD"
          />
          <path
            d="M435.47 275.582C434.186 275.582 433.138 276.63 433.138 277.923C433.138 279.206 434.186 280.253 435.47 280.253C436.753 280.253 437.801 279.206 437.801 277.923C437.801 276.63 436.753 275.582 435.47 275.582Z"
            fill="#ADADAD"
          />
          <path
            d="M435.47 282.038C434.186 282.038 433.138 283.086 433.138 284.368C433.138 285.651 434.186 286.699 435.47 286.699C436.753 286.699 437.801 285.651 437.801 284.368C437.801 283.086 436.753 282.038 435.47 282.038Z"
            fill="#ADADAD"
          />
          <path
            d="M435.47 288.493C434.186 288.493 433.138 289.54 433.138 290.834C433.138 292.116 434.186 293.164 435.47 293.164C436.753 293.164 437.801 292.116 437.801 290.834C437.801 289.54 436.753 288.493 435.47 288.493Z"
            fill="#ADADAD"
          />
          <path
            d="M435.47 294.939C434.186 294.939 433.138 295.986 433.138 297.28C433.138 298.562 434.186 299.61 435.47 299.61C436.753 299.61 437.801 298.562 437.801 297.28C437.801 295.986 436.753 294.939 435.47 294.939Z"
            fill="#ADADAD"
          />
          <path
            d="M435.47 301.395C434.186 301.395 433.138 302.442 433.138 303.725C433.138 305.008 434.186 306.055 435.47 306.055C436.753 306.055 437.801 305.008 437.801 303.725C437.801 302.442 436.753 301.395 435.47 301.395Z"
            fill="#ADADAD"
          />
          <path
            d="M435.47 307.851C434.186 307.851 433.138 308.899 433.138 310.192C433.138 311.475 434.186 312.522 435.47 312.522C436.753 312.522 437.801 311.475 437.801 310.192C437.801 308.899 436.753 307.851 435.47 307.851Z"
            fill="#ADADAD"
          />
          <path
            d="M435.47 314.295C434.186 314.295 433.138 315.343 433.138 316.626C433.138 317.908 434.186 318.956 435.47 318.956C436.753 318.956 437.801 317.908 437.801 316.626C437.801 315.343 436.753 314.295 435.47 314.295Z"
            fill="#ADADAD"
          />
          <path
            d="M435.47 320.752C434.186 320.752 433.138 321.799 433.138 323.082C433.138 324.364 434.186 325.412 435.47 325.412C436.753 325.412 437.801 324.364 437.801 323.082C437.801 321.799 436.753 320.752 435.47 320.752Z"
            fill="#ADADAD"
          />
          <path
            d="M435.47 327.208C434.186 327.208 433.138 328.255 433.138 329.538C433.138 330.821 434.186 331.868 435.47 331.868C436.753 331.868 437.801 330.821 437.801 329.538C437.801 328.255 436.753 327.208 435.47 327.208Z"
            fill="#ADADAD"
          />
          <path
            d="M435.47 333.654C434.186 333.654 433.138 334.701 433.138 335.984C433.138 337.266 434.186 338.314 435.47 338.314C436.753 338.314 437.801 337.266 437.801 335.984C437.801 334.701 436.753 333.654 435.47 333.654Z"
            fill="#ADADAD"
          />
          <path
            d="M429.01 275.582C427.727 275.582 426.679 276.63 426.679 277.923C426.679 279.206 427.727 280.253 429.01 280.253C430.294 280.253 431.342 279.206 431.342 277.923C431.342 276.63 430.294 275.582 429.01 275.582Z"
            fill="#ADADAD"
          />
          <path
            d="M429.01 282.038C427.727 282.038 426.679 283.086 426.679 284.368C426.679 285.651 427.727 286.699 429.01 286.699C430.294 286.699 431.342 285.651 431.342 284.368C431.342 283.086 430.294 282.038 429.01 282.038Z"
            fill="#ADADAD"
          />
          <path
            d="M429.01 288.493C427.727 288.493 426.679 289.54 426.679 290.834C426.679 292.116 427.727 293.164 429.01 293.164C430.294 293.164 431.342 292.116 431.342 290.834C431.342 289.54 430.294 288.493 429.01 288.493Z"
            fill="#ADADAD"
          />
          <path
            d="M429.01 294.939C427.727 294.939 426.679 295.986 426.679 297.28C426.679 298.562 427.727 299.61 429.01 299.61C430.294 299.61 431.342 298.562 431.342 297.28C431.342 295.986 430.294 294.939 429.01 294.939Z"
            fill="#ADADAD"
          />
          <path
            d="M429.01 306.055C430.298 306.055 431.342 305.012 431.342 303.725C431.342 302.438 430.298 301.395 429.01 301.395C427.723 301.395 426.679 302.438 426.679 303.725C426.679 305.012 427.723 306.055 429.01 306.055Z"
            fill="#ADADAD"
          />
          <path
            d="M429.01 307.851C427.727 307.851 426.679 308.899 426.679 310.192C426.679 311.475 427.727 312.522 429.01 312.522C430.294 312.522 431.342 311.475 431.342 310.192C431.342 308.899 430.294 307.851 429.01 307.851Z"
            fill="#ADADAD"
          />
          <path
            d="M429.01 314.295C427.727 314.295 426.679 315.343 426.679 316.626C426.679 317.908 427.727 318.956 429.01 318.956C430.294 318.956 431.342 317.908 431.342 316.626C431.342 315.343 430.294 314.295 429.01 314.295Z"
            fill="#ADADAD"
          />
          <path
            d="M429.01 320.752C427.727 320.752 426.679 321.799 426.679 323.082C426.679 324.364 427.727 325.412 429.01 325.412C430.294 325.412 431.342 324.364 431.342 323.082C431.342 321.799 430.294 320.752 429.01 320.752Z"
            fill="#ADADAD"
          />
          <path
            d="M429.01 327.208C427.727 327.208 426.679 328.255 426.679 329.538C426.679 330.821 427.727 331.868 429.01 331.868C430.294 331.868 431.342 330.821 431.342 329.538C431.342 328.255 430.294 327.208 429.01 327.208Z"
            fill="#ADADAD"
          />
          <path
            d="M429.01 338.324C430.298 338.324 431.342 337.281 431.342 335.994C431.342 334.707 430.298 333.664 429.01 333.664C427.723 333.664 426.679 334.707 426.679 335.994C426.679 337.281 427.723 338.324 429.01 338.324Z"
            fill="#ADADAD"
          />
          <path
            d="M429.01 340.11C427.727 340.11 426.679 341.157 426.679 342.44C426.679 343.723 427.727 344.77 429.01 344.77C430.294 344.77 431.342 343.723 431.342 342.44C431.342 341.157 430.294 340.11 429.01 340.11Z"
            fill="#ADADAD"
          />
          <path
            d="M429.01 346.565C427.727 346.565 426.679 347.612 426.679 348.895C426.679 350.177 427.727 351.225 429.01 351.225C430.294 351.225 431.342 350.177 431.342 348.895C431.342 347.612 430.294 346.565 429.01 346.565Z"
            fill="#ADADAD"
          />
          <path
            d="M422.551 275.582C421.268 275.582 420.22 276.63 420.22 277.923C420.22 279.206 421.268 280.253 422.551 280.253C423.845 280.253 424.893 279.206 424.893 277.923C424.893 276.63 423.845 275.582 422.551 275.582Z"
            fill="#ADADAD"
          />
          <path
            d="M422.551 282.038C421.268 282.038 420.22 283.086 420.22 284.368C420.22 285.651 421.268 286.699 422.551 286.699C423.845 286.699 424.893 285.651 424.893 284.368C424.893 283.086 423.845 282.038 422.551 282.038Z"
            fill="#ADADAD"
          />
          <path
            d="M422.551 288.493C421.268 288.493 420.22 289.54 420.22 290.834C420.22 292.116 421.268 293.164 422.551 293.164C423.845 293.164 424.893 292.116 424.893 290.834C424.893 289.54 423.845 288.493 422.551 288.493Z"
            fill="#ADADAD"
          />
          <path
            d="M422.551 294.939C421.268 294.939 420.22 295.986 420.22 297.28C420.22 298.562 421.268 299.61 422.551 299.61C423.845 299.61 424.893 298.562 424.893 297.28C424.893 295.986 423.845 294.939 422.551 294.939Z"
            fill="#ADADAD"
          />
          <path
            d="M422.551 301.395C421.268 301.395 420.22 302.442 420.22 303.725C420.22 305.008 421.268 306.055 422.551 306.055C423.845 306.055 424.893 305.008 424.893 303.725C424.893 302.442 423.845 301.395 422.551 301.395Z"
            fill="#ADADAD"
          />
          <path
            d="M422.551 307.851C421.268 307.851 420.22 308.899 420.22 310.192C420.22 311.475 421.268 312.522 422.551 312.522C423.845 312.522 424.893 311.475 424.893 310.192C424.893 308.899 423.845 307.851 422.551 307.851Z"
            fill="#ADADAD"
          />
          <path
            d="M422.551 314.295C421.268 314.295 420.22 315.343 420.22 316.626C420.22 317.908 421.268 318.956 422.551 318.956C423.845 318.956 424.893 317.908 424.893 316.626C424.893 315.343 423.845 314.295 422.551 314.295Z"
            fill="#ADADAD"
          />
          <path
            d="M422.551 320.752C421.268 320.752 420.22 321.799 420.22 323.082C420.22 324.364 421.268 325.412 422.551 325.412C423.845 325.412 424.893 324.364 424.893 323.082C424.893 321.799 423.845 320.752 422.551 320.752Z"
            fill="#ADADAD"
          />
          <path
            d="M422.551 327.208C421.268 327.208 420.22 328.255 420.22 329.538C420.22 330.821 421.268 331.868 422.551 331.868C423.845 331.868 424.893 330.821 424.893 329.538C424.893 328.255 423.845 327.208 422.551 327.208Z"
            fill="#ADADAD"
          />
          <path
            d="M422.551 333.654C421.268 333.654 420.22 334.701 420.22 335.984C420.22 337.266 421.268 338.314 422.551 338.314C423.845 338.314 424.893 337.266 424.893 335.984C424.893 334.701 423.845 333.654 422.551 333.654Z"
            fill="#ADADAD"
          />
          <path
            d="M422.551 340.11C421.268 340.11 420.22 341.157 420.22 342.44C420.22 343.723 421.268 344.77 422.551 344.77C423.845 344.77 424.893 343.723 424.893 342.44C424.893 341.157 423.845 340.11 422.551 340.11Z"
            fill="#ADADAD"
          />
          <path
            d="M422.551 346.565C421.268 346.565 420.22 347.612 420.22 348.895C420.22 350.177 421.268 351.225 422.551 351.225C423.845 351.225 424.893 350.177 424.893 348.895C424.893 347.612 423.845 346.565 422.551 346.565Z"
            fill="#ADADAD"
          />
          <path
            d="M416.089 275.582C414.805 275.582 413.757 276.63 413.757 277.923C413.757 279.206 414.805 280.253 416.089 280.253C417.372 280.253 418.42 279.206 418.42 277.923C418.431 276.63 417.383 275.582 416.089 275.582Z"
            fill="#ADADAD"
          />
          <path
            d="M416.089 282.038C414.805 282.038 413.757 283.086 413.757 284.368C413.757 285.651 414.805 286.699 416.089 286.699C417.372 286.699 418.42 285.651 418.42 284.368C418.431 283.086 417.383 282.038 416.089 282.038Z"
            fill="#ADADAD"
          />
          <path
            d="M416.089 288.493C414.805 288.493 413.757 289.54 413.757 290.834C413.757 292.116 414.805 293.164 416.089 293.164C417.372 293.164 418.42 292.116 418.42 290.834C418.431 289.54 417.383 288.493 416.089 288.493Z"
            fill="#ADADAD"
          />
          <path
            d="M416.089 294.939C414.805 294.939 413.757 295.986 413.757 297.28C413.757 298.562 414.805 299.61 416.089 299.61C417.372 299.61 418.42 298.562 418.42 297.28C418.431 295.986 417.383 294.939 416.089 294.939Z"
            fill="#ADADAD"
          />
          <path
            d="M416.089 301.395C414.805 301.395 413.757 302.442 413.757 303.725C413.757 305.008 414.805 306.055 416.089 306.055C417.372 306.055 418.42 305.008 418.42 303.725C418.431 302.442 417.383 301.395 416.089 301.395Z"
            fill="#ADADAD"
          />
          <path
            d="M416.089 307.851C414.805 307.851 413.757 308.899 413.757 310.192C413.757 311.475 414.805 312.522 416.089 312.522C417.372 312.522 418.42 311.475 418.42 310.192C418.431 308.899 417.383 307.851 416.089 307.851Z"
            fill="#ADADAD"
          />
          <path
            d="M416.089 314.295C414.805 314.295 413.757 315.343 413.757 316.626C413.757 317.908 414.805 318.956 416.089 318.956C417.372 318.956 418.42 317.908 418.42 316.626C418.431 315.343 417.383 314.295 416.089 314.295Z"
            fill="#ADADAD"
          />
          <path
            d="M416.089 320.752C414.805 320.752 413.757 321.799 413.757 323.082C413.757 324.364 414.805 325.412 416.089 325.412C417.372 325.412 418.42 324.364 418.42 323.082C418.431 321.799 417.383 320.752 416.089 320.752Z"
            fill="#ADADAD"
          />
          <path
            d="M416.089 327.208C414.805 327.208 413.757 328.255 413.757 329.538C413.757 330.821 414.805 331.868 416.089 331.868C417.372 331.868 418.42 330.821 418.42 329.538C418.431 328.255 417.383 327.208 416.089 327.208Z"
            fill="#ADADAD"
          />
          <path
            d="M416.089 333.654C414.805 333.654 413.757 334.701 413.757 335.984C413.757 337.266 414.805 338.314 416.089 338.314C417.372 338.314 418.42 337.266 418.42 335.984C418.431 334.701 417.383 333.654 416.089 333.654Z"
            fill="#ADADAD"
          />
          <path
            d="M416.089 340.11C414.805 340.11 413.757 341.157 413.757 342.44C413.757 343.723 414.805 344.77 416.089 344.77C417.372 344.77 418.42 343.723 418.42 342.44C418.431 341.157 417.383 340.11 416.089 340.11Z"
            fill="#ADADAD"
          />
          <path
            d="M416.089 346.565C414.805 346.565 413.757 347.612 413.757 348.895C413.757 350.177 414.805 351.225 416.089 351.225C417.372 351.225 418.42 350.177 418.42 348.895C418.431 347.612 417.383 346.565 416.089 346.565Z"
            fill="#ADADAD"
          />
          <path
            d="M409.64 275.582C408.357 275.582 407.298 276.63 407.298 277.923C407.298 279.206 408.346 280.253 409.64 280.253C410.923 280.253 411.971 279.206 411.971 277.923C411.971 276.63 410.923 275.582 409.64 275.582Z"
            fill="#ADADAD"
          />
          <path
            d="M409.64 282.038C408.357 282.038 407.298 283.086 407.298 284.368C407.298 285.651 408.346 286.699 409.64 286.699C410.923 286.699 411.971 285.651 411.971 284.368C411.971 283.086 410.923 282.038 409.64 282.038Z"
            fill="#ADADAD"
          />
          <path
            d="M409.64 288.493C408.357 288.493 407.298 289.54 407.298 290.834C407.298 292.116 408.346 293.164 409.64 293.164C410.923 293.164 411.971 292.116 411.971 290.834C411.971 289.54 410.923 288.493 409.64 288.493Z"
            fill="#ADADAD"
          />
          <path
            d="M409.64 294.939C408.357 294.939 407.298 295.986 407.298 297.28C407.298 298.562 408.346 299.61 409.64 299.61C410.923 299.61 411.971 298.562 411.971 297.28C411.971 295.986 410.923 294.939 409.64 294.939Z"
            fill="#ADADAD"
          />
          <path
            d="M409.64 301.395C408.357 301.395 407.298 302.442 407.298 303.725C407.298 305.008 408.346 306.055 409.64 306.055C410.923 306.055 411.971 305.008 411.971 303.725C411.971 302.442 410.923 301.395 409.64 301.395Z"
            fill="#ADADAD"
          />
          <path
            d="M409.64 307.851C408.357 307.851 407.298 308.899 407.298 310.192C407.298 311.475 408.346 312.522 409.64 312.522C410.923 312.522 411.971 311.475 411.971 310.192C411.971 308.899 410.923 307.851 409.64 307.851Z"
            fill="#ADADAD"
          />
          <path
            d="M409.64 314.295C408.357 314.295 407.298 315.343 407.298 316.626C407.298 317.908 408.346 318.956 409.64 318.956C410.923 318.956 411.971 317.908 411.971 316.626C411.971 315.343 410.923 314.295 409.64 314.295Z"
            fill="#ADADAD"
          />
          <path
            d="M409.64 320.752C408.357 320.752 407.298 321.799 407.298 323.082C407.298 324.364 408.346 325.412 409.64 325.412C410.923 325.412 411.971 324.364 411.971 323.082C411.971 321.799 410.923 320.752 409.64 320.752Z"
            fill="#ADADAD"
          />
          <path
            d="M409.64 327.208C408.357 327.208 407.298 328.255 407.298 329.538C407.298 330.821 408.346 331.868 409.64 331.868C410.923 331.868 411.971 330.821 411.971 329.538C411.971 328.255 410.923 327.208 409.64 327.208Z"
            fill="#ADADAD"
          />
          <path
            d="M409.64 333.654C408.357 333.654 407.298 334.701 407.298 335.984C407.298 337.266 408.346 338.314 409.64 338.314C410.923 338.314 411.971 337.266 411.971 335.984C411.971 334.701 410.923 333.654 409.64 333.654Z"
            fill="#ADADAD"
          />
          <path
            d="M409.64 340.11C408.357 340.11 407.298 341.157 407.298 342.44C407.298 343.723 408.346 344.77 409.64 344.77C410.923 344.77 411.971 343.723 411.971 342.44C411.971 341.157 410.923 340.11 409.64 340.11Z"
            fill="#ADADAD"
          />
          <path
            d="M409.64 346.565C408.357 346.565 407.298 347.612 407.298 348.895C407.298 350.177 408.346 351.225 409.64 351.225C410.923 351.225 411.971 350.177 411.971 348.895C411.971 347.612 410.923 346.565 409.64 346.565Z"
            fill="#ADADAD"
          />
          <path
            d="M403.182 275.582C401.899 275.582 400.851 276.629 400.851 277.922C400.851 279.205 401.899 280.253 403.182 280.253C404.466 280.253 405.514 279.205 405.514 277.922C405.514 276.629 404.466 275.582 403.182 275.582Z"
            fill="#ADADAD"
          />
          <path
            d="M403.182 282.038C401.899 282.038 400.851 283.085 400.851 284.368C400.851 285.651 401.899 286.698 403.182 286.698C404.466 286.698 405.514 285.651 405.514 284.368C405.514 283.085 404.466 282.038 403.182 282.038Z"
            fill="#ADADAD"
          />
          <path
            d="M403.182 288.493C401.899 288.493 400.851 289.541 400.851 290.834C400.851 292.117 401.899 293.164 403.182 293.164C404.466 293.164 405.514 292.117 405.514 290.834C405.514 289.541 404.466 288.493 403.182 288.493Z"
            fill="#ADADAD"
          />
          <path
            d="M403.182 294.939C401.899 294.939 400.851 295.987 400.851 297.28C400.851 298.563 401.899 299.61 403.182 299.61C404.466 299.61 405.514 298.563 405.514 297.28C405.514 295.987 404.466 294.939 403.182 294.939Z"
            fill="#ADADAD"
          />
          <path
            d="M403.182 301.395C401.899 301.395 400.851 302.442 400.851 303.725C400.851 305.008 401.899 306.055 403.182 306.055C404.466 306.055 405.514 305.008 405.514 303.725C405.514 302.442 404.466 301.395 403.182 301.395Z"
            fill="#ADADAD"
          />
          <path
            d="M403.182 307.851C401.899 307.851 400.851 308.898 400.851 310.192C400.851 311.474 401.899 312.522 403.182 312.522C404.466 312.522 405.514 311.474 405.514 310.192C405.514 308.898 404.466 307.851 403.182 307.851Z"
            fill="#ADADAD"
          />
          <path
            d="M403.182 314.296C401.899 314.296 400.851 315.343 400.851 316.626C400.851 317.909 401.899 318.956 403.182 318.956C404.466 318.956 405.514 317.909 405.514 316.626C405.514 315.343 404.466 314.296 403.182 314.296Z"
            fill="#ADADAD"
          />
          <path
            d="M403.182 320.752C401.899 320.752 400.851 321.799 400.851 323.082C400.851 324.364 401.899 325.412 403.182 325.412C404.466 325.412 405.514 324.364 405.514 323.082C405.514 321.799 404.466 320.752 403.182 320.752Z"
            fill="#ADADAD"
          />
          <path
            d="M403.182 327.208C401.899 327.208 400.851 328.255 400.851 329.538C400.851 330.821 401.899 331.868 403.182 331.868C404.466 331.868 405.514 330.821 405.514 329.538C405.514 328.255 404.466 327.208 403.182 327.208Z"
            fill="#ADADAD"
          />
          <path
            d="M403.182 333.653C401.899 333.653 400.851 334.7 400.851 335.983C400.851 337.266 401.899 338.313 403.182 338.313C404.466 338.313 405.514 337.266 405.514 335.983C405.514 334.7 404.466 333.653 403.182 333.653Z"
            fill="#ADADAD"
          />
          <path
            d="M403.182 340.109C401.899 340.109 400.851 341.157 400.851 342.439C400.851 343.722 401.899 344.769 403.182 344.769C404.466 344.769 405.514 343.722 405.514 342.439C405.514 341.157 404.466 340.109 403.182 340.109Z"
            fill="#ADADAD"
          />
          <path
            d="M403.182 346.565C401.899 346.565 400.851 347.612 400.851 348.895C400.851 350.178 401.899 351.225 403.182 351.225C404.466 351.225 405.514 350.178 405.514 348.895C405.514 347.612 404.466 346.565 403.182 346.565Z"
            fill="#ADADAD"
          />
          <path
            d="M396.721 275.582C395.438 275.582 394.379 276.63 394.379 277.923C394.379 279.206 395.427 280.253 396.721 280.253C398.004 280.253 399.052 279.206 399.052 277.923C399.063 276.63 398.015 275.582 396.721 275.582Z"
            fill="#ADADAD"
          />
          <path
            d="M396.721 282.038C395.438 282.038 394.379 283.086 394.379 284.368C394.379 285.651 395.427 286.699 396.721 286.699C398.004 286.699 399.052 285.651 399.052 284.368C399.063 283.086 398.015 282.038 396.721 282.038Z"
            fill="#ADADAD"
          />
          <path
            d="M396.721 288.493C395.438 288.493 394.379 289.54 394.379 290.834C394.379 292.116 395.427 293.164 396.721 293.164C398.004 293.164 399.052 292.116 399.052 290.834C399.063 289.54 398.015 288.493 396.721 288.493Z"
            fill="#ADADAD"
          />
          <path
            d="M396.721 294.939C395.438 294.939 394.379 295.986 394.379 297.28C394.379 298.562 395.427 299.61 396.721 299.61C398.004 299.61 399.052 298.562 399.052 297.28C399.063 295.986 398.015 294.939 396.721 294.939Z"
            fill="#ADADAD"
          />
          <path
            d="M396.721 301.395C395.438 301.395 394.379 302.442 394.379 303.725C394.379 305.008 395.427 306.055 396.721 306.055C398.004 306.055 399.052 305.008 399.052 303.725C399.063 302.442 398.015 301.395 396.721 301.395Z"
            fill="#ADADAD"
          />
          <path
            d="M396.721 307.851C395.438 307.851 394.379 308.899 394.379 310.192C394.379 311.475 395.427 312.522 396.721 312.522C398.004 312.522 399.052 311.475 399.052 310.192C399.063 308.899 398.015 307.851 396.721 307.851Z"
            fill="#ADADAD"
          />
          <path
            d="M396.721 314.295C395.438 314.295 394.379 315.343 394.379 316.626C394.379 317.908 395.427 318.956 396.721 318.956C398.004 318.956 399.052 317.908 399.052 316.626C399.063 315.343 398.015 314.295 396.721 314.295Z"
            fill="#ADADAD"
          />
          <path
            d="M396.721 333.654C395.438 333.654 394.379 334.701 394.379 335.984C394.379 337.266 395.427 338.314 396.721 338.314C398.004 338.314 399.052 337.266 399.052 335.984C399.063 334.701 398.015 333.654 396.721 333.654Z"
            fill="#ADADAD"
          />
          <path
            d="M396.721 340.11C395.438 340.11 394.379 341.157 394.379 342.44C394.379 343.723 395.427 344.77 396.721 344.77C398.004 344.77 399.052 343.723 399.052 342.44C399.063 341.157 398.015 340.11 396.721 340.11Z"
            fill="#ADADAD"
          />
          <path
            d="M390.275 275.582C388.991 275.582 387.943 276.63 387.943 277.923C387.943 279.206 388.991 280.253 390.275 280.253C391.558 280.253 392.606 279.206 392.606 277.923C392.606 276.63 391.558 275.582 390.275 275.582Z"
            fill="#ADADAD"
          />
          <path
            d="M390.275 282.038C388.991 282.038 387.943 283.086 387.943 284.368C387.943 285.651 388.991 286.699 390.275 286.699C391.558 286.699 392.606 285.651 392.606 284.368C392.606 283.086 391.558 282.038 390.275 282.038Z"
            fill="#ADADAD"
          />
          <path
            d="M390.275 288.493C388.991 288.493 387.943 289.54 387.943 290.834C387.943 292.116 388.991 293.164 390.275 293.164C391.558 293.164 392.606 292.116 392.606 290.834C392.606 289.54 391.558 288.493 390.275 288.493Z"
            fill="#ADADAD"
          />
          <path
            d="M390.275 294.939C388.991 294.939 387.943 295.986 387.943 297.28C387.943 298.562 388.991 299.61 390.275 299.61C391.558 299.61 392.606 298.562 392.606 297.28C392.606 295.986 391.558 294.939 390.275 294.939Z"
            fill="#ADADAD"
          />
          <path
            d="M390.275 301.395C388.991 301.395 387.943 302.442 387.943 303.725C387.943 305.008 388.991 306.055 390.275 306.055C391.558 306.055 392.606 305.008 392.606 303.725C392.606 302.442 391.558 301.395 390.275 301.395Z"
            fill="#ADADAD"
          />
          <path
            d="M390.275 307.851C388.991 307.851 387.943 308.899 387.943 310.192C387.943 311.475 388.991 312.522 390.275 312.522C391.558 312.522 392.606 311.475 392.606 310.192C392.606 308.899 391.558 307.851 390.275 307.851Z"
            fill="#ADADAD"
          />
          <path
            d="M383.813 275.582C382.53 275.582 381.482 276.629 381.482 277.922C381.482 279.205 382.53 280.253 383.813 280.253C385.107 280.253 386.155 279.205 386.155 277.922C386.155 276.629 385.096 275.582 383.813 275.582Z"
            fill="#ADADAD"
          />
          <path
            d="M383.813 282.038C382.53 282.038 381.482 283.085 381.482 284.368C381.482 285.651 382.53 286.698 383.813 286.698C385.107 286.698 386.155 285.651 386.155 284.368C386.155 283.085 385.096 282.038 383.813 282.038Z"
            fill="#ADADAD"
          />
          <path
            d="M383.813 288.493C382.53 288.493 381.482 289.541 381.482 290.834C381.482 292.117 382.53 293.164 383.813 293.164C385.107 293.164 386.155 292.117 386.155 290.834C386.155 289.541 385.096 288.493 383.813 288.493Z"
            fill="#ADADAD"
          />
          <path
            d="M377.353 275.582C376.07 275.582 375.022 276.63 375.022 277.923C375.022 279.206 376.07 280.253 377.353 280.253C378.636 280.253 379.684 279.206 379.684 277.923C379.695 276.63 378.647 275.582 377.353 275.582Z"
            fill="#ADADAD"
          />
          <path
            d="M377.353 282.038C376.07 282.038 375.022 283.086 375.022 284.368C375.022 285.651 376.07 286.699 377.353 286.699C378.636 286.699 379.684 285.651 379.684 284.368C379.695 283.086 378.647 282.038 377.353 282.038Z"
            fill="#ADADAD"
          />
          <path
            d="M377.353 288.493C376.07 288.493 375.022 289.54 375.022 290.834C375.022 292.116 376.07 293.164 377.353 293.164C378.636 293.164 379.684 292.116 379.684 290.834C379.695 289.54 378.647 288.493 377.353 288.493Z"
            fill="#ADADAD"
          />
          <path
            d="M777.667 133.615C776.384 133.615 775.336 134.662 775.336 135.945C775.336 137.228 776.384 138.275 777.667 138.275C778.951 138.275 779.999 137.228 779.999 135.945C779.999 134.662 778.951 133.615 777.667 133.615Z"
            fill="#ADADAD"
          />
          <path
            d="M771.207 133.615C769.923 133.615 768.875 134.662 768.875 135.945C768.875 137.228 769.923 138.275 771.207 138.275C772.49 138.275 773.549 137.228 773.549 135.945C773.538 134.662 772.49 133.615 771.207 133.615Z"
            fill="#ADADAD"
          />
          <path
            d="M771.207 140.071C769.923 140.071 768.875 141.119 768.875 142.401C768.875 143.684 769.923 144.731 771.207 144.731C772.49 144.731 773.549 143.684 773.549 142.401C773.538 141.119 772.49 140.071 771.207 140.071Z"
            fill="#ADADAD"
          />
          <path
            d="M764.747 127.17C763.464 127.17 762.416 128.217 762.416 129.5C762.416 130.793 763.464 131.841 764.747 131.841C766.042 131.841 767.09 130.793 767.09 129.5C767.079 128.217 766.031 127.17 764.747 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M764.747 133.615C763.464 133.615 762.416 134.662 762.416 135.945C762.416 137.228 763.464 138.275 764.747 138.275C766.042 138.275 767.09 137.228 767.09 135.945C767.079 134.662 766.031 133.615 764.747 133.615Z"
            fill="#ADADAD"
          />
          <path
            d="M758.288 127.17C757.005 127.17 755.957 128.217 755.957 129.5C755.957 130.793 757.005 131.841 758.288 131.841C759.582 131.841 760.63 130.793 760.63 129.5C760.63 128.217 759.582 127.17 758.288 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M758.288 133.615C757.005 133.615 755.957 134.662 755.957 135.945C755.957 137.228 757.005 138.275 758.288 138.275C759.582 138.275 760.63 137.228 760.63 135.945C760.63 134.662 759.582 133.615 758.288 133.615Z"
            fill="#ADADAD"
          />
          <path
            d="M758.288 140.071C757.005 140.071 755.957 141.119 755.957 142.401C755.957 143.684 757.005 144.731 758.288 144.731C759.582 144.731 760.63 143.684 760.63 142.401C760.63 141.119 759.582 140.071 758.288 140.071Z"
            fill="#ADADAD"
          />
          <path
            d="M758.288 146.527C757.005 146.527 755.957 147.574 755.957 148.857C755.957 150.14 757.005 151.187 758.288 151.187C759.582 151.187 760.63 150.14 760.63 148.857C760.63 147.574 759.582 146.527 758.288 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M751.838 120.714C750.555 120.714 749.507 121.761 749.507 123.054C749.507 124.337 750.555 125.385 751.838 125.385C753.121 125.385 754.169 124.337 754.169 123.054C754.169 121.761 753.121 120.714 751.838 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M751.838 127.17C750.555 127.17 749.507 128.217 749.507 129.5C749.507 130.793 750.555 131.841 751.838 131.841C753.121 131.841 754.169 130.793 754.169 129.5C754.169 128.217 753.121 127.17 751.838 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M751.838 133.616C750.555 133.616 749.507 134.663 749.507 135.946C749.507 137.228 750.555 138.276 751.838 138.276C753.121 138.276 754.169 137.228 754.169 135.946C754.169 134.663 753.121 133.616 751.838 133.616Z"
            fill="#ADADAD"
          />
          <path
            d="M751.838 140.072C750.555 140.072 749.507 141.119 749.507 142.402C749.507 143.685 750.555 144.732 751.838 144.732C753.121 144.732 754.169 143.685 754.169 142.402C754.169 141.119 753.121 140.072 751.838 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M751.838 146.527C750.555 146.527 749.507 147.574 749.507 148.857C749.507 150.139 750.555 151.187 751.838 151.187C753.121 151.187 754.169 150.139 754.169 148.857C754.169 147.574 753.121 146.527 751.838 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M745.379 120.714C744.095 120.714 743.047 121.761 743.047 123.054C743.047 124.337 744.095 125.385 745.379 125.385C746.662 125.385 747.71 124.337 747.71 123.054C747.71 121.761 746.662 120.714 745.379 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M745.379 127.17C744.095 127.17 743.047 128.217 743.047 129.5C743.047 130.793 744.095 131.841 745.379 131.841C746.662 131.841 747.71 130.793 747.71 129.5C747.71 128.217 746.662 127.17 745.379 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M745.379 133.616C744.095 133.616 743.047 134.663 743.047 135.946C743.047 137.228 744.095 138.276 745.379 138.276C746.662 138.276 747.71 137.228 747.71 135.946C747.71 134.663 746.662 133.616 745.379 133.616Z"
            fill="#ADADAD"
          />
          <path
            d="M745.379 140.072C744.095 140.072 743.047 141.119 743.047 142.402C743.047 143.685 744.095 144.732 745.379 144.732C746.662 144.732 747.71 143.685 747.71 142.402C747.71 141.119 746.662 140.072 745.379 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M745.379 146.527C744.095 146.527 743.047 147.574 743.047 148.857C743.047 150.139 744.095 151.187 745.379 151.187C746.662 151.187 747.71 150.139 747.71 148.857C747.71 147.574 746.662 146.527 745.379 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M738.919 120.714C737.636 120.714 736.588 121.761 736.588 123.054C736.588 124.337 737.636 125.385 738.919 125.385C740.213 125.385 741.261 124.337 741.261 123.054C741.261 121.761 740.213 120.714 738.919 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M738.919 127.17C737.636 127.17 736.588 128.217 736.588 129.5C736.588 130.793 737.636 131.841 738.919 131.841C740.213 131.841 741.261 130.793 741.261 129.5C741.261 128.217 740.213 127.17 738.919 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M738.919 133.616C737.636 133.616 736.588 134.663 736.588 135.946C736.588 137.228 737.636 138.276 738.919 138.276C740.213 138.276 741.261 137.228 741.261 135.946C741.261 134.663 740.213 133.616 738.919 133.616Z"
            fill="#ADADAD"
          />
          <path
            d="M738.919 140.072C737.636 140.072 736.588 141.119 736.588 142.402C736.588 143.685 737.636 144.732 738.919 144.732C740.213 144.732 741.261 143.685 741.261 142.402C741.261 141.119 740.213 140.072 738.919 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M738.919 146.527C737.636 146.527 736.588 147.574 736.588 148.857C736.588 150.139 737.636 151.187 738.919 151.187C740.213 151.187 741.261 150.139 741.261 148.857C741.261 147.574 740.213 146.527 738.919 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M738.919 152.983C737.636 152.983 736.588 154.03 736.588 155.313C736.588 156.596 737.636 157.654 738.919 157.654C740.213 157.654 741.261 156.606 741.261 155.313C741.261 154.03 740.213 152.983 738.919 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M732.46 120.714C731.176 120.714 730.128 121.762 730.128 123.055C730.128 124.338 731.176 125.385 732.46 125.385C733.743 125.385 734.791 124.338 734.791 123.055C734.802 121.762 733.754 120.714 732.46 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M732.46 127.17C731.176 127.17 730.128 128.217 730.128 129.5C730.128 130.793 731.176 131.841 732.46 131.841C733.743 131.841 734.791 130.793 734.791 129.5C734.802 128.217 733.754 127.17 732.46 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M732.46 133.615C731.176 133.615 730.128 134.662 730.128 135.945C730.128 137.228 731.176 138.275 732.46 138.275C733.743 138.275 734.791 137.228 734.791 135.945C734.802 134.662 733.754 133.615 732.46 133.615Z"
            fill="#ADADAD"
          />
          <path
            d="M732.46 140.071C731.176 140.071 730.128 141.119 730.128 142.401C730.128 143.684 731.176 144.731 732.46 144.731C733.743 144.731 734.791 143.684 734.791 142.401C734.802 141.119 733.754 140.071 732.46 140.071Z"
            fill="#ADADAD"
          />
          <path
            d="M732.46 146.527C731.176 146.527 730.128 147.574 730.128 148.857C730.128 150.14 731.176 151.187 732.46 151.187C733.743 151.187 734.791 150.14 734.791 148.857C734.791 147.574 733.754 146.527 732.46 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M732.46 152.983C731.176 152.983 730.128 154.031 730.128 155.313C730.128 156.596 731.176 157.654 732.46 157.654C733.743 157.654 734.791 156.607 734.791 155.313C734.802 154.031 733.754 152.983 732.46 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M726.012 120.714C724.728 120.714 723.68 121.762 723.68 123.055C723.68 124.338 724.728 125.385 726.012 125.385C727.295 125.385 728.343 124.338 728.343 123.055C728.343 121.762 727.295 120.714 726.012 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M726.012 127.17C724.728 127.17 723.68 128.217 723.68 129.5C723.68 130.793 724.728 131.841 726.012 131.841C727.295 131.841 728.343 130.793 728.343 129.5C728.343 128.217 727.295 127.17 726.012 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M726.012 133.615C724.728 133.615 723.68 134.662 723.68 135.945C723.68 137.228 724.728 138.275 726.012 138.275C727.295 138.275 728.343 137.228 728.343 135.945C728.343 134.662 727.295 133.615 726.012 133.615Z"
            fill="#ADADAD"
          />
          <path
            d="M726.012 140.071C724.728 140.071 723.68 141.119 723.68 142.401C723.68 143.684 724.728 144.731 726.012 144.731C727.295 144.731 728.343 143.684 728.343 142.401C728.343 141.119 727.295 140.071 726.012 140.071Z"
            fill="#ADADAD"
          />
          <path
            d="M726.012 151.187C727.299 151.187 728.343 150.144 728.343 148.857C728.343 147.57 727.299 146.527 726.012 146.527C724.724 146.527 723.68 147.57 723.68 148.857C723.68 150.144 724.724 151.187 726.012 151.187Z"
            fill="#ADADAD"
          />
          <path
            d="M726.012 152.983C724.728 152.983 723.68 154.031 723.68 155.313C723.68 156.596 724.728 157.654 726.012 157.654C727.295 157.654 728.343 156.607 728.343 155.313C728.343 154.031 727.295 152.983 726.012 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M719.55 120.714C718.267 120.714 717.218 121.761 717.218 123.054C717.218 124.337 718.267 125.385 719.55 125.385C720.833 125.385 721.892 124.337 721.892 123.054C721.892 121.761 720.833 120.714 719.55 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M719.55 127.17C718.267 127.17 717.218 128.217 717.218 129.5C717.218 130.793 718.267 131.841 719.55 131.841C720.833 131.841 721.892 130.793 721.892 129.5C721.892 128.217 720.833 127.17 719.55 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M719.55 133.616C718.267 133.616 717.218 134.663 717.218 135.946C717.218 137.228 718.267 138.276 719.55 138.276C720.833 138.276 721.892 137.228 721.892 135.946C721.892 134.663 720.833 133.616 719.55 133.616Z"
            fill="#ADADAD"
          />
          <path
            d="M719.55 140.072C718.267 140.072 717.218 141.119 717.218 142.402C717.218 143.685 718.267 144.732 719.55 144.732C720.833 144.732 721.892 143.685 721.892 142.402C721.892 141.119 720.833 140.072 719.55 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M719.55 146.527C718.267 146.527 717.218 147.574 717.218 148.857C717.218 150.139 718.267 151.187 719.55 151.187C720.833 151.187 721.892 150.139 721.892 148.857C721.892 147.574 720.833 146.527 719.55 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M719.55 152.983C718.267 152.983 717.218 154.03 717.218 155.313C717.218 156.596 718.267 157.654 719.55 157.654C720.833 157.654 721.892 156.606 721.892 155.313C721.892 154.03 720.833 152.983 719.55 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M719.55 159.429C718.267 159.429 717.218 160.476 717.218 161.759C717.218 163.052 718.267 164.099 719.55 164.099C720.833 164.099 721.892 163.052 721.892 161.759C721.892 160.476 720.833 159.429 719.55 159.429Z"
            fill="#ADADAD"
          />
          <path
            d="M719.55 165.885C718.267 165.885 717.218 166.932 717.218 168.215C717.218 169.497 718.267 170.545 719.55 170.545C720.833 170.545 721.892 169.497 721.892 168.215C721.892 166.932 720.833 165.885 719.55 165.885Z"
            fill="#ADADAD"
          />
          <path
            d="M719.55 172.339C718.267 172.339 717.218 173.387 717.218 174.68C717.218 175.963 718.267 177.01 719.55 177.01C720.833 177.01 721.892 175.963 721.892 174.68C721.892 173.387 720.833 172.339 719.55 172.339Z"
            fill="#ADADAD"
          />
          <path
            d="M713.091 120.714C711.807 120.714 710.759 121.761 710.759 123.054C710.759 124.337 711.807 125.385 713.091 125.385C714.385 125.385 715.433 124.337 715.433 123.054C715.433 121.761 714.385 120.714 713.091 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M713.091 127.17C711.807 127.17 710.759 128.217 710.759 129.5C710.759 130.793 711.807 131.841 713.091 131.841C714.385 131.841 715.433 130.793 715.433 129.5C715.433 128.217 714.385 127.17 713.091 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M713.091 133.616C711.807 133.616 710.759 134.663 710.759 135.946C710.759 137.228 711.807 138.276 713.091 138.276C714.385 138.276 715.433 137.228 715.433 135.946C715.433 134.663 714.385 133.616 713.091 133.616Z"
            fill="#ADADAD"
          />
          <path
            d="M713.091 140.072C711.807 140.072 710.759 141.119 710.759 142.402C710.759 143.685 711.807 144.732 713.091 144.732C714.385 144.732 715.433 143.685 715.433 142.402C715.433 141.119 714.385 140.072 713.091 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M713.091 146.527C711.807 146.527 710.759 147.574 710.759 148.857C710.759 150.139 711.807 151.187 713.091 151.187C714.385 151.187 715.433 150.139 715.433 148.857C715.433 147.574 714.385 146.527 713.091 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M713.091 159.429C711.807 159.429 710.759 160.476 710.759 161.759C710.759 163.052 711.807 164.099 713.091 164.099C714.385 164.099 715.433 163.052 715.433 161.759C715.433 160.476 714.385 159.429 713.091 159.429Z"
            fill="#ADADAD"
          />
          <path
            d="M713.091 165.885C711.807 165.885 710.759 166.932 710.759 168.215C710.759 169.497 711.807 170.545 713.091 170.545C714.385 170.545 715.433 169.497 715.433 168.215C715.433 166.932 714.385 165.885 713.091 165.885Z"
            fill="#ADADAD"
          />
          <path
            d="M713.091 172.339C711.807 172.339 710.759 173.387 710.759 174.68C710.759 175.963 711.807 177.01 713.091 177.01C714.385 177.01 715.433 175.963 715.433 174.68C715.433 173.387 714.385 172.339 713.091 172.339Z"
            fill="#ADADAD"
          />
          <path
            d="M713.091 178.785C711.807 178.785 710.759 179.833 710.759 181.115C710.759 182.398 711.807 183.445 713.091 183.445C714.385 183.445 715.433 182.398 715.433 181.115C715.433 179.833 714.385 178.785 713.091 178.785Z"
            fill="#ADADAD"
          />
          <path
            d="M706.631 120.714C705.348 120.714 704.3 121.761 704.3 123.054C704.3 124.337 705.348 125.385 706.631 125.385C707.925 125.385 708.973 124.337 708.973 123.054C708.973 121.761 707.925 120.714 706.631 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M706.631 127.17C705.348 127.17 704.3 128.217 704.3 129.5C704.3 130.793 705.348 131.841 706.631 131.841C707.925 131.841 708.973 130.793 708.973 129.5C708.973 128.217 707.925 127.17 706.631 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M706.631 133.616C705.348 133.616 704.3 134.663 704.3 135.946C704.3 137.228 705.348 138.276 706.631 138.276C707.925 138.276 708.973 137.228 708.973 135.946C708.973 134.663 707.925 133.616 706.631 133.616Z"
            fill="#ADADAD"
          />
          <path
            d="M706.631 140.072C705.348 140.072 704.3 141.119 704.3 142.402C704.3 143.685 705.348 144.732 706.631 144.732C707.925 144.732 708.973 143.685 708.973 142.402C708.973 141.119 707.925 140.072 706.631 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M706.631 146.527C705.348 146.527 704.3 147.574 704.3 148.857C704.3 150.139 705.348 151.187 706.631 151.187C707.925 151.187 708.973 150.139 708.973 148.857C708.973 147.574 707.925 146.527 706.631 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M706.631 152.983C705.348 152.983 704.3 154.03 704.3 155.313C704.3 156.596 705.348 157.654 706.631 157.654C707.925 157.654 708.973 156.606 708.973 155.313C708.973 154.03 707.925 152.983 706.631 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M706.631 172.339C705.348 172.339 704.3 173.387 704.3 174.68C704.3 175.963 705.348 177.01 706.631 177.01C707.925 177.01 708.973 175.963 708.973 174.68C708.973 173.387 707.925 172.339 706.631 172.339Z"
            fill="#ADADAD"
          />
          <path
            d="M700.183 114.258C698.9 114.258 697.852 115.306 697.852 116.599C697.852 117.881 698.9 118.929 700.183 118.929C701.467 118.929 702.515 117.881 702.515 116.599C702.515 115.306 701.467 114.258 700.183 114.258Z"
            fill="#ADADAD"
          />
          <path
            d="M700.183 120.714C698.9 120.714 697.852 121.762 697.852 123.055C697.852 124.338 698.9 125.385 700.183 125.385C701.467 125.385 702.515 124.338 702.515 123.055C702.515 121.762 701.467 120.714 700.183 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M700.183 127.17C698.9 127.17 697.852 128.217 697.852 129.5C697.852 130.793 698.9 131.841 700.183 131.841C701.467 131.841 702.515 130.793 702.515 129.5C702.515 128.217 701.467 127.17 700.183 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M700.183 133.615C698.9 133.615 697.852 134.662 697.852 135.945C697.852 137.228 698.9 138.275 700.183 138.275C701.467 138.275 702.515 137.228 702.515 135.945C702.515 134.662 701.467 133.615 700.183 133.615Z"
            fill="#ADADAD"
          />
          <path
            d="M700.183 140.071C698.9 140.071 697.852 141.119 697.852 142.401C697.852 143.684 698.9 144.731 700.183 144.731C701.467 144.731 702.515 143.684 702.515 142.401C702.515 141.119 701.467 140.071 700.183 140.071Z"
            fill="#ADADAD"
          />
          <path
            d="M700.183 146.527C698.9 146.527 697.852 147.574 697.852 148.857C697.852 150.14 698.9 151.187 700.183 151.187C701.467 151.187 702.515 150.14 702.515 148.857C702.515 147.574 701.467 146.527 700.183 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M700.183 152.983C698.9 152.983 697.852 154.031 697.852 155.313C697.852 156.596 698.9 157.654 700.183 157.654C701.467 157.654 702.515 156.607 702.515 155.313C702.515 154.031 701.467 152.983 700.183 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M693.724 101.357C692.441 101.357 691.393 102.404 691.393 103.687C691.393 104.969 692.441 106.017 693.724 106.017C695.007 106.017 696.055 104.969 696.055 103.687C696.055 102.404 695.007 101.357 693.724 101.357Z"
            fill="#ADADAD"
          />
          <path
            d="M693.724 114.258C692.441 114.258 691.393 115.306 691.393 116.599C691.393 117.881 692.441 118.929 693.724 118.929C695.007 118.929 696.055 117.881 696.055 116.599C696.055 115.306 695.007 114.258 693.724 114.258Z"
            fill="#ADADAD"
          />
          <path
            d="M693.724 120.714C692.441 120.714 691.393 121.762 691.393 123.055C691.393 124.338 692.441 125.385 693.724 125.385C695.007 125.385 696.055 124.338 696.055 123.055C696.055 121.762 695.007 120.714 693.724 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M693.724 127.17C692.441 127.17 691.393 128.217 691.393 129.5C691.393 130.793 692.441 131.841 693.724 131.841C695.007 131.841 696.055 130.793 696.055 129.5C696.055 128.217 695.007 127.17 693.724 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M693.724 133.615C692.441 133.615 691.393 134.662 691.393 135.945C691.393 137.228 692.441 138.275 693.724 138.275C695.007 138.275 696.055 137.228 696.055 135.945C696.055 134.662 695.007 133.615 693.724 133.615Z"
            fill="#ADADAD"
          />
          <path
            d="M693.724 140.071C692.441 140.071 691.393 141.119 691.393 142.401C691.393 143.684 692.441 144.731 693.724 144.731C695.007 144.731 696.055 143.684 696.055 142.401C696.055 141.119 695.007 140.071 693.724 140.071Z"
            fill="#ADADAD"
          />
          <path
            d="M693.724 146.527C692.441 146.527 691.393 147.574 691.393 148.857C691.393 150.14 692.441 151.187 693.724 151.187C695.007 151.187 696.055 150.14 696.055 148.857C696.055 147.574 695.007 146.527 693.724 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M693.724 152.983C692.441 152.983 691.393 154.031 691.393 155.313C691.393 156.596 692.441 157.654 693.724 157.654C695.007 157.654 696.055 156.607 696.055 155.313C696.055 154.031 695.007 152.983 693.724 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M687.274 101.357C685.99 101.357 684.942 102.404 684.942 103.687C684.942 104.97 685.99 106.017 687.274 106.017C688.557 106.017 689.605 104.97 689.605 103.687C689.605 102.404 688.557 101.357 687.274 101.357Z"
            fill="#ADADAD"
          />
          <path
            d="M687.274 114.257C685.99 114.257 684.942 115.305 684.942 116.598C684.942 117.881 685.99 118.928 687.274 118.928C688.557 118.928 689.605 117.881 689.605 116.598C689.605 115.305 688.557 114.257 687.274 114.257Z"
            fill="#ADADAD"
          />
          <path
            d="M687.274 120.714C685.99 120.714 684.942 121.761 684.942 123.054C684.942 124.337 685.99 125.385 687.274 125.385C688.557 125.385 689.605 124.337 689.605 123.054C689.605 121.761 688.557 120.714 687.274 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M687.274 127.17C685.99 127.17 684.942 128.217 684.942 129.5C684.942 130.793 685.99 131.841 687.274 131.841C688.557 131.841 689.605 130.793 689.605 129.5C689.605 128.217 688.557 127.17 687.274 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M687.274 133.616C685.99 133.616 684.942 134.663 684.942 135.946C684.942 137.228 685.99 138.276 687.274 138.276C688.557 138.276 689.605 137.228 689.605 135.946C689.605 134.663 688.557 133.616 687.274 133.616Z"
            fill="#ADADAD"
          />
          <path
            d="M687.274 140.072C685.99 140.072 684.942 141.119 684.942 142.402C684.942 143.685 685.99 144.732 687.274 144.732C688.557 144.732 689.605 143.685 689.605 142.402C689.605 141.119 688.557 140.072 687.274 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M687.274 146.527C685.99 146.527 684.942 147.574 684.942 148.857C684.942 150.139 685.99 151.187 687.274 151.187C688.557 151.187 689.605 150.139 689.605 148.857C689.605 147.574 688.557 146.527 687.274 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M687.274 152.983C685.99 152.983 684.942 154.03 684.942 155.313C684.942 156.596 685.99 157.654 687.274 157.654C688.557 157.654 689.605 156.606 689.605 155.313C689.605 154.03 688.557 152.983 687.274 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M680.814 94.9008C679.531 94.9008 678.483 95.9483 678.483 97.2309C678.483 98.5135 679.531 99.561 680.814 99.561C682.098 99.561 683.146 98.5135 683.146 97.2309C683.146 95.9483 682.098 94.9008 680.814 94.9008Z"
            fill="#ADADAD"
          />
          <path
            d="M680.814 101.357C679.531 101.357 678.483 102.404 678.483 103.687C678.483 104.97 679.531 106.017 680.814 106.017C682.098 106.017 683.146 104.97 683.146 103.687C683.146 102.404 682.098 101.357 680.814 101.357Z"
            fill="#ADADAD"
          />
          <path
            d="M680.814 107.813C679.531 107.813 678.483 108.861 678.483 110.143C678.483 111.426 679.531 112.473 680.814 112.473C682.098 112.473 683.146 111.426 683.146 110.143C683.146 108.861 682.098 107.813 680.814 107.813Z"
            fill="#ADADAD"
          />
          <path
            d="M680.814 114.257C679.531 114.257 678.483 115.305 678.483 116.598C678.483 117.881 679.531 118.928 680.814 118.928C682.098 118.928 683.146 117.881 683.146 116.598C683.146 115.305 682.098 114.257 680.814 114.257Z"
            fill="#ADADAD"
          />
          <path
            d="M680.814 120.714C679.531 120.714 678.483 121.761 678.483 123.054C678.483 124.337 679.531 125.385 680.814 125.385C682.098 125.385 683.146 124.337 683.146 123.054C683.146 121.761 682.098 120.714 680.814 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M680.814 127.17C679.531 127.17 678.483 128.217 678.483 129.5C678.483 130.793 679.531 131.841 680.814 131.841C682.098 131.841 683.146 130.793 683.146 129.5C683.146 128.217 682.098 127.17 680.814 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M680.814 133.616C679.531 133.616 678.483 134.663 678.483 135.946C678.483 137.228 679.531 138.276 680.814 138.276C682.098 138.276 683.146 137.228 683.146 135.946C683.146 134.663 682.098 133.616 680.814 133.616Z"
            fill="#ADADAD"
          />
          <path
            d="M680.814 140.072C679.531 140.072 678.483 141.119 678.483 142.402C678.483 143.685 679.531 144.732 680.814 144.732C682.098 144.732 683.146 143.685 683.146 142.402C683.146 141.119 682.098 140.072 680.814 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M680.814 146.527C679.531 146.527 678.483 147.574 678.483 148.857C678.483 150.139 679.531 151.187 680.814 151.187C682.098 151.187 683.146 150.139 683.146 148.857C683.146 147.574 682.098 146.527 680.814 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M680.814 152.983C679.531 152.983 678.483 154.03 678.483 155.313C678.483 156.596 679.531 157.654 680.814 157.654C682.098 157.654 683.146 156.606 683.146 155.313C683.146 154.03 682.098 152.983 680.814 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M674.357 94.9008C673.073 94.9008 672.015 95.9483 672.015 97.2309C672.015 98.5135 673.063 99.561 674.357 99.561C675.64 99.561 676.688 98.5135 676.688 97.2309C676.688 95.9483 675.651 94.9008 674.357 94.9008Z"
            fill="#ADADAD"
          />
          <path
            d="M674.357 101.357C673.073 101.357 672.015 102.404 672.015 103.687C672.015 104.97 673.063 106.017 674.357 106.017C675.64 106.017 676.688 104.97 676.688 103.687C676.688 102.404 675.651 101.357 674.357 101.357Z"
            fill="#ADADAD"
          />
          <path
            d="M674.357 107.813C673.073 107.813 672.015 108.861 672.015 110.143C672.015 111.426 673.063 112.473 674.357 112.473C675.64 112.473 676.688 111.426 676.688 110.143C676.688 108.861 675.651 107.813 674.357 107.813Z"
            fill="#ADADAD"
          />
          <path
            d="M674.357 114.257C673.073 114.257 672.015 115.305 672.015 116.598C672.015 117.881 673.063 118.928 674.357 118.928C675.64 118.928 676.688 117.881 676.688 116.598C676.688 115.305 675.651 114.257 674.357 114.257Z"
            fill="#ADADAD"
          />
          <path
            d="M674.357 120.714C673.073 120.714 672.015 121.761 672.015 123.054C672.015 124.337 673.063 125.385 674.357 125.385C675.64 125.385 676.688 124.337 676.688 123.054C676.688 121.761 675.651 120.714 674.357 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M674.357 127.17C673.073 127.17 672.015 128.217 672.015 129.5C672.015 130.793 673.063 131.841 674.357 131.841C675.64 131.841 676.688 130.793 676.688 129.5C676.688 128.217 675.651 127.17 674.357 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M674.357 133.616C673.073 133.616 672.015 134.663 672.015 135.946C672.015 137.228 673.063 138.276 674.357 138.276C675.64 138.276 676.688 137.228 676.688 135.946C676.688 134.663 675.651 133.616 674.357 133.616Z"
            fill="#ADADAD"
          />
          <path
            d="M674.357 140.072C673.073 140.072 672.015 141.119 672.015 142.402C672.015 143.685 673.063 144.732 674.357 144.732C675.64 144.732 676.688 143.685 676.688 142.402C676.688 141.119 675.651 140.072 674.357 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M674.357 146.527C673.073 146.527 672.015 147.574 672.015 148.857C672.015 150.139 673.063 151.187 674.357 151.187C675.64 151.187 676.688 150.139 676.688 148.857C676.688 147.574 675.651 146.527 674.357 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M674.357 152.983C673.073 152.983 672.015 154.03 672.015 155.313C672.015 156.596 673.063 157.654 674.357 157.654C675.64 157.654 676.688 156.606 676.688 155.313C676.688 154.03 675.651 152.983 674.357 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M674.357 159.429C673.073 159.429 672.015 160.476 672.015 161.759C672.015 163.052 673.063 164.099 674.357 164.099C675.64 164.099 676.688 163.052 676.688 161.759C676.688 160.476 675.651 159.429 674.357 159.429Z"
            fill="#ADADAD"
          />
          <path
            d="M667.895 94.9008C666.612 94.9008 665.564 95.9483 665.564 97.2309C665.564 98.5135 666.612 99.561 667.895 99.561C669.179 99.561 670.227 98.5135 670.227 97.2309C670.227 95.9483 669.179 94.9008 667.895 94.9008Z"
            fill="#ADADAD"
          />
          <path
            d="M667.895 101.357C666.612 101.357 665.564 102.404 665.564 103.687C665.564 104.97 666.612 106.017 667.895 106.017C669.179 106.017 670.227 104.97 670.227 103.687C670.227 102.404 669.179 101.357 667.895 101.357Z"
            fill="#ADADAD"
          />
          <path
            d="M667.895 114.257C666.612 114.257 665.564 115.305 665.564 116.598C665.564 117.881 666.612 118.928 667.895 118.928C669.179 118.928 670.227 117.881 670.227 116.598C670.227 115.305 669.179 114.257 667.895 114.257Z"
            fill="#ADADAD"
          />
          <path
            d="M667.895 120.714C666.612 120.714 665.564 121.761 665.564 123.054C665.564 124.337 666.612 125.385 667.895 125.385C669.179 125.385 670.227 124.337 670.227 123.054C670.227 121.761 669.179 120.714 667.895 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M667.895 127.17C666.612 127.17 665.564 128.217 665.564 129.5C665.564 130.793 666.612 131.841 667.895 131.841C669.179 131.841 670.227 130.793 670.227 129.5C670.227 128.217 669.179 127.17 667.895 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M667.895 133.616C666.612 133.616 665.564 134.663 665.564 135.946C665.564 137.228 666.612 138.276 667.895 138.276C669.179 138.276 670.227 137.228 670.227 135.946C670.227 134.663 669.179 133.616 667.895 133.616Z"
            fill="#ADADAD"
          />
          <path
            d="M667.895 140.072C666.612 140.072 665.564 141.119 665.564 142.402C665.564 143.685 666.612 144.732 667.895 144.732C669.179 144.732 670.227 143.685 670.227 142.402C670.227 141.119 669.179 140.072 667.895 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M667.895 146.527C666.612 146.527 665.564 147.574 665.564 148.857C665.564 150.139 666.612 151.187 667.895 151.187C669.179 151.187 670.227 150.139 670.227 148.857C670.227 147.574 669.179 146.527 667.895 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M667.895 152.983C666.612 152.983 665.564 154.03 665.564 155.313C665.564 156.596 666.612 157.654 667.895 157.654C669.179 157.654 670.227 156.606 670.227 155.313C670.227 154.03 669.179 152.983 667.895 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M667.895 159.429C666.612 159.429 665.564 160.476 665.564 161.759C665.564 163.052 666.612 164.099 667.895 164.099C669.179 164.099 670.227 163.052 670.227 161.759C670.227 160.476 669.179 159.429 667.895 159.429Z"
            fill="#ADADAD"
          />
          <path
            d="M661.448 114.258C660.164 114.258 659.116 115.306 659.116 116.599C659.116 117.881 660.164 118.929 661.448 118.929C662.731 118.929 663.779 117.881 663.779 116.599C663.779 115.306 662.731 114.258 661.448 114.258Z"
            fill="#ADADAD"
          />
          <path
            d="M661.448 120.714C660.164 120.714 659.116 121.762 659.116 123.055C659.116 124.338 660.164 125.385 661.448 125.385C662.731 125.385 663.779 124.338 663.779 123.055C663.779 121.762 662.731 120.714 661.448 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M661.448 127.17C660.164 127.17 659.116 128.217 659.116 129.5C659.116 130.793 660.164 131.841 661.448 131.841C662.731 131.841 663.779 130.793 663.779 129.5C663.779 128.217 662.731 127.17 661.448 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M661.448 133.615C660.164 133.615 659.116 134.662 659.116 135.945C659.116 137.228 660.164 138.275 661.448 138.275C662.731 138.275 663.779 137.228 663.779 135.945C663.779 134.662 662.731 133.615 661.448 133.615Z"
            fill="#ADADAD"
          />
          <path
            d="M661.448 140.071C660.164 140.071 659.116 141.119 659.116 142.401C659.116 143.684 660.164 144.731 661.448 144.731C662.731 144.731 663.779 143.684 663.779 142.401C663.779 141.119 662.731 140.071 661.448 140.071Z"
            fill="#ADADAD"
          />
          <path
            d="M661.448 151.187C662.735 151.187 663.779 150.144 663.779 148.857C663.779 147.57 662.735 146.527 661.448 146.527C660.16 146.527 659.116 147.57 659.116 148.857C659.116 150.144 660.16 151.187 661.448 151.187Z"
            fill="#ADADAD"
          />
          <path
            d="M661.448 152.983C660.164 152.983 659.116 154.031 659.116 155.313C659.116 156.596 660.164 157.654 661.448 157.654C662.731 157.654 663.779 156.607 663.779 155.313C663.779 154.031 662.731 152.983 661.448 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M661.448 159.428C660.164 159.428 659.116 160.476 659.116 161.758C659.116 163.052 660.164 164.099 661.448 164.099C662.731 164.099 663.779 163.052 663.779 161.758C663.779 160.476 662.731 159.428 661.448 159.428Z"
            fill="#ADADAD"
          />
          <path
            d="M654.988 114.258C653.694 114.258 652.646 115.306 652.646 116.599C652.646 117.881 653.694 118.929 654.988 118.929C656.271 118.929 657.319 117.881 657.319 116.599C657.319 115.306 656.271 114.258 654.988 114.258Z"
            fill="#ADADAD"
          />
          <path
            d="M654.988 120.714C653.694 120.714 652.646 121.762 652.646 123.055C652.646 124.338 653.694 125.385 654.988 125.385C656.271 125.385 657.319 124.338 657.319 123.055C657.319 121.762 656.271 120.714 654.988 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M654.988 127.17C653.694 127.17 652.646 128.217 652.646 129.5C652.646 130.793 653.694 131.841 654.988 131.841C656.271 131.841 657.319 130.793 657.319 129.5C657.319 128.217 656.271 127.17 654.988 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M654.988 133.615C653.694 133.615 652.646 134.662 652.646 135.945C652.646 137.228 653.694 138.275 654.988 138.275C656.271 138.275 657.319 137.228 657.319 135.945C657.319 134.662 656.271 133.615 654.988 133.615Z"
            fill="#ADADAD"
          />
          <path
            d="M654.988 140.071C653.694 140.071 652.646 141.119 652.646 142.401C652.646 143.684 653.694 144.731 654.988 144.731C656.271 144.731 657.319 143.684 657.319 142.401C657.319 141.119 656.271 140.071 654.988 140.071Z"
            fill="#ADADAD"
          />
          <path
            d="M654.988 146.527C653.694 146.527 652.646 147.574 652.646 148.857C652.646 150.14 653.694 151.187 654.988 151.187C656.271 151.187 657.319 150.14 657.319 148.857C657.319 147.574 656.271 146.527 654.988 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M654.988 152.983C653.694 152.983 652.646 154.031 652.646 155.313C652.646 156.596 653.694 157.654 654.988 157.654C656.271 157.654 657.319 156.607 657.319 155.313C657.319 154.031 656.271 152.983 654.988 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M654.988 159.428C653.694 159.428 652.646 160.476 652.646 161.758C652.646 163.052 653.694 164.099 654.988 164.099C656.271 164.099 657.319 163.052 657.319 161.758C657.319 160.476 656.271 159.428 654.988 159.428Z"
            fill="#ADADAD"
          />
          <path
            d="M648.529 107.813C647.246 107.813 646.197 108.86 646.197 110.143C646.197 111.426 647.246 112.473 648.529 112.473C649.812 112.473 650.86 111.426 650.86 110.143C650.86 108.86 649.823 107.813 648.529 107.813Z"
            fill="#ADADAD"
          />
          <path
            d="M648.529 114.258C647.246 114.258 646.197 115.306 646.197 116.599C646.197 117.881 647.246 118.929 648.529 118.929C649.812 118.929 650.86 117.881 650.86 116.599C650.86 115.306 649.823 114.258 648.529 114.258Z"
            fill="#ADADAD"
          />
          <path
            d="M648.529 120.714C647.246 120.714 646.197 121.762 646.197 123.055C646.197 124.338 647.246 125.385 648.529 125.385C649.812 125.385 650.86 124.338 650.86 123.055C650.86 121.762 649.823 120.714 648.529 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M648.529 127.17C647.246 127.17 646.197 128.217 646.197 129.5C646.197 130.793 647.246 131.841 648.529 131.841C649.812 131.841 650.86 130.793 650.86 129.5C650.86 128.217 649.823 127.17 648.529 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M648.529 133.615C647.246 133.615 646.197 134.662 646.197 135.945C646.197 137.228 647.246 138.275 648.529 138.275C649.812 138.275 650.86 137.228 650.86 135.945C650.86 134.662 649.823 133.615 648.529 133.615Z"
            fill="#ADADAD"
          />
          <path
            d="M648.529 140.071C647.246 140.071 646.197 141.119 646.197 142.401C646.197 143.684 647.246 144.731 648.529 144.731C649.812 144.731 650.86 143.684 650.86 142.401C650.86 141.119 649.823 140.071 648.529 140.071Z"
            fill="#ADADAD"
          />
          <path
            d="M648.529 146.527C647.246 146.527 646.197 147.574 646.197 148.857C646.197 150.14 647.246 151.187 648.529 151.187C649.812 151.187 650.86 150.14 650.86 148.857C650.86 147.574 649.823 146.527 648.529 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M648.529 152.983C647.246 152.983 646.197 154.031 646.197 155.313C646.197 156.596 647.246 157.654 648.529 157.654C649.812 157.654 650.86 156.607 650.86 155.313C650.86 154.031 649.823 152.983 648.529 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M648.529 159.428C647.246 159.428 646.197 160.476 646.197 161.758C646.197 163.052 647.246 164.099 648.529 164.099C649.812 164.099 650.86 163.052 650.86 161.758C650.86 160.476 649.823 159.428 648.529 159.428Z"
            fill="#ADADAD"
          />
          <path
            d="M642.07 107.813C640.786 107.813 639.738 108.861 639.738 110.143C639.738 111.426 640.786 112.473 642.07 112.473C643.353 112.473 644.401 111.426 644.401 110.143C644.412 108.861 643.364 107.813 642.07 107.813Z"
            fill="#ADADAD"
          />
          <path
            d="M642.07 114.257C640.786 114.257 639.738 115.305 639.738 116.598C639.738 117.881 640.786 118.928 642.07 118.928C643.353 118.928 644.401 117.881 644.401 116.598C644.412 115.305 643.364 114.257 642.07 114.257Z"
            fill="#ADADAD"
          />
          <path
            d="M642.07 120.714C640.786 120.714 639.738 121.761 639.738 123.054C639.738 124.337 640.786 125.385 642.07 125.385C643.353 125.385 644.401 124.337 644.401 123.054C644.412 121.761 643.364 120.714 642.07 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M642.07 127.17C640.786 127.17 639.738 128.217 639.738 129.5C639.738 130.793 640.786 131.841 642.07 131.841C643.353 131.841 644.401 130.793 644.401 129.5C644.412 128.217 643.364 127.17 642.07 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M642.07 133.616C640.786 133.616 639.738 134.663 639.738 135.946C639.738 137.228 640.786 138.276 642.07 138.276C643.353 138.276 644.401 137.228 644.401 135.946C644.412 134.663 643.364 133.616 642.07 133.616Z"
            fill="#ADADAD"
          />
          <path
            d="M642.07 140.072C640.786 140.072 639.738 141.119 639.738 142.402C639.738 143.685 640.786 144.732 642.07 144.732C643.353 144.732 644.401 143.685 644.401 142.402C644.412 141.119 643.364 140.072 642.07 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M642.07 146.527C640.786 146.527 639.738 147.574 639.738 148.857C639.738 150.139 640.786 151.187 642.07 151.187C643.353 151.187 644.401 150.139 644.401 148.857C644.401 147.574 643.364 146.527 642.07 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M642.07 152.983C640.786 152.983 639.738 154.03 639.738 155.313C639.738 156.596 640.786 157.654 642.07 157.654C643.353 157.654 644.401 156.606 644.401 155.313C644.412 154.03 643.364 152.983 642.07 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M642.07 159.429C640.786 159.429 639.738 160.476 639.738 161.759C639.738 163.052 640.786 164.099 642.07 164.099C643.353 164.099 644.401 163.052 644.401 161.759C644.412 160.476 643.364 159.429 642.07 159.429Z"
            fill="#ADADAD"
          />
          <path
            d="M635.619 112.473C636.907 112.473 637.951 111.43 637.951 110.143C637.951 108.856 636.907 107.813 635.619 107.813C634.332 107.813 633.288 108.856 633.288 110.143C633.288 111.43 634.332 112.473 635.619 112.473Z"
            fill="#ADADAD"
          />
          <path
            d="M635.619 114.257C634.336 114.257 633.288 115.305 633.288 116.598C633.288 117.881 634.336 118.928 635.619 118.928C636.903 118.928 637.951 117.881 637.951 116.598C637.951 115.305 636.903 114.257 635.619 114.257Z"
            fill="#ADADAD"
          />
          <path
            d="M635.619 120.714C634.336 120.714 633.288 121.761 633.288 123.054C633.288 124.337 634.336 125.385 635.619 125.385C636.903 125.385 637.951 124.337 637.951 123.054C637.951 121.761 636.903 120.714 635.619 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M635.619 127.17C634.336 127.17 633.288 128.217 633.288 129.5C633.288 130.793 634.336 131.841 635.619 131.841C636.903 131.841 637.951 130.793 637.951 129.5C637.951 128.217 636.903 127.17 635.619 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M635.619 133.616C634.336 133.616 633.288 134.663 633.288 135.946C633.288 137.228 634.336 138.276 635.619 138.276C636.903 138.276 637.951 137.228 637.951 135.946C637.951 134.663 636.903 133.616 635.619 133.616Z"
            fill="#ADADAD"
          />
          <path
            d="M635.619 140.072C634.336 140.072 633.288 141.119 633.288 142.402C633.288 143.685 634.336 144.732 635.619 144.732C636.903 144.732 637.951 143.685 637.951 142.402C637.951 141.119 636.903 140.072 635.619 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M635.619 151.187C636.907 151.187 637.951 150.144 637.951 148.857C637.951 147.57 636.907 146.527 635.619 146.527C634.332 146.527 633.288 147.57 633.288 148.857C633.288 150.144 634.332 151.187 635.619 151.187Z"
            fill="#ADADAD"
          />
          <path
            d="M635.619 152.983C634.336 152.983 633.288 154.03 633.288 155.313C633.288 156.596 634.336 157.654 635.619 157.654C636.903 157.654 637.951 156.606 637.951 155.313C637.951 154.03 636.903 152.983 635.619 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M635.619 159.429C634.336 159.429 633.288 160.476 633.288 161.759C633.288 163.052 634.336 164.099 635.619 164.099C636.903 164.099 637.951 163.052 637.951 161.759C637.951 160.476 636.903 159.429 635.619 159.429Z"
            fill="#ADADAD"
          />
          <path
            d="M629.16 107.813C627.877 107.813 626.828 108.86 626.828 110.143C626.828 111.426 627.877 112.473 629.16 112.473C630.443 112.473 631.502 111.426 631.502 110.143C631.491 108.86 630.443 107.813 629.16 107.813Z"
            fill="#ADADAD"
          />
          <path
            d="M629.16 114.258C627.877 114.258 626.828 115.306 626.828 116.599C626.828 117.881 627.877 118.929 629.16 118.929C630.443 118.929 631.502 117.881 631.502 116.599C631.491 115.306 630.443 114.258 629.16 114.258Z"
            fill="#ADADAD"
          />
          <path
            d="M629.16 120.714C627.877 120.714 626.828 121.762 626.828 123.055C626.828 124.338 627.877 125.385 629.16 125.385C630.443 125.385 631.502 124.338 631.502 123.055C631.491 121.762 630.443 120.714 629.16 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M629.16 127.17C627.877 127.17 626.828 128.217 626.828 129.5C626.828 130.793 627.877 131.841 629.16 131.841C630.443 131.841 631.502 130.793 631.502 129.5C631.491 128.217 630.443 127.17 629.16 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M629.16 133.615C627.877 133.615 626.828 134.662 626.828 135.945C626.828 137.228 627.877 138.275 629.16 138.275C630.443 138.275 631.502 137.228 631.502 135.945C631.491 134.662 630.443 133.615 629.16 133.615Z"
            fill="#ADADAD"
          />
          <path
            d="M629.16 140.071C627.877 140.071 626.828 141.119 626.828 142.401C626.828 143.684 627.877 144.731 629.16 144.731C630.443 144.731 631.502 143.684 631.502 142.401C631.491 141.119 630.443 140.071 629.16 140.071Z"
            fill="#ADADAD"
          />
          <path
            d="M629.16 146.527C627.877 146.527 626.828 147.574 626.828 148.857C626.828 150.14 627.877 151.187 629.16 151.187C630.443 151.187 631.502 150.14 631.502 148.857C631.502 147.574 630.443 146.527 629.16 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M629.16 152.983C627.877 152.983 626.828 154.031 626.828 155.313C626.828 156.596 627.877 157.654 629.16 157.654C630.443 157.654 631.502 156.607 631.502 155.313C631.491 154.031 630.443 152.983 629.16 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M629.16 159.428C627.877 159.428 626.828 160.476 626.828 161.758C626.828 163.052 627.877 164.099 629.16 164.099C630.443 164.099 631.502 163.052 631.502 161.758C631.491 160.476 630.443 159.428 629.16 159.428Z"
            fill="#ADADAD"
          />
          <path
            d="M622.701 107.813C621.417 107.813 620.369 108.86 620.369 110.143C620.369 111.426 621.417 112.473 622.701 112.473C623.995 112.473 625.043 111.426 625.043 110.143C625.043 108.86 623.995 107.813 622.701 107.813Z"
            fill="#ADADAD"
          />
          <path
            d="M622.701 114.258C621.417 114.258 620.369 115.306 620.369 116.599C620.369 117.881 621.417 118.929 622.701 118.929C623.995 118.929 625.043 117.881 625.043 116.599C625.043 115.306 623.995 114.258 622.701 114.258Z"
            fill="#ADADAD"
          />
          <path
            d="M622.701 120.714C621.417 120.714 620.369 121.762 620.369 123.055C620.369 124.338 621.417 125.385 622.701 125.385C623.995 125.385 625.043 124.338 625.043 123.055C625.043 121.762 623.995 120.714 622.701 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M622.701 127.17C621.417 127.17 620.369 128.217 620.369 129.5C620.369 130.793 621.417 131.841 622.701 131.841C623.995 131.841 625.043 130.793 625.043 129.5C625.043 128.217 623.995 127.17 622.701 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M622.701 133.615C621.417 133.615 620.369 134.662 620.369 135.945C620.369 137.228 621.417 138.275 622.701 138.275C623.995 138.275 625.043 137.228 625.043 135.945C625.043 134.662 623.995 133.615 622.701 133.615Z"
            fill="#ADADAD"
          />
          <path
            d="M622.701 140.071C621.417 140.071 620.369 141.119 620.369 142.401C620.369 143.684 621.417 144.731 622.701 144.731C623.995 144.731 625.043 143.684 625.043 142.401C625.043 141.119 623.995 140.071 622.701 140.071Z"
            fill="#ADADAD"
          />
          <path
            d="M622.701 146.527C621.417 146.527 620.369 147.574 620.369 148.857C620.369 150.14 621.417 151.187 622.701 151.187C623.995 151.187 625.043 150.14 625.043 148.857C625.043 147.574 623.995 146.527 622.701 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M622.701 152.983C621.417 152.983 620.369 154.031 620.369 155.313C620.369 156.596 621.417 157.654 622.701 157.654C623.995 157.654 625.043 156.607 625.043 155.313C625.043 154.031 623.995 152.983 622.701 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M622.701 159.428C621.417 159.428 620.369 160.476 620.369 161.758C620.369 163.052 621.417 164.099 622.701 164.099C623.995 164.099 625.043 163.052 625.043 161.758C625.043 160.476 623.995 159.428 622.701 159.428Z"
            fill="#ADADAD"
          />
          <path
            d="M616.25 94.9008C614.967 94.9008 613.919 95.9483 613.919 97.2309C613.919 98.5135 614.967 99.561 616.25 99.561C617.533 99.561 618.581 98.5135 618.581 97.2309C618.581 95.9483 617.533 94.9008 616.25 94.9008Z"
            fill="#ADADAD"
          />
          <path
            d="M616.25 101.357C614.967 101.357 613.919 102.404 613.919 103.687C613.919 104.97 614.967 106.017 616.25 106.017C617.533 106.017 618.581 104.97 618.581 103.687C618.581 102.404 617.533 101.357 616.25 101.357Z"
            fill="#ADADAD"
          />
          <path
            d="M616.25 112.473C617.538 112.473 618.581 111.43 618.581 110.143C618.581 108.856 617.538 107.813 616.25 107.813C614.962 107.813 613.919 108.856 613.919 110.143C613.919 111.43 614.962 112.473 616.25 112.473Z"
            fill="#ADADAD"
          />
          <path
            d="M616.25 114.257C614.967 114.257 613.919 115.305 613.919 116.598C613.919 117.881 614.967 118.928 616.25 118.928C617.533 118.928 618.581 117.881 618.581 116.598C618.581 115.305 617.533 114.257 616.25 114.257Z"
            fill="#ADADAD"
          />
          <path
            d="M616.25 120.714C614.967 120.714 613.919 121.761 613.919 123.054C613.919 124.337 614.967 125.385 616.25 125.385C617.533 125.385 618.581 124.337 618.581 123.054C618.581 121.761 617.533 120.714 616.25 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M616.25 127.17C614.967 127.17 613.919 128.217 613.919 129.5C613.919 130.793 614.967 131.841 616.25 131.841C617.533 131.841 618.581 130.793 618.581 129.5C618.581 128.217 617.533 127.17 616.25 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M616.25 133.616C614.967 133.616 613.919 134.663 613.919 135.946C613.919 137.228 614.967 138.276 616.25 138.276C617.533 138.276 618.581 137.228 618.581 135.946C618.581 134.663 617.533 133.616 616.25 133.616Z"
            fill="#ADADAD"
          />
          <path
            d="M616.25 140.072C614.967 140.072 613.919 141.119 613.919 142.402C613.919 143.685 614.967 144.732 616.25 144.732C617.533 144.732 618.581 143.685 618.581 142.402C618.581 141.119 617.533 140.072 616.25 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M616.25 151.187C617.538 151.187 618.581 150.144 618.581 148.857C618.581 147.57 617.538 146.527 616.25 146.527C614.962 146.527 613.919 147.57 613.919 148.857C613.919 150.144 614.962 151.187 616.25 151.187Z"
            fill="#ADADAD"
          />
          <path
            d="M616.25 152.983C614.967 152.983 613.919 154.03 613.919 155.313C613.919 156.596 614.967 157.654 616.25 157.654C617.533 157.654 618.581 156.606 618.581 155.313C618.581 154.03 617.533 152.983 616.25 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M616.25 159.429C614.967 159.429 613.919 160.476 613.919 161.759C613.919 163.052 614.967 164.099 616.25 164.099C617.533 164.099 618.581 163.052 618.581 161.759C618.581 160.476 617.533 159.429 616.25 159.429Z"
            fill="#ADADAD"
          />
          <path
            d="M609.791 94.9008C608.507 94.9008 607.459 95.9483 607.459 97.2309C607.459 98.5135 608.507 99.561 609.791 99.561C611.074 99.561 612.122 98.5135 612.122 97.2309C612.122 95.9483 611.074 94.9008 609.791 94.9008Z"
            fill="#ADADAD"
          />
          <path
            d="M609.791 101.357C608.507 101.357 607.459 102.404 607.459 103.687C607.459 104.97 608.507 106.017 609.791 106.017C611.074 106.017 612.122 104.97 612.122 103.687C612.122 102.404 611.074 101.357 609.791 101.357Z"
            fill="#ADADAD"
          />
          <path
            d="M609.791 107.813C608.507 107.813 607.459 108.861 607.459 110.143C607.459 111.426 608.507 112.473 609.791 112.473C611.074 112.473 612.122 111.426 612.122 110.143C612.122 108.861 611.074 107.813 609.791 107.813Z"
            fill="#ADADAD"
          />
          <path
            d="M609.791 114.257C608.507 114.257 607.459 115.305 607.459 116.598C607.459 117.881 608.507 118.928 609.791 118.928C611.074 118.928 612.122 117.881 612.122 116.598C612.122 115.305 611.074 114.257 609.791 114.257Z"
            fill="#ADADAD"
          />
          <path
            d="M609.791 120.714C608.507 120.714 607.459 121.761 607.459 123.054C607.459 124.337 608.507 125.385 609.791 125.385C611.074 125.385 612.122 124.337 612.122 123.054C612.122 121.761 611.074 120.714 609.791 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M609.791 127.17C608.507 127.17 607.459 128.217 607.459 129.5C607.459 130.793 608.507 131.841 609.791 131.841C611.074 131.841 612.122 130.793 612.122 129.5C612.122 128.217 611.074 127.17 609.791 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M609.791 133.616C608.507 133.616 607.459 134.663 607.459 135.946C607.459 137.228 608.507 138.276 609.791 138.276C611.074 138.276 612.122 137.228 612.122 135.946C612.122 134.663 611.074 133.616 609.791 133.616Z"
            fill="#ADADAD"
          />
          <path
            d="M609.791 140.072C608.507 140.072 607.459 141.119 607.459 142.402C607.459 143.685 608.507 144.732 609.791 144.732C611.074 144.732 612.122 143.685 612.122 142.402C612.122 141.119 611.074 140.072 609.791 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M609.791 146.527C608.507 146.527 607.459 147.574 607.459 148.857C607.459 150.139 608.507 151.187 609.791 151.187C611.074 151.187 612.122 150.139 612.122 148.857C612.122 147.574 611.074 146.527 609.791 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M609.791 152.983C608.507 152.983 607.459 154.03 607.459 155.313C607.459 156.596 608.507 157.654 609.791 157.654C611.074 157.654 612.122 156.606 612.122 155.313C612.122 154.03 611.074 152.983 609.791 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M609.791 159.429C608.507 159.429 607.459 160.476 607.459 161.759C607.459 163.052 608.507 164.099 609.791 164.099C611.074 164.099 612.122 163.052 612.122 161.759C612.122 160.476 611.074 159.429 609.791 159.429Z"
            fill="#ADADAD"
          />
          <path
            d="M603.334 94.9008C602.051 94.9008 601.003 95.9483 601.003 97.2309C601.003 98.5135 602.051 99.561 603.334 99.561C604.617 99.561 605.666 98.5135 605.666 97.2309C605.676 95.9483 604.628 94.9008 603.334 94.9008Z"
            fill="#ADADAD"
          />
          <path
            d="M603.334 101.357C602.051 101.357 601.003 102.404 601.003 103.687C601.003 104.97 602.051 106.017 603.334 106.017C604.617 106.017 605.666 104.97 605.666 103.687C605.676 102.404 604.628 101.357 603.334 101.357Z"
            fill="#ADADAD"
          />
          <path
            d="M603.334 107.813C602.051 107.813 601.003 108.861 601.003 110.143C601.003 111.426 602.051 112.473 603.334 112.473C604.617 112.473 605.666 111.426 605.666 110.143C605.676 108.861 604.628 107.813 603.334 107.813Z"
            fill="#ADADAD"
          />
          <path
            d="M603.334 114.257C602.051 114.257 601.003 115.305 601.003 116.598C601.003 117.881 602.051 118.928 603.334 118.928C604.617 118.928 605.666 117.881 605.666 116.598C605.676 115.305 604.628 114.257 603.334 114.257Z"
            fill="#ADADAD"
          />
          <path
            d="M603.334 120.714C602.051 120.714 601.003 121.761 601.003 123.054C601.003 124.337 602.051 125.385 603.334 125.385C604.617 125.385 605.666 124.337 605.666 123.054C605.676 121.761 604.628 120.714 603.334 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M603.334 127.17C602.051 127.17 601.003 128.217 601.003 129.5C601.003 130.793 602.051 131.841 603.334 131.841C604.617 131.841 605.666 130.793 605.666 129.5C605.676 128.217 604.628 127.17 603.334 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M603.334 133.616C602.051 133.616 601.003 134.663 601.003 135.946C601.003 137.228 602.051 138.276 603.334 138.276C604.617 138.276 605.666 137.228 605.666 135.946C605.676 134.663 604.628 133.616 603.334 133.616Z"
            fill="#ADADAD"
          />
          <path
            d="M603.334 140.072C602.051 140.072 601.003 141.119 601.003 142.402C601.003 143.685 602.051 144.732 603.334 144.732C604.617 144.732 605.666 143.685 605.666 142.402C605.676 141.119 604.628 140.072 603.334 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M603.334 146.527C602.051 146.527 601.003 147.574 601.003 148.857C601.003 150.139 602.051 151.187 603.334 151.187C604.617 151.187 605.666 150.139 605.666 148.857C605.666 147.574 604.628 146.527 603.334 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M603.334 152.983C602.051 152.983 601.003 154.03 601.003 155.313C601.003 156.596 602.051 157.654 603.334 157.654C604.617 157.654 605.666 156.606 605.666 155.313C605.676 154.03 604.628 152.983 603.334 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M603.334 159.429C602.051 159.429 601.003 160.476 601.003 161.759C601.003 163.052 602.051 164.099 603.334 164.099C604.617 164.099 605.666 163.052 605.666 161.759C605.676 160.476 604.628 159.429 603.334 159.429Z"
            fill="#ADADAD"
          />
          <path
            d="M596.875 82.0003C595.591 82.0003 594.543 83.0478 594.543 84.3411C594.543 85.6237 595.591 86.6712 596.875 86.6712C598.158 86.6712 599.206 85.6237 599.206 84.3411C599.217 83.0478 598.169 82.0003 596.875 82.0003Z"
            fill="#ADADAD"
          />
          <path
            d="M596.875 88.4446C595.591 88.4446 594.543 89.4921 594.543 90.7747C594.543 92.0574 595.591 93.1048 596.875 93.1048C598.158 93.1048 599.206 92.0574 599.206 90.7747C599.217 89.4921 598.169 88.4446 596.875 88.4446Z"
            fill="#ADADAD"
          />
          <path
            d="M596.875 94.9008C595.591 94.9008 594.543 95.9483 594.543 97.2309C594.543 98.5135 595.591 99.561 596.875 99.561C598.158 99.561 599.206 98.5135 599.206 97.2309C599.217 95.9483 598.169 94.9008 596.875 94.9008Z"
            fill="#ADADAD"
          />
          <path
            d="M596.875 101.357C595.591 101.357 594.543 102.404 594.543 103.687C594.543 104.97 595.591 106.017 596.875 106.017C598.158 106.017 599.206 104.97 599.206 103.687C599.217 102.404 598.169 101.357 596.875 101.357Z"
            fill="#ADADAD"
          />
          <path
            d="M596.875 107.813C595.591 107.813 594.543 108.861 594.543 110.143C594.543 111.426 595.591 112.473 596.875 112.473C598.158 112.473 599.206 111.426 599.206 110.143C599.217 108.861 598.169 107.813 596.875 107.813Z"
            fill="#ADADAD"
          />
          <path
            d="M596.875 114.257C595.591 114.257 594.543 115.305 594.543 116.598C594.543 117.881 595.591 118.928 596.875 118.928C598.158 118.928 599.206 117.881 599.206 116.598C599.217 115.305 598.169 114.257 596.875 114.257Z"
            fill="#ADADAD"
          />
          <path
            d="M596.875 120.714C595.591 120.714 594.543 121.761 594.543 123.054C594.543 124.337 595.591 125.385 596.875 125.385C598.158 125.385 599.206 124.337 599.206 123.054C599.217 121.761 598.169 120.714 596.875 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M596.875 127.17C595.591 127.17 594.543 128.217 594.543 129.5C594.543 130.793 595.591 131.841 596.875 131.841C598.158 131.841 599.206 130.793 599.206 129.5C599.217 128.217 598.169 127.17 596.875 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M596.875 133.616C595.591 133.616 594.543 134.663 594.543 135.946C594.543 137.228 595.591 138.276 596.875 138.276C598.158 138.276 599.206 137.228 599.206 135.946C599.217 134.663 598.169 133.616 596.875 133.616Z"
            fill="#ADADAD"
          />
          <path
            d="M596.875 140.072C595.591 140.072 594.543 141.119 594.543 142.402C594.543 143.685 595.591 144.732 596.875 144.732C598.158 144.732 599.206 143.685 599.206 142.402C599.217 141.119 598.169 140.072 596.875 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M596.875 146.527C595.591 146.527 594.543 147.574 594.543 148.857C594.543 150.139 595.591 151.187 596.875 151.187C598.158 151.187 599.206 150.139 599.206 148.857C599.206 147.574 598.169 146.527 596.875 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M596.875 152.983C595.591 152.983 594.543 154.03 594.543 155.313C594.543 156.596 595.591 157.654 596.875 157.654C598.158 157.654 599.206 156.606 599.206 155.313C599.217 154.03 598.169 152.983 596.875 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M596.875 159.429C595.591 159.429 594.543 160.476 594.543 161.759C594.543 163.052 595.591 164.099 596.875 164.099C598.158 164.099 599.206 163.052 599.206 161.759C599.217 160.476 598.169 159.429 596.875 159.429Z"
            fill="#ADADAD"
          />
          <path
            d="M590.424 81.9998C589.141 81.9998 588.093 83.0473 588.093 84.3406C588.093 85.6232 589.141 86.6707 590.424 86.6707C591.708 86.6707 592.756 85.6232 592.756 84.3406C592.756 83.0473 591.708 81.9998 590.424 81.9998Z"
            fill="#ADADAD"
          />
          <path
            d="M590.424 88.4448C589.141 88.4448 588.093 89.4923 588.093 90.775C588.093 92.0576 589.141 93.1051 590.424 93.1051C591.708 93.1051 592.756 92.0576 592.756 90.775C592.756 89.4923 591.708 88.4448 590.424 88.4448Z"
            fill="#ADADAD"
          />
          <path
            d="M590.424 94.9011C589.141 94.9011 588.093 95.9486 588.093 97.2312C588.093 98.5138 589.141 99.5613 590.424 99.5613C591.708 99.5613 592.756 98.5138 592.756 97.2312C592.756 95.9486 591.708 94.9011 590.424 94.9011Z"
            fill="#ADADAD"
          />
          <path
            d="M590.424 101.357C589.141 101.357 588.093 102.404 588.093 103.687C588.093 104.969 589.141 106.017 590.424 106.017C591.708 106.017 592.756 104.969 592.756 103.687C592.756 102.404 591.708 101.357 590.424 101.357Z"
            fill="#ADADAD"
          />
          <path
            d="M590.424 107.813C589.141 107.813 588.093 108.86 588.093 110.143C588.093 111.426 589.141 112.473 590.424 112.473C591.708 112.473 592.756 111.426 592.756 110.143C592.756 108.86 591.708 107.813 590.424 107.813Z"
            fill="#ADADAD"
          />
          <path
            d="M590.424 114.258C589.141 114.258 588.093 115.306 588.093 116.599C588.093 117.881 589.141 118.929 590.424 118.929C591.708 118.929 592.756 117.881 592.756 116.599C592.756 115.306 591.708 114.258 590.424 114.258Z"
            fill="#ADADAD"
          />
          <path
            d="M590.424 120.714C589.141 120.714 588.093 121.762 588.093 123.055C588.093 124.338 589.141 125.385 590.424 125.385C591.708 125.385 592.756 124.338 592.756 123.055C592.756 121.762 591.708 120.714 590.424 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M590.424 127.17C589.141 127.17 588.093 128.217 588.093 129.5C588.093 130.793 589.141 131.841 590.424 131.841C591.708 131.841 592.756 130.793 592.756 129.5C592.756 128.217 591.708 127.17 590.424 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M590.424 133.615C589.141 133.615 588.093 134.662 588.093 135.945C588.093 137.228 589.141 138.275 590.424 138.275C591.708 138.275 592.756 137.228 592.756 135.945C592.756 134.662 591.708 133.615 590.424 133.615Z"
            fill="#ADADAD"
          />
          <path
            d="M590.424 140.071C589.141 140.071 588.093 141.119 588.093 142.401C588.093 143.684 589.141 144.731 590.424 144.731C591.708 144.731 592.756 143.684 592.756 142.401C592.756 141.119 591.708 140.071 590.424 140.071Z"
            fill="#ADADAD"
          />
          <path
            d="M590.424 146.527C589.141 146.527 588.093 147.574 588.093 148.857C588.093 150.14 589.141 151.187 590.424 151.187C591.708 151.187 592.756 150.14 592.756 148.857C592.756 147.574 591.708 146.527 590.424 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M590.424 152.983C589.141 152.983 588.093 154.031 588.093 155.313C588.093 156.596 589.141 157.654 590.424 157.654C591.708 157.654 592.756 156.607 592.756 155.313C592.756 154.031 591.708 152.983 590.424 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M590.424 159.428C589.141 159.428 588.093 160.476 588.093 161.758C588.093 163.052 589.141 164.099 590.424 164.099C591.708 164.099 592.756 163.052 592.756 161.758C592.756 160.476 591.708 159.428 590.424 159.428Z"
            fill="#ADADAD"
          />
          <path
            d="M583.965 75.5436C582.681 75.5436 581.633 76.591 581.633 77.8844C581.633 79.167 582.681 80.2145 583.965 80.2145C585.248 80.2145 586.296 79.167 586.296 77.8844C586.307 76.591 585.259 75.5436 583.965 75.5436Z"
            fill="#ADADAD"
          />
          <path
            d="M583.965 81.9998C582.681 81.9998 581.633 83.0473 581.633 84.3406C581.633 85.6232 582.681 86.6707 583.965 86.6707C585.248 86.6707 586.296 85.6232 586.296 84.3406C586.307 83.0473 585.259 81.9998 583.965 81.9998Z"
            fill="#ADADAD"
          />
          <path
            d="M583.965 94.9011C582.681 94.9011 581.633 95.9486 581.633 97.2312C581.633 98.5138 582.681 99.5613 583.965 99.5613C585.248 99.5613 586.296 98.5138 586.296 97.2312C586.307 95.9486 585.259 94.9011 583.965 94.9011Z"
            fill="#ADADAD"
          />
          <path
            d="M583.965 101.357C582.681 101.357 581.633 102.404 581.633 103.687C581.633 104.969 582.681 106.017 583.965 106.017C585.248 106.017 586.296 104.969 586.296 103.687C586.307 102.404 585.259 101.357 583.965 101.357Z"
            fill="#ADADAD"
          />
          <path
            d="M583.965 107.813C582.681 107.813 581.633 108.86 581.633 110.143C581.633 111.426 582.681 112.473 583.965 112.473C585.248 112.473 586.296 111.426 586.296 110.143C586.307 108.86 585.259 107.813 583.965 107.813Z"
            fill="#ADADAD"
          />
          <path
            d="M583.965 114.258C582.681 114.258 581.633 115.306 581.633 116.599C581.633 117.881 582.681 118.929 583.965 118.929C585.248 118.929 586.296 117.881 586.296 116.599C586.307 115.306 585.259 114.258 583.965 114.258Z"
            fill="#ADADAD"
          />
          <path
            d="M583.965 120.714C582.681 120.714 581.633 121.762 581.633 123.055C581.633 124.338 582.681 125.385 583.965 125.385C585.248 125.385 586.296 124.338 586.296 123.055C586.307 121.762 585.259 120.714 583.965 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M583.965 127.17C582.681 127.17 581.633 128.217 581.633 129.5C581.633 130.793 582.681 131.841 583.965 131.841C585.248 131.841 586.296 130.793 586.296 129.5C586.307 128.217 585.259 127.17 583.965 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M583.965 133.615C582.681 133.615 581.633 134.662 581.633 135.945C581.633 137.228 582.681 138.275 583.965 138.275C585.248 138.275 586.296 137.228 586.296 135.945C586.307 134.662 585.259 133.615 583.965 133.615Z"
            fill="#ADADAD"
          />
          <path
            d="M583.965 140.071C582.681 140.071 581.633 141.119 581.633 142.401C581.633 143.684 582.681 144.731 583.965 144.731C585.248 144.731 586.296 143.684 586.296 142.401C586.307 141.119 585.259 140.071 583.965 140.071Z"
            fill="#ADADAD"
          />
          <path
            d="M583.965 146.527C582.681 146.527 581.633 147.574 581.633 148.857C581.633 150.14 582.681 151.187 583.965 151.187C585.248 151.187 586.296 150.14 586.296 148.857C586.296 147.574 585.259 146.527 583.965 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M583.965 152.983C582.681 152.983 581.633 154.031 581.633 155.313C581.633 156.596 582.681 157.654 583.965 157.654C585.248 157.654 586.296 156.607 586.296 155.313C586.307 154.031 585.259 152.983 583.965 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M583.965 159.428C582.681 159.428 581.633 160.476 581.633 161.758C581.633 163.052 582.681 164.099 583.965 164.099C585.248 164.099 586.296 163.052 586.296 161.758C586.307 160.476 585.259 159.428 583.965 159.428Z"
            fill="#ADADAD"
          />
          <path
            d="M577.514 69.0879C576.231 69.0879 575.183 70.1354 575.183 71.418C575.183 72.7113 576.231 73.7588 577.514 73.7588C578.798 73.7588 579.846 72.7113 579.846 71.418C579.835 70.1354 578.798 69.0879 577.514 69.0879Z"
            fill="#ADADAD"
          />
          <path
            d="M577.514 75.5441C576.231 75.5441 575.183 76.5916 575.183 77.8849C575.183 79.1675 576.231 80.215 577.514 80.215C578.798 80.215 579.846 79.1675 579.846 77.8849C579.835 76.5916 578.798 75.5441 577.514 75.5441Z"
            fill="#ADADAD"
          />
          <path
            d="M577.514 82.0003C576.231 82.0003 575.183 83.0478 575.183 84.3411C575.183 85.6237 576.231 86.6712 577.514 86.6712C578.798 86.6712 579.846 85.6237 579.846 84.3411C579.835 83.0478 578.798 82.0003 577.514 82.0003Z"
            fill="#ADADAD"
          />
          <path
            d="M577.514 94.9008C576.231 94.9008 575.183 95.9483 575.183 97.2309C575.183 98.5135 576.231 99.561 577.514 99.561C578.798 99.561 579.846 98.5135 579.846 97.2309C579.835 95.9483 578.798 94.9008 577.514 94.9008Z"
            fill="#ADADAD"
          />
          <path
            d="M577.514 101.357C576.231 101.357 575.183 102.404 575.183 103.687C575.183 104.97 576.231 106.017 577.514 106.017C578.798 106.017 579.846 104.97 579.846 103.687C579.835 102.404 578.798 101.357 577.514 101.357Z"
            fill="#ADADAD"
          />
          <path
            d="M577.514 107.813C576.231 107.813 575.183 108.861 575.183 110.143C575.183 111.426 576.231 112.473 577.514 112.473C578.798 112.473 579.846 111.426 579.846 110.143C579.835 108.861 578.798 107.813 577.514 107.813Z"
            fill="#ADADAD"
          />
          <path
            d="M577.514 114.257C576.231 114.257 575.183 115.305 575.183 116.598C575.183 117.881 576.231 118.928 577.514 118.928C578.798 118.928 579.846 117.881 579.846 116.598C579.835 115.305 578.798 114.257 577.514 114.257Z"
            fill="#ADADAD"
          />
          <path
            d="M577.514 120.714C576.231 120.714 575.183 121.761 575.183 123.054C575.183 124.337 576.231 125.385 577.514 125.385C578.798 125.385 579.846 124.337 579.846 123.054C579.835 121.761 578.798 120.714 577.514 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M577.514 127.17C576.231 127.17 575.183 128.217 575.183 129.5C575.183 130.793 576.231 131.841 577.514 131.841C578.798 131.841 579.846 130.793 579.846 129.5C579.835 128.217 578.798 127.17 577.514 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M577.514 133.616C576.231 133.616 575.183 134.663 575.183 135.946C575.183 137.228 576.231 138.276 577.514 138.276C578.798 138.276 579.846 137.228 579.846 135.946C579.835 134.663 578.798 133.616 577.514 133.616Z"
            fill="#ADADAD"
          />
          <path
            d="M577.514 140.072C576.231 140.072 575.183 141.119 575.183 142.402C575.183 143.685 576.231 144.732 577.514 144.732C578.798 144.732 579.846 143.685 579.846 142.402C579.835 141.119 578.798 140.072 577.514 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M577.514 146.527C576.231 146.527 575.183 147.574 575.183 148.857C575.183 150.139 576.231 151.187 577.514 151.187C578.798 151.187 579.846 150.139 579.846 148.857C579.846 147.574 578.798 146.527 577.514 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M577.514 152.983C576.231 152.983 575.183 154.03 575.183 155.313C575.183 156.596 576.231 157.654 577.514 157.654C578.798 157.654 579.846 156.606 579.846 155.313C579.835 154.03 578.798 152.983 577.514 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M577.514 159.429C576.231 159.429 575.183 160.476 575.183 161.759C575.183 163.052 576.231 164.099 577.514 164.099C578.798 164.099 579.846 163.052 579.846 161.759C579.835 160.476 578.798 159.429 577.514 159.429Z"
            fill="#ADADAD"
          />
          <path
            d="M571.057 75.5441C569.763 75.5441 568.715 76.5916 568.715 77.8849C568.715 79.1675 569.763 80.215 571.057 80.215C572.34 80.215 573.388 79.1675 573.388 77.8849C573.388 76.5916 572.34 75.5441 571.057 75.5441Z"
            fill="#ADADAD"
          />
          <path
            d="M571.057 94.9008C569.763 94.9008 568.715 95.9483 568.715 97.2309C568.715 98.5135 569.763 99.561 571.057 99.561C572.34 99.561 573.388 98.5135 573.388 97.2309C573.388 95.9483 572.34 94.9008 571.057 94.9008Z"
            fill="#ADADAD"
          />
          <path
            d="M571.057 101.357C569.763 101.357 568.715 102.404 568.715 103.687C568.715 104.97 569.763 106.017 571.057 106.017C572.34 106.017 573.388 104.97 573.388 103.687C573.388 102.404 572.34 101.357 571.057 101.357Z"
            fill="#ADADAD"
          />
          <path
            d="M571.057 107.813C569.763 107.813 568.715 108.861 568.715 110.143C568.715 111.426 569.763 112.473 571.057 112.473C572.34 112.473 573.388 111.426 573.388 110.143C573.388 108.861 572.34 107.813 571.057 107.813Z"
            fill="#ADADAD"
          />
          <path
            d="M571.057 114.257C569.763 114.257 568.715 115.305 568.715 116.598C568.715 117.881 569.763 118.928 571.057 118.928C572.34 118.928 573.388 117.881 573.388 116.598C573.388 115.305 572.34 114.257 571.057 114.257Z"
            fill="#ADADAD"
          />
          <path
            d="M571.057 120.714C569.763 120.714 568.715 121.761 568.715 123.054C568.715 124.337 569.763 125.385 571.057 125.385C572.34 125.385 573.388 124.337 573.388 123.054C573.388 121.761 572.34 120.714 571.057 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M571.057 127.17C569.763 127.17 568.715 128.217 568.715 129.5C568.715 130.793 569.763 131.841 571.057 131.841C572.34 131.841 573.388 130.793 573.388 129.5C573.388 128.217 572.34 127.17 571.057 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M571.057 133.616C569.763 133.616 568.715 134.663 568.715 135.946C568.715 137.228 569.763 138.276 571.057 138.276C572.34 138.276 573.388 137.228 573.388 135.946C573.388 134.663 572.34 133.616 571.057 133.616Z"
            fill="#ADADAD"
          />
          <path
            d="M571.057 140.072C569.763 140.072 568.715 141.119 568.715 142.402C568.715 143.685 569.763 144.732 571.057 144.732C572.34 144.732 573.388 143.685 573.388 142.402C573.388 141.119 572.34 140.072 571.057 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M571.057 146.527C569.763 146.527 568.715 147.574 568.715 148.857C568.715 150.139 569.763 151.187 571.057 151.187C572.34 151.187 573.388 150.139 573.388 148.857C573.388 147.574 572.34 146.527 571.057 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M571.057 152.983C569.763 152.983 568.715 154.03 568.715 155.313C568.715 156.596 569.763 157.654 571.057 157.654C572.34 157.654 573.388 156.606 573.388 155.313C573.388 154.03 572.34 152.983 571.057 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M571.057 159.429C569.763 159.429 568.715 160.476 568.715 161.759C568.715 163.052 569.763 164.099 571.057 164.099C572.34 164.099 573.388 163.052 573.388 161.759C573.388 160.476 572.34 159.429 571.057 159.429Z"
            fill="#ADADAD"
          />
          <path
            d="M564.596 101.357C563.312 101.357 562.264 102.404 562.264 103.687C562.264 104.97 563.312 106.017 564.596 106.017C565.879 106.017 566.927 104.97 566.927 103.687C566.927 102.404 565.879 101.357 564.596 101.357Z"
            fill="#ADADAD"
          />
          <path
            d="M564.596 107.813C563.312 107.813 562.264 108.861 562.264 110.143C562.264 111.426 563.312 112.473 564.596 112.473C565.879 112.473 566.927 111.426 566.927 110.143C566.927 108.861 565.879 107.813 564.596 107.813Z"
            fill="#ADADAD"
          />
          <path
            d="M564.596 114.257C563.312 114.257 562.264 115.305 562.264 116.598C562.264 117.881 563.312 118.928 564.596 118.928C565.879 118.928 566.927 117.881 566.927 116.598C566.927 115.305 565.879 114.257 564.596 114.257Z"
            fill="#ADADAD"
          />
          <path
            d="M564.596 120.714C563.312 120.714 562.264 121.761 562.264 123.054C562.264 124.337 563.312 125.385 564.596 125.385C565.879 125.385 566.927 124.337 566.927 123.054C566.927 121.761 565.879 120.714 564.596 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M564.596 127.17C563.312 127.17 562.264 128.217 562.264 129.5C562.264 130.793 563.312 131.841 564.596 131.841C565.879 131.841 566.927 130.793 566.927 129.5C566.927 128.217 565.879 127.17 564.596 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M564.596 133.616C563.312 133.616 562.264 134.663 562.264 135.946C562.264 137.228 563.312 138.276 564.596 138.276C565.879 138.276 566.927 137.228 566.927 135.946C566.927 134.663 565.879 133.616 564.596 133.616Z"
            fill="#ADADAD"
          />
          <path
            d="M564.596 140.072C563.312 140.072 562.264 141.119 562.264 142.402C562.264 143.685 563.312 144.732 564.596 144.732C565.879 144.732 566.927 143.685 566.927 142.402C566.927 141.119 565.879 140.072 564.596 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M564.596 146.527C563.312 146.527 562.264 147.574 562.264 148.857C562.264 150.139 563.312 151.187 564.596 151.187C565.879 151.187 566.927 150.139 566.927 148.857C566.927 147.574 565.879 146.527 564.596 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M564.596 152.983C563.312 152.983 562.264 154.03 562.264 155.313C562.264 156.596 563.312 157.654 564.596 157.654C565.879 157.654 566.927 156.606 566.927 155.313C566.927 154.03 565.879 152.983 564.596 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M564.596 159.429C563.312 159.429 562.264 160.476 562.264 161.759C562.264 163.052 563.312 164.099 564.596 164.099C565.879 164.099 566.927 163.052 566.927 161.759C566.927 160.476 565.879 159.429 564.596 159.429Z"
            fill="#ADADAD"
          />
          <path
            d="M558.136 101.357C556.853 101.357 555.805 102.404 555.805 103.687C555.805 104.969 556.853 106.017 558.136 106.017C559.42 106.017 560.468 104.969 560.468 103.687C560.479 102.404 559.43 101.357 558.136 101.357Z"
            fill="#ADADAD"
          />
          <path
            d="M558.136 107.813C556.853 107.813 555.805 108.86 555.805 110.143C555.805 111.426 556.853 112.473 558.136 112.473C559.42 112.473 560.468 111.426 560.468 110.143C560.479 108.86 559.43 107.813 558.136 107.813Z"
            fill="#ADADAD"
          />
          <path
            d="M558.136 114.258C556.853 114.258 555.805 115.306 555.805 116.599C555.805 117.881 556.853 118.929 558.136 118.929C559.42 118.929 560.468 117.881 560.468 116.599C560.479 115.306 559.43 114.258 558.136 114.258Z"
            fill="#ADADAD"
          />
          <path
            d="M558.136 120.714C556.853 120.714 555.805 121.762 555.805 123.055C555.805 124.338 556.853 125.385 558.136 125.385C559.42 125.385 560.468 124.338 560.468 123.055C560.479 121.762 559.43 120.714 558.136 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M558.136 127.17C556.853 127.17 555.805 128.217 555.805 129.5C555.805 130.793 556.853 131.841 558.136 131.841C559.42 131.841 560.468 130.793 560.468 129.5C560.479 128.217 559.43 127.17 558.136 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M558.136 133.615C556.853 133.615 555.805 134.662 555.805 135.945C555.805 137.228 556.853 138.275 558.136 138.275C559.42 138.275 560.468 137.228 560.468 135.945C560.479 134.662 559.43 133.615 558.136 133.615Z"
            fill="#ADADAD"
          />
          <path
            d="M558.136 140.071C556.853 140.071 555.805 141.119 555.805 142.401C555.805 143.684 556.853 144.731 558.136 144.731C559.42 144.731 560.468 143.684 560.468 142.401C560.479 141.119 559.43 140.071 558.136 140.071Z"
            fill="#ADADAD"
          />
          <path
            d="M558.136 146.527C556.853 146.527 555.805 147.574 555.805 148.857C555.805 150.14 556.853 151.187 558.136 151.187C559.42 151.187 560.468 150.14 560.468 148.857C560.468 147.574 559.431 146.527 558.136 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M558.136 152.983C556.853 152.983 555.805 154.031 555.805 155.313C555.805 156.596 556.853 157.654 558.136 157.654C559.42 157.654 560.468 156.607 560.468 155.313C560.479 154.031 559.43 152.983 558.136 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M558.136 159.428C556.853 159.428 555.805 160.476 555.805 161.758C555.805 163.052 556.853 164.099 558.136 164.099C559.42 164.099 560.468 163.052 560.468 161.758C560.479 160.476 559.43 159.428 558.136 159.428Z"
            fill="#ADADAD"
          />
          <path
            d="M551.677 107.813C550.394 107.813 549.346 108.86 549.346 110.143C549.346 111.426 550.394 112.473 551.677 112.473C552.96 112.473 554.009 111.426 554.009 110.143C554.019 108.86 552.971 107.813 551.677 107.813Z"
            fill="#ADADAD"
          />
          <path
            d="M551.677 114.258C550.394 114.258 549.346 115.306 549.346 116.599C549.346 117.881 550.394 118.929 551.677 118.929C552.96 118.929 554.009 117.881 554.009 116.599C554.019 115.306 552.971 114.258 551.677 114.258Z"
            fill="#ADADAD"
          />
          <path
            d="M551.677 120.714C550.394 120.714 549.346 121.762 549.346 123.055C549.346 124.338 550.394 125.385 551.677 125.385C552.96 125.385 554.009 124.338 554.009 123.055C554.019 121.762 552.971 120.714 551.677 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M551.677 127.17C550.394 127.17 549.346 128.217 549.346 129.5C549.346 130.793 550.394 131.841 551.677 131.841C552.96 131.841 554.009 130.793 554.009 129.5C554.019 128.217 552.971 127.17 551.677 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M551.677 133.615C550.394 133.615 549.346 134.662 549.346 135.945C549.346 137.228 550.394 138.275 551.677 138.275C552.96 138.275 554.009 137.228 554.009 135.945C554.019 134.662 552.971 133.615 551.677 133.615Z"
            fill="#ADADAD"
          />
          <path
            d="M551.677 140.071C550.394 140.071 549.346 141.119 549.346 142.401C549.346 143.684 550.394 144.731 551.677 144.731C552.96 144.731 554.009 143.684 554.009 142.401C554.019 141.119 552.971 140.071 551.677 140.071Z"
            fill="#ADADAD"
          />
          <path
            d="M551.677 146.527C550.394 146.527 549.346 147.574 549.346 148.857C549.346 150.14 550.394 151.187 551.677 151.187C552.96 151.187 554.009 150.14 554.009 148.857C554.009 147.574 552.971 146.527 551.677 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M551.677 152.983C550.394 152.983 549.346 154.031 549.346 155.313C549.346 156.596 550.394 157.654 551.677 157.654C552.96 157.654 554.009 156.607 554.009 155.313C554.019 154.031 552.971 152.983 551.677 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M551.677 159.428C550.394 159.428 549.346 160.476 549.346 161.758C549.346 163.052 550.394 164.099 551.677 164.099C552.96 164.099 554.009 163.052 554.009 161.758C554.019 160.476 552.971 159.428 551.677 159.428Z"
            fill="#ADADAD"
          />
          <path
            d="M545.229 107.813C543.946 107.813 542.898 108.861 542.898 110.143C542.898 111.426 543.946 112.473 545.229 112.473C546.513 112.473 547.561 111.426 547.561 110.143C547.561 108.861 546.513 107.813 545.229 107.813Z"
            fill="#ADADAD"
          />
          <path
            d="M545.229 114.257C543.946 114.257 542.898 115.305 542.898 116.598C542.898 117.881 543.946 118.928 545.229 118.928C546.513 118.928 547.561 117.881 547.561 116.598C547.561 115.305 546.513 114.257 545.229 114.257Z"
            fill="#ADADAD"
          />
          <path
            d="M545.229 120.714C543.946 120.714 542.898 121.761 542.898 123.054C542.898 124.337 543.946 125.385 545.229 125.385C546.513 125.385 547.561 124.337 547.561 123.054C547.561 121.761 546.513 120.714 545.229 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M545.229 127.17C543.946 127.17 542.898 128.217 542.898 129.5C542.898 130.793 543.946 131.841 545.229 131.841C546.513 131.841 547.561 130.793 547.561 129.5C547.561 128.217 546.513 127.17 545.229 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M545.229 133.616C543.946 133.616 542.898 134.663 542.898 135.946C542.898 137.228 543.946 138.276 545.229 138.276C546.513 138.276 547.561 137.228 547.561 135.946C547.561 134.663 546.513 133.616 545.229 133.616Z"
            fill="#ADADAD"
          />
          <path
            d="M545.229 140.072C543.946 140.072 542.898 141.119 542.898 142.402C542.898 143.685 543.946 144.732 545.229 144.732C546.513 144.732 547.561 143.685 547.561 142.402C547.561 141.119 546.513 140.072 545.229 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M545.229 146.527C543.946 146.527 542.898 147.574 542.898 148.857C542.898 150.139 543.946 151.187 545.229 151.187C546.513 151.187 547.561 150.139 547.561 148.857C547.561 147.574 546.513 146.527 545.229 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M545.229 152.983C543.946 152.983 542.898 154.03 542.898 155.313C542.898 156.596 543.946 157.654 545.229 157.654C546.513 157.654 547.561 156.606 547.561 155.313C547.561 154.03 546.513 152.983 545.229 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M545.229 159.429C543.946 159.429 542.898 160.476 542.898 161.759C542.898 163.052 543.946 164.099 545.229 164.099C546.513 164.099 547.561 163.052 547.561 161.759C547.561 160.476 546.513 159.429 545.229 159.429Z"
            fill="#ADADAD"
          />
          <path
            d="M538.77 107.813C537.486 107.813 536.438 108.861 536.438 110.143C536.438 111.426 537.486 112.473 538.77 112.473C540.053 112.473 541.112 111.426 541.112 110.143C541.112 108.861 540.064 107.813 538.77 107.813Z"
            fill="#ADADAD"
          />
          <path
            d="M538.77 114.257C537.486 114.257 536.438 115.305 536.438 116.598C536.438 117.881 537.486 118.928 538.77 118.928C540.053 118.928 541.112 117.881 541.112 116.598C541.112 115.305 540.064 114.257 538.77 114.257Z"
            fill="#ADADAD"
          />
          <path
            d="M538.77 120.714C537.486 120.714 536.438 121.761 536.438 123.054C536.438 124.337 537.486 125.385 538.77 125.385C540.053 125.385 541.112 124.337 541.112 123.054C541.112 121.761 540.064 120.714 538.77 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M538.77 127.17C537.486 127.17 536.438 128.217 536.438 129.5C536.438 130.793 537.486 131.841 538.77 131.841C540.053 131.841 541.112 130.793 541.112 129.5C541.112 128.217 540.064 127.17 538.77 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M538.77 133.616C537.486 133.616 536.438 134.663 536.438 135.946C536.438 137.228 537.486 138.276 538.77 138.276C540.053 138.276 541.112 137.228 541.112 135.946C541.112 134.663 540.064 133.616 538.77 133.616Z"
            fill="#ADADAD"
          />
          <path
            d="M538.77 140.072C537.486 140.072 536.438 141.119 536.438 142.402C536.438 143.685 537.486 144.732 538.77 144.732C540.053 144.732 541.112 143.685 541.112 142.402C541.112 141.119 540.064 140.072 538.77 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M538.77 146.527C537.486 146.527 536.438 147.574 536.438 148.857C536.438 150.139 537.486 151.187 538.77 151.187C540.053 151.187 541.112 150.139 541.112 148.857C541.112 147.574 540.064 146.527 538.77 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M538.77 152.983C537.486 152.983 536.438 154.03 536.438 155.313C536.438 156.596 537.486 157.654 538.77 157.654C540.053 157.654 541.112 156.606 541.112 155.313C541.112 154.03 540.064 152.983 538.77 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M538.77 159.429C537.486 159.429 536.438 160.476 536.438 161.759C536.438 163.052 537.486 164.099 538.77 164.099C540.053 164.099 541.112 163.052 541.112 161.759C541.112 160.476 540.064 159.429 538.77 159.429Z"
            fill="#ADADAD"
          />
          <path
            d="M532.319 107.813C531.036 107.813 529.988 108.861 529.988 110.143C529.988 111.426 531.036 112.473 532.319 112.473C533.603 112.473 534.651 111.426 534.651 110.143C534.651 108.861 533.603 107.813 532.319 107.813Z"
            fill="#ADADAD"
          />
          <path
            d="M532.319 114.257C531.036 114.257 529.988 115.305 529.988 116.598C529.988 117.881 531.036 118.928 532.319 118.928C533.603 118.928 534.651 117.881 534.651 116.598C534.651 115.305 533.603 114.257 532.319 114.257Z"
            fill="#ADADAD"
          />
          <path
            d="M532.319 120.714C531.036 120.714 529.988 121.761 529.988 123.054C529.988 124.337 531.036 125.385 532.319 125.385C533.603 125.385 534.651 124.337 534.651 123.054C534.651 121.761 533.603 120.714 532.319 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M532.319 127.17C531.036 127.17 529.988 128.217 529.988 129.5C529.988 130.793 531.036 131.841 532.319 131.841C533.603 131.841 534.651 130.793 534.651 129.5C534.651 128.217 533.603 127.17 532.319 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M532.319 133.616C531.036 133.616 529.988 134.663 529.988 135.946C529.988 137.228 531.036 138.276 532.319 138.276C533.603 138.276 534.651 137.228 534.651 135.946C534.651 134.663 533.603 133.616 532.319 133.616Z"
            fill="#ADADAD"
          />
          <path
            d="M532.319 140.072C531.036 140.072 529.988 141.119 529.988 142.402C529.988 143.685 531.036 144.732 532.319 144.732C533.603 144.732 534.651 143.685 534.651 142.402C534.651 141.119 533.603 140.072 532.319 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M532.319 146.527C531.036 146.527 529.988 147.574 529.988 148.857C529.988 150.139 531.036 151.187 532.319 151.187C533.603 151.187 534.651 150.139 534.651 148.857C534.651 147.574 533.603 146.527 532.319 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M532.319 152.983C531.036 152.983 529.988 154.03 529.988 155.313C529.988 156.596 531.036 157.654 532.319 157.654C533.603 157.654 534.651 156.606 534.651 155.313C534.651 154.03 533.603 152.983 532.319 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M532.319 159.429C531.036 159.429 529.988 160.476 529.988 161.759C529.988 163.052 531.036 164.099 532.319 164.099C533.603 164.099 534.651 163.052 534.651 161.759C534.651 160.476 533.603 159.429 532.319 159.429Z"
            fill="#ADADAD"
          />
          <path
            d="M532.319 165.885C531.036 165.885 529.988 166.932 529.988 168.215C529.988 169.497 531.036 170.545 532.319 170.545C533.603 170.545 534.651 169.497 534.651 168.215C534.651 166.932 533.603 165.885 532.319 165.885Z"
            fill="#ADADAD"
          />
          <path
            d="M532.319 172.339C531.036 172.339 529.988 173.387 529.988 174.68C529.988 175.963 531.036 177.01 532.319 177.01C533.603 177.01 534.651 175.963 534.651 174.68C534.651 173.387 533.603 172.339 532.319 172.339Z"
            fill="#ADADAD"
          />
          <path
            d="M532.319 178.785C531.036 178.785 529.988 179.833 529.988 181.115C529.988 182.398 531.036 183.445 532.319 183.445C533.603 183.445 534.651 182.398 534.651 181.115C534.651 179.833 533.603 178.785 532.319 178.785Z"
            fill="#ADADAD"
          />
          <path
            d="M525.851 107.813C524.568 107.813 523.52 108.861 523.52 110.143C523.52 111.426 524.568 112.473 525.851 112.473C527.145 112.473 528.193 111.426 528.193 110.143C528.193 108.861 527.145 107.813 525.851 107.813Z"
            fill="#ADADAD"
          />
          <path
            d="M525.851 114.257C524.568 114.257 523.52 115.305 523.52 116.598C523.52 117.881 524.568 118.928 525.851 118.928C527.145 118.928 528.193 117.881 528.193 116.598C528.193 115.305 527.145 114.257 525.851 114.257Z"
            fill="#ADADAD"
          />
          <path
            d="M525.851 120.714C524.568 120.714 523.52 121.761 523.52 123.054C523.52 124.337 524.568 125.385 525.851 125.385C527.145 125.385 528.193 124.337 528.193 123.054C528.193 121.761 527.145 120.714 525.851 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M525.851 127.17C524.568 127.17 523.52 128.217 523.52 129.5C523.52 130.793 524.568 131.841 525.851 131.841C527.145 131.841 528.193 130.793 528.193 129.5C528.193 128.217 527.145 127.17 525.851 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M525.851 133.616C524.568 133.616 523.52 134.663 523.52 135.946C523.52 137.228 524.568 138.276 525.851 138.276C527.145 138.276 528.193 137.228 528.193 135.946C528.193 134.663 527.145 133.616 525.851 133.616Z"
            fill="#ADADAD"
          />
          <path
            d="M525.851 140.072C524.568 140.072 523.52 141.119 523.52 142.402C523.52 143.685 524.568 144.732 525.851 144.732C527.145 144.732 528.193 143.685 528.193 142.402C528.193 141.119 527.145 140.072 525.851 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M525.851 146.527C524.568 146.527 523.52 147.574 523.52 148.857C523.52 150.139 524.568 151.187 525.851 151.187C527.145 151.187 528.193 150.139 528.193 148.857C528.193 147.574 527.145 146.527 525.851 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M525.851 152.983C524.568 152.983 523.52 154.03 523.52 155.313C523.52 156.596 524.568 157.654 525.851 157.654C527.145 157.654 528.193 156.606 528.193 155.313C528.193 154.03 527.145 152.983 525.851 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M525.851 159.429C524.568 159.429 523.52 160.476 523.52 161.759C523.52 163.052 524.568 164.099 525.851 164.099C527.145 164.099 528.193 163.052 528.193 161.759C528.193 160.476 527.145 159.429 525.851 159.429Z"
            fill="#ADADAD"
          />
          <path
            d="M525.851 165.885C524.568 165.885 523.52 166.932 523.52 168.215C523.52 169.497 524.568 170.545 525.851 170.545C527.145 170.545 528.193 169.497 528.193 168.215C528.193 166.932 527.145 165.885 525.851 165.885Z"
            fill="#ADADAD"
          />
          <path
            d="M525.851 172.339C524.568 172.339 523.52 173.387 523.52 174.68C523.52 175.963 524.568 177.01 525.851 177.01C527.145 177.01 528.193 175.963 528.193 174.68C528.193 173.387 527.145 172.339 525.851 172.339Z"
            fill="#ADADAD"
          />
          <path
            d="M525.851 178.785C524.568 178.785 523.52 179.833 523.52 181.115C523.52 182.398 524.568 183.445 525.851 183.445C527.145 183.445 528.193 182.398 528.193 181.115C528.193 179.833 527.145 178.785 525.851 178.785Z"
            fill="#ADADAD"
          />
          <path
            d="M519.401 94.9011C518.117 94.9011 517.069 95.9486 517.069 97.2312C517.069 98.5138 518.117 99.5613 519.401 99.5613C520.684 99.5613 521.732 98.5138 521.732 97.2312C521.732 95.9486 520.684 94.9011 519.401 94.9011Z"
            fill="#ADADAD"
          />
          <path
            d="M519.401 107.813C518.117 107.813 517.069 108.86 517.069 110.143C517.069 111.426 518.117 112.473 519.401 112.473C520.684 112.473 521.732 111.426 521.732 110.143C521.732 108.86 520.684 107.813 519.401 107.813Z"
            fill="#ADADAD"
          />
          <path
            d="M519.401 114.258C518.117 114.258 517.069 115.306 517.069 116.599C517.069 117.881 518.117 118.929 519.401 118.929C520.684 118.929 521.732 117.881 521.732 116.599C521.732 115.306 520.684 114.258 519.401 114.258Z"
            fill="#ADADAD"
          />
          <path
            d="M519.401 120.714C518.117 120.714 517.069 121.762 517.069 123.055C517.069 124.338 518.117 125.385 519.401 125.385C520.684 125.385 521.732 124.338 521.732 123.055C521.732 121.762 520.684 120.714 519.401 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M519.401 127.17C518.117 127.17 517.069 128.217 517.069 129.5C517.069 130.793 518.117 131.841 519.401 131.841C520.684 131.841 521.732 130.793 521.732 129.5C521.732 128.217 520.684 127.17 519.401 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M519.401 133.615C518.117 133.615 517.069 134.662 517.069 135.945C517.069 137.228 518.117 138.275 519.401 138.275C520.684 138.275 521.732 137.228 521.732 135.945C521.732 134.662 520.684 133.615 519.401 133.615Z"
            fill="#ADADAD"
          />
          <path
            d="M519.401 140.071C518.117 140.071 517.069 141.119 517.069 142.401C517.069 143.684 518.117 144.731 519.401 144.731C520.684 144.731 521.732 143.684 521.732 142.401C521.732 141.119 520.684 140.071 519.401 140.071Z"
            fill="#ADADAD"
          />
          <path
            d="M519.401 146.527C518.117 146.527 517.069 147.574 517.069 148.857C517.069 150.14 518.117 151.187 519.401 151.187C520.684 151.187 521.732 150.14 521.732 148.857C521.732 147.574 520.684 146.527 519.401 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M519.401 152.983C518.117 152.983 517.069 154.031 517.069 155.313C517.069 156.596 518.117 157.654 519.401 157.654C520.684 157.654 521.732 156.607 521.732 155.313C521.732 154.031 520.684 152.983 519.401 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M519.401 159.428C518.117 159.428 517.069 160.476 517.069 161.758C517.069 163.052 518.117 164.099 519.401 164.099C520.684 164.099 521.732 163.052 521.732 161.758C521.732 160.476 520.684 159.428 519.401 159.428Z"
            fill="#ADADAD"
          />
          <path
            d="M519.401 165.884C518.117 165.884 517.069 166.932 517.069 168.215C517.069 169.497 518.117 170.545 519.401 170.545C520.684 170.545 521.732 169.497 521.732 168.215C521.732 166.932 520.684 165.884 519.401 165.884Z"
            fill="#ADADAD"
          />
          <path
            d="M519.401 172.34C518.117 172.34 517.069 173.388 517.069 174.681C517.069 175.963 518.117 177.011 519.401 177.011C520.684 177.011 521.732 175.963 521.732 174.681C521.732 173.388 520.684 172.34 519.401 172.34Z"
            fill="#ADADAD"
          />
          <path
            d="M519.401 178.786C518.117 178.786 517.069 179.833 517.069 181.116C517.069 182.398 518.117 183.446 519.401 183.446C520.684 183.446 521.732 182.398 521.732 181.116C521.732 179.833 520.684 178.786 519.401 178.786Z"
            fill="#ADADAD"
          />
          <path
            d="M512.941 94.9011C511.658 94.9011 510.61 95.9486 510.61 97.2312C510.61 98.5138 511.658 99.5613 512.941 99.5613C514.225 99.5613 515.273 98.5138 515.273 97.2312C515.273 95.9486 514.235 94.9011 512.941 94.9011Z"
            fill="#ADADAD"
          />
          <path
            d="M512.941 114.258C511.658 114.258 510.61 115.306 510.61 116.599C510.61 117.881 511.658 118.929 512.941 118.929C514.225 118.929 515.273 117.881 515.273 116.599C515.273 115.306 514.235 114.258 512.941 114.258Z"
            fill="#ADADAD"
          />
          <path
            d="M512.941 120.714C511.658 120.714 510.61 121.762 510.61 123.055C510.61 124.338 511.658 125.385 512.941 125.385C514.225 125.385 515.273 124.338 515.273 123.055C515.273 121.762 514.235 120.714 512.941 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M512.941 127.17C511.658 127.17 510.61 128.217 510.61 129.5C510.61 130.793 511.658 131.841 512.941 131.841C514.225 131.841 515.273 130.793 515.273 129.5C515.273 128.217 514.235 127.17 512.941 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M512.941 133.615C511.658 133.615 510.61 134.662 510.61 135.945C510.61 137.228 511.658 138.275 512.941 138.275C514.225 138.275 515.273 137.228 515.273 135.945C515.273 134.662 514.235 133.615 512.941 133.615Z"
            fill="#ADADAD"
          />
          <path
            d="M512.941 140.071C511.658 140.071 510.61 141.119 510.61 142.401C510.61 143.684 511.658 144.731 512.941 144.731C514.225 144.731 515.273 143.684 515.273 142.401C515.273 141.119 514.235 140.071 512.941 140.071Z"
            fill="#ADADAD"
          />
          <path
            d="M512.941 146.527C511.658 146.527 510.61 147.574 510.61 148.857C510.61 150.14 511.658 151.187 512.941 151.187C514.225 151.187 515.273 150.14 515.273 148.857C515.273 147.574 514.235 146.527 512.941 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M512.941 152.983C511.658 152.983 510.61 154.031 510.61 155.313C510.61 156.596 511.658 157.654 512.941 157.654C514.225 157.654 515.273 156.607 515.273 155.313C515.273 154.031 514.235 152.983 512.941 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M512.941 159.428C511.658 159.428 510.61 160.476 510.61 161.758C510.61 163.052 511.658 164.099 512.941 164.099C514.225 164.099 515.273 163.052 515.273 161.758C515.273 160.476 514.235 159.428 512.941 159.428Z"
            fill="#ADADAD"
          />
          <path
            d="M512.941 165.884C511.658 165.884 510.61 166.932 510.61 168.215C510.61 169.497 511.658 170.545 512.941 170.545C514.225 170.545 515.273 169.497 515.273 168.215C515.273 166.932 514.235 165.884 512.941 165.884Z"
            fill="#ADADAD"
          />
          <path
            d="M512.941 172.34C511.658 172.34 510.61 173.388 510.61 174.681C510.61 175.963 511.658 177.011 512.941 177.011C514.225 177.011 515.273 175.963 515.273 174.681C515.273 173.388 514.235 172.34 512.941 172.34Z"
            fill="#ADADAD"
          />
          <path
            d="M512.941 178.786C511.658 178.786 510.61 179.833 510.61 181.116C510.61 182.398 511.658 183.446 512.941 183.446C514.225 183.446 515.273 182.398 515.273 181.116C515.273 179.833 514.235 178.786 512.941 178.786Z"
            fill="#ADADAD"
          />
          <path
            d="M506.493 94.9011C505.209 94.9011 504.151 95.9486 504.151 97.2312C504.151 98.5138 505.199 99.5613 506.493 99.5613C507.776 99.5613 508.824 98.5138 508.824 97.2312C508.824 95.9486 507.776 94.9011 506.493 94.9011Z"
            fill="#ADADAD"
          />
          <path
            d="M506.493 120.714C505.209 120.714 504.151 121.762 504.151 123.055C504.151 124.338 505.199 125.385 506.493 125.385C507.776 125.385 508.824 124.338 508.824 123.055C508.824 121.762 507.776 120.714 506.493 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M506.493 127.17C505.209 127.17 504.151 128.217 504.151 129.5C504.151 130.793 505.199 131.841 506.493 131.841C507.776 131.841 508.824 130.793 508.824 129.5C508.824 128.217 507.776 127.17 506.493 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M506.493 133.615C505.209 133.615 504.151 134.662 504.151 135.945C504.151 137.228 505.199 138.275 506.493 138.275C507.776 138.275 508.824 137.228 508.824 135.945C508.824 134.662 507.776 133.615 506.493 133.615Z"
            fill="#ADADAD"
          />
          <path
            d="M506.493 140.071C505.209 140.071 504.151 141.119 504.151 142.401C504.151 143.684 505.199 144.731 506.493 144.731C507.776 144.731 508.824 143.684 508.824 142.401C508.824 141.119 507.776 140.071 506.493 140.071Z"
            fill="#ADADAD"
          />
          <path
            d="M506.493 146.527C505.209 146.527 504.151 147.574 504.151 148.857C504.151 150.14 505.199 151.187 506.493 151.187C507.776 151.187 508.824 150.14 508.824 148.857C508.824 147.574 507.776 146.527 506.493 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M506.493 165.884C505.209 165.884 504.151 166.932 504.151 168.215C504.151 169.497 505.199 170.545 506.493 170.545C507.776 170.545 508.824 169.497 508.824 168.215C508.824 166.932 507.776 165.884 506.493 165.884Z"
            fill="#ADADAD"
          />
          <path
            d="M506.493 172.34C505.209 172.34 504.151 173.388 504.151 174.681C504.151 175.963 505.199 177.011 506.493 177.011C507.776 177.011 508.824 175.963 508.824 174.681C508.824 173.388 507.776 172.34 506.493 172.34Z"
            fill="#ADADAD"
          />
          <path
            d="M506.493 178.786C505.209 178.786 504.151 179.833 504.151 181.116C504.151 182.398 505.199 183.446 506.493 183.446C507.776 183.446 508.824 182.398 508.824 181.116C508.824 179.833 507.776 178.786 506.493 178.786Z"
            fill="#ADADAD"
          />
          <path
            d="M500.034 94.9008C498.751 94.9008 497.703 95.9483 497.703 97.2309C497.703 98.5135 498.751 99.561 500.034 99.561C501.328 99.561 502.376 98.5135 502.376 97.2309C502.366 95.9483 501.317 94.9008 500.034 94.9008Z"
            fill="#ADADAD"
          />
          <path
            d="M500.034 101.357C498.751 101.357 497.703 102.404 497.703 103.687C497.703 104.97 498.751 106.017 500.034 106.017C501.328 106.017 502.376 104.97 502.376 103.687C502.366 102.404 501.317 101.357 500.034 101.357Z"
            fill="#ADADAD"
          />
          <path
            d="M500.034 120.714C498.751 120.714 497.703 121.761 497.703 123.054C497.703 124.337 498.751 125.385 500.034 125.385C501.328 125.385 502.376 124.337 502.376 123.054C502.366 121.761 501.317 120.714 500.034 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M500.034 127.17C498.751 127.17 497.703 128.217 497.703 129.5C497.703 130.793 498.751 131.841 500.034 131.841C501.328 131.841 502.376 130.793 502.376 129.5C502.366 128.217 501.317 127.17 500.034 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M500.034 133.616C498.751 133.616 497.703 134.663 497.703 135.946C497.703 137.228 498.751 138.276 500.034 138.276C501.328 138.276 502.376 137.228 502.376 135.946C502.366 134.663 501.317 133.616 500.034 133.616Z"
            fill="#ADADAD"
          />
          <path
            d="M500.034 140.072C498.751 140.072 497.703 141.119 497.703 142.402C497.703 143.685 498.751 144.732 500.034 144.732C501.328 144.732 502.376 143.685 502.376 142.402C502.366 141.119 501.317 140.072 500.034 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M500.034 146.527C498.751 146.527 497.703 147.574 497.703 148.857C497.703 150.139 498.751 151.187 500.034 151.187C501.328 151.187 502.376 150.139 502.376 148.857C502.376 147.574 501.317 146.527 500.034 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M493.575 101.357C492.291 101.357 491.243 102.404 491.243 103.687C491.243 104.97 492.291 106.017 493.575 106.017C494.869 106.017 495.917 104.97 495.917 103.687C495.917 102.404 494.858 101.357 493.575 101.357Z"
            fill="#ADADAD"
          />
          <path
            d="M493.575 107.813C492.291 107.813 491.243 108.861 491.243 110.143C491.243 111.426 492.291 112.473 493.575 112.473C494.869 112.473 495.917 111.426 495.917 110.143C495.917 108.861 494.858 107.813 493.575 107.813Z"
            fill="#ADADAD"
          />
          <path
            d="M493.575 114.257C492.291 114.257 491.243 115.305 491.243 116.598C491.243 117.881 492.291 118.928 493.575 118.928C494.869 118.928 495.917 117.881 495.917 116.598C495.917 115.305 494.858 114.257 493.575 114.257Z"
            fill="#ADADAD"
          />
          <path
            d="M493.575 127.17C492.291 127.17 491.243 128.217 491.243 129.5C491.243 130.793 492.291 131.841 493.575 131.841C494.869 131.841 495.917 130.793 495.917 129.5C495.917 128.217 494.858 127.17 493.575 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M493.575 133.616C492.291 133.616 491.243 134.663 491.243 135.946C491.243 137.228 492.291 138.276 493.575 138.276C494.869 138.276 495.917 137.228 495.917 135.946C495.917 134.663 494.858 133.616 493.575 133.616Z"
            fill="#ADADAD"
          />
          <path
            d="M493.575 140.072C492.291 140.072 491.243 141.119 491.243 142.402C491.243 143.685 492.291 144.732 493.575 144.732C494.869 144.732 495.917 143.685 495.917 142.402C495.917 141.119 494.858 140.072 493.575 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M493.575 146.527C492.291 146.527 491.243 147.574 491.243 148.857C491.243 150.139 492.291 151.187 493.575 151.187C494.869 151.187 495.917 150.139 495.917 148.857C495.917 147.574 494.858 146.527 493.575 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M487.124 112.473C488.412 112.473 489.456 111.43 489.456 110.143C489.456 108.856 488.412 107.813 487.124 107.813C485.837 107.813 484.793 108.856 484.793 110.143C484.793 111.43 485.837 112.473 487.124 112.473Z"
            fill="#ADADAD"
          />
          <path
            d="M487.124 114.258C485.841 114.258 484.793 115.306 484.793 116.599C484.793 117.881 485.841 118.929 487.124 118.929C488.408 118.929 489.456 117.881 489.456 116.599C489.456 115.306 488.408 114.258 487.124 114.258Z"
            fill="#ADADAD"
          />
          <path
            d="M487.124 127.17C485.841 127.17 484.793 128.217 484.793 129.5C484.793 130.793 485.841 131.841 487.124 131.841C488.408 131.841 489.456 130.793 489.456 129.5C489.456 128.217 488.408 127.17 487.124 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M487.124 133.615C485.841 133.615 484.793 134.662 484.793 135.945C484.793 137.228 485.841 138.275 487.124 138.275C488.408 138.275 489.456 137.228 489.456 135.945C489.456 134.662 488.408 133.615 487.124 133.615Z"
            fill="#ADADAD"
          />
          <path
            d="M487.124 140.071C485.841 140.071 484.793 141.119 484.793 142.401C484.793 143.684 485.841 144.731 487.124 144.731C488.408 144.731 489.456 143.684 489.456 142.401C489.456 141.119 488.408 140.071 487.124 140.071Z"
            fill="#ADADAD"
          />
          <path
            d="M487.124 151.187C488.412 151.187 489.456 150.144 489.456 148.857C489.456 147.57 488.412 146.527 487.124 146.527C485.837 146.527 484.793 147.57 484.793 148.857C484.793 150.144 485.837 151.187 487.124 151.187Z"
            fill="#ADADAD"
          />
          <path
            d="M480.654 114.258C479.37 114.258 478.322 115.306 478.322 116.599C478.322 117.881 479.37 118.929 480.654 118.929C481.937 118.929 482.996 117.881 482.996 116.599C482.996 115.306 481.948 114.258 480.654 114.258Z"
            fill="#ADADAD"
          />
          <path
            d="M480.654 127.17C479.37 127.17 478.322 128.217 478.322 129.5C478.322 130.793 479.37 131.841 480.654 131.841C481.937 131.841 482.996 130.793 482.996 129.5C482.996 128.217 481.948 127.17 480.654 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M480.654 133.615C479.37 133.615 478.322 134.662 478.322 135.945C478.322 137.228 479.37 138.275 480.654 138.275C481.937 138.275 482.996 137.228 482.996 135.945C482.996 134.662 481.948 133.615 480.654 133.615Z"
            fill="#ADADAD"
          />
          <path
            d="M480.654 140.071C479.37 140.071 478.322 141.119 478.322 142.401C478.322 143.684 479.37 144.731 480.654 144.731C481.937 144.731 482.996 143.684 482.996 142.401C482.996 141.119 481.948 140.071 480.654 140.071Z"
            fill="#ADADAD"
          />
          <path
            d="M480.654 146.527C479.37 146.527 478.322 147.574 478.322 148.857C478.322 150.14 479.37 151.187 480.654 151.187C481.937 151.187 482.996 150.14 482.996 148.857C482.996 147.574 481.948 146.527 480.654 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M474.206 120.714C472.922 120.714 471.874 121.762 471.874 123.055C471.874 124.338 472.922 125.385 474.206 125.385C475.489 125.385 476.537 124.338 476.537 123.055C476.537 121.762 475.489 120.714 474.206 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M474.206 127.17C472.922 127.17 471.874 128.217 471.874 129.5C471.874 130.793 472.922 131.841 474.206 131.841C475.489 131.841 476.537 130.793 476.537 129.5C476.537 128.217 475.489 127.17 474.206 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M474.206 133.615C472.922 133.615 471.874 134.662 471.874 135.945C471.874 137.228 472.922 138.275 474.206 138.275C475.489 138.275 476.537 137.228 476.537 135.945C476.537 134.662 475.489 133.615 474.206 133.615Z"
            fill="#ADADAD"
          />
          <path
            d="M474.206 140.071C472.922 140.071 471.874 141.119 471.874 142.401C471.874 143.684 472.922 144.731 474.206 144.731C475.489 144.731 476.537 143.684 476.537 142.401C476.537 141.119 475.489 140.071 474.206 140.071Z"
            fill="#ADADAD"
          />
          <path
            d="M474.206 146.527C472.922 146.527 471.874 147.574 471.874 148.857C471.874 150.14 472.922 151.187 474.206 151.187C475.489 151.187 476.537 150.14 476.537 148.857C476.537 147.574 475.489 146.527 474.206 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M467.746 127.17C466.463 127.17 465.415 128.217 465.415 129.5C465.415 130.793 466.463 131.841 467.746 131.841C469.029 131.841 470.077 130.793 470.077 129.5C470.088 128.217 469.04 127.17 467.746 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M467.746 133.616C466.463 133.616 465.415 134.663 465.415 135.946C465.415 137.228 466.463 138.276 467.746 138.276C469.029 138.276 470.077 137.228 470.077 135.946C470.088 134.663 469.04 133.616 467.746 133.616Z"
            fill="#ADADAD"
          />
          <path
            d="M467.746 140.072C466.463 140.072 465.415 141.119 465.415 142.402C465.415 143.685 466.463 144.732 467.746 144.732C469.029 144.732 470.077 143.685 470.077 142.402C470.088 141.119 469.04 140.072 467.746 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M467.746 151.187C469.034 151.187 470.078 150.144 470.078 148.857C470.078 147.57 469.034 146.527 467.746 146.527C466.458 146.527 465.415 147.57 465.415 148.857C465.415 150.144 466.458 151.187 467.746 151.187Z"
            fill="#ADADAD"
          />
          <path
            d="M377.353 178.785C376.07 178.785 375.022 179.833 375.022 181.115C375.022 182.398 376.07 183.445 377.353 183.445C378.636 183.445 379.684 182.398 379.684 181.115C379.695 179.833 378.647 178.785 377.353 178.785Z"
            fill="#ADADAD"
          />
          <path
            d="M370.905 172.339C369.622 172.339 368.574 173.387 368.574 174.68C368.574 175.963 369.622 177.01 370.905 177.01C372.189 177.01 373.237 175.963 373.237 174.68C373.237 173.387 372.189 172.339 370.905 172.339Z"
            fill="#ADADAD"
          />
          <path
            d="M364.445 157.643C365.728 157.643 366.776 156.596 366.776 155.302C366.776 154.02 365.728 152.972 364.445 152.972C363.151 152.972 362.103 154.02 362.103 155.302C362.103 156.596 363.161 157.643 364.445 157.643Z"
            fill="#ADADAD"
          />
          <path
            d="M370.905 152.983C369.622 152.983 368.574 154.03 368.574 155.313C368.574 156.596 369.622 157.654 370.905 157.654C372.189 157.654 373.237 156.606 373.237 155.313C373.237 154.03 372.189 152.983 370.905 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M364.445 164.099C365.728 164.099 366.776 163.052 366.776 161.759C366.776 160.476 365.728 159.429 364.445 159.429C363.151 159.429 362.103 160.476 362.103 161.759C362.103 163.052 363.161 164.099 364.445 164.099Z"
            fill="#ADADAD"
          />
          <path
            d="M364.445 170.555C365.728 170.555 366.776 169.508 366.776 168.225C366.776 166.943 365.728 165.895 364.445 165.895C363.151 165.895 362.103 166.943 362.103 168.225C362.103 169.508 363.161 170.555 364.445 170.555Z"
            fill="#ADADAD"
          />
          <path
            d="M364.445 177.01C365.728 177.01 366.776 175.963 366.776 174.68C366.776 173.398 365.728 172.339 364.445 172.339C363.151 172.339 362.103 173.387 362.103 174.68C362.103 175.963 363.161 177.01 364.445 177.01Z"
            fill="#ADADAD"
          />
          <path
            d="M364.445 183.456C365.728 183.456 366.776 182.408 366.776 181.126C366.776 179.843 365.728 178.796 364.445 178.796C363.151 178.796 362.103 179.843 362.103 181.126C362.103 182.408 363.161 183.456 364.445 183.456Z"
            fill="#ADADAD"
          />
          <path
            d="M357.986 159.428C356.703 159.428 355.654 160.476 355.654 161.758C355.654 163.052 356.703 164.099 357.986 164.099C359.269 164.099 360.317 163.052 360.317 161.758C360.328 160.476 359.28 159.428 357.986 159.428Z"
            fill="#ADADAD"
          />
          <path
            d="M357.986 165.884C356.703 165.884 355.654 166.932 355.654 168.215C355.654 169.497 356.703 170.545 357.986 170.545C359.269 170.545 360.317 169.497 360.317 168.215C360.328 166.932 359.28 165.884 357.986 165.884Z"
            fill="#ADADAD"
          />
          <path
            d="M357.986 172.34C356.703 172.34 355.654 173.388 355.654 174.681C355.654 175.963 356.703 177.011 357.986 177.011C359.269 177.011 360.317 175.963 360.317 174.681C360.328 173.388 359.28 172.34 357.986 172.34Z"
            fill="#ADADAD"
          />
          <path
            d="M357.986 178.786C356.703 178.786 355.654 179.833 355.654 181.116C355.654 182.398 356.703 183.446 357.986 183.446C359.269 183.446 360.317 182.398 360.317 181.116C360.328 179.833 359.28 178.786 357.986 178.786Z"
            fill="#ADADAD"
          />
          <path
            d="M351.527 165.884C350.243 165.884 349.195 166.932 349.195 168.215C349.195 169.497 350.243 170.545 351.527 170.545C352.81 170.545 353.858 169.497 353.858 168.215C353.869 166.932 352.821 165.884 351.527 165.884Z"
            fill="#ADADAD"
          />
          <path
            d="M351.527 172.34C350.243 172.34 349.195 173.388 349.195 174.681C349.195 175.963 350.243 177.011 351.527 177.011C352.81 177.011 353.858 175.963 353.858 174.681C353.869 173.388 352.821 172.34 351.527 172.34Z"
            fill="#ADADAD"
          />
          <path
            d="M345.077 172.34C343.794 172.34 342.746 173.388 342.746 174.681C342.746 175.963 343.794 177.011 345.077 177.011C346.361 177.011 347.409 175.963 347.409 174.681C347.409 173.388 346.361 172.34 345.077 172.34Z"
            fill="#ADADAD"
          />
          <path
            d="M461.297 127.17C460.014 127.17 458.955 128.217 458.955 129.5C458.955 130.793 460.003 131.841 461.297 131.841C462.581 131.841 463.629 130.793 463.629 129.5C463.629 128.217 462.581 127.17 461.297 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M461.297 133.616C460.014 133.616 458.955 134.663 458.955 135.946C458.955 137.228 460.003 138.276 461.297 138.276C462.581 138.276 463.629 137.228 463.629 135.946C463.629 134.663 462.581 133.616 461.297 133.616Z"
            fill="#ADADAD"
          />
          <path
            d="M461.297 140.072C460.014 140.072 458.955 141.119 458.955 142.402C458.955 143.685 460.003 144.732 461.297 144.732C462.581 144.732 463.629 143.685 463.629 142.402C463.629 141.119 462.581 140.072 461.297 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M461.297 146.527C460.014 146.527 458.955 147.574 458.955 148.857C458.955 150.139 460.003 151.187 461.297 151.187C462.581 151.187 463.629 150.139 463.629 148.857C463.629 147.574 462.581 146.527 461.297 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M461.297 198.154C460.014 198.154 458.955 199.201 458.955 200.484C458.955 201.767 460.003 202.814 461.297 202.814C462.581 202.814 463.629 201.767 463.629 200.484C463.629 199.201 462.581 198.154 461.297 198.154Z"
            fill="#ADADAD"
          />
          <path
            d="M454.837 127.17C453.553 127.17 452.505 128.217 452.505 129.5C452.505 130.793 453.553 131.841 454.837 131.841C456.131 131.841 457.179 130.793 457.179 129.5C457.168 128.217 456.12 127.17 454.837 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M454.837 133.615C453.553 133.615 452.505 134.662 452.505 135.945C452.505 137.228 453.553 138.275 454.837 138.275C456.131 138.275 457.179 137.228 457.179 135.945C457.168 134.662 456.12 133.615 454.837 133.615Z"
            fill="#ADADAD"
          />
          <path
            d="M454.837 140.071C453.553 140.071 452.505 141.119 452.505 142.401C452.505 143.684 453.553 144.731 454.837 144.731C456.131 144.731 457.179 143.684 457.179 142.401C457.168 141.119 456.12 140.071 454.837 140.071Z"
            fill="#ADADAD"
          />
          <path
            d="M454.837 146.527C453.553 146.527 452.505 147.574 452.505 148.857C452.505 150.14 453.553 151.187 454.837 151.187C456.131 151.187 457.179 150.14 457.179 148.857C457.179 147.574 456.12 146.527 454.837 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M454.837 172.34C453.553 172.34 452.505 173.388 452.505 174.681C452.505 175.963 453.553 177.011 454.837 177.011C456.131 177.011 457.179 175.963 457.179 174.681C457.168 173.388 456.12 172.34 454.837 172.34Z"
            fill="#ADADAD"
          />
          <path
            d="M454.837 178.786C453.553 178.786 452.505 179.833 452.505 181.116C452.505 182.398 453.553 183.446 454.837 183.446C456.131 183.446 457.179 182.398 457.179 181.116C457.168 179.833 456.12 178.786 454.837 178.786Z"
            fill="#ADADAD"
          />
          <path
            d="M454.837 185.241C453.553 185.241 452.505 186.289 452.505 187.571C452.505 188.865 453.553 189.912 454.837 189.912C456.131 189.912 457.179 188.865 457.179 187.571C457.168 186.289 456.12 185.241 454.837 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M454.837 191.698C453.553 191.698 452.505 192.745 452.505 194.028C452.505 195.31 453.553 196.358 454.837 196.358C456.131 196.358 457.179 195.31 457.179 194.028C457.168 192.745 456.12 191.698 454.837 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M454.837 198.153C453.553 198.153 452.505 199.201 452.505 200.483C452.505 201.766 453.553 202.813 454.837 202.813C456.131 202.813 457.179 201.766 457.179 200.483C457.168 199.201 456.12 198.153 454.837 198.153Z"
            fill="#ADADAD"
          />
          <path
            d="M448.377 127.17C447.094 127.17 446.046 128.217 446.046 129.5C446.046 130.793 447.094 131.841 448.377 131.841C449.661 131.841 450.709 130.793 450.709 129.5C450.709 128.217 449.661 127.17 448.377 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M448.377 133.615C447.094 133.615 446.046 134.662 446.046 135.945C446.046 137.228 447.094 138.275 448.377 138.275C449.661 138.275 450.709 137.228 450.709 135.945C450.709 134.662 449.661 133.615 448.377 133.615Z"
            fill="#ADADAD"
          />
          <path
            d="M448.377 140.071C447.094 140.071 446.046 141.119 446.046 142.401C446.046 143.684 447.094 144.731 448.377 144.731C449.661 144.731 450.709 143.684 450.709 142.401C450.709 141.119 449.661 140.071 448.377 140.071Z"
            fill="#ADADAD"
          />
          <path
            d="M448.377 146.527C447.094 146.527 446.046 147.574 446.046 148.857C446.046 150.14 447.094 151.187 448.377 151.187C449.661 151.187 450.709 150.14 450.709 148.857C450.709 147.574 449.661 146.527 448.377 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M448.377 165.884C447.094 165.884 446.046 166.932 446.046 168.215C446.046 169.497 447.094 170.545 448.377 170.545C449.661 170.545 450.709 169.497 450.709 168.215C450.709 166.932 449.661 165.884 448.377 165.884Z"
            fill="#ADADAD"
          />
          <path
            d="M448.377 172.34C447.094 172.34 446.046 173.388 446.046 174.681C446.046 175.963 447.094 177.011 448.377 177.011C449.661 177.011 450.709 175.963 450.709 174.681C450.709 173.388 449.661 172.34 448.377 172.34Z"
            fill="#ADADAD"
          />
          <path
            d="M448.377 178.786C447.094 178.786 446.046 179.833 446.046 181.116C446.046 182.398 447.094 183.446 448.377 183.446C449.661 183.446 450.709 182.398 450.709 181.116C450.709 179.833 449.661 178.786 448.377 178.786Z"
            fill="#ADADAD"
          />
          <path
            d="M448.377 185.241C447.094 185.241 446.046 186.289 446.046 187.571C446.046 188.865 447.094 189.912 448.377 189.912C449.661 189.912 450.709 188.865 450.709 187.571C450.709 186.289 449.661 185.241 448.377 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M448.377 191.698C447.094 191.698 446.046 192.745 446.046 194.028C446.046 195.31 447.094 196.358 448.377 196.358C449.661 196.358 450.709 195.31 450.709 194.028C450.709 192.745 449.661 191.698 448.377 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M448.377 198.153C447.094 198.153 446.046 199.201 446.046 200.483C446.046 201.766 447.094 202.813 448.377 202.813C449.661 202.813 450.709 201.766 450.709 200.483C450.709 199.201 449.661 198.153 448.377 198.153Z"
            fill="#ADADAD"
          />
          <path
            d="M441.929 127.17C440.635 127.17 439.586 128.217 439.586 129.5C439.586 130.793 440.635 131.841 441.929 131.841C443.212 131.841 444.26 130.793 444.26 129.5C444.26 128.217 443.212 127.17 441.929 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M441.929 133.615C440.635 133.615 439.586 134.662 439.586 135.945C439.586 137.228 440.635 138.275 441.929 138.275C443.212 138.275 444.26 137.228 444.26 135.945C444.26 134.662 443.212 133.615 441.929 133.615Z"
            fill="#ADADAD"
          />
          <path
            d="M441.929 140.071C440.635 140.071 439.586 141.119 439.586 142.401C439.586 143.684 440.635 144.731 441.929 144.731C443.212 144.731 444.26 143.684 444.26 142.401C444.26 141.119 443.212 140.071 441.929 140.071Z"
            fill="#ADADAD"
          />
          <path
            d="M441.929 146.527C440.635 146.527 439.586 147.574 439.586 148.857C439.586 150.14 440.635 151.187 441.929 151.187C443.212 151.187 444.26 150.14 444.26 148.857C444.26 147.574 443.212 146.527 441.929 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M441.929 152.983C440.635 152.983 439.586 154.031 439.586 155.313C439.586 156.596 440.635 157.654 441.929 157.654C443.212 157.654 444.26 156.607 444.26 155.313C444.26 154.031 443.212 152.983 441.929 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M441.929 159.428C440.635 159.428 439.586 160.476 439.586 161.758C439.586 163.052 440.635 164.099 441.929 164.099C443.212 164.099 444.26 163.052 444.26 161.758C444.26 160.476 443.212 159.428 441.929 159.428Z"
            fill="#ADADAD"
          />
          <path
            d="M441.929 165.884C440.635 165.884 439.586 166.932 439.586 168.215C439.586 169.497 440.635 170.545 441.929 170.545C443.212 170.545 444.26 169.497 444.26 168.215C444.26 166.932 443.212 165.884 441.929 165.884Z"
            fill="#ADADAD"
          />
          <path
            d="M441.929 172.34C440.635 172.34 439.586 173.388 439.586 174.681C439.586 175.963 440.635 177.011 441.929 177.011C443.212 177.011 444.26 175.963 444.26 174.681C444.26 173.388 443.212 172.34 441.929 172.34Z"
            fill="#ADADAD"
          />
          <path
            d="M441.929 178.786C440.635 178.786 439.586 179.833 439.586 181.116C439.586 182.398 440.635 183.446 441.929 183.446C443.212 183.446 444.26 182.398 444.26 181.116C444.26 179.833 443.212 178.786 441.929 178.786Z"
            fill="#ADADAD"
          />
          <path
            d="M441.929 185.241C440.635 185.241 439.586 186.289 439.586 187.571C439.586 188.865 440.635 189.912 441.929 189.912C443.212 189.912 444.26 188.865 444.26 187.571C444.26 186.289 443.212 185.241 441.929 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M441.929 191.698C440.635 191.698 439.586 192.745 439.586 194.028C439.586 195.31 440.635 196.358 441.929 196.358C443.212 196.358 444.26 195.31 444.26 194.028C444.26 192.745 443.212 191.698 441.929 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M435.47 114.257C434.186 114.257 433.138 115.305 433.138 116.598C433.138 117.881 434.186 118.928 435.47 118.928C436.753 118.928 437.801 117.881 437.801 116.598C437.801 115.305 436.753 114.257 435.47 114.257Z"
            fill="#ADADAD"
          />
          <path
            d="M435.47 120.714C434.186 120.714 433.138 121.761 433.138 123.054C433.138 124.337 434.186 125.385 435.47 125.385C436.753 125.385 437.801 124.337 437.801 123.054C437.801 121.761 436.753 120.714 435.47 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M435.47 127.17C434.186 127.17 433.138 128.217 433.138 129.5C433.138 130.793 434.186 131.841 435.47 131.841C436.753 131.841 437.801 130.793 437.801 129.5C437.801 128.217 436.753 127.17 435.47 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M435.47 133.616C434.186 133.616 433.138 134.663 433.138 135.946C433.138 137.228 434.186 138.276 435.47 138.276C436.753 138.276 437.801 137.228 437.801 135.946C437.801 134.663 436.753 133.616 435.47 133.616Z"
            fill="#ADADAD"
          />
          <path
            d="M435.47 140.072C434.186 140.072 433.138 141.119 433.138 142.402C433.138 143.685 434.186 144.732 435.47 144.732C436.753 144.732 437.801 143.685 437.801 142.402C437.801 141.119 436.753 140.072 435.47 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M435.47 146.527C434.186 146.527 433.138 147.574 433.138 148.857C433.138 150.139 434.186 151.187 435.47 151.187C436.753 151.187 437.801 150.139 437.801 148.857C437.801 147.574 436.753 146.527 435.47 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M435.47 152.983C434.186 152.983 433.138 154.03 433.138 155.313C433.138 156.596 434.186 157.654 435.47 157.654C436.753 157.654 437.801 156.606 437.801 155.313C437.801 154.03 436.753 152.983 435.47 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M435.47 159.429C434.186 159.429 433.138 160.476 433.138 161.759C433.138 163.052 434.186 164.099 435.47 164.099C436.753 164.099 437.801 163.052 437.801 161.759C437.801 160.476 436.753 159.429 435.47 159.429Z"
            fill="#ADADAD"
          />
          <path
            d="M435.47 165.885C434.186 165.885 433.138 166.932 433.138 168.215C433.138 169.497 434.186 170.545 435.47 170.545C436.753 170.545 437.801 169.497 437.801 168.215C437.801 166.932 436.753 165.885 435.47 165.885Z"
            fill="#ADADAD"
          />
          <path
            d="M435.47 172.339C434.186 172.339 433.138 173.387 433.138 174.68C433.138 175.963 434.186 177.01 435.47 177.01C436.753 177.01 437.801 175.963 437.801 174.68C437.801 173.387 436.753 172.339 435.47 172.339Z"
            fill="#ADADAD"
          />
          <path
            d="M435.47 178.785C434.186 178.785 433.138 179.833 433.138 181.115C433.138 182.398 434.186 183.445 435.47 183.445C436.753 183.445 437.801 182.398 437.801 181.115C437.801 179.833 436.753 178.785 435.47 178.785Z"
            fill="#ADADAD"
          />
          <path
            d="M435.47 185.241C434.186 185.241 433.138 186.289 433.138 187.572C433.138 188.865 434.186 189.912 435.47 189.912C436.753 189.912 437.801 188.865 437.801 187.572C437.801 186.289 436.753 185.241 435.47 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M429.01 114.257C427.727 114.257 426.679 115.305 426.679 116.598C426.679 117.881 427.727 118.928 429.01 118.928C430.294 118.928 431.342 117.881 431.342 116.598C431.342 115.305 430.294 114.257 429.01 114.257Z"
            fill="#ADADAD"
          />
          <path
            d="M429.01 120.714C427.727 120.714 426.679 121.761 426.679 123.054C426.679 124.337 427.727 125.385 429.01 125.385C430.294 125.385 431.342 124.337 431.342 123.054C431.342 121.761 430.294 120.714 429.01 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M429.01 127.17C427.727 127.17 426.679 128.217 426.679 129.5C426.679 130.793 427.727 131.841 429.01 131.841C430.294 131.841 431.342 130.793 431.342 129.5C431.342 128.217 430.294 127.17 429.01 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M429.01 133.616C427.727 133.616 426.679 134.663 426.679 135.946C426.679 137.228 427.727 138.276 429.01 138.276C430.294 138.276 431.342 137.228 431.342 135.946C431.342 134.663 430.294 133.616 429.01 133.616Z"
            fill="#ADADAD"
          />
          <path
            d="M429.01 140.072C427.727 140.072 426.679 141.119 426.679 142.402C426.679 143.685 427.727 144.732 429.01 144.732C430.294 144.732 431.342 143.685 431.342 142.402C431.342 141.119 430.294 140.072 429.01 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M429.01 151.187C430.298 151.187 431.342 150.144 431.342 148.857C431.342 147.57 430.298 146.527 429.01 146.527C427.723 146.527 426.679 147.57 426.679 148.857C426.679 150.144 427.723 151.187 429.01 151.187Z"
            fill="#ADADAD"
          />
          <path
            d="M429.01 152.983C427.727 152.983 426.679 154.03 426.679 155.313C426.679 156.596 427.727 157.654 429.01 157.654C430.294 157.654 431.342 156.606 431.342 155.313C431.342 154.03 430.294 152.983 429.01 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M429.01 159.429C427.727 159.429 426.679 160.476 426.679 161.759C426.679 163.052 427.727 164.099 429.01 164.099C430.294 164.099 431.342 163.052 431.342 161.759C431.342 160.476 430.294 159.429 429.01 159.429Z"
            fill="#ADADAD"
          />
          <path
            d="M429.01 165.885C427.727 165.885 426.679 166.932 426.679 168.215C426.679 169.497 427.727 170.545 429.01 170.545C430.294 170.545 431.342 169.497 431.342 168.215C431.342 166.932 430.294 165.885 429.01 165.885Z"
            fill="#ADADAD"
          />
          <path
            d="M429.01 172.339C427.727 172.339 426.679 173.387 426.679 174.68C426.679 175.963 427.727 177.01 429.01 177.01C430.294 177.01 431.342 175.963 431.342 174.68C431.342 173.387 430.294 172.339 429.01 172.339Z"
            fill="#ADADAD"
          />
          <path
            d="M429.01 178.785C427.727 178.785 426.679 179.833 426.679 181.115C426.679 182.398 427.727 183.445 429.01 183.445C430.294 183.445 431.342 182.398 431.342 181.115C431.342 179.833 430.294 178.785 429.01 178.785Z"
            fill="#ADADAD"
          />
          <path
            d="M429.01 185.241C427.727 185.241 426.679 186.289 426.679 187.572C426.679 188.865 427.727 189.912 429.01 189.912C430.294 189.912 431.342 188.865 431.342 187.572C431.342 186.289 430.294 185.241 429.01 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M422.551 120.714C421.268 120.714 420.22 121.761 420.22 123.054C420.22 124.337 421.268 125.385 422.551 125.385C423.845 125.385 424.893 124.337 424.893 123.054C424.893 121.761 423.845 120.714 422.551 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M422.551 127.17C421.268 127.17 420.22 128.217 420.22 129.5C420.22 130.793 421.268 131.841 422.551 131.841C423.845 131.841 424.893 130.793 424.893 129.5C424.893 128.217 423.845 127.17 422.551 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M422.551 133.616C421.268 133.616 420.22 134.663 420.22 135.946C420.22 137.228 421.268 138.276 422.551 138.276C423.845 138.276 424.893 137.228 424.893 135.946C424.893 134.663 423.845 133.616 422.551 133.616Z"
            fill="#ADADAD"
          />
          <path
            d="M422.551 140.072C421.268 140.072 420.22 141.119 420.22 142.402C420.22 143.685 421.268 144.732 422.551 144.732C423.845 144.732 424.893 143.685 424.893 142.402C424.893 141.119 423.845 140.072 422.551 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M422.551 146.527C421.268 146.527 420.22 147.574 420.22 148.857C420.22 150.139 421.268 151.187 422.551 151.187C423.845 151.187 424.893 150.139 424.893 148.857C424.893 147.574 423.845 146.527 422.551 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M422.551 152.983C421.268 152.983 420.22 154.03 420.22 155.313C420.22 156.596 421.268 157.654 422.551 157.654C423.845 157.654 424.893 156.606 424.893 155.313C424.893 154.03 423.845 152.983 422.551 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M422.551 159.429C421.268 159.429 420.22 160.476 420.22 161.759C420.22 163.052 421.268 164.099 422.551 164.099C423.845 164.099 424.893 163.052 424.893 161.759C424.893 160.476 423.845 159.429 422.551 159.429Z"
            fill="#ADADAD"
          />
          <path
            d="M422.551 165.885C421.268 165.885 420.22 166.932 420.22 168.215C420.22 169.497 421.268 170.545 422.551 170.545C423.845 170.545 424.893 169.497 424.893 168.215C424.893 166.932 423.845 165.885 422.551 165.885Z"
            fill="#ADADAD"
          />
          <path
            d="M422.551 172.339C421.268 172.339 420.22 173.387 420.22 174.68C420.22 175.963 421.268 177.01 422.551 177.01C423.845 177.01 424.893 175.963 424.893 174.68C424.893 173.387 423.845 172.339 422.551 172.339Z"
            fill="#ADADAD"
          />
          <path
            d="M422.551 178.785C421.268 178.785 420.22 179.833 420.22 181.115C420.22 182.398 421.268 183.445 422.551 183.445C423.845 183.445 424.893 182.398 424.893 181.115C424.893 179.833 423.845 178.785 422.551 178.785Z"
            fill="#ADADAD"
          />
          <path
            d="M422.551 185.241C421.268 185.241 420.22 186.289 420.22 187.572C420.22 188.865 421.268 189.912 422.551 189.912C423.845 189.912 424.893 188.865 424.893 187.572C424.893 186.289 423.845 185.241 422.551 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M416.089 120.714C414.805 120.714 413.757 121.761 413.757 123.054C413.757 124.337 414.805 125.385 416.089 125.385C417.372 125.385 418.42 124.337 418.42 123.054C418.431 121.761 417.383 120.714 416.089 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M416.089 127.17C414.805 127.17 413.757 128.217 413.757 129.5C413.757 130.793 414.805 131.841 416.089 131.841C417.372 131.841 418.42 130.793 418.42 129.5C418.431 128.217 417.383 127.17 416.089 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M416.089 133.616C414.805 133.616 413.757 134.663 413.757 135.946C413.757 137.228 414.805 138.276 416.089 138.276C417.372 138.276 418.42 137.228 418.42 135.946C418.431 134.663 417.383 133.616 416.089 133.616Z"
            fill="#ADADAD"
          />
          <path
            d="M416.089 140.072C414.805 140.072 413.757 141.119 413.757 142.402C413.757 143.685 414.805 144.732 416.089 144.732C417.372 144.732 418.42 143.685 418.42 142.402C418.431 141.119 417.383 140.072 416.089 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M416.089 146.527C414.805 146.527 413.757 147.574 413.757 148.857C413.757 150.139 414.805 151.187 416.089 151.187C417.372 151.187 418.42 150.139 418.42 148.857C418.42 147.574 417.383 146.527 416.089 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M416.089 165.885C414.805 165.885 413.757 166.932 413.757 168.215C413.757 169.497 414.805 170.545 416.089 170.545C417.372 170.545 418.42 169.497 418.42 168.215C418.431 166.932 417.383 165.885 416.089 165.885Z"
            fill="#ADADAD"
          />
          <path
            d="M416.089 172.339C414.805 172.339 413.757 173.387 413.757 174.68C413.757 175.963 414.805 177.01 416.089 177.01C417.372 177.01 418.42 175.963 418.42 174.68C418.431 173.387 417.383 172.339 416.089 172.339Z"
            fill="#ADADAD"
          />
          <path
            d="M416.089 178.785C414.805 178.785 413.757 179.833 413.757 181.115C413.757 182.398 414.805 183.445 416.089 183.445C417.372 183.445 418.42 182.398 418.42 181.115C418.431 179.833 417.383 178.785 416.089 178.785Z"
            fill="#ADADAD"
          />
          <path
            d="M416.089 185.241C414.805 185.241 413.757 186.289 413.757 187.572C413.757 188.865 414.805 189.912 416.089 189.912C417.372 189.912 418.42 188.865 418.42 187.572C418.431 186.289 417.383 185.241 416.089 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M409.64 120.714C408.357 120.714 407.298 121.761 407.298 123.054C407.298 124.337 408.346 125.385 409.64 125.385C410.923 125.385 411.971 124.337 411.971 123.054C411.971 121.761 410.923 120.714 409.64 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M409.64 127.17C408.357 127.17 407.298 128.217 407.298 129.5C407.298 130.793 408.346 131.841 409.64 131.841C410.923 131.841 411.971 130.793 411.971 129.5C411.971 128.217 410.923 127.17 409.64 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M409.64 133.616C408.357 133.616 407.298 134.663 407.298 135.946C407.298 137.228 408.346 138.276 409.64 138.276C410.923 138.276 411.971 137.228 411.971 135.946C411.971 134.663 410.923 133.616 409.64 133.616Z"
            fill="#ADADAD"
          />
          <path
            d="M409.64 140.072C408.357 140.072 407.298 141.119 407.298 142.402C407.298 143.685 408.346 144.732 409.64 144.732C410.923 144.732 411.971 143.685 411.971 142.402C411.971 141.119 410.923 140.072 409.64 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M409.64 152.983C408.357 152.983 407.298 154.03 407.298 155.313C407.298 156.596 408.346 157.654 409.64 157.654C410.923 157.654 411.971 156.606 411.971 155.313C411.971 154.03 410.923 152.983 409.64 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M409.64 172.339C408.357 172.339 407.298 173.387 407.298 174.68C407.298 175.963 408.346 177.01 409.64 177.01C410.923 177.01 411.971 175.963 411.971 174.68C411.971 173.387 410.923 172.339 409.64 172.339Z"
            fill="#ADADAD"
          />
          <path
            d="M409.64 178.785C408.357 178.785 407.298 179.833 407.298 181.115C407.298 182.398 408.346 183.445 409.64 183.445C410.923 183.445 411.971 182.398 411.971 181.115C411.971 179.833 410.923 178.785 409.64 178.785Z"
            fill="#ADADAD"
          />
          <path
            d="M409.64 185.241C408.357 185.241 407.298 186.289 407.298 187.572C407.298 188.865 408.346 189.912 409.64 189.912C410.923 189.912 411.971 188.865 411.971 187.572C411.971 186.289 410.923 185.241 409.64 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M403.182 127.17C401.899 127.17 400.851 128.217 400.851 129.5C400.851 130.793 401.899 131.841 403.182 131.841C404.466 131.841 405.514 130.793 405.514 129.5C405.514 128.217 404.466 127.17 403.182 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M403.182 133.615C401.899 133.615 400.851 134.662 400.851 135.945C400.851 137.228 401.899 138.275 403.182 138.275C404.466 138.275 405.514 137.228 405.514 135.945C405.514 134.662 404.466 133.615 403.182 133.615Z"
            fill="#ADADAD"
          />
          <path
            d="M403.182 140.071C401.899 140.071 400.851 141.119 400.851 142.401C400.851 143.684 401.899 144.731 403.182 144.731C404.466 144.731 405.514 143.684 405.514 142.401C405.514 141.119 404.466 140.071 403.182 140.071Z"
            fill="#ADADAD"
          />
          <path
            d="M403.182 146.527C401.899 146.527 400.851 147.574 400.851 148.857C400.851 150.14 401.899 151.187 403.182 151.187C404.466 151.187 405.514 150.14 405.514 148.857C405.514 147.574 404.466 146.527 403.182 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M403.182 152.983C401.899 152.983 400.851 154.031 400.851 155.313C400.851 156.596 401.899 157.654 403.182 157.654C404.466 157.654 405.514 156.607 405.514 155.313C405.514 154.031 404.466 152.983 403.182 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M403.182 159.428C401.899 159.428 400.851 160.476 400.851 161.758C400.851 163.052 401.899 164.099 403.182 164.099C404.466 164.099 405.514 163.052 405.514 161.758C405.514 160.476 404.466 159.428 403.182 159.428Z"
            fill="#ADADAD"
          />
          <path
            d="M403.182 165.884C401.899 165.884 400.851 166.932 400.851 168.215C400.851 169.497 401.899 170.545 403.182 170.545C404.466 170.545 405.514 169.497 405.514 168.215C405.514 166.932 404.466 165.884 403.182 165.884Z"
            fill="#ADADAD"
          />
          <path
            d="M403.182 172.34C401.899 172.34 400.851 173.388 400.851 174.681C400.851 175.963 401.899 177.011 403.182 177.011C404.466 177.011 405.514 175.963 405.514 174.681C405.514 173.388 404.466 172.34 403.182 172.34Z"
            fill="#ADADAD"
          />
          <path
            d="M403.182 178.786C401.899 178.786 400.851 179.833 400.851 181.116C400.851 182.398 401.899 183.446 403.182 183.446C404.466 183.446 405.514 182.398 405.514 181.116C405.514 179.833 404.466 178.786 403.182 178.786Z"
            fill="#ADADAD"
          />
          <path
            d="M403.182 185.241C401.899 185.241 400.851 186.289 400.851 187.571C400.851 188.865 401.899 189.912 403.182 189.912C404.466 189.912 405.514 188.865 405.514 187.571C405.514 186.289 404.466 185.241 403.182 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M396.721 133.616C395.438 133.616 394.379 134.663 394.379 135.946C394.379 137.228 395.427 138.276 396.721 138.276C398.004 138.276 399.052 137.228 399.052 135.946C399.063 134.663 398.015 133.616 396.721 133.616Z"
            fill="#ADADAD"
          />
          <path
            d="M396.721 140.072C395.438 140.072 394.379 141.119 394.379 142.402C394.379 143.685 395.427 144.732 396.721 144.732C398.004 144.732 399.052 143.685 399.052 142.402C399.063 141.119 398.015 140.072 396.721 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M396.721 146.527C395.438 146.527 394.379 147.574 394.379 148.857C394.379 150.139 395.427 151.187 396.721 151.187C398.004 151.187 399.052 150.139 399.052 148.857C399.052 147.574 398.015 146.527 396.721 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M396.721 152.983C395.438 152.983 394.379 154.03 394.379 155.313C394.379 156.596 395.427 157.654 396.721 157.654C398.004 157.654 399.052 156.606 399.052 155.313C399.063 154.03 398.015 152.983 396.721 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M396.721 159.429C395.438 159.429 394.379 160.476 394.379 161.759C394.379 163.052 395.427 164.099 396.721 164.099C398.004 164.099 399.052 163.052 399.052 161.759C399.063 160.476 398.015 159.429 396.721 159.429Z"
            fill="#ADADAD"
          />
          <path
            d="M396.721 172.339C395.438 172.339 394.379 173.387 394.379 174.68C394.379 175.963 395.427 177.01 396.721 177.01C398.004 177.01 399.052 175.963 399.052 174.68C399.063 173.387 398.015 172.339 396.721 172.339Z"
            fill="#ADADAD"
          />
          <path
            d="M396.721 178.785C395.438 178.785 394.379 179.833 394.379 181.115C394.379 182.398 395.427 183.445 396.721 183.445C398.004 183.445 399.052 182.398 399.052 181.115C399.063 179.833 398.015 178.785 396.721 178.785Z"
            fill="#ADADAD"
          />
          <path
            d="M396.721 185.241C395.438 185.241 394.379 186.289 394.379 187.572C394.379 188.865 395.427 189.912 396.721 189.912C398.004 189.912 399.052 188.865 399.052 187.572C399.063 186.289 398.015 185.241 396.721 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M390.275 140.072C388.991 140.072 387.943 141.119 387.943 142.402C387.943 143.685 388.991 144.732 390.275 144.732C391.558 144.732 392.606 143.685 392.606 142.402C392.606 141.119 391.558 140.072 390.275 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M390.275 146.527C388.991 146.527 387.943 147.574 387.943 148.857C387.943 150.139 388.991 151.187 390.275 151.187C391.558 151.187 392.606 150.139 392.606 148.857C392.606 147.574 391.558 146.527 390.275 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M390.275 152.983C388.991 152.983 387.943 154.03 387.943 155.313C387.943 156.596 388.991 157.654 390.275 157.654C391.558 157.654 392.606 156.606 392.606 155.313C392.606 154.03 391.558 152.983 390.275 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M390.275 159.429C388.991 159.429 387.943 160.476 387.943 161.759C387.943 163.052 388.991 164.099 390.275 164.099C391.558 164.099 392.606 163.052 392.606 161.759C392.606 160.476 391.558 159.429 390.275 159.429Z"
            fill="#ADADAD"
          />
          <path
            d="M390.275 165.885C388.991 165.885 387.943 166.932 387.943 168.215C387.943 169.497 388.991 170.545 390.275 170.545C391.558 170.545 392.606 169.497 392.606 168.215C392.606 166.932 391.558 165.885 390.275 165.885Z"
            fill="#ADADAD"
          />
          <path
            d="M390.275 172.339C388.991 172.339 387.943 173.387 387.943 174.68C387.943 175.963 388.991 177.01 390.275 177.01C391.558 177.01 392.606 175.963 392.606 174.68C392.606 173.387 391.558 172.339 390.275 172.339Z"
            fill="#ADADAD"
          />
          <path
            d="M390.275 178.785C388.991 178.785 387.943 179.833 387.943 181.115C387.943 182.398 388.991 183.445 390.275 183.445C391.558 183.445 392.606 182.398 392.606 181.115C392.606 179.833 391.558 178.785 390.275 178.785Z"
            fill="#ADADAD"
          />
          <path
            d="M390.275 185.241C388.991 185.241 387.943 186.289 387.943 187.572C387.943 188.865 388.991 189.912 390.275 189.912C391.558 189.912 392.606 188.865 392.606 187.572C392.606 186.289 391.558 185.241 390.275 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M390.275 191.698C388.991 191.698 387.943 192.745 387.943 194.028C387.943 195.31 388.991 196.358 390.275 196.358C391.558 196.358 392.606 195.31 392.606 194.028C392.606 192.745 391.558 191.698 390.275 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M383.813 146.527C382.53 146.527 381.482 147.574 381.482 148.857C381.482 150.14 382.53 151.187 383.813 151.187C385.107 151.187 386.155 150.14 386.155 148.857C386.155 147.574 385.096 146.527 383.813 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M383.813 152.983C382.53 152.983 381.482 154.031 381.482 155.313C381.482 156.596 382.53 157.654 383.813 157.654C385.107 157.654 386.155 156.607 386.155 155.313C386.155 154.031 385.096 152.983 383.813 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M383.813 159.428C382.53 159.428 381.482 160.476 381.482 161.758C381.482 163.052 382.53 164.099 383.813 164.099C385.107 164.099 386.155 163.052 386.155 161.758C386.155 160.476 385.096 159.428 383.813 159.428Z"
            fill="#ADADAD"
          />
          <path
            d="M383.813 178.786C382.53 178.786 381.482 179.833 381.482 181.116C381.482 182.398 382.53 183.446 383.813 183.446C385.107 183.446 386.155 182.398 386.155 181.116C386.155 179.833 385.096 178.786 383.813 178.786Z"
            fill="#ADADAD"
          />
          <path
            d="M383.813 185.241C382.53 185.241 381.482 186.289 381.482 187.571C381.482 188.865 382.53 189.912 383.813 189.912C385.107 189.912 386.155 188.865 386.155 187.571C386.155 186.289 385.096 185.241 383.813 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M383.813 191.698C382.53 191.698 381.482 192.745 381.482 194.028C381.482 195.31 382.53 196.358 383.813 196.358C385.107 196.358 386.155 195.31 386.155 194.028C386.155 192.745 385.096 191.698 383.813 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M383.813 198.153C382.53 198.153 381.482 199.201 381.482 200.483C381.482 201.766 382.53 202.813 383.813 202.813C385.107 202.813 386.155 201.766 386.155 200.483C386.155 199.201 385.096 198.153 383.813 198.153Z"
            fill="#ADADAD"
          />
          <path
            d="M377.353 185.241C376.07 185.241 375.022 186.289 375.022 187.572C375.022 188.865 376.07 189.912 377.353 189.912C378.636 189.912 379.684 188.865 379.684 187.572C379.695 186.289 378.647 185.241 377.353 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M377.353 191.698C376.07 191.698 375.022 192.745 375.022 194.028C375.022 195.31 376.07 196.358 377.353 196.358C378.636 196.358 379.684 195.31 379.684 194.028C379.695 192.745 378.647 191.698 377.353 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M377.353 198.154C376.07 198.154 375.022 199.201 375.022 200.484C375.022 201.767 376.07 202.814 377.353 202.814C378.636 202.814 379.684 201.767 379.684 200.484C379.695 199.201 378.647 198.154 377.353 198.154Z"
            fill="#ADADAD"
          />
          <path
            d="M377.353 204.598C376.07 204.598 375.022 205.646 375.022 206.928C375.022 208.211 376.07 209.258 377.353 209.258C378.636 209.258 379.684 208.211 379.684 206.928C379.695 205.646 378.647 204.598 377.353 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M370.905 185.241C369.622 185.241 368.574 186.289 368.574 187.572C368.574 188.865 369.622 189.912 370.905 189.912C372.189 189.912 373.237 188.865 373.237 187.572C373.237 186.289 372.189 185.241 370.905 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M370.905 191.698C369.622 191.698 368.574 192.745 368.574 194.028C368.574 195.31 369.622 196.358 370.905 196.358C372.189 196.358 373.237 195.31 373.237 194.028C373.237 192.745 372.189 191.698 370.905 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M364.445 191.698C363.151 191.698 362.103 192.745 362.103 194.028C362.103 195.31 363.151 196.358 364.445 196.358C365.739 196.358 366.787 195.31 366.787 194.028C366.787 192.745 365.739 191.698 364.445 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M357.999 191.698C356.715 191.698 355.667 192.745 355.667 194.028C355.667 195.31 356.715 196.358 357.999 196.358C359.282 196.358 360.33 195.31 360.33 194.028C360.33 192.745 359.282 191.698 357.999 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M370.905 198.154C369.622 198.154 368.574 199.201 368.574 200.484C368.574 201.767 369.622 202.814 370.905 202.814C372.189 202.814 373.237 201.767 373.237 200.484C373.237 199.201 372.189 198.154 370.905 198.154Z"
            fill="#ADADAD"
          />
          <path
            d="M370.905 204.598C369.622 204.598 368.574 205.646 368.574 206.928C368.574 208.211 369.622 209.258 370.905 209.258C372.189 209.258 373.237 208.211 373.237 206.928C373.237 205.646 372.189 204.598 370.905 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M370.905 211.054C369.622 211.054 368.574 212.102 368.574 213.395C368.574 214.678 369.622 215.725 370.905 215.725C372.189 215.725 373.237 214.678 373.237 213.395C373.237 212.102 372.189 211.054 370.905 211.054Z"
            fill="#ADADAD"
          />
          <path
            d="M370.905 275.582C369.622 275.582 368.574 276.63 368.574 277.923C368.574 279.206 369.622 280.253 370.905 280.253C372.189 280.253 373.237 279.206 373.237 277.923C373.237 276.63 372.189 275.582 370.905 275.582Z"
            fill="#ADADAD"
          />
          <path
            d="M370.905 282.038C369.622 282.038 368.574 283.086 368.574 284.368C368.574 285.651 369.622 286.699 370.905 286.699C372.189 286.699 373.237 285.651 373.237 284.368C373.237 283.086 372.189 282.038 370.905 282.038Z"
            fill="#ADADAD"
          />
          <path
            d="M370.905 288.493C369.622 288.493 368.574 289.54 368.574 290.834C368.574 292.116 369.622 293.164 370.905 293.164C372.189 293.164 373.237 292.116 373.237 290.834C373.237 289.54 372.189 288.493 370.905 288.493Z"
            fill="#ADADAD"
          />
          <path
            d="M364.446 275.582C363.163 275.582 362.115 276.63 362.115 277.923C362.115 279.206 363.163 280.253 364.446 280.253C365.729 280.253 366.777 279.206 366.777 277.923C366.777 276.63 365.729 275.582 364.446 275.582Z"
            fill="#ADADAD"
          />
          <path
            d="M364.446 282.038C363.163 282.038 362.115 283.086 362.115 284.368C362.115 285.651 363.163 286.699 364.446 286.699C365.729 286.699 366.777 285.651 366.777 284.368C366.777 283.086 365.729 282.038 364.446 282.038Z"
            fill="#ADADAD"
          />
          <path
            d="M364.446 288.493C363.163 288.493 362.115 289.54 362.115 290.834C362.115 292.116 363.163 293.164 364.446 293.164C365.729 293.164 366.777 292.116 366.777 290.834C366.777 289.54 365.729 288.493 364.446 288.493Z"
            fill="#ADADAD"
          />
          <path
            d="M357.986 275.582C356.703 275.582 355.654 276.629 355.654 277.922C355.654 279.205 356.703 280.253 357.986 280.253C359.269 280.253 360.317 279.205 360.317 277.922C360.317 276.629 359.28 275.582 357.986 275.582Z"
            fill="#ADADAD"
          />
          <path
            d="M357.986 282.038C356.703 282.038 355.654 283.085 355.654 284.368C355.654 285.651 356.703 286.698 357.986 286.698C359.269 286.698 360.317 285.651 360.317 284.368C360.317 283.085 359.28 282.038 357.986 282.038Z"
            fill="#ADADAD"
          />
          <path
            d="M357.986 288.493C356.703 288.493 355.654 289.541 355.654 290.834C355.654 292.117 356.703 293.164 357.986 293.164C359.269 293.164 360.317 292.117 360.317 290.834C360.317 289.541 359.28 288.493 357.986 288.493Z"
            fill="#ADADAD"
          />
          <path
            d="M357.986 294.939C356.703 294.939 355.654 295.987 355.654 297.28C355.654 298.563 356.703 299.61 357.986 299.61C359.269 299.61 360.317 298.563 360.317 297.28C360.317 295.987 359.28 294.939 357.986 294.939Z"
            fill="#ADADAD"
          />
          <path
            d="M364.446 204.598C363.163 204.598 362.115 205.646 362.115 206.928C362.115 208.211 363.163 209.258 364.446 209.258C365.729 209.258 366.777 208.211 366.777 206.928C366.777 205.646 365.729 204.598 364.446 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M364.446 211.054C363.163 211.054 362.115 212.102 362.115 213.395C362.115 214.678 363.163 215.725 364.446 215.725C365.729 215.725 366.777 214.678 366.777 213.395C366.777 212.102 365.729 211.054 364.446 211.054Z"
            fill="#ADADAD"
          />
          <path
            d="M364.446 217.51C363.163 217.51 362.115 218.558 362.115 219.841C362.115 221.123 363.163 222.181 364.446 222.181C365.729 222.181 366.777 221.134 366.777 219.841C366.777 218.558 365.729 217.51 364.446 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M357.986 204.598C356.703 204.598 355.654 205.646 355.654 206.928C355.654 208.211 356.703 209.258 357.986 209.258C359.269 209.258 360.317 208.211 360.317 206.928C360.317 205.646 359.28 204.598 357.986 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M357.986 211.055C356.703 211.055 355.654 212.102 355.654 213.395C355.654 214.678 356.703 215.725 357.986 215.725C359.269 215.725 360.317 214.678 360.317 213.395C360.317 212.102 359.28 211.055 357.986 211.055Z"
            fill="#ADADAD"
          />
          <path
            d="M357.986 217.51C356.703 217.51 355.654 218.558 355.654 219.84C355.654 221.123 356.703 222.181 357.986 222.181C359.269 222.181 360.317 221.134 360.317 219.84C360.317 218.558 359.28 217.51 357.986 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M351.527 204.598C350.243 204.598 349.195 205.646 349.195 206.928C349.195 208.211 350.243 209.258 351.527 209.258C352.81 209.258 353.858 208.211 353.858 206.928C353.869 205.646 352.821 204.598 351.527 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M351.527 211.055C350.243 211.055 349.195 212.102 349.195 213.395C349.195 214.678 350.243 215.725 351.527 215.725C352.81 215.725 353.858 214.678 353.858 213.395C353.869 212.102 352.821 211.055 351.527 211.055Z"
            fill="#ADADAD"
          />
          <path
            d="M351.527 217.51C350.243 217.51 349.195 218.558 349.195 219.84C349.195 221.123 350.243 222.181 351.527 222.181C352.81 222.181 353.858 221.134 353.858 219.84C353.869 218.558 352.821 217.51 351.527 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M512.941 204.598C511.658 204.598 510.61 205.646 510.61 206.928C510.61 208.211 511.658 209.258 512.941 209.258C514.225 209.258 515.273 208.211 515.273 206.928C515.273 205.646 514.235 204.598 512.941 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M512.941 211.055C511.658 211.055 510.61 212.102 510.61 213.395C510.61 214.678 511.658 215.725 512.941 215.725C514.225 215.725 515.273 214.678 515.273 213.395C515.273 212.102 514.235 211.055 512.941 211.055Z"
            fill="#ADADAD"
          />
          <path
            d="M512.941 217.51C511.658 217.51 510.61 218.558 510.61 219.84C510.61 221.123 511.658 222.181 512.941 222.181C514.225 222.181 515.273 221.134 515.273 219.84C515.273 218.558 514.235 217.51 512.941 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M512.941 223.966C511.658 223.966 510.61 225.014 510.61 226.297C510.61 227.579 511.658 228.627 512.941 228.627C514.225 228.627 515.273 227.579 515.273 226.297C515.273 225.003 514.235 223.966 512.941 223.966Z"
            fill="#ADADAD"
          />
          <path
            d="M512.941 230.411C511.658 230.411 510.61 231.459 510.61 232.752C510.61 234.035 511.658 235.082 512.941 235.082C514.225 235.082 515.273 234.035 515.273 232.752C515.273 231.459 514.235 230.411 512.941 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M512.941 236.868C511.658 236.868 510.61 237.915 510.61 239.208C510.61 240.491 511.658 241.539 512.941 241.539C514.225 241.539 515.273 240.491 515.273 239.208C515.273 237.915 514.235 236.868 512.941 236.868Z"
            fill="#ADADAD"
          />
          <path
            d="M512.941 243.313C511.658 243.313 510.61 244.36 510.61 245.654C510.61 246.936 511.658 247.984 512.941 247.984C514.225 247.984 515.273 246.936 515.273 245.654C515.273 244.36 514.235 243.313 512.941 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M506.493 152.983C505.209 152.983 504.151 154.031 504.151 155.313C504.151 156.596 505.199 157.654 506.493 157.654C507.776 157.654 508.824 156.607 508.824 155.313C508.824 154.031 507.776 152.983 506.493 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M506.493 159.428C505.209 159.428 504.151 160.476 504.151 161.758C504.151 163.052 505.199 164.099 506.493 164.099C507.776 164.099 508.824 163.052 508.824 161.758C508.824 160.476 507.776 159.428 506.493 159.428Z"
            fill="#ADADAD"
          />
          <path
            d="M506.493 204.598C505.209 204.598 504.151 205.646 504.151 206.928C504.151 208.211 505.199 209.258 506.493 209.258C507.776 209.258 508.824 208.211 508.824 206.928C508.824 205.646 507.776 204.598 506.493 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M506.493 211.055C505.209 211.055 504.151 212.102 504.151 213.395C504.151 214.678 505.199 215.725 506.493 215.725C507.776 215.725 508.824 214.678 508.824 213.395C508.824 212.102 507.776 211.055 506.493 211.055Z"
            fill="#ADADAD"
          />
          <path
            d="M506.493 217.51C505.209 217.51 504.151 218.558 504.151 219.84C504.151 221.123 505.199 222.181 506.493 222.181C507.776 222.181 508.824 221.134 508.824 219.84C508.824 218.558 507.776 217.51 506.493 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M506.493 223.966C505.209 223.966 504.151 225.014 504.151 226.297C504.151 227.579 505.199 228.627 506.493 228.627C507.776 228.627 508.824 227.579 508.824 226.297C508.824 225.003 507.776 223.966 506.493 223.966Z"
            fill="#ADADAD"
          />
          <path
            d="M506.493 230.411C505.209 230.411 504.151 231.459 504.151 232.752C504.151 234.035 505.199 235.082 506.493 235.082C507.776 235.082 508.824 234.035 508.824 232.752C508.824 231.459 507.776 230.411 506.493 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M506.493 236.868C505.209 236.868 504.151 237.915 504.151 239.208C504.151 240.491 505.199 241.539 506.493 241.539C507.776 241.539 508.824 240.491 508.824 239.208C508.824 237.915 507.776 236.868 506.493 236.868Z"
            fill="#ADADAD"
          />
          <path
            d="M506.493 243.313C505.209 243.313 504.151 244.36 504.151 245.654C504.151 246.936 505.199 247.984 506.493 247.984C507.776 247.984 508.824 246.936 508.824 245.654C508.824 244.36 507.776 243.313 506.493 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M500.034 152.983C498.751 152.983 497.703 154.03 497.703 155.313C497.703 156.596 498.751 157.654 500.034 157.654C501.328 157.654 502.376 156.606 502.376 155.313C502.366 154.03 501.317 152.983 500.034 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M500.034 159.429C498.751 159.429 497.703 160.476 497.703 161.759C497.703 163.052 498.751 164.099 500.034 164.099C501.328 164.099 502.376 163.052 502.376 161.759C502.366 160.476 501.317 159.429 500.034 159.429Z"
            fill="#ADADAD"
          />
          <path
            d="M500.034 165.885C498.751 165.885 497.703 166.932 497.703 168.215C497.703 169.497 498.751 170.545 500.034 170.545C501.328 170.545 502.376 169.497 502.376 168.215C502.366 166.932 501.317 165.885 500.034 165.885Z"
            fill="#ADADAD"
          />
          <path
            d="M500.034 172.339C498.751 172.339 497.703 173.387 497.703 174.68C497.703 175.963 498.751 177.01 500.034 177.01C501.328 177.01 502.376 175.963 502.376 174.68C502.366 173.387 501.317 172.339 500.034 172.339Z"
            fill="#ADADAD"
          />
          <path
            d="M500.034 178.785C498.751 178.785 497.703 179.833 497.703 181.115C497.703 182.398 498.751 183.445 500.034 183.445C501.328 183.445 502.376 182.398 502.376 181.115C502.366 179.833 501.317 178.785 500.034 178.785Z"
            fill="#ADADAD"
          />
          <path
            d="M500.034 185.241C498.751 185.241 497.703 186.289 497.703 187.572C497.703 188.865 498.751 189.912 500.034 189.912C501.328 189.912 502.376 188.865 502.376 187.572C502.366 186.289 501.317 185.241 500.034 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M500.034 191.698C498.751 191.698 497.703 192.745 497.703 194.028C497.703 195.31 498.751 196.358 500.034 196.358C501.328 196.358 502.376 195.31 502.376 194.028C502.366 192.745 501.317 191.698 500.034 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M500.034 198.154C498.751 198.154 497.703 199.201 497.703 200.484C497.703 201.767 498.751 202.814 500.034 202.814C501.328 202.814 502.376 201.767 502.376 200.484C502.366 199.201 501.317 198.154 500.034 198.154Z"
            fill="#ADADAD"
          />
          <path
            d="M500.034 204.598C498.751 204.598 497.703 205.646 497.703 206.928C497.703 208.211 498.751 209.258 500.034 209.258C501.328 209.258 502.376 208.211 502.376 206.928C502.366 205.646 501.317 204.598 500.034 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M500.034 211.054C498.751 211.054 497.703 212.102 497.703 213.395C497.703 214.678 498.751 215.725 500.034 215.725C501.328 215.725 502.376 214.678 502.376 213.395C502.366 212.102 501.317 211.054 500.034 211.054Z"
            fill="#ADADAD"
          />
          <path
            d="M500.034 217.51C498.751 217.51 497.703 218.558 497.703 219.841C497.703 221.123 498.751 222.181 500.034 222.181C501.328 222.181 502.376 221.134 502.376 219.841C502.366 218.558 501.317 217.51 500.034 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M493.575 152.983C492.291 152.983 491.243 154.03 491.243 155.313C491.243 156.596 492.291 157.654 493.575 157.654C494.869 157.654 495.917 156.606 495.917 155.313C495.917 154.03 494.858 152.983 493.575 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M493.575 159.429C492.291 159.429 491.243 160.476 491.243 161.759C491.243 163.052 492.291 164.099 493.575 164.099C494.869 164.099 495.917 163.052 495.917 161.759C495.917 160.476 494.858 159.429 493.575 159.429Z"
            fill="#ADADAD"
          />
          <path
            d="M493.575 165.885C492.291 165.885 491.243 166.932 491.243 168.215C491.243 169.497 492.291 170.545 493.575 170.545C494.869 170.545 495.917 169.497 495.917 168.215C495.917 166.932 494.858 165.885 493.575 165.885Z"
            fill="#ADADAD"
          />
          <path
            d="M493.575 172.339C492.291 172.339 491.243 173.387 491.243 174.68C491.243 175.963 492.291 177.01 493.575 177.01C494.869 177.01 495.917 175.963 495.917 174.68C495.917 173.387 494.858 172.339 493.575 172.339Z"
            fill="#ADADAD"
          />
          <path
            d="M493.575 178.785C492.291 178.785 491.243 179.833 491.243 181.115C491.243 182.398 492.291 183.445 493.575 183.445C494.869 183.445 495.917 182.398 495.917 181.115C495.917 179.833 494.858 178.785 493.575 178.785Z"
            fill="#ADADAD"
          />
          <path
            d="M493.575 185.241C492.291 185.241 491.243 186.289 491.243 187.572C491.243 188.865 492.291 189.912 493.575 189.912C494.869 189.912 495.917 188.865 495.917 187.572C495.917 186.289 494.858 185.241 493.575 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M493.575 191.698C492.291 191.698 491.243 192.745 491.243 194.028C491.243 195.31 492.291 196.358 493.575 196.358C494.869 196.358 495.917 195.31 495.917 194.028C495.917 192.745 494.858 191.698 493.575 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M493.575 198.154C492.291 198.154 491.243 199.201 491.243 200.484C491.243 201.767 492.291 202.814 493.575 202.814C494.869 202.814 495.917 201.767 495.917 200.484C495.917 199.201 494.858 198.154 493.575 198.154Z"
            fill="#ADADAD"
          />
          <path
            d="M493.575 256.225C492.291 256.225 491.243 257.273 491.243 258.566C491.243 259.849 492.291 260.896 493.575 260.896C494.869 260.896 495.917 259.849 495.917 258.566C495.917 257.273 494.858 256.225 493.575 256.225Z"
            fill="#ADADAD"
          />
          <path
            d="M487.124 152.983C485.841 152.983 484.793 154.031 484.793 155.313C484.793 156.596 485.841 157.654 487.124 157.654C488.408 157.654 489.456 156.607 489.456 155.313C489.456 154.031 488.408 152.983 487.124 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M487.124 159.428C485.841 159.428 484.793 160.476 484.793 161.758C484.793 163.052 485.841 164.099 487.124 164.099C488.408 164.099 489.456 163.052 489.456 161.758C489.456 160.476 488.408 159.428 487.124 159.428Z"
            fill="#ADADAD"
          />
          <path
            d="M487.124 165.884C485.841 165.884 484.793 166.932 484.793 168.215C484.793 169.497 485.841 170.545 487.124 170.545C488.408 170.545 489.456 169.497 489.456 168.215C489.456 166.932 488.408 165.884 487.124 165.884Z"
            fill="#ADADAD"
          />
          <path
            d="M487.124 172.34C485.841 172.34 484.793 173.388 484.793 174.681C484.793 175.963 485.841 177.011 487.124 177.011C488.408 177.011 489.456 175.963 489.456 174.681C489.456 173.388 488.408 172.34 487.124 172.34Z"
            fill="#ADADAD"
          />
          <path
            d="M487.124 178.786C485.841 178.786 484.793 179.833 484.793 181.116C484.793 182.398 485.841 183.446 487.124 183.446C488.408 183.446 489.456 182.398 489.456 181.116C489.456 179.833 488.408 178.786 487.124 178.786Z"
            fill="#ADADAD"
          />
          <path
            d="M487.124 185.241C485.841 185.241 484.793 186.289 484.793 187.571C484.793 188.865 485.841 189.912 487.124 189.912C488.408 189.912 489.456 188.865 489.456 187.571C489.456 186.289 488.408 185.241 487.124 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M487.124 191.698C485.841 191.698 484.793 192.745 484.793 194.028C484.793 195.31 485.841 196.358 487.124 196.358C488.408 196.358 489.456 195.31 489.456 194.028C489.456 192.745 488.408 191.698 487.124 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M487.124 198.153C485.841 198.153 484.793 199.201 484.793 200.483C484.793 201.766 485.841 202.813 487.124 202.813C488.408 202.813 489.456 201.766 489.456 200.483C489.456 199.201 488.408 198.153 487.124 198.153Z"
            fill="#ADADAD"
          />
          <path
            d="M487.124 256.225C485.841 256.225 484.793 257.272 484.793 258.565C484.793 259.848 485.841 260.896 487.124 260.896C488.408 260.896 489.456 259.848 489.456 258.565C489.456 257.272 488.408 256.225 487.124 256.225Z"
            fill="#ADADAD"
          />
          <path
            d="M487.124 262.68C485.841 262.68 484.793 263.728 484.793 265.01C484.793 266.293 485.841 267.341 487.124 267.341C488.408 267.341 489.456 266.293 489.456 265.01C489.456 263.728 488.408 262.68 487.124 262.68Z"
            fill="#ADADAD"
          />
          <path
            d="M480.654 152.983C479.37 152.983 478.322 154.031 478.322 155.313C478.322 156.596 479.37 157.654 480.654 157.654C481.937 157.654 482.996 156.607 482.996 155.313C482.996 154.031 481.948 152.983 480.654 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M480.654 159.428C479.37 159.428 478.322 160.476 478.322 161.758C478.322 163.052 479.37 164.099 480.654 164.099C481.937 164.099 482.996 163.052 482.996 161.758C482.996 160.476 481.948 159.428 480.654 159.428Z"
            fill="#ADADAD"
          />
          <path
            d="M480.654 165.884C479.37 165.884 478.322 166.932 478.322 168.215C478.322 169.497 479.37 170.545 480.654 170.545C481.937 170.545 482.996 169.497 482.996 168.215C482.996 166.932 481.948 165.884 480.654 165.884Z"
            fill="#ADADAD"
          />
          <path
            d="M480.654 172.34C479.37 172.34 478.322 173.388 478.322 174.681C478.322 175.963 479.37 177.011 480.654 177.011C481.937 177.011 482.996 175.963 482.996 174.681C482.996 173.388 481.948 172.34 480.654 172.34Z"
            fill="#ADADAD"
          />
          <path
            d="M480.654 178.786C479.37 178.786 478.322 179.833 478.322 181.116C478.322 182.398 479.37 183.446 480.654 183.446C481.937 183.446 482.996 182.398 482.996 181.116C482.996 179.833 481.948 178.786 480.654 178.786Z"
            fill="#ADADAD"
          />
          <path
            d="M480.654 185.241C479.37 185.241 478.322 186.289 478.322 187.571C478.322 188.865 479.37 189.912 480.654 189.912C481.937 189.912 482.996 188.865 482.996 187.571C482.996 186.289 481.948 185.241 480.654 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M480.654 191.698C479.37 191.698 478.322 192.745 478.322 194.028C478.322 195.31 479.37 196.358 480.654 196.358C481.937 196.358 482.996 195.31 482.996 194.028C482.996 192.745 481.948 191.698 480.654 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M480.654 198.153C479.37 198.153 478.322 199.201 478.322 200.483C478.322 201.766 479.37 202.813 480.654 202.813C481.937 202.813 482.996 201.766 482.996 200.483C482.996 199.201 481.948 198.153 480.654 198.153Z"
            fill="#ADADAD"
          />
          <path
            d="M480.654 249.768C479.37 249.768 478.322 250.816 478.322 252.098C478.322 253.381 479.37 254.429 480.654 254.429C481.937 254.429 482.996 253.381 482.996 252.098C482.996 250.816 481.948 249.768 480.654 249.768Z"
            fill="#ADADAD"
          />
          <path
            d="M480.654 256.225C479.37 256.225 478.322 257.272 478.322 258.565C478.322 259.848 479.37 260.896 480.654 260.896C481.937 260.896 482.996 259.848 482.996 258.565C482.996 257.272 481.948 256.225 480.654 256.225Z"
            fill="#ADADAD"
          />
          <path
            d="M480.654 262.68C479.37 262.68 478.322 263.728 478.322 265.01C478.322 266.293 479.37 267.341 480.654 267.341C481.937 267.341 482.996 266.293 482.996 265.01C482.996 263.728 481.948 262.68 480.654 262.68Z"
            fill="#ADADAD"
          />
          <path
            d="M480.654 269.126C479.37 269.126 478.322 270.173 478.322 271.456C478.322 272.739 479.37 273.786 480.654 273.786C481.937 273.786 482.996 272.739 482.996 271.456C482.996 270.173 481.948 269.126 480.654 269.126Z"
            fill="#ADADAD"
          />
          <path
            d="M474.206 152.983C472.922 152.983 471.874 154.031 471.874 155.313C471.874 156.596 472.922 157.654 474.206 157.654C475.489 157.654 476.537 156.607 476.537 155.313C476.537 154.031 475.489 152.983 474.206 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M474.206 159.428C472.922 159.428 471.874 160.476 471.874 161.758C471.874 163.052 472.922 164.099 474.206 164.099C475.489 164.099 476.537 163.052 476.537 161.758C476.537 160.476 475.489 159.428 474.206 159.428Z"
            fill="#ADADAD"
          />
          <path
            d="M474.206 165.884C472.922 165.884 471.874 166.932 471.874 168.215C471.874 169.497 472.922 170.545 474.206 170.545C475.489 170.545 476.537 169.497 476.537 168.215C476.537 166.932 475.489 165.884 474.206 165.884Z"
            fill="#ADADAD"
          />
          <path
            d="M474.206 172.34C472.922 172.34 471.874 173.388 471.874 174.681C471.874 175.963 472.922 177.011 474.206 177.011C475.489 177.011 476.537 175.963 476.537 174.681C476.537 173.388 475.489 172.34 474.206 172.34Z"
            fill="#ADADAD"
          />
          <path
            d="M474.206 178.786C472.922 178.786 471.874 179.833 471.874 181.116C471.874 182.398 472.922 183.446 474.206 183.446C475.489 183.446 476.537 182.398 476.537 181.116C476.537 179.833 475.489 178.786 474.206 178.786Z"
            fill="#ADADAD"
          />
          <path
            d="M474.206 185.241C472.922 185.241 471.874 186.289 471.874 187.571C471.874 188.865 472.922 189.912 474.206 189.912C475.489 189.912 476.537 188.865 476.537 187.571C476.537 186.289 475.489 185.241 474.206 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M474.206 191.698C472.922 191.698 471.874 192.745 471.874 194.028C471.874 195.31 472.922 196.358 474.206 196.358C475.489 196.358 476.537 195.31 476.537 194.028C476.537 192.745 475.489 191.698 474.206 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M474.206 198.153C472.922 198.153 471.874 199.201 471.874 200.483C471.874 201.766 472.922 202.813 474.206 202.813C475.489 202.813 476.537 201.766 476.537 200.483C476.537 199.201 475.489 198.153 474.206 198.153Z"
            fill="#ADADAD"
          />
          <path
            d="M474.206 204.598C472.922 204.598 471.874 205.646 471.874 206.928C471.874 208.211 472.922 209.258 474.206 209.258C475.489 209.258 476.537 208.211 476.537 206.928C476.537 205.646 475.489 204.598 474.206 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M474.206 211.055C472.922 211.055 471.874 212.102 471.874 213.395C471.874 214.678 472.922 215.725 474.206 215.725C475.489 215.725 476.537 214.678 476.537 213.395C476.537 212.102 475.489 211.055 474.206 211.055Z"
            fill="#ADADAD"
          />
          <path
            d="M474.206 236.868C472.922 236.868 471.874 237.915 471.874 239.208C471.874 240.491 472.922 241.539 474.206 241.539C475.489 241.539 476.537 240.491 476.537 239.208C476.537 237.915 475.489 236.868 474.206 236.868Z"
            fill="#ADADAD"
          />
          <path
            d="M474.206 243.313C472.922 243.313 471.874 244.36 471.874 245.654C471.874 246.936 472.922 247.984 474.206 247.984C475.489 247.984 476.537 246.936 476.537 245.654C476.537 244.36 475.489 243.313 474.206 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M474.206 249.768C472.922 249.768 471.874 250.816 471.874 252.098C471.874 253.381 472.922 254.429 474.206 254.429C475.489 254.429 476.537 253.381 476.537 252.098C476.537 250.816 475.489 249.768 474.206 249.768Z"
            fill="#ADADAD"
          />
          <path
            d="M474.206 256.225C472.922 256.225 471.874 257.272 471.874 258.565C471.874 259.848 472.922 260.896 474.206 260.896C475.489 260.896 476.537 259.848 476.537 258.565C476.537 257.272 475.489 256.225 474.206 256.225Z"
            fill="#ADADAD"
          />
          <path
            d="M474.206 262.68C472.922 262.68 471.874 263.728 471.874 265.01C471.874 266.293 472.922 267.341 474.206 267.341C475.489 267.341 476.537 266.293 476.537 265.01C476.537 263.728 475.489 262.68 474.206 262.68Z"
            fill="#ADADAD"
          />
          <path
            d="M474.206 269.126C472.922 269.126 471.874 270.173 471.874 271.456C471.874 272.739 472.922 273.786 474.206 273.786C475.489 273.786 476.537 272.739 476.537 271.456C476.537 270.173 475.489 269.126 474.206 269.126Z"
            fill="#ADADAD"
          />
          <path
            d="M467.746 152.983C466.463 152.983 465.415 154.03 465.415 155.313C465.415 156.596 466.463 157.654 467.746 157.654C469.029 157.654 470.077 156.606 470.077 155.313C470.088 154.03 469.04 152.983 467.746 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M467.746 159.429C466.463 159.429 465.415 160.476 465.415 161.759C465.415 163.052 466.463 164.099 467.746 164.099C469.029 164.099 470.077 163.052 470.077 161.759C470.088 160.476 469.04 159.429 467.746 159.429Z"
            fill="#ADADAD"
          />
          <path
            d="M467.746 165.885C466.463 165.885 465.415 166.932 465.415 168.215C465.415 169.497 466.463 170.545 467.746 170.545C469.029 170.545 470.077 169.497 470.077 168.215C470.088 166.932 469.04 165.885 467.746 165.885Z"
            fill="#ADADAD"
          />
          <path
            d="M467.746 172.339C466.463 172.339 465.415 173.387 465.415 174.68C465.415 175.963 466.463 177.01 467.746 177.01C469.029 177.01 470.077 175.963 470.077 174.68C470.088 173.387 469.04 172.339 467.746 172.339Z"
            fill="#ADADAD"
          />
          <path
            d="M467.746 178.785C466.463 178.785 465.415 179.833 465.415 181.115C465.415 182.398 466.463 183.445 467.746 183.445C469.029 183.445 470.077 182.398 470.077 181.115C470.088 179.833 469.04 178.785 467.746 178.785Z"
            fill="#ADADAD"
          />
          <path
            d="M467.746 185.241C466.463 185.241 465.415 186.289 465.415 187.572C465.415 188.865 466.463 189.912 467.746 189.912C469.029 189.912 470.077 188.865 470.077 187.572C470.088 186.289 469.04 185.241 467.746 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M467.746 191.698C466.463 191.698 465.415 192.745 465.415 194.028C465.415 195.31 466.463 196.358 467.746 196.358C469.029 196.358 470.077 195.31 470.077 194.028C470.088 192.745 469.04 191.698 467.746 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M467.746 198.154C466.463 198.154 465.415 199.201 465.415 200.484C465.415 201.767 466.463 202.814 467.746 202.814C469.029 202.814 470.077 201.767 470.077 200.484C470.088 199.201 469.04 198.154 467.746 198.154Z"
            fill="#ADADAD"
          />
          <path
            d="M467.746 204.598C466.463 204.598 465.415 205.646 465.415 206.928C465.415 208.211 466.463 209.258 467.746 209.258C469.029 209.258 470.077 208.211 470.077 206.928C470.088 205.646 469.04 204.598 467.746 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M467.746 211.054C466.463 211.054 465.415 212.102 465.415 213.395C465.415 214.678 466.463 215.725 467.746 215.725C469.029 215.725 470.077 214.678 470.077 213.395C470.088 212.102 469.04 211.054 467.746 211.054Z"
            fill="#ADADAD"
          />
          <path
            d="M467.746 236.867C466.463 236.867 465.415 237.915 465.415 239.208C465.415 240.491 466.463 241.538 467.746 241.538C469.029 241.538 470.077 240.491 470.077 239.208C470.088 237.915 469.04 236.867 467.746 236.867Z"
            fill="#ADADAD"
          />
          <path
            d="M467.746 243.313C466.463 243.313 465.415 244.36 465.415 245.654C465.415 246.936 466.463 247.984 467.746 247.984C469.029 247.984 470.077 246.936 470.077 245.654C470.088 244.36 469.04 243.313 467.746 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M467.746 249.768C466.463 249.768 465.415 250.815 465.415 252.098C465.415 253.38 466.463 254.428 467.746 254.428C469.029 254.428 470.077 253.38 470.077 252.098C470.088 250.815 469.04 249.768 467.746 249.768Z"
            fill="#ADADAD"
          />
          <path
            d="M467.746 256.225C466.463 256.225 465.415 257.273 465.415 258.566C465.415 259.849 466.463 260.896 467.746 260.896C469.029 260.896 470.077 259.849 470.077 258.566C470.088 257.273 469.04 256.225 467.746 256.225Z"
            fill="#ADADAD"
          />
          <path
            d="M467.746 262.68C466.463 262.68 465.415 263.728 465.415 265.01C465.415 266.293 466.463 267.34 467.746 267.34C469.029 267.34 470.077 266.293 470.077 265.01C470.088 263.728 469.04 262.68 467.746 262.68Z"
            fill="#ADADAD"
          />
          <path
            d="M467.746 269.126C466.463 269.126 465.415 270.173 465.415 271.456C465.415 272.739 466.463 273.786 467.746 273.786C469.029 273.786 470.078 272.739 470.078 271.456C470.078 270.173 469.04 269.126 467.746 269.126Z"
            fill="#ADADAD"
          />
          <path
            d="M461.297 152.983C460.014 152.983 458.955 154.03 458.955 155.313C458.955 156.596 460.003 157.654 461.297 157.654C462.581 157.654 463.629 156.606 463.629 155.313C463.629 154.03 462.581 152.983 461.297 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M461.297 159.429C460.014 159.429 458.955 160.476 458.955 161.759C458.955 163.052 460.003 164.099 461.297 164.099C462.581 164.099 463.629 163.052 463.629 161.759C463.629 160.476 462.581 159.429 461.297 159.429Z"
            fill="#ADADAD"
          />
          <path
            d="M461.297 165.885C460.014 165.885 458.955 166.932 458.955 168.215C458.955 169.497 460.003 170.545 461.297 170.545C462.581 170.545 463.629 169.497 463.629 168.215C463.629 166.932 462.581 165.885 461.297 165.885Z"
            fill="#ADADAD"
          />
          <path
            d="M461.297 172.339C460.014 172.339 458.955 173.387 458.955 174.68C458.955 175.963 460.003 177.01 461.297 177.01C462.581 177.01 463.629 175.963 463.629 174.68C463.629 173.387 462.581 172.339 461.297 172.339Z"
            fill="#ADADAD"
          />
          <path
            d="M461.297 178.785C460.014 178.785 458.955 179.833 458.955 181.115C458.955 182.398 460.003 183.445 461.297 183.445C462.581 183.445 463.629 182.398 463.629 181.115C463.629 179.833 462.581 178.785 461.297 178.785Z"
            fill="#ADADAD"
          />
          <path
            d="M461.297 185.241C460.014 185.241 458.955 186.289 458.955 187.572C458.955 188.865 460.003 189.912 461.297 189.912C462.581 189.912 463.629 188.865 463.629 187.572C463.629 186.289 462.581 185.241 461.297 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M461.297 191.698C460.014 191.698 458.955 192.745 458.955 194.028C458.955 195.31 460.003 196.358 461.297 196.358C462.581 196.358 463.629 195.31 463.629 194.028C463.629 192.745 462.581 191.698 461.297 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M461.297 204.598C460.014 204.598 458.955 205.646 458.955 206.928C458.955 208.211 460.003 209.258 461.297 209.258C462.581 209.258 463.629 208.211 463.629 206.928C463.629 205.646 462.581 204.598 461.297 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M461.297 211.054C460.014 211.054 458.955 212.102 458.955 213.395C458.955 214.678 460.003 215.725 461.297 215.725C462.581 215.725 463.629 214.678 463.629 213.395C463.629 212.102 462.581 211.054 461.297 211.054Z"
            fill="#ADADAD"
          />
          <path
            d="M461.297 230.411C460.014 230.411 458.955 231.458 458.955 232.752C458.955 234.034 460.003 235.082 461.297 235.082C462.581 235.082 463.629 234.034 463.629 232.752C463.629 231.458 462.581 230.411 461.297 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M461.297 236.867C460.014 236.867 458.955 237.915 458.955 239.208C458.955 240.491 460.003 241.538 461.297 241.538C462.581 241.538 463.629 240.491 463.629 239.208C463.629 237.915 462.581 236.867 461.297 236.867Z"
            fill="#ADADAD"
          />
          <path
            d="M461.297 243.313C460.014 243.313 458.955 244.36 458.955 245.654C458.955 246.936 460.003 247.984 461.297 247.984C462.581 247.984 463.629 246.936 463.629 245.654C463.629 244.36 462.581 243.313 461.297 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M461.297 249.768C460.014 249.768 458.955 250.815 458.955 252.098C458.955 253.38 460.003 254.428 461.297 254.428C462.581 254.428 463.629 253.38 463.629 252.098C463.629 250.815 462.581 249.768 461.297 249.768Z"
            fill="#ADADAD"
          />
          <path
            d="M461.297 256.225C460.014 256.225 458.955 257.273 458.955 258.566C458.955 259.849 460.003 260.896 461.297 260.896C462.581 260.896 463.629 259.849 463.629 258.566C463.629 257.273 462.581 256.225 461.297 256.225Z"
            fill="#ADADAD"
          />
          <path
            d="M461.297 262.68C460.014 262.68 458.955 263.728 458.955 265.01C458.955 266.293 460.003 267.34 461.297 267.34C462.581 267.34 463.629 266.293 463.629 265.01C463.629 263.728 462.581 262.68 461.297 262.68Z"
            fill="#ADADAD"
          />
          <path
            d="M454.837 152.983C453.553 152.983 452.505 154.031 452.505 155.313C452.505 156.596 453.553 157.654 454.837 157.654C456.131 157.654 457.179 156.607 457.179 155.313C457.168 154.031 456.12 152.983 454.837 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M454.837 159.428C453.553 159.428 452.505 160.476 452.505 161.758C452.505 163.052 453.553 164.099 454.837 164.099C456.131 164.099 457.179 163.052 457.179 161.758C457.168 160.476 456.12 159.428 454.837 159.428Z"
            fill="#ADADAD"
          />
          <path
            d="M454.837 165.884C453.553 165.884 452.505 166.932 452.505 168.215C452.505 169.497 453.553 170.545 454.837 170.545C456.131 170.545 457.179 169.497 457.179 168.215C457.168 166.932 456.12 165.884 454.837 165.884Z"
            fill="#ADADAD"
          />
          <path
            d="M454.837 211.055C453.553 211.055 452.505 212.102 452.505 213.395C452.505 214.678 453.553 215.725 454.837 215.725C456.131 215.725 457.179 214.678 457.179 213.395C457.168 212.102 456.12 211.055 454.837 211.055Z"
            fill="#ADADAD"
          />
          <path
            d="M454.837 217.51C453.553 217.51 452.505 218.558 452.505 219.84C452.505 221.123 453.553 222.181 454.837 222.181C456.131 222.181 457.179 221.134 457.179 219.84C457.168 218.558 456.12 217.51 454.837 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M454.837 223.966C453.553 223.966 452.505 225.014 452.505 226.297C452.505 227.579 453.553 228.627 454.837 228.627C456.131 228.627 457.179 227.579 457.179 226.297C457.168 225.003 456.12 223.966 454.837 223.966Z"
            fill="#ADADAD"
          />
          <path
            d="M454.837 230.411C453.553 230.411 452.505 231.459 452.505 232.752C452.505 234.035 453.553 235.082 454.837 235.082C456.131 235.082 457.179 234.035 457.179 232.752C457.168 231.459 456.12 230.411 454.837 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M454.837 236.868C453.553 236.868 452.505 237.915 452.505 239.208C452.505 240.491 453.553 241.539 454.837 241.539C456.131 241.539 457.179 240.491 457.179 239.208C457.168 237.915 456.12 236.868 454.837 236.868Z"
            fill="#ADADAD"
          />
          <path
            d="M454.837 243.313C453.553 243.313 452.505 244.36 452.505 245.654C452.505 246.936 453.553 247.984 454.837 247.984C456.131 247.984 457.179 246.936 457.179 245.654C457.168 244.36 456.12 243.313 454.837 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M454.837 249.768C453.553 249.768 452.505 250.816 452.505 252.098C452.505 253.381 453.553 254.429 454.837 254.429C456.131 254.429 457.179 253.381 457.179 252.098C457.168 250.816 456.12 249.768 454.837 249.768Z"
            fill="#ADADAD"
          />
          <path
            d="M454.837 256.225C453.553 256.225 452.505 257.272 452.505 258.565C452.505 259.848 453.553 260.896 454.837 260.896C456.131 260.896 457.179 259.848 457.179 258.565C457.168 257.272 456.12 256.225 454.837 256.225Z"
            fill="#ADADAD"
          />
          <path
            d="M454.837 269.126C453.553 269.126 452.505 270.173 452.505 271.456C452.505 272.739 453.553 273.786 454.837 273.786C456.131 273.786 457.179 272.739 457.179 271.456C457.179 270.173 456.12 269.126 454.837 269.126Z"
            fill="#ADADAD"
          />
          <path
            d="M448.377 152.983C447.094 152.983 446.046 154.031 446.046 155.313C446.046 156.596 447.094 157.654 448.377 157.654C449.661 157.654 450.709 156.607 450.709 155.313C450.709 154.031 449.661 152.983 448.377 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M448.377 159.428C447.094 159.428 446.046 160.476 446.046 161.758C446.046 163.052 447.094 164.099 448.377 164.099C449.661 164.099 450.709 163.052 450.709 161.758C450.709 160.476 449.661 159.428 448.377 159.428Z"
            fill="#ADADAD"
          />
          <path
            d="M448.377 211.055C447.094 211.055 446.046 212.102 446.046 213.395C446.046 214.678 447.094 215.725 448.377 215.725C449.661 215.725 450.709 214.678 450.709 213.395C450.709 212.102 449.661 211.055 448.377 211.055Z"
            fill="#ADADAD"
          />
          <path
            d="M448.377 217.51C447.094 217.51 446.046 218.558 446.046 219.84C446.046 221.123 447.094 222.181 448.377 222.181C449.661 222.181 450.709 221.134 450.709 219.84C450.709 218.558 449.661 217.51 448.377 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M448.377 223.966C447.094 223.966 446.046 225.014 446.046 226.297C446.046 227.579 447.094 228.627 448.377 228.627C449.661 228.627 450.709 227.579 450.709 226.297C450.709 225.003 449.661 223.966 448.377 223.966Z"
            fill="#ADADAD"
          />
          <path
            d="M448.377 230.411C447.094 230.411 446.046 231.459 446.046 232.752C446.046 234.035 447.094 235.082 448.377 235.082C449.661 235.082 450.709 234.035 450.709 232.752C450.709 231.459 449.661 230.411 448.377 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M448.377 236.868C447.094 236.868 446.046 237.915 446.046 239.208C446.046 240.491 447.094 241.539 448.377 241.539C449.661 241.539 450.709 240.491 450.709 239.208C450.709 237.915 449.661 236.868 448.377 236.868Z"
            fill="#ADADAD"
          />
          <path
            d="M448.377 243.313C447.094 243.313 446.046 244.36 446.046 245.654C446.046 246.936 447.094 247.984 448.377 247.984C449.661 247.984 450.709 246.936 450.709 245.654C450.709 244.36 449.661 243.313 448.377 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M448.377 256.225C447.094 256.225 446.046 257.272 446.046 258.565C446.046 259.848 447.094 260.896 448.377 260.896C449.661 260.896 450.709 259.848 450.709 258.565C450.709 257.272 449.661 256.225 448.377 256.225Z"
            fill="#ADADAD"
          />
          <path
            d="M448.377 262.68C447.094 262.68 446.046 263.728 446.046 265.01C446.046 266.293 447.094 267.341 448.377 267.341C449.661 267.341 450.709 266.293 450.709 265.01C450.709 263.728 449.661 262.68 448.377 262.68Z"
            fill="#ADADAD"
          />
          <path
            d="M448.377 269.126C447.094 269.126 446.046 270.173 446.046 271.456C446.046 272.739 447.094 273.786 448.377 273.786C449.661 273.786 450.709 272.739 450.709 271.456C450.709 270.173 449.661 269.126 448.377 269.126Z"
            fill="#ADADAD"
          />
          <path
            d="M441.929 211.055C440.635 211.055 439.586 212.102 439.586 213.395C439.586 214.678 440.635 215.725 441.929 215.725C443.212 215.725 444.26 214.678 444.26 213.395C444.26 212.102 443.212 211.055 441.929 211.055Z"
            fill="#ADADAD"
          />
          <path
            d="M441.929 217.51C440.635 217.51 439.586 218.558 439.586 219.84C439.586 221.123 440.635 222.181 441.929 222.181C443.212 222.181 444.26 221.134 444.26 219.84C444.26 218.558 443.212 217.51 441.929 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M441.929 223.966C440.635 223.966 439.586 225.014 439.586 226.297C439.586 227.579 440.635 228.627 441.929 228.627C443.212 228.627 444.26 227.579 444.26 226.297C444.26 225.003 443.212 223.966 441.929 223.966Z"
            fill="#ADADAD"
          />
          <path
            d="M441.929 236.868C440.635 236.868 439.586 237.915 439.586 239.208C439.586 240.491 440.635 241.539 441.929 241.539C443.212 241.539 444.26 240.491 444.26 239.208C444.26 237.915 443.212 236.868 441.929 236.868Z"
            fill="#ADADAD"
          />
          <path
            d="M441.929 243.313C440.635 243.313 439.586 244.36 439.586 245.654C439.586 246.936 440.635 247.984 441.929 247.984C443.212 247.984 444.26 246.936 444.26 245.654C444.26 244.36 443.212 243.313 441.929 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M441.929 249.768C440.635 249.768 439.586 250.816 439.586 252.098C439.586 253.381 440.635 254.429 441.929 254.429C443.212 254.429 444.26 253.381 444.26 252.098C444.26 250.816 443.212 249.768 441.929 249.768Z"
            fill="#ADADAD"
          />
          <path
            d="M441.929 256.225C440.635 256.225 439.586 257.272 439.586 258.565C439.586 259.848 440.635 260.896 441.929 260.896C443.212 260.896 444.26 259.848 444.26 258.565C444.26 257.272 443.212 256.225 441.929 256.225Z"
            fill="#ADADAD"
          />
          <path
            d="M441.929 262.68C440.635 262.68 439.586 263.728 439.586 265.01C439.586 266.293 440.635 267.341 441.929 267.341C443.212 267.341 444.26 266.293 444.26 265.01C444.26 263.728 443.212 262.68 441.929 262.68Z"
            fill="#ADADAD"
          />
          <path
            d="M441.929 269.126C440.635 269.126 439.586 270.173 439.586 271.456C439.586 272.739 440.635 273.786 441.929 273.786C443.212 273.786 444.26 272.739 444.26 271.456C444.26 270.173 443.212 269.126 441.929 269.126Z"
            fill="#ADADAD"
          />
          <path
            d="M435.47 211.054C434.186 211.054 433.138 212.102 433.138 213.395C433.138 214.678 434.186 215.725 435.47 215.725C436.753 215.725 437.801 214.678 437.801 213.395C437.801 212.102 436.753 211.054 435.47 211.054Z"
            fill="#ADADAD"
          />
          <path
            d="M435.47 217.51C434.186 217.51 433.138 218.558 433.138 219.841C433.138 221.123 434.186 222.181 435.47 222.181C436.753 222.181 437.801 221.134 437.801 219.841C437.801 218.558 436.753 217.51 435.47 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M435.47 236.867C434.186 236.867 433.138 237.915 433.138 239.208C433.138 240.491 434.186 241.538 435.47 241.538C436.753 241.538 437.801 240.491 437.801 239.208C437.801 237.915 436.753 236.867 435.47 236.867Z"
            fill="#ADADAD"
          />
          <path
            d="M435.47 243.313C434.186 243.313 433.138 244.36 433.138 245.654C433.138 246.936 434.186 247.984 435.47 247.984C436.753 247.984 437.801 246.936 437.801 245.654C437.801 244.36 436.753 243.313 435.47 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M435.47 249.768C434.186 249.768 433.138 250.815 433.138 252.098C433.138 253.38 434.186 254.428 435.47 254.428C436.753 254.428 437.801 253.38 437.801 252.098C437.801 250.815 436.753 249.768 435.47 249.768Z"
            fill="#ADADAD"
          />
          <path
            d="M435.47 256.225C434.186 256.225 433.138 257.273 433.138 258.566C433.138 259.849 434.186 260.896 435.47 260.896C436.753 260.896 437.801 259.849 437.801 258.566C437.801 257.273 436.753 256.225 435.47 256.225Z"
            fill="#ADADAD"
          />
          <path
            d="M435.47 262.68C434.186 262.68 433.138 263.728 433.138 265.01C433.138 266.293 434.186 267.34 435.47 267.34C436.753 267.34 437.801 266.293 437.801 265.01C437.801 263.728 436.753 262.68 435.47 262.68Z"
            fill="#ADADAD"
          />
          <path
            d="M435.47 269.126C434.186 269.126 433.138 270.173 433.138 271.456C433.138 272.739 434.186 273.786 435.47 273.786C436.753 273.786 437.801 272.739 437.801 271.456C437.801 270.173 436.753 269.126 435.47 269.126Z"
            fill="#ADADAD"
          />
          <path
            d="M429.01 236.867C427.727 236.867 426.679 237.915 426.679 239.208C426.679 240.491 427.727 241.538 429.01 241.538C430.294 241.538 431.342 240.491 431.342 239.208C431.342 237.915 430.294 236.867 429.01 236.867Z"
            fill="#ADADAD"
          />
          <path
            d="M429.01 243.313C427.727 243.313 426.679 244.36 426.679 245.654C426.679 246.936 427.727 247.984 429.01 247.984C430.294 247.984 431.342 246.936 431.342 245.654C431.342 244.36 430.294 243.313 429.01 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M429.01 249.768C427.727 249.768 426.679 250.815 426.679 252.098C426.679 253.38 427.727 254.428 429.01 254.428C430.294 254.428 431.342 253.38 431.342 252.098C431.342 250.815 430.294 249.768 429.01 249.768Z"
            fill="#ADADAD"
          />
          <path
            d="M429.01 256.225C427.727 256.225 426.679 257.273 426.679 258.566C426.679 259.849 427.727 260.896 429.01 260.896C430.294 260.896 431.342 259.849 431.342 258.566C431.342 257.273 430.294 256.225 429.01 256.225Z"
            fill="#ADADAD"
          />
          <path
            d="M429.01 262.68C427.727 262.68 426.679 263.728 426.679 265.01C426.679 266.293 427.727 267.34 429.01 267.34C430.294 267.34 431.342 266.293 431.342 265.01C431.342 263.728 430.294 262.68 429.01 262.68Z"
            fill="#ADADAD"
          />
          <path
            d="M429.01 269.126C427.727 269.126 426.679 270.173 426.679 271.456C426.679 272.739 427.727 273.786 429.01 273.786C430.294 273.786 431.342 272.739 431.342 271.456C431.342 270.173 430.294 269.126 429.01 269.126Z"
            fill="#ADADAD"
          />
          <path
            d="M422.551 230.411C421.268 230.411 420.22 231.458 420.22 232.752C420.22 234.034 421.268 235.082 422.551 235.082C423.845 235.082 424.893 234.034 424.893 232.752C424.893 231.458 423.845 230.411 422.551 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M422.551 236.867C421.268 236.867 420.22 237.915 420.22 239.208C420.22 240.491 421.268 241.538 422.551 241.538C423.845 241.538 424.893 240.491 424.893 239.208C424.893 237.915 423.845 236.867 422.551 236.867Z"
            fill="#ADADAD"
          />
          <path
            d="M422.551 243.313C421.268 243.313 420.22 244.36 420.22 245.654C420.22 246.936 421.268 247.984 422.551 247.984C423.845 247.984 424.893 246.936 424.893 245.654C424.893 244.36 423.845 243.313 422.551 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M422.551 249.768C421.268 249.768 420.22 250.815 420.22 252.098C420.22 253.38 421.268 254.428 422.551 254.428C423.845 254.428 424.893 253.38 424.893 252.098C424.893 250.815 423.845 249.768 422.551 249.768Z"
            fill="#ADADAD"
          />
          <path
            d="M422.551 256.225C421.268 256.225 420.22 257.273 420.22 258.566C420.22 259.849 421.268 260.896 422.551 260.896C423.845 260.896 424.893 259.849 424.893 258.566C424.893 257.273 423.845 256.225 422.551 256.225Z"
            fill="#ADADAD"
          />
          <path
            d="M422.551 262.68C421.268 262.68 420.22 263.728 420.22 265.01C420.22 266.293 421.268 267.34 422.551 267.34C423.845 267.34 424.893 266.293 424.893 265.01C424.893 263.728 423.845 262.68 422.551 262.68Z"
            fill="#ADADAD"
          />
          <path
            d="M422.551 269.126C421.268 269.126 420.22 270.173 420.22 271.456C420.22 272.739 421.268 273.786 422.551 273.786C423.845 273.786 424.893 272.739 424.893 271.456C424.893 270.173 423.845 269.126 422.551 269.126Z"
            fill="#ADADAD"
          />
          <path
            d="M416.089 230.411C414.805 230.411 413.757 231.458 413.757 232.752C413.757 234.034 414.805 235.082 416.089 235.082C417.372 235.082 418.42 234.034 418.42 232.752C418.431 231.458 417.383 230.411 416.089 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M416.089 236.867C414.805 236.867 413.757 237.915 413.757 239.208C413.757 240.491 414.805 241.538 416.089 241.538C417.372 241.538 418.42 240.491 418.42 239.208C418.431 237.915 417.383 236.867 416.089 236.867Z"
            fill="#ADADAD"
          />
          <path
            d="M416.089 243.313C414.805 243.313 413.757 244.36 413.757 245.654C413.757 246.936 414.805 247.984 416.089 247.984C417.372 247.984 418.42 246.936 418.42 245.654C418.431 244.36 417.383 243.313 416.089 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M416.089 249.768C414.805 249.768 413.757 250.815 413.757 252.098C413.757 253.38 414.805 254.428 416.089 254.428C417.372 254.428 418.42 253.38 418.42 252.098C418.431 250.815 417.383 249.768 416.089 249.768Z"
            fill="#ADADAD"
          />
          <path
            d="M416.089 256.225C414.805 256.225 413.757 257.273 413.757 258.566C413.757 259.849 414.805 260.896 416.089 260.896C417.372 260.896 418.42 259.849 418.42 258.566C418.431 257.273 417.383 256.225 416.089 256.225Z"
            fill="#ADADAD"
          />
          <path
            d="M416.089 262.68C414.805 262.68 413.757 263.728 413.757 265.01C413.757 266.293 414.805 267.34 416.089 267.34C417.372 267.34 418.42 266.293 418.42 265.01C418.431 263.728 417.383 262.68 416.089 262.68Z"
            fill="#ADADAD"
          />
          <path
            d="M416.089 269.126C414.805 269.126 413.757 270.173 413.757 271.456C413.757 272.739 414.805 273.786 416.089 273.786C417.372 273.786 418.42 272.739 418.42 271.456C418.42 270.173 417.383 269.126 416.089 269.126Z"
            fill="#ADADAD"
          />
          <path
            d="M409.64 236.867C408.357 236.867 407.298 237.915 407.298 239.208C407.298 240.491 408.346 241.538 409.64 241.538C410.923 241.538 411.971 240.491 411.971 239.208C411.971 237.915 410.923 236.867 409.64 236.867Z"
            fill="#ADADAD"
          />
          <path
            d="M409.64 243.313C408.357 243.313 407.298 244.36 407.298 245.654C407.298 246.936 408.346 247.984 409.64 247.984C410.923 247.984 411.971 246.936 411.971 245.654C411.971 244.36 410.923 243.313 409.64 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M409.64 249.768C408.357 249.768 407.298 250.815 407.298 252.098C407.298 253.38 408.346 254.428 409.64 254.428C410.923 254.428 411.971 253.38 411.971 252.098C411.971 250.815 410.923 249.768 409.64 249.768Z"
            fill="#ADADAD"
          />
          <path
            d="M409.64 256.225C408.357 256.225 407.298 257.273 407.298 258.566C407.298 259.849 408.346 260.896 409.64 260.896C410.923 260.896 411.971 259.849 411.971 258.566C411.971 257.273 410.923 256.225 409.64 256.225Z"
            fill="#ADADAD"
          />
          <path
            d="M409.64 262.68C408.357 262.68 407.298 263.728 407.298 265.01C407.298 266.293 408.346 267.34 409.64 267.34C410.923 267.34 411.971 266.293 411.971 265.01C411.971 263.728 410.923 262.68 409.64 262.68Z"
            fill="#ADADAD"
          />
          <path
            d="M409.64 269.126C408.357 269.126 407.298 270.173 407.298 271.456C407.298 272.739 408.346 273.786 409.64 273.786C410.923 273.786 411.971 272.739 411.971 271.456C411.971 270.173 410.923 269.126 409.64 269.126Z"
            fill="#ADADAD"
          />
          <path
            d="M403.182 236.868C401.899 236.868 400.851 237.915 400.851 239.208C400.851 240.491 401.899 241.539 403.182 241.539C404.466 241.539 405.514 240.491 405.514 239.208C405.514 237.915 404.466 236.868 403.182 236.868Z"
            fill="#ADADAD"
          />
          <path
            d="M403.182 243.313C401.899 243.313 400.851 244.36 400.851 245.654C400.851 246.936 401.899 247.984 403.182 247.984C404.466 247.984 405.514 246.936 405.514 245.654C405.514 244.36 404.466 243.313 403.182 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M403.182 249.768C401.899 249.768 400.851 250.816 400.851 252.098C400.851 253.381 401.899 254.429 403.182 254.429C404.466 254.429 405.514 253.381 405.514 252.098C405.514 250.816 404.466 249.768 403.182 249.768Z"
            fill="#ADADAD"
          />
          <path
            d="M403.182 256.225C401.899 256.225 400.851 257.272 400.851 258.565C400.851 259.848 401.899 260.896 403.182 260.896C404.466 260.896 405.514 259.848 405.514 258.565C405.514 257.272 404.466 256.225 403.182 256.225Z"
            fill="#ADADAD"
          />
          <path
            d="M403.182 262.68C401.899 262.68 400.851 263.728 400.851 265.01C400.851 266.293 401.899 267.341 403.182 267.341C404.466 267.341 405.514 266.293 405.514 265.01C405.514 263.728 404.466 262.68 403.182 262.68Z"
            fill="#ADADAD"
          />
          <path
            d="M403.182 269.126C401.899 269.126 400.851 270.173 400.851 271.456C400.851 272.739 401.899 273.786 403.182 273.786C404.466 273.786 405.514 272.739 405.514 271.456C405.514 270.173 404.466 269.126 403.182 269.126Z"
            fill="#ADADAD"
          />
          <path
            d="M396.721 230.411C395.438 230.411 394.379 231.458 394.379 232.752C394.379 234.034 395.427 235.082 396.721 235.082C398.004 235.082 399.052 234.034 399.052 232.752C399.063 231.458 398.015 230.411 396.721 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M396.721 236.867C395.438 236.867 394.379 237.915 394.379 239.208C394.379 240.491 395.427 241.538 396.721 241.538C398.004 241.538 399.052 240.491 399.052 239.208C399.063 237.915 398.015 236.867 396.721 236.867Z"
            fill="#ADADAD"
          />
          <path
            d="M396.721 243.313C395.438 243.313 394.379 244.36 394.379 245.654C394.379 246.936 395.427 247.984 396.721 247.984C398.004 247.984 399.052 246.936 399.052 245.654C399.063 244.36 398.015 243.313 396.721 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M396.721 249.768C395.438 249.768 394.379 250.815 394.379 252.098C394.379 253.38 395.427 254.428 396.721 254.428C398.004 254.428 399.052 253.38 399.052 252.098C399.063 250.815 398.015 249.768 396.721 249.768Z"
            fill="#ADADAD"
          />
          <path
            d="M396.721 256.225C395.438 256.225 394.379 257.273 394.379 258.566C394.379 259.849 395.427 260.896 396.721 260.896C398.004 260.896 399.052 259.849 399.052 258.566C399.063 257.273 398.015 256.225 396.721 256.225Z"
            fill="#ADADAD"
          />
          <path
            d="M396.721 262.68C395.438 262.68 394.379 263.728 394.379 265.01C394.379 266.293 395.427 267.34 396.721 267.34C398.004 267.34 399.052 266.293 399.052 265.01C399.063 263.728 398.015 262.68 396.721 262.68Z"
            fill="#ADADAD"
          />
          <path
            d="M396.721 269.126C395.438 269.126 394.379 270.173 394.379 271.456C394.379 272.739 395.427 273.786 396.721 273.786C398.004 273.786 399.052 272.739 399.052 271.456C399.052 270.173 398.015 269.126 396.721 269.126Z"
            fill="#ADADAD"
          />
          <path
            d="M390.275 223.967C388.991 223.967 387.943 225.014 387.943 226.297C387.943 227.579 388.991 228.627 390.275 228.627C391.558 228.627 392.606 227.579 392.606 226.297C392.606 225.004 391.558 223.967 390.275 223.967Z"
            fill="#ADADAD"
          />
          <path
            d="M390.275 230.411C388.991 230.411 387.943 231.458 387.943 232.752C387.943 234.034 388.991 235.082 390.275 235.082C391.558 235.082 392.606 234.034 392.606 232.752C392.606 231.458 391.558 230.411 390.275 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M390.275 236.867C388.991 236.867 387.943 237.915 387.943 239.208C387.943 240.491 388.991 241.538 390.275 241.538C391.558 241.538 392.606 240.491 392.606 239.208C392.606 237.915 391.558 236.867 390.275 236.867Z"
            fill="#ADADAD"
          />
          <path
            d="M390.275 243.313C388.991 243.313 387.943 244.36 387.943 245.654C387.943 246.936 388.991 247.984 390.275 247.984C391.558 247.984 392.606 246.936 392.606 245.654C392.606 244.36 391.558 243.313 390.275 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M390.275 249.768C388.991 249.768 387.943 250.815 387.943 252.098C387.943 253.38 388.991 254.428 390.275 254.428C391.558 254.428 392.606 253.38 392.606 252.098C392.606 250.815 391.558 249.768 390.275 249.768Z"
            fill="#ADADAD"
          />
          <path
            d="M390.275 256.225C388.991 256.225 387.943 257.273 387.943 258.566C387.943 259.849 388.991 260.896 390.275 260.896C391.558 260.896 392.606 259.849 392.606 258.566C392.606 257.273 391.558 256.225 390.275 256.225Z"
            fill="#ADADAD"
          />
          <path
            d="M390.275 262.68C388.991 262.68 387.943 263.728 387.943 265.01C387.943 266.293 388.991 267.34 390.275 267.34C391.558 267.34 392.606 266.293 392.606 265.01C392.606 263.728 391.558 262.68 390.275 262.68Z"
            fill="#ADADAD"
          />
          <path
            d="M390.275 269.126C388.991 269.126 387.943 270.173 387.943 271.456C387.943 272.739 388.991 273.786 390.275 273.786C391.558 273.786 392.606 272.739 392.606 271.456C392.606 270.173 391.558 269.126 390.275 269.126Z"
            fill="#ADADAD"
          />
          <path
            d="M383.813 223.966C382.53 223.966 381.482 225.014 381.482 226.297C381.482 227.579 382.53 228.627 383.813 228.627C385.107 228.627 386.155 227.579 386.155 226.297C386.155 225.003 385.096 223.966 383.813 223.966Z"
            fill="#ADADAD"
          />
          <path
            d="M383.813 230.411C382.53 230.411 381.482 231.459 381.482 232.752C381.482 234.035 382.53 235.082 383.813 235.082C385.107 235.082 386.155 234.035 386.155 232.752C386.155 231.459 385.096 230.411 383.813 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M383.813 236.868C382.53 236.868 381.482 237.915 381.482 239.208C381.482 240.491 382.53 241.539 383.813 241.539C385.107 241.539 386.155 240.491 386.155 239.208C386.155 237.915 385.096 236.868 383.813 236.868Z"
            fill="#ADADAD"
          />
          <path
            d="M383.813 243.313C382.53 243.313 381.482 244.36 381.482 245.654C381.482 246.936 382.53 247.984 383.813 247.984C385.107 247.984 386.155 246.936 386.155 245.654C386.155 244.36 385.096 243.313 383.813 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M383.813 249.768C382.53 249.768 381.482 250.816 381.482 252.098C381.482 253.381 382.53 254.429 383.813 254.429C385.107 254.429 386.155 253.381 386.155 252.098C386.155 250.816 385.096 249.768 383.813 249.768Z"
            fill="#ADADAD"
          />
          <path
            d="M383.813 256.225C382.53 256.225 381.482 257.272 381.482 258.565C381.482 259.848 382.53 260.896 383.813 260.896C385.107 260.896 386.155 259.848 386.155 258.565C386.155 257.272 385.096 256.225 383.813 256.225Z"
            fill="#ADADAD"
          />
          <path
            d="M383.813 262.68C382.53 262.68 381.482 263.728 381.482 265.01C381.482 266.293 382.53 267.341 383.813 267.341C385.107 267.341 386.155 266.293 386.155 265.01C386.155 263.728 385.096 262.68 383.813 262.68Z"
            fill="#ADADAD"
          />
          <path
            d="M383.813 269.126C382.53 269.126 381.482 270.173 381.482 271.456C381.482 272.739 382.53 273.786 383.813 273.786C385.107 273.786 386.155 272.739 386.155 271.456C386.155 270.173 385.096 269.126 383.813 269.126Z"
            fill="#ADADAD"
          />
          <path
            d="M377.353 223.967C376.07 223.967 375.022 225.014 375.022 226.297C375.022 227.579 376.07 228.627 377.353 228.627C378.636 228.627 379.684 227.579 379.684 226.297C379.695 225.004 378.647 223.967 377.353 223.967Z"
            fill="#ADADAD"
          />
          <path
            d="M377.353 230.411C376.07 230.411 375.022 231.458 375.022 232.752C375.022 234.034 376.07 235.082 377.353 235.082C378.636 235.082 379.684 234.034 379.684 232.752C379.695 231.458 378.647 230.411 377.353 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M377.353 236.867C376.07 236.867 375.022 237.915 375.022 239.208C375.022 240.491 376.07 241.538 377.353 241.538C378.636 241.538 379.684 240.491 379.684 239.208C379.695 237.915 378.647 236.867 377.353 236.867Z"
            fill="#ADADAD"
          />
          <path
            d="M377.353 243.313C376.07 243.313 375.022 244.36 375.022 245.654C375.022 246.936 376.07 247.984 377.353 247.984C378.636 247.984 379.684 246.936 379.684 245.654C379.695 244.36 378.647 243.313 377.353 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M377.353 249.768C376.07 249.768 375.022 250.815 375.022 252.098C375.022 253.38 376.07 254.428 377.353 254.428C378.636 254.428 379.684 253.38 379.684 252.098C379.695 250.815 378.647 249.768 377.353 249.768Z"
            fill="#ADADAD"
          />
          <path
            d="M377.353 256.225C376.07 256.225 375.022 257.273 375.022 258.566C375.022 259.849 376.07 260.896 377.353 260.896C378.636 260.896 379.684 259.849 379.684 258.566C379.695 257.273 378.647 256.225 377.353 256.225Z"
            fill="#ADADAD"
          />
          <path
            d="M377.353 262.68C376.07 262.68 375.022 263.728 375.022 265.01C375.022 266.293 376.07 267.34 377.353 267.34C378.636 267.34 379.684 266.293 379.684 265.01C379.695 263.728 378.647 262.68 377.353 262.68Z"
            fill="#ADADAD"
          />
          <path
            d="M377.353 269.126C376.07 269.126 375.022 270.173 375.022 271.456C375.022 272.739 376.07 273.786 377.353 273.786C378.636 273.786 379.684 272.739 379.684 271.456C379.684 270.173 378.647 269.126 377.353 269.126Z"
            fill="#ADADAD"
          />
          <path
            d="M370.905 223.967C369.622 223.967 368.574 225.014 368.574 226.297C368.574 227.579 369.622 228.627 370.905 228.627C372.189 228.627 373.237 227.579 373.237 226.297C373.237 225.004 372.189 223.967 370.905 223.967Z"
            fill="#ADADAD"
          />
          <path
            d="M370.905 230.411C369.622 230.411 368.574 231.458 368.574 232.752C368.574 234.034 369.622 235.082 370.905 235.082C372.189 235.082 373.237 234.034 373.237 232.752C373.237 231.458 372.189 230.411 370.905 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M370.905 236.867C369.622 236.867 368.574 237.915 368.574 239.208C368.574 240.491 369.622 241.538 370.905 241.538C372.189 241.538 373.237 240.491 373.237 239.208C373.237 237.915 372.189 236.867 370.905 236.867Z"
            fill="#ADADAD"
          />
          <path
            d="M370.905 243.313C369.622 243.313 368.574 244.36 368.574 245.654C368.574 246.936 369.622 247.984 370.905 247.984C372.189 247.984 373.237 246.936 373.237 245.654C373.237 244.36 372.189 243.313 370.905 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M370.905 249.768C369.622 249.768 368.574 250.815 368.574 252.098C368.574 253.38 369.622 254.428 370.905 254.428C372.189 254.428 373.237 253.38 373.237 252.098C373.237 250.815 372.189 249.768 370.905 249.768Z"
            fill="#ADADAD"
          />
          <path
            d="M370.905 256.225C369.622 256.225 368.574 257.273 368.574 258.566C368.574 259.849 369.622 260.896 370.905 260.896C372.189 260.896 373.237 259.849 373.237 258.566C373.237 257.273 372.189 256.225 370.905 256.225Z"
            fill="#ADADAD"
          />
          <path
            d="M370.905 262.68C369.622 262.68 368.574 263.728 368.574 265.01C368.574 266.293 369.622 267.34 370.905 267.34C372.189 267.34 373.237 266.293 373.237 265.01C373.237 263.728 372.189 262.68 370.905 262.68Z"
            fill="#ADADAD"
          />
          <path
            d="M370.905 269.126C369.622 269.126 368.574 270.173 368.574 271.456C368.574 272.739 369.622 273.786 370.905 273.786C372.189 273.786 373.237 272.739 373.237 271.456C373.237 270.173 372.189 269.126 370.905 269.126Z"
            fill="#ADADAD"
          />
          <path
            d="M364.446 223.967C363.163 223.967 362.115 225.014 362.115 226.297C362.115 227.579 363.163 228.627 364.446 228.627C365.729 228.627 366.777 227.579 366.777 226.297C366.777 225.004 365.729 223.967 364.446 223.967Z"
            fill="#ADADAD"
          />
          <path
            d="M364.446 230.411C363.163 230.411 362.115 231.458 362.115 232.752C362.115 234.034 363.163 235.082 364.446 235.082C365.729 235.082 366.777 234.034 366.777 232.752C366.777 231.458 365.729 230.411 364.446 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M364.446 236.867C363.163 236.867 362.115 237.915 362.115 239.208C362.115 240.491 363.163 241.538 364.446 241.538C365.729 241.538 366.777 240.491 366.777 239.208C366.777 237.915 365.729 236.867 364.446 236.867Z"
            fill="#ADADAD"
          />
          <path
            d="M364.446 243.313C363.163 243.313 362.115 244.36 362.115 245.654C362.115 246.936 363.163 247.984 364.446 247.984C365.729 247.984 366.777 246.936 366.777 245.654C366.777 244.36 365.729 243.313 364.446 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M364.446 249.768C363.163 249.768 362.115 250.815 362.115 252.098C362.115 253.38 363.163 254.428 364.446 254.428C365.729 254.428 366.777 253.38 366.777 252.098C366.777 250.815 365.729 249.768 364.446 249.768Z"
            fill="#ADADAD"
          />
          <path
            d="M364.446 256.225C363.163 256.225 362.115 257.273 362.115 258.566C362.115 259.849 363.163 260.896 364.446 260.896C365.729 260.896 366.777 259.849 366.777 258.566C366.777 257.273 365.729 256.225 364.446 256.225Z"
            fill="#ADADAD"
          />
          <path
            d="M364.446 262.68C363.163 262.68 362.115 263.728 362.115 265.01C362.115 266.293 363.163 267.34 364.446 267.34C365.729 267.34 366.777 266.293 366.777 265.01C366.777 263.728 365.729 262.68 364.446 262.68Z"
            fill="#ADADAD"
          />
          <path
            d="M364.446 269.126C363.163 269.126 362.115 270.173 362.115 271.456C362.115 272.739 363.163 273.786 364.446 273.786C365.729 273.786 366.777 272.739 366.777 271.456C366.777 270.173 365.729 269.126 364.446 269.126Z"
            fill="#ADADAD"
          />
          <path
            d="M357.986 223.966C356.703 223.966 355.654 225.014 355.654 226.297C355.654 227.579 356.703 228.627 357.986 228.627C359.269 228.627 360.317 227.579 360.317 226.297C360.317 225.003 359.28 223.966 357.986 223.966Z"
            fill="#ADADAD"
          />
          <path
            d="M357.986 230.411C356.703 230.411 355.654 231.459 355.654 232.752C355.654 234.035 356.703 235.082 357.986 235.082C359.269 235.082 360.317 234.035 360.317 232.752C360.317 231.459 359.28 230.411 357.986 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M357.986 236.868C356.703 236.868 355.654 237.915 355.654 239.208C355.654 240.491 356.703 241.539 357.986 241.539C359.269 241.539 360.317 240.491 360.317 239.208C360.317 237.915 359.28 236.868 357.986 236.868Z"
            fill="#ADADAD"
          />
          <path
            d="M357.986 243.313C356.703 243.313 355.654 244.36 355.654 245.654C355.654 246.936 356.703 247.984 357.986 247.984C359.269 247.984 360.317 246.936 360.317 245.654C360.317 244.36 359.28 243.313 357.986 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M357.986 249.768C356.703 249.768 355.654 250.816 355.654 252.098C355.654 253.381 356.703 254.429 357.986 254.429C359.269 254.429 360.317 253.381 360.317 252.098C360.317 250.816 359.28 249.768 357.986 249.768Z"
            fill="#ADADAD"
          />
          <path
            d="M357.986 256.225C356.703 256.225 355.654 257.272 355.654 258.565C355.654 259.848 356.703 260.896 357.986 260.896C359.269 260.896 360.317 259.848 360.317 258.565C360.317 257.272 359.28 256.225 357.986 256.225Z"
            fill="#ADADAD"
          />
          <path
            d="M357.986 262.68C356.703 262.68 355.654 263.728 355.654 265.01C355.654 266.293 356.703 267.341 357.986 267.341C359.269 267.341 360.317 266.293 360.317 265.01C360.317 263.728 359.28 262.68 357.986 262.68Z"
            fill="#ADADAD"
          />
          <path
            d="M357.986 269.126C356.703 269.126 355.654 270.173 355.654 271.456C355.654 272.739 356.703 273.786 357.986 273.786C359.269 273.786 360.317 272.739 360.317 271.456C360.317 270.173 359.28 269.126 357.986 269.126Z"
            fill="#ADADAD"
          />
          <path
            d="M351.527 230.411C350.243 230.411 349.195 231.459 349.195 232.752C349.195 234.035 350.243 235.082 351.527 235.082C352.81 235.082 353.858 234.035 353.858 232.752C353.869 231.459 352.821 230.411 351.527 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M351.527 236.868C350.243 236.868 349.195 237.915 349.195 239.208C349.195 240.491 350.243 241.539 351.527 241.539C352.81 241.539 353.858 240.491 353.858 239.208C353.869 237.915 352.821 236.868 351.527 236.868Z"
            fill="#ADADAD"
          />
          <path
            d="M351.527 243.313C350.243 243.313 349.195 244.36 349.195 245.654C349.195 246.936 350.243 247.984 351.527 247.984C352.81 247.984 353.858 246.936 353.858 245.654C353.869 244.36 352.821 243.313 351.527 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M351.527 249.768C350.243 249.768 349.195 250.816 349.195 252.098C349.195 253.381 350.243 254.429 351.527 254.429C352.81 254.429 353.858 253.381 353.858 252.098C353.869 250.816 352.821 249.768 351.527 249.768Z"
            fill="#ADADAD"
          />
          <path
            d="M351.527 256.225C350.243 256.225 349.195 257.272 349.195 258.565C349.195 259.848 350.243 260.896 351.527 260.896C352.81 260.896 353.858 259.848 353.858 258.565C353.869 257.272 352.821 256.225 351.527 256.225Z"
            fill="#ADADAD"
          />
          <path
            d="M351.527 262.68C350.243 262.68 349.195 263.728 349.195 265.01C349.195 266.293 350.243 267.341 351.527 267.341C352.81 267.341 353.858 266.293 353.858 265.01C353.869 263.728 352.821 262.68 351.527 262.68Z"
            fill="#ADADAD"
          />
          <path
            d="M351.527 269.126C350.243 269.126 349.195 270.173 349.195 271.456C349.195 272.739 350.243 273.786 351.527 273.786C352.81 273.786 353.858 272.739 353.858 271.456C353.858 270.173 352.821 269.126 351.527 269.126Z"
            fill="#ADADAD"
          />
          <path
            d="M351.527 275.582C350.243 275.582 349.195 276.629 349.195 277.922C349.195 279.205 350.243 280.253 351.527 280.253C352.81 280.253 353.858 279.205 353.858 277.922C353.869 276.629 352.821 275.582 351.527 275.582Z"
            fill="#ADADAD"
          />
          <path
            d="M351.527 282.038C350.243 282.038 349.195 283.085 349.195 284.368C349.195 285.651 350.243 286.698 351.527 286.698C352.81 286.698 353.858 285.651 353.858 284.368C353.869 283.085 352.821 282.038 351.527 282.038Z"
            fill="#ADADAD"
          />
          <path
            d="M351.527 288.493C350.243 288.493 349.195 289.541 349.195 290.834C349.195 292.117 350.243 293.164 351.527 293.164C352.81 293.164 353.858 292.117 353.858 290.834C353.869 289.541 352.821 288.493 351.527 288.493Z"
            fill="#ADADAD"
          />
          <path
            d="M351.527 294.939C350.243 294.939 349.195 295.987 349.195 297.28C349.195 298.563 350.243 299.61 351.527 299.61C352.81 299.61 353.858 298.563 353.858 297.28C353.869 295.987 352.821 294.939 351.527 294.939Z"
            fill="#ADADAD"
          />
          <path
            d="M345.077 243.313C343.794 243.313 342.746 244.36 342.746 245.654C342.746 246.936 343.794 247.984 345.077 247.984C346.361 247.984 347.409 246.936 347.409 245.654C347.409 244.36 346.361 243.313 345.077 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M345.077 249.768C343.794 249.768 342.746 250.816 342.746 252.098C342.746 253.381 343.794 254.429 345.077 254.429C346.361 254.429 347.409 253.381 347.409 252.098C347.409 250.816 346.361 249.768 345.077 249.768Z"
            fill="#ADADAD"
          />
          <path
            d="M345.077 256.225C343.794 256.225 342.746 257.272 342.746 258.565C342.746 259.848 343.794 260.896 345.077 260.896C346.361 260.896 347.409 259.848 347.409 258.565C347.409 257.272 346.361 256.225 345.077 256.225Z"
            fill="#ADADAD"
          />
          <path
            d="M345.077 262.68C343.794 262.68 342.746 263.728 342.746 265.01C342.746 266.293 343.794 267.341 345.077 267.341C346.361 267.341 347.409 266.293 347.409 265.01C347.409 263.728 346.361 262.68 345.077 262.68Z"
            fill="#ADADAD"
          />
          <path
            d="M345.077 269.126C343.794 269.126 342.746 270.173 342.746 271.456C342.746 272.739 343.794 273.786 345.077 273.786C346.361 273.786 347.409 272.739 347.409 271.456C347.409 270.173 346.361 269.126 345.077 269.126Z"
            fill="#ADADAD"
          />
          <path
            d="M345.077 275.582C343.794 275.582 342.746 276.629 342.746 277.922C342.746 279.205 343.794 280.253 345.077 280.253C346.361 280.253 347.409 279.205 347.409 277.922C347.409 276.629 346.361 275.582 345.077 275.582Z"
            fill="#ADADAD"
          />
          <path
            d="M345.077 282.038C343.794 282.038 342.746 283.085 342.746 284.368C342.746 285.651 343.794 286.698 345.077 286.698C346.361 286.698 347.409 285.651 347.409 284.368C347.409 283.085 346.361 282.038 345.077 282.038Z"
            fill="#ADADAD"
          />
          <path
            d="M345.077 288.493C343.794 288.493 342.746 289.541 342.746 290.834C342.746 292.117 343.794 293.164 345.077 293.164C346.361 293.164 347.409 292.117 347.409 290.834C347.409 289.541 346.361 288.493 345.077 288.493Z"
            fill="#ADADAD"
          />
          <path
            d="M338.616 249.768C337.322 249.768 336.274 250.815 336.274 252.098C336.274 253.38 337.322 254.428 338.616 254.428C339.9 254.428 340.958 253.38 340.958 252.098C340.958 250.815 339.91 249.768 338.616 249.768Z"
            fill="#ADADAD"
          />
          <path
            d="M338.616 256.225C337.322 256.225 336.274 257.273 336.274 258.566C336.274 259.849 337.322 260.896 338.616 260.896C339.9 260.896 340.958 259.849 340.958 258.566C340.958 257.273 339.91 256.225 338.616 256.225Z"
            fill="#ADADAD"
          />
          <path
            d="M338.616 262.68C337.322 262.68 336.274 263.728 336.274 265.01C336.274 266.293 337.322 267.34 338.616 267.34C339.9 267.34 340.958 266.293 340.958 265.01C340.958 263.728 339.91 262.68 338.616 262.68Z"
            fill="#ADADAD"
          />
          <path
            d="M338.616 269.126C337.322 269.126 336.274 270.173 336.274 271.456C336.274 272.739 337.322 273.786 338.616 273.786C339.9 273.786 340.958 272.739 340.958 271.456C340.958 270.173 339.91 269.126 338.616 269.126Z"
            fill="#ADADAD"
          />
          <path
            d="M338.616 275.582C337.322 275.582 336.274 276.63 336.274 277.923C336.274 279.206 337.322 280.253 338.616 280.253C339.9 280.253 340.958 279.206 340.958 277.923C340.958 276.63 339.91 275.582 338.616 275.582Z"
            fill="#ADADAD"
          />
          <path
            d="M332.158 256.225C330.875 256.225 329.827 257.273 329.827 258.566C329.827 259.849 330.875 260.896 332.158 260.896C333.441 260.896 334.489 259.849 334.489 258.566C334.5 257.273 333.452 256.225 332.158 256.225Z"
            fill="#ADADAD"
          />
          <path
            d="M293.422 314.296C292.138 314.296 291.09 315.343 291.09 316.626C291.09 317.909 292.138 318.956 293.422 318.956C294.705 318.956 295.753 317.909 295.753 316.626C295.753 315.343 294.705 314.296 293.422 314.296Z"
            fill="#ADADAD"
          />
          <path
            d="M293.422 320.752C292.138 320.752 291.09 321.799 291.09 323.082C291.09 324.364 292.138 325.412 293.422 325.412C294.705 325.412 295.753 324.364 295.753 323.082C295.753 321.799 294.705 320.752 293.422 320.752Z"
            fill="#ADADAD"
          />
          <path
            d="M286.962 314.296C285.679 314.296 284.62 315.343 284.62 316.626C284.62 317.909 285.668 318.956 286.962 318.956C288.245 318.956 289.293 317.909 289.293 316.626C289.304 315.343 288.256 314.296 286.962 314.296Z"
            fill="#ADADAD"
          />
          <path
            d="M286.962 320.752C285.679 320.752 284.62 321.799 284.62 323.082C284.62 324.364 285.668 325.412 286.962 325.412C288.245 325.412 289.293 324.364 289.293 323.082C289.304 321.799 288.256 320.752 286.962 320.752Z"
            fill="#ADADAD"
          />
          <path
            d="M286.962 327.208C285.679 327.208 284.62 328.255 284.62 329.538C284.62 330.821 285.668 331.868 286.962 331.868C288.245 331.868 289.293 330.821 289.293 329.538C289.304 328.255 288.256 327.208 286.962 327.208Z"
            fill="#ADADAD"
          />
          <path
            d="M286.962 333.653C285.679 333.653 284.62 334.7 284.62 335.983C284.62 337.266 285.668 338.313 286.962 338.313C288.245 338.313 289.293 337.266 289.293 335.983C289.304 334.7 288.256 333.653 286.962 333.653Z"
            fill="#ADADAD"
          />
          <path
            d="M286.962 340.109C285.679 340.109 284.62 341.157 284.62 342.439C284.62 343.722 285.668 344.769 286.962 344.769C288.245 344.769 289.293 343.722 289.293 342.439C289.304 341.157 288.256 340.109 286.962 340.109Z"
            fill="#ADADAD"
          />
          <path
            d="M280.514 314.296C279.231 314.296 278.172 315.343 278.172 316.626C278.172 317.909 279.22 318.956 280.514 318.956C281.797 318.956 282.845 317.909 282.845 316.626C282.845 315.343 281.797 314.296 280.514 314.296Z"
            fill="#ADADAD"
          />
          <path
            d="M280.514 320.752C279.231 320.752 278.172 321.799 278.172 323.082C278.172 324.364 279.22 325.412 280.514 325.412C281.797 325.412 282.845 324.364 282.845 323.082C282.845 321.799 281.797 320.752 280.514 320.752Z"
            fill="#ADADAD"
          />
          <path
            d="M280.514 327.208C279.231 327.208 278.172 328.255 278.172 329.538C278.172 330.821 279.22 331.868 280.514 331.868C281.797 331.868 282.845 330.821 282.845 329.538C282.845 328.255 281.797 327.208 280.514 327.208Z"
            fill="#ADADAD"
          />
          <path
            d="M280.514 333.653C279.231 333.653 278.172 334.7 278.172 335.983C278.172 337.266 279.22 338.313 280.514 338.313C281.797 338.313 282.845 337.266 282.845 335.983C282.845 334.7 281.797 333.653 280.514 333.653Z"
            fill="#ADADAD"
          />
          <path
            d="M280.514 340.109C279.231 340.109 278.172 341.157 278.172 342.439C278.172 343.722 279.22 344.769 280.514 344.769C281.797 344.769 282.845 343.722 282.845 342.439C282.845 341.157 281.797 340.109 280.514 340.109Z"
            fill="#ADADAD"
          />
          <path
            d="M280.514 346.565C279.231 346.565 278.172 347.612 278.172 348.895C278.172 350.178 279.22 351.225 280.514 351.225C281.797 351.225 282.845 350.178 282.845 348.895C282.845 347.612 281.797 346.565 280.514 346.565Z"
            fill="#ADADAD"
          />
          <path
            d="M280.514 353.021C279.231 353.021 278.172 354.069 278.172 355.362C278.172 356.645 279.22 357.692 280.514 357.692C281.797 357.692 282.845 356.645 282.845 355.362C282.845 354.069 281.797 353.021 280.514 353.021Z"
            fill="#ADADAD"
          />
          <path
            d="M274.053 307.851C272.77 307.851 271.722 308.899 271.722 310.192C271.722 311.475 272.77 312.522 274.053 312.522C275.336 312.522 276.384 311.475 276.384 310.192C276.384 308.899 275.336 307.851 274.053 307.851Z"
            fill="#ADADAD"
          />
          <path
            d="M274.053 314.295C272.77 314.295 271.722 315.343 271.722 316.626C271.722 317.908 272.77 318.956 274.053 318.956C275.336 318.956 276.384 317.908 276.384 316.626C276.384 315.343 275.336 314.295 274.053 314.295Z"
            fill="#ADADAD"
          />
          <path
            d="M274.053 320.752C272.77 320.752 271.722 321.799 271.722 323.082C271.722 324.364 272.77 325.412 274.053 325.412C275.336 325.412 276.384 324.364 276.384 323.082C276.384 321.799 275.336 320.752 274.053 320.752Z"
            fill="#ADADAD"
          />
          <path
            d="M274.053 327.208C272.77 327.208 271.722 328.255 271.722 329.538C271.722 330.821 272.77 331.868 274.053 331.868C275.336 331.868 276.384 330.821 276.384 329.538C276.384 328.255 275.336 327.208 274.053 327.208Z"
            fill="#ADADAD"
          />
          <path
            d="M274.053 333.654C272.77 333.654 271.722 334.701 271.722 335.984C271.722 337.266 272.77 338.314 274.053 338.314C275.336 338.314 276.384 337.266 276.384 335.984C276.384 334.701 275.336 333.654 274.053 333.654Z"
            fill="#ADADAD"
          />
          <path
            d="M274.053 340.11C272.77 340.11 271.722 341.157 271.722 342.44C271.722 343.723 272.77 344.77 274.053 344.77C275.336 344.77 276.384 343.723 276.384 342.44C276.384 341.157 275.336 340.11 274.053 340.11Z"
            fill="#ADADAD"
          />
          <path
            d="M274.053 346.565C272.77 346.565 271.722 347.612 271.722 348.895C271.722 350.177 272.77 351.225 274.053 351.225C275.336 351.225 276.384 350.177 276.384 348.895C276.384 347.612 275.336 346.565 274.053 346.565Z"
            fill="#ADADAD"
          />
          <path
            d="M274.053 353.021C272.77 353.021 271.722 354.068 271.722 355.362C271.722 356.644 272.77 357.692 274.053 357.692C275.336 357.692 276.384 356.644 276.384 355.362C276.384 354.068 275.336 353.021 274.053 353.021Z"
            fill="#ADADAD"
          />
          <path
            d="M267.594 307.851C266.31 307.851 265.262 308.899 265.262 310.192C265.262 311.475 266.31 312.522 267.594 312.522C268.877 312.522 269.925 311.475 269.925 310.192C269.925 308.899 268.877 307.851 267.594 307.851Z"
            fill="#ADADAD"
          />
          <path
            d="M267.594 314.295C266.31 314.295 265.262 315.343 265.262 316.626C265.262 317.908 266.31 318.956 267.594 318.956C268.877 318.956 269.925 317.908 269.925 316.626C269.925 315.343 268.877 314.295 267.594 314.295Z"
            fill="#ADADAD"
          />
          <path
            d="M267.594 320.752C266.31 320.752 265.262 321.799 265.262 323.082C265.262 324.364 266.31 325.412 267.594 325.412C268.877 325.412 269.925 324.364 269.925 323.082C269.925 321.799 268.877 320.752 267.594 320.752Z"
            fill="#ADADAD"
          />
          <path
            d="M267.594 327.208C266.31 327.208 265.262 328.255 265.262 329.538C265.262 330.821 266.31 331.868 267.594 331.868C268.877 331.868 269.925 330.821 269.925 329.538C269.925 328.255 268.877 327.208 267.594 327.208Z"
            fill="#ADADAD"
          />
          <path
            d="M267.594 333.654C266.31 333.654 265.262 334.701 265.262 335.984C265.262 337.266 266.31 338.314 267.594 338.314C268.877 338.314 269.925 337.266 269.925 335.984C269.925 334.701 268.877 333.654 267.594 333.654Z"
            fill="#ADADAD"
          />
          <path
            d="M267.594 340.11C266.31 340.11 265.262 341.157 265.262 342.44C265.262 343.723 266.31 344.77 267.594 344.77C268.877 344.77 269.925 343.723 269.925 342.44C269.925 341.157 268.877 340.11 267.594 340.11Z"
            fill="#ADADAD"
          />
          <path
            d="M267.594 346.565C266.31 346.565 265.262 347.612 265.262 348.895C265.262 350.177 266.31 351.225 267.594 351.225C268.877 351.225 269.925 350.177 269.925 348.895C269.925 347.612 268.877 346.565 267.594 346.565Z"
            fill="#ADADAD"
          />
          <path
            d="M267.594 353.021C266.31 353.021 265.262 354.068 265.262 355.362C265.262 356.644 266.31 357.692 267.594 357.692C268.877 357.692 269.925 356.644 269.925 355.362C269.925 354.068 268.877 353.021 267.594 353.021Z"
            fill="#ADADAD"
          />
          <path
            d="M267.594 359.477C266.31 359.477 265.262 360.524 265.262 361.807C265.262 363.09 266.31 364.137 267.594 364.137C268.877 364.137 269.925 363.09 269.925 361.807C269.925 360.514 268.877 359.477 267.594 359.477Z"
            fill="#ADADAD"
          />
          <path
            d="M261.134 294.939C259.851 294.939 258.803 295.986 258.803 297.28C258.803 298.562 259.851 299.61 261.134 299.61C262.418 299.61 263.466 298.562 263.466 297.28C263.476 295.986 262.428 294.939 261.134 294.939Z"
            fill="#ADADAD"
          />
          <path
            d="M261.134 301.395C259.851 301.395 258.803 302.442 258.803 303.725C258.803 305.008 259.851 306.055 261.134 306.055C262.418 306.055 263.466 305.008 263.466 303.725C263.476 302.442 262.428 301.395 261.134 301.395Z"
            fill="#ADADAD"
          />
          <path
            d="M261.134 307.851C259.851 307.851 258.803 308.899 258.803 310.192C258.803 311.475 259.851 312.522 261.134 312.522C262.418 312.522 263.466 311.475 263.466 310.192C263.476 308.899 262.428 307.851 261.134 307.851Z"
            fill="#ADADAD"
          />
          <path
            d="M261.134 314.295C259.851 314.295 258.803 315.343 258.803 316.626C258.803 317.908 259.851 318.956 261.134 318.956C262.418 318.956 263.466 317.908 263.466 316.626C263.476 315.343 262.428 314.295 261.134 314.295Z"
            fill="#ADADAD"
          />
          <path
            d="M261.134 320.752C259.851 320.752 258.803 321.799 258.803 323.082C258.803 324.364 259.851 325.412 261.134 325.412C262.418 325.412 263.466 324.364 263.466 323.082C263.476 321.799 262.428 320.752 261.134 320.752Z"
            fill="#ADADAD"
          />
          <path
            d="M261.134 327.208C259.851 327.208 258.803 328.255 258.803 329.538C258.803 330.821 259.851 331.868 261.134 331.868C262.418 331.868 263.466 330.821 263.466 329.538C263.476 328.255 262.428 327.208 261.134 327.208Z"
            fill="#ADADAD"
          />
          <path
            d="M261.134 333.654C259.851 333.654 258.803 334.701 258.803 335.984C258.803 337.266 259.851 338.314 261.134 338.314C262.418 338.314 263.466 337.266 263.466 335.984C263.476 334.701 262.428 333.654 261.134 333.654Z"
            fill="#ADADAD"
          />
          <path
            d="M261.134 340.11C259.851 340.11 258.803 341.157 258.803 342.44C258.803 343.723 259.851 344.77 261.134 344.77C262.418 344.77 263.466 343.723 263.466 342.44C263.476 341.157 262.428 340.11 261.134 340.11Z"
            fill="#ADADAD"
          />
          <path
            d="M261.134 346.565C259.851 346.565 258.803 347.612 258.803 348.895C258.803 350.177 259.851 351.225 261.134 351.225C262.418 351.225 263.466 350.177 263.466 348.895C263.476 347.612 262.428 346.565 261.134 346.565Z"
            fill="#ADADAD"
          />
          <path
            d="M261.134 353.021C259.851 353.021 258.803 354.068 258.803 355.362C258.803 356.644 259.851 357.692 261.134 357.692C262.418 357.692 263.466 356.644 263.466 355.362C263.476 354.068 262.428 353.021 261.134 353.021Z"
            fill="#ADADAD"
          />
          <path
            d="M261.134 359.477C259.851 359.477 258.803 360.524 258.803 361.807C258.803 363.09 259.851 364.137 261.134 364.137C262.418 364.137 263.466 363.09 263.466 361.807C263.476 360.514 262.428 359.477 261.134 359.477Z"
            fill="#ADADAD"
          />
          <path
            d="M261.134 365.923C259.851 365.923 258.803 366.97 258.803 368.264C258.803 369.546 259.851 370.594 261.134 370.594C262.418 370.594 263.466 369.546 263.466 368.264C263.476 366.97 262.428 365.923 261.134 365.923Z"
            fill="#ADADAD"
          />
          <path
            d="M261.134 372.377C259.851 372.377 258.803 373.425 258.803 374.708C258.803 375.99 259.851 377.038 261.134 377.038C262.418 377.038 263.466 375.99 263.466 374.708C263.476 373.425 262.428 372.377 261.134 372.377Z"
            fill="#ADADAD"
          />
          <path
            d="M254.686 294.939C253.403 294.939 252.355 295.987 252.355 297.28C252.355 298.563 253.403 299.61 254.686 299.61C255.98 299.61 257.028 298.563 257.028 297.28C257.017 295.987 255.969 294.939 254.686 294.939Z"
            fill="#ADADAD"
          />
          <path
            d="M254.686 301.395C253.403 301.395 252.355 302.442 252.355 303.725C252.355 305.008 253.403 306.055 254.686 306.055C255.98 306.055 257.028 305.008 257.028 303.725C257.017 302.442 255.969 301.395 254.686 301.395Z"
            fill="#ADADAD"
          />
          <path
            d="M254.686 307.851C253.403 307.851 252.355 308.898 252.355 310.192C252.355 311.474 253.403 312.522 254.686 312.522C255.98 312.522 257.028 311.474 257.028 310.192C257.017 308.898 255.969 307.851 254.686 307.851Z"
            fill="#ADADAD"
          />
          <path
            d="M254.686 314.296C253.403 314.296 252.355 315.343 252.355 316.626C252.355 317.909 253.403 318.956 254.686 318.956C255.98 318.956 257.028 317.909 257.028 316.626C257.017 315.343 255.969 314.296 254.686 314.296Z"
            fill="#ADADAD"
          />
          <path
            d="M254.686 320.752C253.403 320.752 252.355 321.799 252.355 323.082C252.355 324.364 253.403 325.412 254.686 325.412C255.98 325.412 257.028 324.364 257.028 323.082C257.017 321.799 255.969 320.752 254.686 320.752Z"
            fill="#ADADAD"
          />
          <path
            d="M254.686 327.208C253.403 327.208 252.355 328.255 252.355 329.538C252.355 330.821 253.403 331.868 254.686 331.868C255.98 331.868 257.028 330.821 257.028 329.538C257.017 328.255 255.969 327.208 254.686 327.208Z"
            fill="#ADADAD"
          />
          <path
            d="M254.686 333.653C253.403 333.653 252.355 334.7 252.355 335.983C252.355 337.266 253.403 338.313 254.686 338.313C255.98 338.313 257.028 337.266 257.028 335.983C257.017 334.7 255.969 333.653 254.686 333.653Z"
            fill="#ADADAD"
          />
          <path
            d="M254.686 340.109C253.403 340.109 252.355 341.157 252.355 342.439C252.355 343.722 253.403 344.769 254.686 344.769C255.98 344.769 257.028 343.722 257.028 342.439C257.017 341.157 255.969 340.109 254.686 340.109Z"
            fill="#ADADAD"
          />
          <path
            d="M254.686 346.565C253.403 346.565 252.355 347.612 252.355 348.895C252.355 350.178 253.403 351.225 254.686 351.225C255.98 351.225 257.028 350.178 257.028 348.895C257.017 347.612 255.969 346.565 254.686 346.565Z"
            fill="#ADADAD"
          />
          <path
            d="M254.686 353.021C253.403 353.021 252.355 354.069 252.355 355.362C252.355 356.645 253.403 357.692 254.686 357.692C255.98 357.692 257.028 356.645 257.028 355.362C257.017 354.069 255.969 353.021 254.686 353.021Z"
            fill="#ADADAD"
          />
          <path
            d="M254.686 359.477C253.403 359.477 252.355 360.524 252.355 361.807C252.355 363.09 253.403 364.137 254.686 364.137C255.98 364.137 257.028 363.09 257.028 361.807C257.017 360.514 255.969 359.477 254.686 359.477Z"
            fill="#ADADAD"
          />
          <path
            d="M254.686 365.922C253.403 365.922 252.355 366.97 252.355 368.263C252.355 369.546 253.403 370.593 254.686 370.593C255.98 370.593 257.028 369.546 257.028 368.263C257.017 366.97 255.969 365.922 254.686 365.922Z"
            fill="#ADADAD"
          />
          <path
            d="M254.686 372.378C253.403 372.378 252.355 373.425 252.355 374.708C252.355 375.991 253.403 377.038 254.686 377.038C255.98 377.038 257.028 375.991 257.028 374.708C257.017 373.425 255.969 372.378 254.686 372.378Z"
            fill="#ADADAD"
          />
          <path
            d="M254.686 378.834C253.403 378.834 252.355 379.881 252.355 381.164C252.355 382.446 253.403 383.494 254.686 383.494C255.98 383.494 257.028 382.446 257.028 381.164C257.028 379.881 255.969 378.834 254.686 378.834Z"
            fill="#ADADAD"
          />
          <path
            d="M248.227 294.939C246.943 294.939 245.895 295.987 245.895 297.28C245.895 298.563 246.943 299.61 248.227 299.61C249.51 299.61 250.558 298.563 250.558 297.28C250.558 295.987 249.51 294.939 248.227 294.939Z"
            fill="#ADADAD"
          />
          <path
            d="M248.227 301.395C246.943 301.395 245.895 302.442 245.895 303.725C245.895 305.008 246.943 306.055 248.227 306.055C249.51 306.055 250.558 305.008 250.558 303.725C250.558 302.442 249.51 301.395 248.227 301.395Z"
            fill="#ADADAD"
          />
          <path
            d="M248.227 307.851C246.943 307.851 245.895 308.898 245.895 310.192C245.895 311.474 246.943 312.522 248.227 312.522C249.51 312.522 250.558 311.474 250.558 310.192C250.558 308.898 249.51 307.851 248.227 307.851Z"
            fill="#ADADAD"
          />
          <path
            d="M248.227 314.296C246.943 314.296 245.895 315.343 245.895 316.626C245.895 317.909 246.943 318.956 248.227 318.956C249.51 318.956 250.558 317.909 250.558 316.626C250.558 315.343 249.51 314.296 248.227 314.296Z"
            fill="#ADADAD"
          />
          <path
            d="M248.227 320.752C246.943 320.752 245.895 321.799 245.895 323.082C245.895 324.364 246.943 325.412 248.227 325.412C249.51 325.412 250.558 324.364 250.558 323.082C250.558 321.799 249.51 320.752 248.227 320.752Z"
            fill="#ADADAD"
          />
          <path
            d="M248.227 327.208C246.943 327.208 245.895 328.255 245.895 329.538C245.895 330.821 246.943 331.868 248.227 331.868C249.51 331.868 250.558 330.821 250.558 329.538C250.558 328.255 249.51 327.208 248.227 327.208Z"
            fill="#ADADAD"
          />
          <path
            d="M248.227 333.653C246.943 333.653 245.895 334.7 245.895 335.983C245.895 337.266 246.943 338.313 248.227 338.313C249.51 338.313 250.558 337.266 250.558 335.983C250.558 334.7 249.51 333.653 248.227 333.653Z"
            fill="#ADADAD"
          />
          <path
            d="M248.227 340.109C246.943 340.109 245.895 341.157 245.895 342.439C245.895 343.722 246.943 344.769 248.227 344.769C249.51 344.769 250.558 343.722 250.558 342.439C250.558 341.157 249.51 340.109 248.227 340.109Z"
            fill="#ADADAD"
          />
          <path
            d="M248.227 346.565C246.943 346.565 245.895 347.612 245.895 348.895C245.895 350.178 246.943 351.225 248.227 351.225C249.51 351.225 250.558 350.178 250.558 348.895C250.558 347.612 249.51 346.565 248.227 346.565Z"
            fill="#ADADAD"
          />
          <path
            d="M248.227 353.021C246.943 353.021 245.895 354.069 245.895 355.362C245.895 356.645 246.943 357.692 248.227 357.692C249.51 357.692 250.558 356.645 250.558 355.362C250.558 354.069 249.51 353.021 248.227 353.021Z"
            fill="#ADADAD"
          />
          <path
            d="M241.765 294.939C240.482 294.939 239.433 295.986 239.433 297.28C239.433 298.562 240.482 299.61 241.765 299.61C243.048 299.61 244.107 298.562 244.107 297.28C244.107 295.986 243.059 294.939 241.765 294.939Z"
            fill="#ADADAD"
          />
          <path
            d="M241.765 301.395C240.482 301.395 239.433 302.442 239.433 303.725C239.433 305.008 240.482 306.055 241.765 306.055C243.048 306.055 244.107 305.008 244.107 303.725C244.107 302.442 243.059 301.395 241.765 301.395Z"
            fill="#ADADAD"
          />
          <path
            d="M241.765 307.851C240.482 307.851 239.433 308.899 239.433 310.192C239.433 311.475 240.482 312.522 241.765 312.522C243.048 312.522 244.107 311.475 244.107 310.192C244.107 308.899 243.059 307.851 241.765 307.851Z"
            fill="#ADADAD"
          />
          <path
            d="M241.765 314.295C240.482 314.295 239.433 315.343 239.433 316.626C239.433 317.908 240.482 318.956 241.765 318.956C243.048 318.956 244.107 317.908 244.107 316.626C244.107 315.343 243.059 314.295 241.765 314.295Z"
            fill="#ADADAD"
          />
          <path
            d="M241.765 320.752C240.482 320.752 239.433 321.799 239.433 323.082C239.433 324.364 240.482 325.412 241.765 325.412C243.048 325.412 244.107 324.364 244.107 323.082C244.107 321.799 243.059 320.752 241.765 320.752Z"
            fill="#ADADAD"
          />
          <path
            d="M241.765 327.208C240.482 327.208 239.433 328.255 239.433 329.538C239.433 330.821 240.482 331.868 241.765 331.868C243.048 331.868 244.107 330.821 244.107 329.538C244.107 328.255 243.059 327.208 241.765 327.208Z"
            fill="#ADADAD"
          />
          <path
            d="M241.765 333.654C240.482 333.654 239.433 334.701 239.433 335.984C239.433 337.266 240.482 338.314 241.765 338.314C243.048 338.314 244.107 337.266 244.107 335.984C244.107 334.701 243.059 333.654 241.765 333.654Z"
            fill="#ADADAD"
          />
          <path
            d="M241.765 340.11C240.482 340.11 239.433 341.157 239.433 342.44C239.433 343.723 240.482 344.77 241.765 344.77C243.048 344.77 244.107 343.723 244.107 342.44C244.107 341.157 243.059 340.11 241.765 340.11Z"
            fill="#ADADAD"
          />
          <path
            d="M241.765 346.565C240.482 346.565 239.433 347.612 239.433 348.895C239.433 350.177 240.482 351.225 241.765 351.225C243.048 351.225 244.107 350.177 244.107 348.895C244.107 347.612 243.059 346.565 241.765 346.565Z"
            fill="#ADADAD"
          />
          <path
            d="M241.765 353.021C240.482 353.021 239.433 354.068 239.433 355.362C239.433 356.644 240.482 357.692 241.765 357.692C243.048 357.692 244.107 356.644 244.107 355.362C244.107 354.068 243.059 353.021 241.765 353.021Z"
            fill="#ADADAD"
          />
          <path
            d="M235.305 294.939C234.022 294.939 232.974 295.986 232.974 297.28C232.974 298.562 234.022 299.61 235.305 299.61C236.6 299.61 237.648 298.562 237.648 297.28C237.648 295.986 236.6 294.939 235.305 294.939Z"
            fill="#ADADAD"
          />
          <path
            d="M235.305 301.395C234.022 301.395 232.974 302.442 232.974 303.725C232.974 305.008 234.022 306.055 235.305 306.055C236.6 306.055 237.648 305.008 237.648 303.725C237.648 302.442 236.6 301.395 235.305 301.395Z"
            fill="#ADADAD"
          />
          <path
            d="M235.305 307.851C234.022 307.851 232.974 308.899 232.974 310.192C232.974 311.475 234.022 312.522 235.305 312.522C236.6 312.522 237.648 311.475 237.648 310.192C237.648 308.899 236.6 307.851 235.305 307.851Z"
            fill="#ADADAD"
          />
          <path
            d="M235.305 314.295C234.022 314.295 232.974 315.343 232.974 316.626C232.974 317.908 234.022 318.956 235.305 318.956C236.6 318.956 237.648 317.908 237.648 316.626C237.648 315.343 236.6 314.295 235.305 314.295Z"
            fill="#ADADAD"
          />
          <path
            d="M235.305 320.752C234.022 320.752 232.974 321.799 232.974 323.082C232.974 324.364 234.022 325.412 235.305 325.412C236.6 325.412 237.648 324.364 237.648 323.082C237.648 321.799 236.6 320.752 235.305 320.752Z"
            fill="#ADADAD"
          />
          <path
            d="M235.305 327.208C234.022 327.208 232.974 328.255 232.974 329.538C232.974 330.821 234.022 331.868 235.305 331.868C236.6 331.868 237.648 330.821 237.648 329.538C237.648 328.255 236.6 327.208 235.305 327.208Z"
            fill="#ADADAD"
          />
          <path
            d="M235.305 333.654C234.022 333.654 232.974 334.701 232.974 335.984C232.974 337.266 234.022 338.314 235.305 338.314C236.6 338.314 237.648 337.266 237.648 335.984C237.648 334.701 236.6 333.654 235.305 333.654Z"
            fill="#ADADAD"
          />
          <path
            d="M228.858 294.939C227.575 294.939 226.527 295.986 226.527 297.28C226.527 298.562 227.575 299.61 228.858 299.61C230.141 299.61 231.189 298.562 231.189 297.28C231.189 295.986 230.141 294.939 228.858 294.939Z"
            fill="#ADADAD"
          />
          <path
            d="M228.858 301.395C227.575 301.395 226.527 302.442 226.527 303.725C226.527 305.008 227.575 306.055 228.858 306.055C230.141 306.055 231.189 305.008 231.189 303.725C231.189 302.442 230.141 301.395 228.858 301.395Z"
            fill="#ADADAD"
          />
          <path
            d="M228.858 307.851C227.575 307.851 226.527 308.899 226.527 310.192C226.527 311.475 227.575 312.522 228.858 312.522C230.141 312.522 231.189 311.475 231.189 310.192C231.189 308.899 230.141 307.851 228.858 307.851Z"
            fill="#ADADAD"
          />
          <path
            d="M228.858 314.295C227.575 314.295 226.527 315.343 226.527 316.626C226.527 317.908 227.575 318.956 228.858 318.956C230.141 318.956 231.189 317.908 231.189 316.626C231.189 315.343 230.141 314.295 228.858 314.295Z"
            fill="#ADADAD"
          />
          <path
            d="M228.858 320.752C227.575 320.752 226.527 321.799 226.527 323.082C226.527 324.364 227.575 325.412 228.858 325.412C230.141 325.412 231.189 324.364 231.189 323.082C231.189 321.799 230.141 320.752 228.858 320.752Z"
            fill="#ADADAD"
          />
          <path
            d="M228.858 327.208C227.575 327.208 226.527 328.255 226.527 329.538C226.527 330.821 227.575 331.868 228.858 331.868C230.141 331.868 231.189 330.821 231.189 329.538C231.189 328.255 230.141 327.208 228.858 327.208Z"
            fill="#ADADAD"
          />
          <path
            d="M228.858 333.654C227.575 333.654 226.527 334.701 226.527 335.984C226.527 337.266 227.575 338.314 228.858 338.314C230.141 338.314 231.189 337.266 231.189 335.984C231.189 334.701 230.141 333.654 228.858 333.654Z"
            fill="#ADADAD"
          />
          <path
            d="M222.398 294.939C221.115 294.939 220.067 295.987 220.067 297.28C220.067 298.563 221.115 299.61 222.398 299.61C223.682 299.61 224.73 298.563 224.73 297.28C224.741 295.987 223.692 294.939 222.398 294.939Z"
            fill="#ADADAD"
          />
          <path
            d="M222.398 301.395C221.115 301.395 220.067 302.442 220.067 303.725C220.067 305.008 221.115 306.055 222.398 306.055C223.682 306.055 224.73 305.008 224.73 303.725C224.741 302.442 223.692 301.395 222.398 301.395Z"
            fill="#ADADAD"
          />
          <path
            d="M222.398 307.851C221.115 307.851 220.067 308.898 220.067 310.192C220.067 311.474 221.115 312.522 222.398 312.522C223.682 312.522 224.73 311.474 224.73 310.192C224.741 308.898 223.692 307.851 222.398 307.851Z"
            fill="#ADADAD"
          />
          <path
            d="M222.398 314.296C221.115 314.296 220.067 315.343 220.067 316.626C220.067 317.909 221.115 318.956 222.398 318.956C223.682 318.956 224.73 317.909 224.73 316.626C224.741 315.343 223.692 314.296 222.398 314.296Z"
            fill="#ADADAD"
          />
          <path
            d="M248.227 359.477C246.943 359.477 245.895 360.524 245.895 361.807C245.895 363.09 246.943 364.137 248.227 364.137C249.51 364.137 250.558 363.09 250.558 361.807C250.558 360.514 249.51 359.477 248.227 359.477Z"
            fill="#ADADAD"
          />
          <path
            d="M248.227 365.922C246.943 365.922 245.895 366.97 245.895 368.263C245.895 369.546 246.943 370.593 248.227 370.593C249.51 370.593 250.558 369.546 250.558 368.263C250.558 366.97 249.51 365.922 248.227 365.922Z"
            fill="#ADADAD"
          />
          <path
            d="M248.227 372.378C246.943 372.378 245.895 373.425 245.895 374.708C245.895 375.991 246.943 377.038 248.227 377.038C249.51 377.038 250.558 375.991 250.558 374.708C250.558 373.425 249.51 372.378 248.227 372.378Z"
            fill="#ADADAD"
          />
          <path
            d="M248.227 378.834C246.943 378.834 245.895 379.881 245.895 381.164C245.895 382.446 246.943 383.494 248.227 383.494C249.51 383.494 250.558 382.446 250.558 381.164C250.558 379.881 249.51 378.834 248.227 378.834Z"
            fill="#ADADAD"
          />
          <path
            d="M248.227 385.279C246.943 385.279 245.895 386.327 245.895 387.609C245.895 388.892 246.943 389.95 248.227 389.95C249.51 389.95 250.558 388.903 250.558 387.609C250.558 386.327 249.51 385.279 248.227 385.279Z"
            fill="#ADADAD"
          />
          <path
            d="M241.765 288.493C240.482 288.493 239.433 289.54 239.433 290.834C239.433 292.116 240.482 293.164 241.765 293.164C243.048 293.164 244.107 292.116 244.107 290.834C244.107 289.54 243.059 288.493 241.765 288.493Z"
            fill="#ADADAD"
          />
          <path
            d="M241.765 359.477C240.482 359.477 239.433 360.524 239.433 361.807C239.433 363.09 240.482 364.137 241.765 364.137C243.048 364.137 244.107 363.09 244.107 361.807C244.107 360.514 243.059 359.477 241.765 359.477Z"
            fill="#ADADAD"
          />
          <path
            d="M241.765 365.923C240.482 365.923 239.433 366.97 239.433 368.264C239.433 369.546 240.482 370.594 241.765 370.594C243.048 370.594 244.107 369.546 244.107 368.264C244.107 366.97 243.059 365.923 241.765 365.923Z"
            fill="#ADADAD"
          />
          <path
            d="M241.765 372.377C240.482 372.377 239.433 373.425 239.433 374.708C239.433 375.99 240.482 377.038 241.765 377.038C243.048 377.038 244.107 375.99 244.107 374.708C244.107 373.425 243.059 372.377 241.765 372.377Z"
            fill="#ADADAD"
          />
          <path
            d="M241.765 378.834C240.482 378.834 239.433 379.881 239.433 381.164C239.433 382.446 240.482 383.494 241.765 383.494C243.048 383.494 244.107 382.446 244.107 381.164C244.107 379.881 243.059 378.834 241.765 378.834Z"
            fill="#ADADAD"
          />
          <path
            d="M241.765 385.279C240.482 385.279 239.433 386.327 239.433 387.609C239.433 388.892 240.482 389.95 241.765 389.95C243.048 389.95 244.107 388.903 244.107 387.609C244.107 386.327 243.059 385.279 241.765 385.279Z"
            fill="#ADADAD"
          />
          <path
            d="M241.765 391.736C240.482 391.736 239.433 392.783 239.433 394.066C239.433 395.348 240.482 396.396 241.765 396.396C243.048 396.396 244.107 395.348 244.107 394.066C244.107 392.783 243.059 391.736 241.765 391.736Z"
            fill="#ADADAD"
          />
          <path
            d="M235.305 282.038C234.022 282.038 232.974 283.086 232.974 284.368C232.974 285.651 234.022 286.699 235.305 286.699C236.6 286.699 237.648 285.651 237.648 284.368C237.648 283.086 236.6 282.038 235.305 282.038Z"
            fill="#ADADAD"
          />
          <path
            d="M235.305 288.493C234.022 288.493 232.974 289.54 232.974 290.834C232.974 292.116 234.022 293.164 235.305 293.164C236.6 293.164 237.648 292.116 237.648 290.834C237.648 289.54 236.6 288.493 235.305 288.493Z"
            fill="#ADADAD"
          />
          <path
            d="M235.305 340.11C234.022 340.11 232.974 341.157 232.974 342.44C232.974 343.723 234.022 344.77 235.305 344.77C236.6 344.77 237.648 343.723 237.648 342.44C237.648 341.157 236.6 340.11 235.305 340.11Z"
            fill="#ADADAD"
          />
          <path
            d="M235.305 346.565C234.022 346.565 232.974 347.612 232.974 348.895C232.974 350.177 234.022 351.225 235.305 351.225C236.6 351.225 237.648 350.177 237.648 348.895C237.648 347.612 236.6 346.565 235.305 346.565Z"
            fill="#ADADAD"
          />
          <path
            d="M235.305 353.021C234.022 353.021 232.974 354.068 232.974 355.362C232.974 356.644 234.022 357.692 235.305 357.692C236.6 357.692 237.648 356.644 237.648 355.362C237.648 354.068 236.6 353.021 235.305 353.021Z"
            fill="#ADADAD"
          />
          <path
            d="M235.305 359.477C234.022 359.477 232.974 360.524 232.974 361.807C232.974 363.09 234.022 364.137 235.305 364.137C236.6 364.137 237.648 363.09 237.648 361.807C237.648 360.514 236.6 359.477 235.305 359.477Z"
            fill="#ADADAD"
          />
          <path
            d="M235.305 365.923C234.022 365.923 232.974 366.97 232.974 368.264C232.974 369.546 234.022 370.594 235.305 370.594C236.6 370.594 237.648 369.546 237.648 368.264C237.648 366.97 236.6 365.923 235.305 365.923Z"
            fill="#ADADAD"
          />
          <path
            d="M235.305 372.377C234.022 372.377 232.974 373.425 232.974 374.708C232.974 375.99 234.022 377.038 235.305 377.038C236.6 377.038 237.648 375.99 237.648 374.708C237.648 373.425 236.6 372.377 235.305 372.377Z"
            fill="#ADADAD"
          />
          <path
            d="M235.305 378.834C234.022 378.834 232.974 379.881 232.974 381.164C232.974 382.446 234.022 383.494 235.305 383.494C236.6 383.494 237.648 382.446 237.648 381.164C237.648 379.881 236.6 378.834 235.305 378.834Z"
            fill="#ADADAD"
          />
          <path
            d="M235.305 385.279C234.022 385.279 232.974 386.327 232.974 387.609C232.974 388.892 234.022 389.95 235.305 389.95C236.6 389.95 237.648 388.903 237.648 387.609C237.648 386.327 236.6 385.279 235.305 385.279Z"
            fill="#ADADAD"
          />
          <path
            d="M235.305 391.736C234.022 391.736 232.974 392.783 232.974 394.066C232.974 395.348 234.022 396.396 235.305 396.396C236.6 396.396 237.648 395.348 237.648 394.066C237.648 392.783 236.6 391.736 235.305 391.736Z"
            fill="#ADADAD"
          />
          <path
            d="M235.305 398.192C234.022 398.192 232.974 399.239 232.974 400.522C232.974 401.805 234.022 402.852 235.305 402.852C236.6 402.852 237.648 401.805 237.648 400.522C237.648 399.239 236.6 398.192 235.305 398.192Z"
            fill="#ADADAD"
          />
          <path
            d="M228.858 282.038C227.575 282.038 226.527 283.086 226.527 284.368C226.527 285.651 227.575 286.699 228.858 286.699C230.141 286.699 231.189 285.651 231.189 284.368C231.189 283.086 230.141 282.038 228.858 282.038Z"
            fill="#ADADAD"
          />
          <path
            d="M228.858 288.493C227.575 288.493 226.527 289.54 226.527 290.834C226.527 292.116 227.575 293.164 228.858 293.164C230.141 293.164 231.189 292.116 231.189 290.834C231.189 289.54 230.141 288.493 228.858 288.493Z"
            fill="#ADADAD"
          />
          <path
            d="M228.858 340.11C227.575 340.11 226.527 341.157 226.527 342.44C226.527 343.723 227.575 344.77 228.858 344.77C230.141 344.77 231.189 343.723 231.189 342.44C231.189 341.157 230.141 340.11 228.858 340.11Z"
            fill="#ADADAD"
          />
          <path
            d="M228.858 346.565C227.575 346.565 226.527 347.612 226.527 348.895C226.527 350.177 227.575 351.225 228.858 351.225C230.141 351.225 231.189 350.177 231.189 348.895C231.189 347.612 230.141 346.565 228.858 346.565Z"
            fill="#ADADAD"
          />
          <path
            d="M228.858 353.021C227.575 353.021 226.527 354.068 226.527 355.362C226.527 356.644 227.575 357.692 228.858 357.692C230.141 357.692 231.189 356.644 231.189 355.362C231.189 354.068 230.141 353.021 228.858 353.021Z"
            fill="#ADADAD"
          />
          <path
            d="M228.858 359.477C227.575 359.477 226.527 360.524 226.527 361.807C226.527 363.09 227.575 364.137 228.858 364.137C230.141 364.137 231.189 363.09 231.189 361.807C231.189 360.514 230.141 359.477 228.858 359.477Z"
            fill="#ADADAD"
          />
          <path
            d="M228.858 365.923C227.575 365.923 226.527 366.97 226.527 368.264C226.527 369.546 227.575 370.594 228.858 370.594C230.141 370.594 231.189 369.546 231.189 368.264C231.189 366.97 230.141 365.923 228.858 365.923Z"
            fill="#ADADAD"
          />
          <path
            d="M228.858 372.377C227.575 372.377 226.527 373.425 226.527 374.708C226.527 375.99 227.575 377.038 228.858 377.038C230.141 377.038 231.189 375.99 231.189 374.708C231.189 373.425 230.141 372.377 228.858 372.377Z"
            fill="#ADADAD"
          />
          <path
            d="M228.858 378.834C227.575 378.834 226.527 379.881 226.527 381.164C226.527 382.446 227.575 383.494 228.858 383.494C230.141 383.494 231.189 382.446 231.189 381.164C231.189 379.881 230.141 378.834 228.858 378.834Z"
            fill="#ADADAD"
          />
          <path
            d="M228.858 385.279C227.575 385.279 226.527 386.327 226.527 387.609C226.527 388.892 227.575 389.95 228.858 389.95C230.141 389.95 231.189 388.903 231.189 387.609C231.189 386.327 230.141 385.279 228.858 385.279Z"
            fill="#ADADAD"
          />
          <path
            d="M228.858 391.736C227.575 391.736 226.527 392.783 226.527 394.066C226.527 395.348 227.575 396.396 228.858 396.396C230.141 396.396 231.189 395.348 231.189 394.066C231.189 392.783 230.141 391.736 228.858 391.736Z"
            fill="#ADADAD"
          />
          <path
            d="M228.858 398.192C227.575 398.192 226.527 399.239 226.527 400.522C226.527 401.805 227.575 402.852 228.858 402.852C230.141 402.852 231.189 401.805 231.189 400.522C231.189 399.239 230.141 398.192 228.858 398.192Z"
            fill="#ADADAD"
          />
          <path
            d="M228.858 404.636C227.575 404.636 226.527 405.684 226.527 406.966C226.527 408.26 227.575 409.307 228.858 409.307C230.141 409.307 231.189 408.26 231.189 406.966C231.189 405.684 230.141 404.636 228.858 404.636Z"
            fill="#ADADAD"
          />
          <path
            d="M228.858 436.905C227.575 436.905 226.527 437.953 226.527 439.235C226.527 440.518 227.575 441.565 228.858 441.565C230.141 441.565 231.189 440.518 231.189 439.235C231.189 437.953 230.141 436.905 228.858 436.905Z"
            fill="#ADADAD"
          />
          <path
            d="M222.398 282.038C221.115 282.038 220.067 283.085 220.067 284.368C220.067 285.651 221.115 286.698 222.398 286.698C223.682 286.698 224.73 285.651 224.73 284.368C224.741 283.085 223.692 282.038 222.398 282.038Z"
            fill="#ADADAD"
          />
          <path
            d="M222.398 288.493C221.115 288.493 220.067 289.541 220.067 290.834C220.067 292.117 221.115 293.164 222.398 293.164C223.682 293.164 224.73 292.117 224.73 290.834C224.741 289.541 223.692 288.493 222.398 288.493Z"
            fill="#ADADAD"
          />
          <path
            d="M222.398 320.752C221.115 320.752 220.067 321.799 220.067 323.082C220.067 324.364 221.115 325.412 222.398 325.412C223.682 325.412 224.73 324.364 224.73 323.082C224.741 321.799 223.692 320.752 222.398 320.752Z"
            fill="#ADADAD"
          />
          <path
            d="M222.398 327.208C221.115 327.208 220.067 328.255 220.067 329.538C220.067 330.821 221.115 331.868 222.398 331.868C223.682 331.868 224.73 330.821 224.73 329.538C224.741 328.255 223.692 327.208 222.398 327.208Z"
            fill="#ADADAD"
          />
          <path
            d="M222.398 333.653C221.115 333.653 220.067 334.7 220.067 335.983C220.067 337.266 221.115 338.313 222.398 338.313C223.682 338.313 224.73 337.266 224.73 335.983C224.741 334.7 223.692 333.653 222.398 333.653Z"
            fill="#ADADAD"
          />
          <path
            d="M222.398 340.109C221.115 340.109 220.067 341.157 220.067 342.439C220.067 343.722 221.115 344.769 222.398 344.769C223.682 344.769 224.73 343.722 224.73 342.439C224.741 341.157 223.692 340.109 222.398 340.109Z"
            fill="#ADADAD"
          />
          <path
            d="M222.398 346.565C221.115 346.565 220.067 347.612 220.067 348.895C220.067 350.178 221.115 351.225 222.398 351.225C223.682 351.225 224.73 350.178 224.73 348.895C224.741 347.612 223.692 346.565 222.398 346.565Z"
            fill="#ADADAD"
          />
          <path
            d="M222.398 353.021C221.115 353.021 220.067 354.069 220.067 355.362C220.067 356.645 221.115 357.692 222.398 357.692C223.682 357.692 224.73 356.645 224.73 355.362C224.741 354.069 223.692 353.021 222.398 353.021Z"
            fill="#ADADAD"
          />
          <path
            d="M222.398 359.477C221.115 359.477 220.067 360.524 220.067 361.807C220.067 363.09 221.115 364.137 222.398 364.137C223.682 364.137 224.73 363.09 224.73 361.807C224.741 360.514 223.692 359.477 222.398 359.477Z"
            fill="#ADADAD"
          />
          <path
            d="M222.398 365.922C221.115 365.922 220.067 366.97 220.067 368.263C220.067 369.546 221.115 370.593 222.398 370.593C223.682 370.593 224.73 369.546 224.73 368.263C224.741 366.97 223.692 365.922 222.398 365.922Z"
            fill="#ADADAD"
          />
          <path
            d="M222.398 372.378C221.115 372.378 220.067 373.425 220.067 374.708C220.067 375.991 221.115 377.038 222.398 377.038C223.682 377.038 224.73 375.991 224.73 374.708C224.741 373.425 223.692 372.378 222.398 372.378Z"
            fill="#ADADAD"
          />
          <path
            d="M222.398 378.834C221.115 378.834 220.067 379.881 220.067 381.164C220.067 382.446 221.115 383.494 222.398 383.494C223.682 383.494 224.73 382.446 224.73 381.164C224.73 379.881 223.692 378.834 222.398 378.834Z"
            fill="#ADADAD"
          />
          <path
            d="M222.398 385.279C221.115 385.279 220.067 386.327 220.067 387.609C220.067 388.892 221.115 389.95 222.398 389.95C223.682 389.95 224.73 388.903 224.73 387.609C224.741 386.327 223.692 385.279 222.398 385.279Z"
            fill="#ADADAD"
          />
          <path
            d="M222.398 391.735C221.115 391.735 220.067 392.782 220.067 394.065C220.067 395.348 221.115 396.395 222.398 396.395C223.682 396.395 224.73 395.348 224.73 394.065C224.741 392.782 223.692 391.735 222.398 391.735Z"
            fill="#ADADAD"
          />
          <path
            d="M222.398 398.191C221.115 398.191 220.067 399.239 220.067 400.521C220.067 401.804 221.115 402.851 222.398 402.851C223.682 402.851 224.73 401.804 224.73 400.521C224.741 399.239 223.692 398.191 222.398 398.191Z"
            fill="#ADADAD"
          />
          <path
            d="M222.398 404.636C221.115 404.636 220.067 405.684 220.067 406.966C220.067 408.26 221.115 409.307 222.398 409.307C223.682 409.307 224.73 408.26 224.73 406.966C224.741 405.684 223.692 404.636 222.398 404.636Z"
            fill="#ADADAD"
          />
          <path
            d="M222.398 411.092C221.115 411.092 220.067 412.14 220.067 413.423C220.067 414.705 221.115 415.753 222.398 415.753C223.682 415.753 224.73 414.705 224.73 413.423C224.741 412.14 223.692 411.092 222.398 411.092Z"
            fill="#ADADAD"
          />
          <path
            d="M222.398 417.548C221.115 417.548 220.067 418.596 220.067 419.889C220.067 421.172 221.115 422.208 222.398 422.208C223.682 422.208 224.73 421.161 224.73 419.889C224.741 418.596 223.692 417.548 222.398 417.548Z"
            fill="#ADADAD"
          />
          <path
            d="M222.398 430.449C221.115 430.449 220.067 431.497 220.067 432.79C220.067 434.073 221.115 435.12 222.398 435.12C223.682 435.12 224.73 434.073 224.73 432.79C224.741 431.497 223.692 430.449 222.398 430.449Z"
            fill="#ADADAD"
          />
          <path
            d="M222.398 436.905C221.115 436.905 220.067 437.953 220.067 439.235C220.067 440.518 221.115 441.565 222.398 441.565C223.682 441.565 224.73 440.518 224.73 439.235C224.741 437.953 223.692 436.905 222.398 436.905Z"
            fill="#ADADAD"
          />
          <path
            d="M215.95 275.582C214.656 275.582 213.608 276.629 213.608 277.922C213.608 279.205 214.656 280.253 215.95 280.253C217.233 280.253 218.281 279.205 218.281 277.922C218.281 276.629 217.233 275.582 215.95 275.582Z"
            fill="#ADADAD"
          />
          <path
            d="M215.95 282.038C214.656 282.038 213.608 283.085 213.608 284.368C213.608 285.651 214.656 286.698 215.95 286.698C217.233 286.698 218.281 285.651 218.281 284.368C218.281 283.085 217.233 282.038 215.95 282.038Z"
            fill="#ADADAD"
          />
          <path
            d="M215.95 288.493C214.656 288.493 213.608 289.541 213.608 290.834C213.608 292.117 214.656 293.164 215.95 293.164C217.233 293.164 218.281 292.117 218.281 290.834C218.281 289.541 217.233 288.493 215.95 288.493Z"
            fill="#ADADAD"
          />
          <path
            d="M215.95 294.939C214.656 294.939 213.608 295.987 213.608 297.28C213.608 298.563 214.656 299.61 215.95 299.61C217.233 299.61 218.281 298.563 218.281 297.28C218.281 295.987 217.233 294.939 215.95 294.939Z"
            fill="#ADADAD"
          />
          <path
            d="M215.95 301.395C214.656 301.395 213.608 302.442 213.608 303.725C213.608 305.008 214.656 306.055 215.95 306.055C217.233 306.055 218.281 305.008 218.281 303.725C218.281 302.442 217.233 301.395 215.95 301.395Z"
            fill="#ADADAD"
          />
          <path
            d="M215.95 307.851C214.656 307.851 213.608 308.898 213.608 310.192C213.608 311.474 214.656 312.522 215.95 312.522C217.233 312.522 218.281 311.474 218.281 310.192C218.281 308.898 217.233 307.851 215.95 307.851Z"
            fill="#ADADAD"
          />
          <path
            d="M215.95 314.296C214.656 314.296 213.608 315.343 213.608 316.626C213.608 317.909 214.656 318.956 215.95 318.956C217.233 318.956 218.281 317.909 218.281 316.626C218.281 315.343 217.233 314.296 215.95 314.296Z"
            fill="#ADADAD"
          />
          <path
            d="M215.95 320.752C214.656 320.752 213.608 321.799 213.608 323.082C213.608 324.364 214.656 325.412 215.95 325.412C217.233 325.412 218.281 324.364 218.281 323.082C218.281 321.799 217.233 320.752 215.95 320.752Z"
            fill="#ADADAD"
          />
          <path
            d="M215.95 327.208C214.656 327.208 213.608 328.255 213.608 329.538C213.608 330.821 214.656 331.868 215.95 331.868C217.233 331.868 218.281 330.821 218.281 329.538C218.281 328.255 217.233 327.208 215.95 327.208Z"
            fill="#ADADAD"
          />
          <path
            d="M215.95 333.653C214.656 333.653 213.608 334.7 213.608 335.983C213.608 337.266 214.656 338.313 215.95 338.313C217.233 338.313 218.281 337.266 218.281 335.983C218.281 334.7 217.233 333.653 215.95 333.653Z"
            fill="#ADADAD"
          />
          <path
            d="M215.95 340.109C214.656 340.109 213.608 341.157 213.608 342.439C213.608 343.722 214.656 344.769 215.95 344.769C217.233 344.769 218.281 343.722 218.281 342.439C218.281 341.157 217.233 340.109 215.95 340.109Z"
            fill="#ADADAD"
          />
          <path
            d="M215.95 365.922C214.656 365.922 213.608 366.97 213.608 368.263C213.608 369.546 214.656 370.593 215.95 370.593C217.233 370.593 218.281 369.546 218.281 368.263C218.281 366.97 217.233 365.922 215.95 365.922Z"
            fill="#ADADAD"
          />
          <path
            d="M215.95 372.378C214.656 372.378 213.608 373.425 213.608 374.708C213.608 375.991 214.656 377.038 215.95 377.038C217.233 377.038 218.281 375.991 218.281 374.708C218.281 373.425 217.233 372.378 215.95 372.378Z"
            fill="#ADADAD"
          />
          <path
            d="M215.95 378.834C214.656 378.834 213.608 379.881 213.608 381.164C213.608 382.446 214.656 383.494 215.95 383.494C217.233 383.494 218.281 382.446 218.281 381.164C218.281 379.881 217.233 378.834 215.95 378.834Z"
            fill="#ADADAD"
          />
          <path
            d="M215.95 385.279C214.656 385.279 213.608 386.327 213.608 387.609C213.608 388.892 214.656 389.95 215.95 389.95C217.233 389.95 218.281 388.903 218.281 387.609C218.281 386.327 217.233 385.279 215.95 385.279Z"
            fill="#ADADAD"
          />
          <path
            d="M215.95 391.735C214.656 391.735 213.608 392.782 213.608 394.065C213.608 395.348 214.656 396.395 215.95 396.395C217.233 396.395 218.281 395.348 218.281 394.065C218.281 392.782 217.233 391.735 215.95 391.735Z"
            fill="#ADADAD"
          />
          <path
            d="M215.95 398.191C214.656 398.191 213.608 399.239 213.608 400.521C213.608 401.804 214.656 402.851 215.95 402.851C217.233 402.851 218.281 401.804 218.281 400.521C218.281 399.239 217.233 398.191 215.95 398.191Z"
            fill="#ADADAD"
          />
          <path
            d="M215.95 404.636C214.656 404.636 213.608 405.684 213.608 406.966C213.608 408.26 214.656 409.307 215.95 409.307C217.233 409.307 218.281 408.26 218.281 406.966C218.281 405.684 217.233 404.636 215.95 404.636Z"
            fill="#ADADAD"
          />
          <path
            d="M215.95 411.092C214.656 411.092 213.608 412.14 213.608 413.423C213.608 414.705 214.656 415.753 215.95 415.753C217.233 415.753 218.281 414.705 218.281 413.423C218.281 412.14 217.233 411.092 215.95 411.092Z"
            fill="#ADADAD"
          />
          <path
            d="M215.95 417.548C214.656 417.548 213.608 418.596 213.608 419.889C213.608 421.172 214.656 422.208 215.95 422.208C217.233 422.208 218.281 421.161 218.281 419.889C218.281 418.596 217.233 417.548 215.95 417.548Z"
            fill="#ADADAD"
          />
          <path
            d="M215.95 424.004C214.656 424.004 213.608 425.052 213.608 426.335C213.608 427.617 214.656 428.665 215.95 428.665C217.233 428.665 218.281 427.617 218.281 426.335C218.281 425.052 217.233 424.004 215.95 424.004Z"
            fill="#ADADAD"
          />
          <path
            d="M215.95 430.449C214.656 430.449 213.608 431.497 213.608 432.79C213.608 434.073 214.656 435.12 215.95 435.12C217.233 435.12 218.281 434.073 218.281 432.79C218.281 431.497 217.233 430.449 215.95 430.449Z"
            fill="#ADADAD"
          />
          <path
            d="M215.95 436.905C214.656 436.905 213.608 437.953 213.608 439.235C213.608 440.518 214.656 441.565 215.95 441.565C217.233 441.565 218.281 440.518 218.281 439.235C218.281 437.953 217.233 436.905 215.95 436.905Z"
            fill="#ADADAD"
          />
          <path
            d="M209.489 282.038C208.205 282.038 207.157 283.086 207.157 284.368C207.157 285.651 208.205 286.699 209.489 286.699C210.772 286.699 211.82 285.651 211.82 284.368C211.82 283.086 210.772 282.038 209.489 282.038Z"
            fill="#ADADAD"
          />
          <path
            d="M209.489 288.493C208.205 288.493 207.157 289.54 207.157 290.834C207.157 292.116 208.205 293.164 209.489 293.164C210.772 293.164 211.82 292.116 211.82 290.834C211.82 289.54 210.772 288.493 209.489 288.493Z"
            fill="#ADADAD"
          />
          <path
            d="M209.489 294.939C208.205 294.939 207.157 295.986 207.157 297.28C207.157 298.562 208.205 299.61 209.489 299.61C210.772 299.61 211.82 298.562 211.82 297.28C211.82 295.986 210.772 294.939 209.489 294.939Z"
            fill="#ADADAD"
          />
          <path
            d="M209.489 301.395C208.205 301.395 207.157 302.442 207.157 303.725C207.157 305.008 208.205 306.055 209.489 306.055C210.772 306.055 211.82 305.008 211.82 303.725C211.82 302.442 210.772 301.395 209.489 301.395Z"
            fill="#ADADAD"
          />
          <path
            d="M209.489 307.851C208.205 307.851 207.157 308.899 207.157 310.192C207.157 311.475 208.205 312.522 209.489 312.522C210.772 312.522 211.82 311.475 211.82 310.192C211.82 308.899 210.772 307.851 209.489 307.851Z"
            fill="#ADADAD"
          />
          <path
            d="M209.489 314.295C208.205 314.295 207.157 315.343 207.157 316.626C207.157 317.908 208.205 318.956 209.489 318.956C210.772 318.956 211.82 317.908 211.82 316.626C211.82 315.343 210.772 314.295 209.489 314.295Z"
            fill="#ADADAD"
          />
          <path
            d="M209.489 320.752C208.205 320.752 207.157 321.799 207.157 323.082C207.157 324.364 208.205 325.412 209.489 325.412C210.772 325.412 211.82 324.364 211.82 323.082C211.82 321.799 210.772 320.752 209.489 320.752Z"
            fill="#ADADAD"
          />
          <path
            d="M209.489 327.208C208.205 327.208 207.157 328.255 207.157 329.538C207.157 330.821 208.205 331.868 209.489 331.868C210.772 331.868 211.82 330.821 211.82 329.538C211.82 328.255 210.772 327.208 209.489 327.208Z"
            fill="#ADADAD"
          />
          <path
            d="M209.489 333.654C208.205 333.654 207.157 334.701 207.157 335.984C207.157 337.266 208.205 338.314 209.489 338.314C210.772 338.314 211.82 337.266 211.82 335.984C211.82 334.701 210.772 333.654 209.489 333.654Z"
            fill="#ADADAD"
          />
          <path
            d="M209.489 398.192C208.205 398.192 207.157 399.239 207.157 400.522C207.157 401.805 208.205 402.852 209.489 402.852C210.772 402.852 211.82 401.805 211.82 400.522C211.82 399.239 210.772 398.192 209.489 398.192Z"
            fill="#ADADAD"
          />
          <path
            d="M209.489 404.636C208.205 404.636 207.157 405.684 207.157 406.966C207.157 408.26 208.205 409.307 209.489 409.307C210.772 409.307 211.82 408.26 211.82 406.966C211.82 405.684 210.772 404.636 209.489 404.636Z"
            fill="#ADADAD"
          />
          <path
            d="M209.489 411.092C208.205 411.092 207.157 412.14 207.157 413.422C207.157 414.705 208.205 415.753 209.489 415.753C210.772 415.753 211.82 414.705 211.82 413.422C211.82 412.14 210.772 411.092 209.489 411.092Z"
            fill="#ADADAD"
          />
          <path
            d="M209.489 417.548C208.205 417.548 207.157 418.596 207.157 419.889C207.157 421.172 208.205 422.209 209.489 422.209C210.772 422.209 211.82 421.161 211.82 419.889C211.82 418.596 210.772 417.548 209.489 417.548Z"
            fill="#ADADAD"
          />
          <path
            d="M209.489 424.005C208.205 424.005 207.157 425.052 207.157 426.335C207.157 427.617 208.205 428.665 209.489 428.665C210.772 428.665 211.82 427.617 211.82 426.335C211.82 425.052 210.772 424.005 209.489 424.005Z"
            fill="#ADADAD"
          />
          <path
            d="M209.489 430.449C208.205 430.449 207.157 431.496 207.157 432.79C207.157 434.072 208.205 435.12 209.489 435.12C210.772 435.12 211.82 434.072 211.82 432.79C211.82 431.496 210.772 430.449 209.489 430.449Z"
            fill="#ADADAD"
          />
          <path
            d="M203.029 282.038C201.746 282.038 200.698 283.086 200.698 284.368C200.698 285.651 201.746 286.699 203.029 286.699C204.313 286.699 205.361 285.651 205.361 284.368C205.361 283.086 204.313 282.038 203.029 282.038Z"
            fill="#ADADAD"
          />
          <path
            d="M203.029 288.493C201.746 288.493 200.698 289.54 200.698 290.834C200.698 292.116 201.746 293.164 203.029 293.164C204.313 293.164 205.361 292.116 205.361 290.834C205.361 289.54 204.313 288.493 203.029 288.493Z"
            fill="#ADADAD"
          />
          <path
            d="M203.029 294.939C201.746 294.939 200.698 295.986 200.698 297.28C200.698 298.562 201.746 299.61 203.029 299.61C204.313 299.61 205.361 298.562 205.361 297.28C205.361 295.986 204.313 294.939 203.029 294.939Z"
            fill="#ADADAD"
          />
          <path
            d="M203.029 306.055C204.317 306.055 205.361 305.012 205.361 303.725C205.361 302.438 204.317 301.395 203.029 301.395C201.742 301.395 200.698 302.438 200.698 303.725C200.698 305.012 201.742 306.055 203.029 306.055Z"
            fill="#ADADAD"
          />
          <path
            d="M203.029 307.851C201.746 307.851 200.698 308.899 200.698 310.192C200.698 311.475 201.746 312.522 203.029 312.522C204.313 312.522 205.361 311.475 205.361 310.192C205.361 308.899 204.313 307.851 203.029 307.851Z"
            fill="#ADADAD"
          />
          <path
            d="M203.029 314.295C201.746 314.295 200.698 315.343 200.698 316.626C200.698 317.908 201.746 318.956 203.029 318.956C204.313 318.956 205.361 317.908 205.361 316.626C205.361 315.343 204.313 314.295 203.029 314.295Z"
            fill="#ADADAD"
          />
          <path
            d="M203.029 320.752C201.746 320.752 200.698 321.799 200.698 323.082C200.698 324.364 201.746 325.412 203.029 325.412C204.313 325.412 205.361 324.364 205.361 323.082C205.361 321.799 204.313 320.752 203.029 320.752Z"
            fill="#ADADAD"
          />
          <path
            d="M203.029 327.208C201.746 327.208 200.698 328.255 200.698 329.538C200.698 330.821 201.746 331.868 203.029 331.868C204.313 331.868 205.361 330.821 205.361 329.538C205.361 328.255 204.313 327.208 203.029 327.208Z"
            fill="#ADADAD"
          />
          <path
            d="M196.572 282.038C195.288 282.038 194.229 283.086 194.229 284.368C194.229 285.651 195.278 286.699 196.572 286.699C197.855 286.699 198.903 285.651 198.903 284.368C198.914 283.086 197.866 282.038 196.572 282.038Z"
            fill="#ADADAD"
          />
          <path
            d="M196.572 288.493C195.288 288.493 194.229 289.54 194.229 290.834C194.229 292.116 195.278 293.164 196.572 293.164C197.855 293.164 198.903 292.116 198.903 290.834C198.914 289.54 197.866 288.493 196.572 288.493Z"
            fill="#ADADAD"
          />
          <path
            d="M196.572 301.395C195.288 301.395 194.229 302.442 194.229 303.725C194.229 305.008 195.278 306.055 196.572 306.055C197.855 306.055 198.903 305.008 198.903 303.725C198.914 302.442 197.866 301.395 196.572 301.395Z"
            fill="#ADADAD"
          />
          <path
            d="M196.572 307.851C195.288 307.851 194.229 308.899 194.229 310.192C194.229 311.475 195.278 312.522 196.572 312.522C197.855 312.522 198.903 311.475 198.903 310.192C198.914 308.899 197.866 307.851 196.572 307.851Z"
            fill="#ADADAD"
          />
          <path
            d="M196.572 314.295C195.288 314.295 194.229 315.343 194.229 316.626C194.229 317.908 195.278 318.956 196.572 318.956C197.855 318.956 198.903 317.908 198.903 316.626C198.914 315.343 197.866 314.295 196.572 314.295Z"
            fill="#ADADAD"
          />
          <path
            d="M190.121 269.126C188.827 269.126 187.779 270.173 187.779 271.456C187.779 272.739 188.827 273.786 190.121 273.786C191.405 273.786 192.453 272.739 192.453 271.456C192.453 270.173 191.405 269.126 190.121 269.126Z"
            fill="#ADADAD"
          />
          <path
            d="M190.121 275.582C188.827 275.582 187.779 276.63 187.779 277.923C187.779 279.206 188.827 280.253 190.121 280.253C191.405 280.253 192.453 279.206 192.453 277.923C192.453 276.63 191.405 275.582 190.121 275.582Z"
            fill="#ADADAD"
          />
          <path
            d="M190.121 282.038C188.827 282.038 187.779 283.086 187.779 284.368C187.779 285.651 188.827 286.699 190.121 286.699C191.405 286.699 192.453 285.651 192.453 284.368C192.453 283.086 191.405 282.038 190.121 282.038Z"
            fill="#ADADAD"
          />
          <path
            d="M183.663 269.126C182.379 269.126 181.331 270.173 181.331 271.456C181.331 272.739 182.379 273.786 183.663 273.786C184.946 273.786 185.994 272.739 185.994 271.456C185.994 270.173 184.946 269.126 183.663 269.126Z"
            fill="#ADADAD"
          />
          <path
            d="M183.663 275.582C182.379 275.582 181.331 276.629 181.331 277.922C181.331 279.205 182.379 280.253 183.663 280.253C184.946 280.253 185.994 279.205 185.994 277.922C185.994 276.629 184.946 275.582 183.663 275.582Z"
            fill="#ADADAD"
          />
          <path
            d="M183.663 282.038C182.379 282.038 181.331 283.085 181.331 284.368C181.331 285.651 182.379 286.698 183.663 286.698C184.946 286.698 185.994 285.651 185.994 284.368C185.994 283.085 184.946 282.038 183.663 282.038Z"
            fill="#ADADAD"
          />
          <path
            d="M177.203 275.582C175.92 275.582 174.872 276.629 174.872 277.922C174.872 279.205 175.92 280.253 177.203 280.253C178.487 280.253 179.535 279.205 179.535 277.922C179.545 276.629 178.497 275.582 177.203 275.582Z"
            fill="#ADADAD"
          />
          <path
            d="M177.203 262.68C175.92 262.68 174.872 263.728 174.872 265.01C174.872 266.293 175.92 267.341 177.203 267.341C178.487 267.341 179.535 266.293 179.535 265.01C179.545 263.728 178.497 262.68 177.203 262.68Z"
            fill="#ADADAD"
          />
          <path
            d="M177.203 269.126C175.92 269.126 174.872 270.173 174.872 271.456C174.872 272.739 175.92 273.786 177.203 273.786C178.487 273.786 179.535 272.739 179.535 271.456C179.535 270.173 178.497 269.126 177.203 269.126Z"
            fill="#ADADAD"
          />
          <path
            d="M170.744 262.68C169.461 262.68 168.413 263.728 168.413 265.01C168.413 266.293 169.461 267.341 170.744 267.341C172.027 267.341 173.075 266.293 173.075 265.01C173.086 263.728 172.038 262.68 170.744 262.68Z"
            fill="#ADADAD"
          />
          <path
            d="M170.744 269.126C169.461 269.126 168.413 270.173 168.413 271.456C168.413 272.739 169.461 273.786 170.744 273.786C172.027 273.786 173.075 272.739 173.075 271.456C173.075 270.173 172.038 269.126 170.744 269.126Z"
            fill="#ADADAD"
          />
          <path
            d="M164.294 262.68C163.01 262.68 161.962 263.728 161.962 265.01C161.962 266.293 163.01 267.34 164.294 267.34C165.588 267.34 166.636 266.293 166.636 265.01C166.625 263.728 165.577 262.68 164.294 262.68Z"
            fill="#ADADAD"
          />
          <path
            d="M164.294 269.126C163.01 269.126 161.962 270.173 161.962 271.456C161.962 272.739 163.01 273.786 164.294 273.786C165.588 273.786 166.636 272.739 166.636 271.456C166.636 270.173 165.577 269.126 164.294 269.126Z"
            fill="#ADADAD"
          />
          <path
            d="M157.834 230.411C156.551 230.411 155.503 231.458 155.503 232.752C155.503 234.034 156.551 235.082 157.834 235.082C159.118 235.082 160.166 234.034 160.166 232.752C160.166 231.458 159.128 230.411 157.834 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M157.834 236.867C156.551 236.867 155.503 237.915 155.503 239.208C155.503 240.491 156.551 241.538 157.834 241.538C159.118 241.538 160.166 240.491 160.166 239.208C160.166 237.915 159.128 236.867 157.834 236.867Z"
            fill="#ADADAD"
          />
          <path
            d="M157.834 243.313C156.551 243.313 155.503 244.36 155.503 245.654C155.503 246.936 156.551 247.984 157.834 247.984C159.118 247.984 160.166 246.936 160.166 245.654C160.166 244.36 159.128 243.313 157.834 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M157.834 249.768C156.551 249.768 155.503 250.815 155.503 252.098C155.503 253.38 156.551 254.428 157.834 254.428C159.118 254.428 160.166 253.38 160.166 252.098C160.166 250.815 159.128 249.768 157.834 249.768Z"
            fill="#ADADAD"
          />
          <path
            d="M157.834 256.225C156.551 256.225 155.503 257.273 155.503 258.566C155.503 259.849 156.551 260.896 157.834 260.896C159.118 260.896 160.166 259.849 160.166 258.566C160.166 257.273 159.128 256.225 157.834 256.225Z"
            fill="#ADADAD"
          />
          <path
            d="M157.834 262.68C156.551 262.68 155.503 263.728 155.503 265.01C155.503 266.293 156.551 267.34 157.834 267.34C159.118 267.34 160.166 266.293 160.166 265.01C160.166 263.728 159.128 262.68 157.834 262.68Z"
            fill="#ADADAD"
          />
          <path
            d="M157.834 269.126C156.551 269.126 155.503 270.173 155.503 271.456C155.503 272.739 156.551 273.786 157.834 273.786C159.118 273.786 160.166 272.739 160.166 271.456C160.166 270.173 159.128 269.126 157.834 269.126Z"
            fill="#ADADAD"
          />
          <path
            d="M151.375 223.966C150.092 223.966 149.043 225.014 149.043 226.297C149.043 227.579 150.092 228.627 151.375 228.627C152.658 228.627 153.706 227.579 153.706 226.297C153.717 225.003 152.669 223.966 151.375 223.966Z"
            fill="#ADADAD"
          />
          <path
            d="M151.375 230.411C150.092 230.411 149.043 231.459 149.043 232.752C149.043 234.035 150.092 235.082 151.375 235.082C152.658 235.082 153.706 234.035 153.706 232.752C153.717 231.459 152.669 230.411 151.375 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M151.375 236.868C150.092 236.868 149.043 237.915 149.043 239.208C149.043 240.491 150.092 241.539 151.375 241.539C152.658 241.539 153.706 240.491 153.706 239.208C153.717 237.915 152.669 236.868 151.375 236.868Z"
            fill="#ADADAD"
          />
          <path
            d="M151.375 243.313C150.092 243.313 149.043 244.36 149.043 245.654C149.043 246.936 150.092 247.984 151.375 247.984C152.658 247.984 153.706 246.936 153.706 245.654C153.717 244.36 152.669 243.313 151.375 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M151.375 249.768C150.092 249.768 149.043 250.816 149.043 252.098C149.043 253.381 150.092 254.429 151.375 254.429C152.658 254.429 153.706 253.381 153.706 252.098C153.717 250.816 152.669 249.768 151.375 249.768Z"
            fill="#ADADAD"
          />
          <path
            d="M151.375 256.225C150.092 256.225 149.043 257.272 149.043 258.565C149.043 259.848 150.092 260.896 151.375 260.896C152.658 260.896 153.706 259.848 153.706 258.565C153.717 257.272 152.669 256.225 151.375 256.225Z"
            fill="#ADADAD"
          />
          <path
            d="M151.375 262.68C150.092 262.68 149.043 263.728 149.043 265.01C149.043 266.293 150.092 267.341 151.375 267.341C152.658 267.341 153.706 266.293 153.706 265.01C153.717 263.728 152.669 262.68 151.375 262.68Z"
            fill="#ADADAD"
          />
          <path
            d="M144.927 223.966C143.643 223.966 142.595 225.014 142.595 226.297C142.595 227.579 143.643 228.627 144.927 228.627C146.21 228.627 147.258 227.579 147.258 226.297C147.258 225.003 146.21 223.966 144.927 223.966Z"
            fill="#ADADAD"
          />
          <path
            d="M144.927 230.411C143.643 230.411 142.595 231.459 142.595 232.752C142.595 234.035 143.643 235.082 144.927 235.082C146.21 235.082 147.258 234.035 147.258 232.752C147.258 231.459 146.21 230.411 144.927 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M144.927 236.868C143.643 236.868 142.595 237.915 142.595 239.208C142.595 240.491 143.643 241.539 144.927 241.539C146.21 241.539 147.258 240.491 147.258 239.208C147.258 237.915 146.21 236.868 144.927 236.868Z"
            fill="#ADADAD"
          />
          <path
            d="M144.927 243.313C143.643 243.313 142.595 244.36 142.595 245.654C142.595 246.936 143.643 247.984 144.927 247.984C146.21 247.984 147.258 246.936 147.258 245.654C147.258 244.36 146.21 243.313 144.927 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M144.927 249.768C143.643 249.768 142.595 250.816 142.595 252.098C142.595 253.381 143.643 254.429 144.927 254.429C146.21 254.429 147.258 253.381 147.258 252.098C147.258 250.816 146.21 249.768 144.927 249.768Z"
            fill="#ADADAD"
          />
          <path
            d="M144.927 256.225C143.643 256.225 142.595 257.272 142.595 258.565C142.595 259.848 143.643 260.896 144.927 260.896C146.21 260.896 147.258 259.848 147.258 258.565C147.258 257.272 146.21 256.225 144.927 256.225Z"
            fill="#ADADAD"
          />
          <path
            d="M144.927 262.68C143.643 262.68 142.595 263.728 142.595 265.01C142.595 266.293 143.643 267.341 144.927 267.341C146.21 267.341 147.258 266.293 147.258 265.01C147.258 263.728 146.21 262.68 144.927 262.68Z"
            fill="#ADADAD"
          />
          <path
            d="M138.465 223.967C137.182 223.967 136.133 225.014 136.133 226.297C136.133 227.579 137.182 228.627 138.465 228.627C139.748 228.627 140.796 227.579 140.796 226.297C140.796 225.004 139.748 223.967 138.465 223.967Z"
            fill="#ADADAD"
          />
          <path
            d="M138.465 230.411C137.182 230.411 136.133 231.458 136.133 232.752C136.133 234.034 137.182 235.082 138.465 235.082C139.748 235.082 140.796 234.034 140.796 232.752C140.796 231.458 139.748 230.411 138.465 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M138.465 236.867C137.182 236.867 136.133 237.915 136.133 239.208C136.133 240.491 137.182 241.538 138.465 241.538C139.748 241.538 140.796 240.491 140.796 239.208C140.796 237.915 139.748 236.867 138.465 236.867Z"
            fill="#ADADAD"
          />
          <path
            d="M138.465 243.313C137.182 243.313 136.133 244.36 136.133 245.654C136.133 246.936 137.182 247.984 138.465 247.984C139.748 247.984 140.796 246.936 140.796 245.654C140.796 244.36 139.748 243.313 138.465 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M138.465 249.768C137.182 249.768 136.133 250.815 136.133 252.098C136.133 253.38 137.182 254.428 138.465 254.428C139.748 254.428 140.796 253.38 140.796 252.098C140.796 250.815 139.748 249.768 138.465 249.768Z"
            fill="#ADADAD"
          />
          <path
            d="M132.017 223.967C130.734 223.967 129.686 225.014 129.686 226.297C129.686 227.579 130.734 228.627 132.017 228.627C133.301 228.627 134.349 227.579 134.349 226.297C134.338 225.004 133.301 223.967 132.017 223.967Z"
            fill="#ADADAD"
          />
          <path
            d="M132.017 230.411C130.734 230.411 129.686 231.458 129.686 232.752C129.686 234.034 130.734 235.082 132.017 235.082C133.301 235.082 134.349 234.034 134.349 232.752C134.338 231.458 133.301 230.411 132.017 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M132.017 236.867C130.734 236.867 129.686 237.915 129.686 239.208C129.686 240.491 130.734 241.538 132.017 241.538C133.301 241.538 134.349 240.491 134.349 239.208C134.338 237.915 133.301 236.867 132.017 236.867Z"
            fill="#ADADAD"
          />
          <path
            d="M132.017 243.313C130.734 243.313 129.686 244.36 129.686 245.654C129.686 246.936 130.734 247.984 132.017 247.984C133.301 247.984 134.349 246.936 134.349 245.654C134.338 244.36 133.301 243.313 132.017 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M132.017 249.768C130.734 249.768 129.686 250.815 129.686 252.098C129.686 253.38 130.734 254.428 132.017 254.428C133.301 254.428 134.349 253.38 134.349 252.098C134.338 250.815 133.301 249.768 132.017 249.768Z"
            fill="#ADADAD"
          />
          <path
            d="M125.549 223.967C124.266 223.967 123.218 225.014 123.218 226.297C123.218 227.579 124.266 228.627 125.549 228.627C126.843 228.627 127.891 227.579 127.891 226.297C127.891 225.004 126.843 223.967 125.549 223.967Z"
            fill="#ADADAD"
          />
          <path
            d="M125.549 230.411C124.266 230.411 123.218 231.458 123.218 232.752C123.218 234.034 124.266 235.082 125.549 235.082C126.843 235.082 127.891 234.034 127.891 232.752C127.891 231.458 126.843 230.411 125.549 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M125.549 236.867C124.266 236.867 123.218 237.915 123.218 239.208C123.218 240.491 124.266 241.538 125.549 241.538C126.843 241.538 127.891 240.491 127.891 239.208C127.891 237.915 126.843 236.867 125.549 236.867Z"
            fill="#ADADAD"
          />
          <path
            d="M125.549 243.313C124.266 243.313 123.218 244.36 123.218 245.654C123.218 246.936 124.266 247.984 125.549 247.984C126.843 247.984 127.891 246.936 127.891 245.654C127.891 244.36 126.843 243.313 125.549 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M119.098 223.966C117.815 223.966 116.767 225.014 116.767 226.297C116.767 227.579 117.815 228.627 119.098 228.627C120.382 228.627 121.43 227.579 121.43 226.297C121.43 225.003 120.382 223.966 119.098 223.966Z"
            fill="#ADADAD"
          />
          <path
            d="M119.098 230.411C117.815 230.411 116.767 231.459 116.767 232.752C116.767 234.035 117.815 235.082 119.098 235.082C120.382 235.082 121.43 234.035 121.43 232.752C121.43 231.459 120.382 230.411 119.098 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M254.686 191.698C253.403 191.698 252.355 192.745 252.355 194.028C252.355 195.31 253.403 196.358 254.686 196.358C255.98 196.358 257.028 195.31 257.028 194.028C257.017 192.745 255.969 191.698 254.686 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M248.227 178.786C246.943 178.786 245.895 179.833 245.895 181.116C245.895 182.398 246.943 183.446 248.227 183.446C249.51 183.446 250.558 182.398 250.558 181.116C250.558 179.833 249.51 178.786 248.227 178.786Z"
            fill="#ADADAD"
          />
          <path
            d="M248.227 185.241C246.943 185.241 245.895 186.289 245.895 187.571C245.895 188.865 246.943 189.912 248.227 189.912C249.51 189.912 250.558 188.865 250.558 187.571C250.558 186.289 249.51 185.241 248.227 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M248.227 191.698C246.943 191.698 245.895 192.745 245.895 194.028C245.895 195.31 246.943 196.358 248.227 196.358C249.51 196.358 250.558 195.31 250.558 194.028C250.558 192.745 249.51 191.698 248.227 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M241.765 172.339C240.482 172.339 239.433 173.387 239.433 174.68C239.433 175.963 240.482 177.01 241.765 177.01C243.048 177.01 244.107 175.963 244.107 174.68C244.107 173.387 243.059 172.339 241.765 172.339Z"
            fill="#ADADAD"
          />
          <path
            d="M241.765 178.785C240.482 178.785 239.433 179.833 239.433 181.115C239.433 182.398 240.482 183.445 241.765 183.445C243.048 183.445 244.107 182.398 244.107 181.115C244.107 179.833 243.059 178.785 241.765 178.785Z"
            fill="#ADADAD"
          />
          <path
            d="M241.765 191.698C240.482 191.698 239.433 192.745 239.433 194.028C239.433 195.31 240.482 196.358 241.765 196.358C243.048 196.358 244.107 195.31 244.107 194.028C244.107 192.745 243.059 191.698 241.765 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M235.305 133.616C234.022 133.616 232.974 134.663 232.974 135.946C232.974 137.228 234.022 138.276 235.305 138.276C236.6 138.276 237.648 137.228 237.648 135.946C237.648 134.663 236.6 133.616 235.305 133.616Z"
            fill="#ADADAD"
          />
          <path
            d="M235.305 165.885C234.022 165.885 232.974 166.932 232.974 168.215C232.974 169.497 234.022 170.545 235.305 170.545C236.6 170.545 237.648 169.497 237.648 168.215C237.648 166.932 236.6 165.885 235.305 165.885Z"
            fill="#ADADAD"
          />
          <path
            d="M235.305 172.339C234.022 172.339 232.974 173.387 232.974 174.68C232.974 175.963 234.022 177.01 235.305 177.01C236.6 177.01 237.648 175.963 237.648 174.68C237.648 173.387 236.6 172.339 235.305 172.339Z"
            fill="#ADADAD"
          />
          <path
            d="M235.305 178.785C234.022 178.785 232.974 179.833 232.974 181.115C232.974 182.398 234.022 183.445 235.305 183.445C236.6 183.445 237.648 182.398 237.648 181.115C237.648 179.833 236.6 178.785 235.305 178.785Z"
            fill="#ADADAD"
          />
          <path
            d="M235.305 198.154C234.022 198.154 232.974 199.201 232.974 200.484C232.974 201.767 234.022 202.814 235.305 202.814C236.6 202.814 237.648 201.767 237.648 200.484C237.648 199.201 236.6 198.154 235.305 198.154Z"
            fill="#ADADAD"
          />
          <path
            d="M228.858 127.17C227.575 127.17 226.527 128.217 226.527 129.5C226.527 130.793 227.575 131.841 228.858 131.841C230.141 131.841 231.189 130.793 231.189 129.5C231.189 128.217 230.141 127.17 228.858 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M228.858 133.616C227.575 133.616 226.527 134.663 226.527 135.946C226.527 137.228 227.575 138.276 228.858 138.276C230.141 138.276 231.189 137.228 231.189 135.946C231.189 134.663 230.141 133.616 228.858 133.616Z"
            fill="#ADADAD"
          />
          <path
            d="M228.858 140.072C227.575 140.072 226.527 141.119 226.527 142.402C226.527 143.685 227.575 144.732 228.858 144.732C230.141 144.732 231.189 143.685 231.189 142.402C231.189 141.119 230.141 140.072 228.858 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M228.858 146.527C227.575 146.527 226.527 147.574 226.527 148.857C226.527 150.139 227.575 151.187 228.858 151.187C230.141 151.187 231.189 150.139 231.189 148.857C231.189 147.574 230.141 146.527 228.858 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M228.858 159.429C227.575 159.429 226.527 160.476 226.527 161.759C226.527 163.052 227.575 164.099 228.858 164.099C230.141 164.099 231.189 163.052 231.189 161.759C231.189 160.476 230.141 159.429 228.858 159.429Z"
            fill="#ADADAD"
          />
          <path
            d="M228.858 165.885C227.575 165.885 226.527 166.932 226.527 168.215C226.527 169.497 227.575 170.545 228.858 170.545C230.141 170.545 231.189 169.497 231.189 168.215C231.189 166.932 230.141 165.885 228.858 165.885Z"
            fill="#ADADAD"
          />
          <path
            d="M228.858 172.339C227.575 172.339 226.527 173.387 226.527 174.68C226.527 175.963 227.575 177.01 228.858 177.01C230.141 177.01 231.189 175.963 231.189 174.68C231.189 173.387 230.141 172.339 228.858 172.339Z"
            fill="#ADADAD"
          />
          <path
            d="M228.858 178.785C227.575 178.785 226.527 179.833 226.527 181.115C226.527 182.398 227.575 183.445 228.858 183.445C230.141 183.445 231.189 182.398 231.189 181.115C231.189 179.833 230.141 178.785 228.858 178.785Z"
            fill="#ADADAD"
          />
          <path
            d="M228.858 191.698C227.575 191.698 226.527 192.745 226.527 194.028C226.527 195.31 227.575 196.358 228.858 196.358C230.141 196.358 231.189 195.31 231.189 194.028C231.189 192.745 230.141 191.698 228.858 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M228.858 198.154C227.575 198.154 226.527 199.201 226.527 200.484C226.527 201.767 227.575 202.814 228.858 202.814C230.141 202.814 231.189 201.767 231.189 200.484C231.189 199.201 230.141 198.154 228.858 198.154Z"
            fill="#ADADAD"
          />
          <path
            d="M222.398 120.714C221.115 120.714 220.067 121.762 220.067 123.055C220.067 124.338 221.115 125.385 222.398 125.385C223.682 125.385 224.73 124.338 224.73 123.055C224.741 121.762 223.692 120.714 222.398 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M222.398 127.17C221.115 127.17 220.067 128.217 220.067 129.5C220.067 130.793 221.115 131.841 222.398 131.841C223.682 131.841 224.73 130.793 224.73 129.5C224.741 128.217 223.692 127.17 222.398 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M222.398 133.615C221.115 133.615 220.067 134.662 220.067 135.945C220.067 137.228 221.115 138.275 222.398 138.275C223.682 138.275 224.73 137.228 224.73 135.945C224.741 134.662 223.692 133.615 222.398 133.615Z"
            fill="#ADADAD"
          />
          <path
            d="M222.398 140.071C221.115 140.071 220.067 141.119 220.067 142.401C220.067 143.684 221.115 144.731 222.398 144.731C223.682 144.731 224.73 143.684 224.73 142.401C224.741 141.119 223.692 140.071 222.398 140.071Z"
            fill="#ADADAD"
          />
          <path
            d="M222.398 146.527C221.115 146.527 220.067 147.574 220.067 148.857C220.067 150.14 221.115 151.187 222.398 151.187C223.682 151.187 224.73 150.14 224.73 148.857C224.73 147.574 223.692 146.527 222.398 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M222.398 159.428C221.115 159.428 220.067 160.476 220.067 161.758C220.067 163.052 221.115 164.099 222.398 164.099C223.682 164.099 224.73 163.052 224.73 161.758C224.741 160.476 223.692 159.428 222.398 159.428Z"
            fill="#ADADAD"
          />
          <path
            d="M222.398 165.884C221.115 165.884 220.067 166.932 220.067 168.215C220.067 169.497 221.115 170.545 222.398 170.545C223.682 170.545 224.73 169.497 224.73 168.215C224.741 166.932 223.692 165.884 222.398 165.884Z"
            fill="#ADADAD"
          />
          <path
            d="M222.398 172.34C221.115 172.34 220.067 173.388 220.067 174.681C220.067 175.963 221.115 177.011 222.398 177.011C223.682 177.011 224.73 175.963 224.73 174.681C224.741 173.388 223.692 172.34 222.398 172.34Z"
            fill="#ADADAD"
          />
          <path
            d="M222.398 178.786C221.115 178.786 220.067 179.833 220.067 181.116C220.067 182.398 221.115 183.446 222.398 183.446C223.682 183.446 224.73 182.398 224.73 181.116C224.741 179.833 223.692 178.786 222.398 178.786Z"
            fill="#ADADAD"
          />
          <path
            d="M222.398 185.241C221.115 185.241 220.067 186.289 220.067 187.571C220.067 188.865 221.115 189.912 222.398 189.912C223.682 189.912 224.73 188.865 224.73 187.571C224.741 186.289 223.692 185.241 222.398 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M222.398 191.698C221.115 191.698 220.067 192.745 220.067 194.028C220.067 195.31 221.115 196.358 222.398 196.358C223.682 196.358 224.73 195.31 224.73 194.028C224.741 192.745 223.692 191.698 222.398 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M222.398 198.153C221.115 198.153 220.067 199.201 220.067 200.483C220.067 201.766 221.115 202.813 222.398 202.813C223.682 202.813 224.73 201.766 224.73 200.483C224.741 199.201 223.692 198.153 222.398 198.153Z"
            fill="#ADADAD"
          />
          <path
            d="M215.95 114.258C214.656 114.258 213.608 115.306 213.608 116.599C213.608 117.881 214.656 118.929 215.95 118.929C217.233 118.929 218.281 117.881 218.281 116.599C218.281 115.306 217.233 114.258 215.95 114.258Z"
            fill="#ADADAD"
          />
          <path
            d="M215.95 120.714C214.656 120.714 213.608 121.762 213.608 123.055C213.608 124.338 214.656 125.385 215.95 125.385C217.233 125.385 218.281 124.338 218.281 123.055C218.281 121.762 217.233 120.714 215.95 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M215.95 127.17C214.656 127.17 213.608 128.217 213.608 129.5C213.608 130.793 214.656 131.841 215.95 131.841C217.233 131.841 218.281 130.793 218.281 129.5C218.281 128.217 217.233 127.17 215.95 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M215.95 133.615C214.656 133.615 213.608 134.662 213.608 135.945C213.608 137.228 214.656 138.275 215.95 138.275C217.233 138.275 218.281 137.228 218.281 135.945C218.281 134.662 217.233 133.615 215.95 133.615Z"
            fill="#ADADAD"
          />
          <path
            d="M215.95 140.071C214.656 140.071 213.608 141.119 213.608 142.401C213.608 143.684 214.656 144.731 215.95 144.731C217.233 144.731 218.281 143.684 218.281 142.401C218.281 141.119 217.233 140.071 215.95 140.071Z"
            fill="#ADADAD"
          />
          <path
            d="M215.95 152.983C214.656 152.983 213.608 154.031 213.608 155.313C213.608 156.596 214.656 157.654 215.95 157.654C217.233 157.654 218.281 156.607 218.281 155.313C218.281 154.031 217.233 152.983 215.95 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M215.95 159.428C214.656 159.428 213.608 160.476 213.608 161.758C213.608 163.052 214.656 164.099 215.95 164.099C217.233 164.099 218.281 163.052 218.281 161.758C218.281 160.476 217.233 159.428 215.95 159.428Z"
            fill="#ADADAD"
          />
          <path
            d="M215.95 165.884C214.656 165.884 213.608 166.932 213.608 168.215C213.608 169.497 214.656 170.545 215.95 170.545C217.233 170.545 218.281 169.497 218.281 168.215C218.281 166.932 217.233 165.884 215.95 165.884Z"
            fill="#ADADAD"
          />
          <path
            d="M215.95 172.34C214.656 172.34 213.608 173.388 213.608 174.681C213.608 175.963 214.656 177.011 215.95 177.011C217.233 177.011 218.281 175.963 218.281 174.681C218.281 173.388 217.233 172.34 215.95 172.34Z"
            fill="#ADADAD"
          />
          <path
            d="M215.95 178.786C214.656 178.786 213.608 179.833 213.608 181.116C213.608 182.398 214.656 183.446 215.95 183.446C217.233 183.446 218.281 182.398 218.281 181.116C218.281 179.833 217.233 178.786 215.95 178.786Z"
            fill="#ADADAD"
          />
          <path
            d="M215.95 185.241C214.656 185.241 213.608 186.289 213.608 187.571C213.608 188.865 214.656 189.912 215.95 189.912C217.233 189.912 218.281 188.865 218.281 187.571C218.281 186.289 217.233 185.241 215.95 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M215.95 191.698C214.656 191.698 213.608 192.745 213.608 194.028C213.608 195.31 214.656 196.358 215.95 196.358C217.233 196.358 218.281 195.31 218.281 194.028C218.281 192.745 217.233 191.698 215.95 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M215.95 198.153C214.656 198.153 213.608 199.201 213.608 200.483C213.608 201.766 214.656 202.813 215.95 202.813C217.233 202.813 218.281 201.766 218.281 200.483C218.281 199.201 217.233 198.153 215.95 198.153Z"
            fill="#ADADAD"
          />
          <path
            d="M215.95 204.598C214.656 204.598 213.608 205.646 213.608 206.928C213.608 208.211 214.656 209.258 215.95 209.258C217.233 209.258 218.281 208.211 218.281 206.928C218.281 205.646 217.233 204.598 215.95 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M209.489 114.257C208.205 114.257 207.157 115.305 207.157 116.598C207.157 117.881 208.205 118.928 209.489 118.928C210.772 118.928 211.82 117.881 211.82 116.598C211.82 115.305 210.772 114.257 209.489 114.257Z"
            fill="#ADADAD"
          />
          <path
            d="M209.489 120.714C208.205 120.714 207.157 121.761 207.157 123.054C207.157 124.337 208.205 125.385 209.489 125.385C210.772 125.385 211.82 124.337 211.82 123.054C211.82 121.761 210.772 120.714 209.489 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M209.489 140.072C208.205 140.072 207.157 141.119 207.157 142.402C207.157 143.685 208.205 144.732 209.489 144.732C210.772 144.732 211.82 143.685 211.82 142.402C211.82 141.119 210.772 140.072 209.489 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M209.489 146.527C208.205 146.527 207.157 147.574 207.157 148.857C207.157 150.139 208.205 151.187 209.489 151.187C210.772 151.187 211.82 150.139 211.82 148.857C211.82 147.574 210.772 146.527 209.489 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M209.489 152.983C208.205 152.983 207.157 154.03 207.157 155.313C207.157 156.596 208.205 157.654 209.489 157.654C210.772 157.654 211.82 156.606 211.82 155.313C211.82 154.03 210.772 152.983 209.489 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M209.489 159.429C208.205 159.429 207.157 160.476 207.157 161.759C207.157 163.052 208.205 164.099 209.489 164.099C210.772 164.099 211.82 163.052 211.82 161.759C211.82 160.476 210.772 159.429 209.489 159.429Z"
            fill="#ADADAD"
          />
          <path
            d="M209.489 165.885C208.205 165.885 207.157 166.932 207.157 168.215C207.157 169.497 208.205 170.545 209.489 170.545C210.772 170.545 211.82 169.497 211.82 168.215C211.82 166.932 210.772 165.885 209.489 165.885Z"
            fill="#ADADAD"
          />
          <path
            d="M209.489 172.339C208.205 172.339 207.157 173.387 207.157 174.68C207.157 175.963 208.205 177.01 209.489 177.01C210.772 177.01 211.82 175.963 211.82 174.68C211.82 173.387 210.772 172.339 209.489 172.339Z"
            fill="#ADADAD"
          />
          <path
            d="M209.489 178.785C208.205 178.785 207.157 179.833 207.157 181.115C207.157 182.398 208.205 183.445 209.489 183.445C210.772 183.445 211.82 182.398 211.82 181.115C211.82 179.833 210.772 178.785 209.489 178.785Z"
            fill="#ADADAD"
          />
          <path
            d="M209.489 185.241C208.205 185.241 207.157 186.289 207.157 187.572C207.157 188.865 208.205 189.912 209.489 189.912C210.772 189.912 211.82 188.865 211.82 187.572C211.82 186.289 210.772 185.241 209.489 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M209.489 191.698C208.205 191.698 207.157 192.745 207.157 194.028C207.157 195.31 208.205 196.358 209.489 196.358C210.772 196.358 211.82 195.31 211.82 194.028C211.82 192.745 210.772 191.698 209.489 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M209.489 198.154C208.205 198.154 207.157 199.201 207.157 200.484C207.157 201.767 208.205 202.814 209.489 202.814C210.772 202.814 211.82 201.767 211.82 200.484C211.82 199.201 210.772 198.154 209.489 198.154Z"
            fill="#ADADAD"
          />
          <path
            d="M209.489 204.598C208.205 204.598 207.157 205.646 207.157 206.928C207.157 208.211 208.205 209.258 209.489 209.258C210.772 209.258 211.82 208.211 211.82 206.928C211.82 205.646 210.772 204.598 209.489 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M209.489 211.054C208.205 211.054 207.157 212.102 207.157 213.395C207.157 214.678 208.205 215.725 209.489 215.725C210.772 215.725 211.82 214.678 211.82 213.395C211.82 212.102 210.772 211.054 209.489 211.054Z"
            fill="#ADADAD"
          />
          <path
            d="M203.029 114.257C201.746 114.257 200.698 115.305 200.698 116.598C200.698 117.881 201.746 118.928 203.029 118.928C204.313 118.928 205.361 117.881 205.361 116.598C205.361 115.305 204.313 114.257 203.029 114.257Z"
            fill="#ADADAD"
          />
          <path
            d="M203.029 120.714C201.746 120.714 200.698 121.761 200.698 123.054C200.698 124.337 201.746 125.385 203.029 125.385C204.313 125.385 205.361 124.337 205.361 123.054C205.361 121.761 204.313 120.714 203.029 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M203.029 127.17C201.746 127.17 200.698 128.217 200.698 129.5C200.698 130.793 201.746 131.841 203.029 131.841C204.313 131.841 205.361 130.793 205.361 129.5C205.361 128.217 204.313 127.17 203.029 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M203.029 140.072C201.746 140.072 200.698 141.119 200.698 142.402C200.698 143.685 201.746 144.732 203.029 144.732C204.313 144.732 205.361 143.685 205.361 142.402C205.361 141.119 204.313 140.072 203.029 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M203.029 151.187C204.317 151.187 205.361 150.144 205.361 148.857C205.361 147.57 204.317 146.527 203.029 146.527C201.742 146.527 200.698 147.57 200.698 148.857C200.698 150.144 201.742 151.187 203.029 151.187Z"
            fill="#ADADAD"
          />
          <path
            d="M203.029 152.983C201.746 152.983 200.698 154.03 200.698 155.313C200.698 156.596 201.746 157.654 203.029 157.654C204.313 157.654 205.361 156.606 205.361 155.313C205.361 154.03 204.313 152.983 203.029 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M203.029 159.429C201.746 159.429 200.698 160.476 200.698 161.759C200.698 163.052 201.746 164.099 203.029 164.099C204.313 164.099 205.361 163.052 205.361 161.759C205.361 160.476 204.313 159.429 203.029 159.429Z"
            fill="#ADADAD"
          />
          <path
            d="M203.029 172.339C201.746 172.339 200.698 173.387 200.698 174.68C200.698 175.963 201.746 177.01 203.029 177.01C204.313 177.01 205.361 175.963 205.361 174.68C205.361 173.387 204.313 172.339 203.029 172.339Z"
            fill="#ADADAD"
          />
          <path
            d="M203.029 178.785C201.746 178.785 200.698 179.833 200.698 181.115C200.698 182.398 201.746 183.445 203.029 183.445C204.313 183.445 205.361 182.398 205.361 181.115C205.361 179.833 204.313 178.785 203.029 178.785Z"
            fill="#ADADAD"
          />
          <path
            d="M203.029 185.241C201.746 185.241 200.698 186.289 200.698 187.572C200.698 188.865 201.746 189.912 203.029 189.912C204.313 189.912 205.361 188.865 205.361 187.572C205.361 186.289 204.313 185.241 203.029 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M203.029 191.698C201.746 191.698 200.698 192.745 200.698 194.028C200.698 195.31 201.746 196.358 203.029 196.358C204.313 196.358 205.361 195.31 205.361 194.028C205.361 192.745 204.313 191.698 203.029 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M203.029 198.154C201.746 198.154 200.698 199.201 200.698 200.484C200.698 201.767 201.746 202.814 203.029 202.814C204.313 202.814 205.361 201.767 205.361 200.484C205.361 199.201 204.313 198.154 203.029 198.154Z"
            fill="#ADADAD"
          />
          <path
            d="M203.029 204.598C201.746 204.598 200.698 205.646 200.698 206.928C200.698 208.211 201.746 209.258 203.029 209.258C204.313 209.258 205.361 208.211 205.361 206.928C205.361 205.646 204.313 204.598 203.029 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M203.029 211.054C201.746 211.054 200.698 212.102 200.698 213.395C200.698 214.678 201.746 215.725 203.029 215.725C204.313 215.725 205.361 214.678 205.361 213.395C205.361 212.102 204.313 211.054 203.029 211.054Z"
            fill="#ADADAD"
          />
          <path
            d="M203.029 217.51C201.746 217.51 200.698 218.558 200.698 219.841C200.698 221.123 201.746 222.181 203.029 222.181C204.313 222.181 205.361 221.134 205.361 219.841C205.361 218.558 204.313 217.51 203.029 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M203.029 223.967C201.746 223.967 200.698 225.014 200.698 226.297C200.698 227.579 201.746 228.627 203.029 228.627C204.313 228.627 205.361 227.579 205.361 226.297C205.361 225.004 204.313 223.967 203.029 223.967Z"
            fill="#ADADAD"
          />
          <path
            d="M196.572 101.357C195.288 101.357 194.229 102.404 194.229 103.687C194.229 104.97 195.278 106.017 196.572 106.017C197.855 106.017 198.903 104.97 198.903 103.687C198.914 102.404 197.866 101.357 196.572 101.357Z"
            fill="#ADADAD"
          />
          <path
            d="M196.572 107.813C195.288 107.813 194.229 108.861 194.229 110.143C194.229 111.426 195.278 112.473 196.572 112.473C197.855 112.473 198.903 111.426 198.903 110.143C198.914 108.861 197.866 107.813 196.572 107.813Z"
            fill="#ADADAD"
          />
          <path
            d="M196.572 114.257C195.288 114.257 194.229 115.305 194.229 116.598C194.229 117.881 195.278 118.928 196.572 118.928C197.855 118.928 198.903 117.881 198.903 116.598C198.914 115.305 197.866 114.257 196.572 114.257Z"
            fill="#ADADAD"
          />
          <path
            d="M196.572 120.714C195.288 120.714 194.229 121.761 194.229 123.054C194.229 124.337 195.278 125.385 196.572 125.385C197.855 125.385 198.903 124.337 198.903 123.054C198.914 121.761 197.866 120.714 196.572 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M196.572 185.241C195.288 185.241 194.229 186.289 194.229 187.572C194.229 188.865 195.278 189.912 196.572 189.912C197.855 189.912 198.903 188.865 198.903 187.572C198.914 186.289 197.866 185.241 196.572 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M196.572 191.698C195.288 191.698 194.229 192.745 194.229 194.028C194.229 195.31 195.278 196.358 196.572 196.358C197.855 196.358 198.903 195.31 198.903 194.028C198.914 192.745 197.866 191.698 196.572 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M196.572 198.154C195.288 198.154 194.229 199.201 194.229 200.484C194.229 201.767 195.278 202.814 196.572 202.814C197.855 202.814 198.903 201.767 198.903 200.484C198.914 199.201 197.866 198.154 196.572 198.154Z"
            fill="#ADADAD"
          />
          <path
            d="M196.572 204.598C195.288 204.598 194.229 205.646 194.229 206.928C194.229 208.211 195.278 209.258 196.572 209.258C197.855 209.258 198.903 208.211 198.903 206.928C198.914 205.646 197.866 204.598 196.572 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M196.572 211.054C195.288 211.054 194.229 212.102 194.229 213.395C194.229 214.678 195.278 215.725 196.572 215.725C197.855 215.725 198.903 214.678 198.903 213.395C198.914 212.102 197.866 211.054 196.572 211.054Z"
            fill="#ADADAD"
          />
          <path
            d="M196.572 217.51C195.288 217.51 194.229 218.558 194.229 219.841C194.229 221.123 195.278 222.181 196.572 222.181C197.855 222.181 198.903 221.134 198.903 219.841C198.914 218.558 197.866 217.51 196.572 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M196.572 223.967C195.288 223.967 194.229 225.014 194.229 226.297C194.229 227.579 195.278 228.627 196.572 228.627C197.855 228.627 198.903 227.579 198.903 226.297C198.914 225.004 197.866 223.967 196.572 223.967Z"
            fill="#ADADAD"
          />
          <path
            d="M196.572 230.411C195.288 230.411 194.229 231.458 194.229 232.752C194.229 234.034 195.278 235.082 196.572 235.082C197.855 235.082 198.903 234.034 198.903 232.752C198.914 231.458 197.866 230.411 196.572 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M196.572 236.867C195.288 236.867 194.229 237.915 194.229 239.208C194.229 240.491 195.278 241.538 196.572 241.538C197.855 241.538 198.903 240.491 198.903 239.208C198.914 237.915 197.866 236.867 196.572 236.867Z"
            fill="#ADADAD"
          />
          <path
            d="M196.572 243.313C195.288 243.313 194.229 244.36 194.229 245.654C194.229 246.936 195.278 247.984 196.572 247.984C197.855 247.984 198.903 246.936 198.903 245.654C198.914 244.36 197.866 243.313 196.572 243.313Z"
            fill="#ADADAD"
          />
          <path
            d="M190.121 101.357C188.827 101.357 187.779 102.404 187.779 103.687C187.779 104.97 188.827 106.017 190.121 106.017C191.405 106.017 192.453 104.97 192.453 103.687C192.453 102.404 191.405 101.357 190.121 101.357Z"
            fill="#ADADAD"
          />
          <path
            d="M190.121 107.813C188.827 107.813 187.779 108.861 187.779 110.143C187.779 111.426 188.827 112.473 190.121 112.473C191.405 112.473 192.453 111.426 192.453 110.143C192.453 108.861 191.405 107.813 190.121 107.813Z"
            fill="#ADADAD"
          />
          <path
            d="M190.121 114.257C188.827 114.257 187.779 115.305 187.779 116.598C187.779 117.881 188.827 118.928 190.121 118.928C191.405 118.928 192.453 117.881 192.453 116.598C192.453 115.305 191.405 114.257 190.121 114.257Z"
            fill="#ADADAD"
          />
          <path
            d="M190.121 120.714C188.827 120.714 187.779 121.761 187.779 123.054C187.779 124.337 188.827 125.385 190.121 125.385C191.405 125.385 192.453 124.337 192.453 123.054C192.453 121.761 191.405 120.714 190.121 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M190.121 127.17C188.827 127.17 187.779 128.217 187.779 129.5C187.779 130.793 188.827 131.841 190.121 131.841C191.405 131.841 192.453 130.793 192.453 129.5C192.453 128.217 191.405 127.17 190.121 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M190.121 133.616C188.827 133.616 187.779 134.663 187.779 135.946C187.779 137.228 188.827 138.276 190.121 138.276C191.405 138.276 192.453 137.228 192.453 135.946C192.453 134.663 191.405 133.616 190.121 133.616Z"
            fill="#ADADAD"
          />
          <path
            d="M190.121 140.072C188.827 140.072 187.779 141.119 187.779 142.402C187.779 143.685 188.827 144.732 190.121 144.732C191.405 144.732 192.453 143.685 192.453 142.402C192.453 141.119 191.405 140.072 190.121 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M190.121 146.527C188.827 146.527 187.779 147.574 187.779 148.857C187.779 150.139 188.827 151.187 190.121 151.187C191.405 151.187 192.453 150.139 192.453 148.857C192.453 147.574 191.405 146.527 190.121 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M190.121 172.339C188.827 172.339 187.779 173.387 187.779 174.68C187.779 175.963 188.827 177.01 190.121 177.01C191.405 177.01 192.453 175.963 192.453 174.68C192.453 173.387 191.405 172.339 190.121 172.339Z"
            fill="#ADADAD"
          />
          <path
            d="M190.121 178.785C188.827 178.785 187.779 179.833 187.779 181.115C187.779 182.398 188.827 183.445 190.121 183.445C191.405 183.445 192.453 182.398 192.453 181.115C192.453 179.833 191.405 178.785 190.121 178.785Z"
            fill="#ADADAD"
          />
          <path
            d="M190.121 185.241C188.827 185.241 187.779 186.289 187.779 187.572C187.779 188.865 188.827 189.912 190.121 189.912C191.405 189.912 192.453 188.865 192.453 187.572C192.453 186.289 191.405 185.241 190.121 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M190.121 191.698C188.827 191.698 187.779 192.745 187.779 194.028C187.779 195.31 188.827 196.358 190.121 196.358C191.405 196.358 192.453 195.31 192.453 194.028C192.453 192.745 191.405 191.698 190.121 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M190.121 198.154C188.827 198.154 187.779 199.201 187.779 200.484C187.779 201.767 188.827 202.814 190.121 202.814C191.405 202.814 192.453 201.767 192.453 200.484C192.453 199.201 191.405 198.154 190.121 198.154Z"
            fill="#ADADAD"
          />
          <path
            d="M190.121 204.598C188.827 204.598 187.779 205.646 187.779 206.928C187.779 208.211 188.827 209.258 190.121 209.258C191.405 209.258 192.453 208.211 192.453 206.928C192.453 205.646 191.405 204.598 190.121 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M190.121 211.054C188.827 211.054 187.779 212.102 187.779 213.395C187.779 214.678 188.827 215.725 190.121 215.725C191.405 215.725 192.453 214.678 192.453 213.395C192.453 212.102 191.405 211.054 190.121 211.054Z"
            fill="#ADADAD"
          />
          <path
            d="M190.121 217.51C188.827 217.51 187.779 218.558 187.779 219.841C187.779 221.123 188.827 222.181 190.121 222.181C191.405 222.181 192.453 221.134 192.453 219.841C192.453 218.558 191.405 217.51 190.121 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M190.121 223.967C188.827 223.967 187.779 225.014 187.779 226.297C187.779 227.579 188.827 228.627 190.121 228.627C191.405 228.627 192.453 227.579 192.453 226.297C192.453 225.004 191.405 223.967 190.121 223.967Z"
            fill="#ADADAD"
          />
          <path
            d="M190.121 230.411C188.827 230.411 187.779 231.458 187.779 232.752C187.779 234.034 188.827 235.082 190.121 235.082C191.405 235.082 192.453 234.034 192.453 232.752C192.453 231.458 191.405 230.411 190.121 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M190.121 236.867C188.827 236.867 187.779 237.915 187.779 239.208C187.779 240.491 188.827 241.538 190.121 241.538C191.405 241.538 192.453 240.491 192.453 239.208C192.453 237.915 191.405 236.867 190.121 236.867Z"
            fill="#ADADAD"
          />
          <path
            d="M183.663 101.357C182.379 101.357 181.331 102.404 181.331 103.687C181.331 104.969 182.379 106.017 183.663 106.017C184.946 106.017 185.994 104.969 185.994 103.687C185.994 102.404 184.946 101.357 183.663 101.357Z"
            fill="#ADADAD"
          />
          <path
            d="M183.663 107.813C182.379 107.813 181.331 108.86 181.331 110.143C181.331 111.426 182.379 112.473 183.663 112.473C184.946 112.473 185.994 111.426 185.994 110.143C185.994 108.86 184.946 107.813 183.663 107.813Z"
            fill="#ADADAD"
          />
          <path
            d="M183.663 114.258C182.379 114.258 181.331 115.306 181.331 116.599C181.331 117.881 182.379 118.929 183.663 118.929C184.946 118.929 185.994 117.881 185.994 116.599C185.994 115.306 184.946 114.258 183.663 114.258Z"
            fill="#ADADAD"
          />
          <path
            d="M183.663 120.714C182.379 120.714 181.331 121.762 181.331 123.055C181.331 124.338 182.379 125.385 183.663 125.385C184.946 125.385 185.994 124.338 185.994 123.055C185.994 121.762 184.946 120.714 183.663 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M183.663 127.17C182.379 127.17 181.331 128.217 181.331 129.5C181.331 130.793 182.379 131.841 183.663 131.841C184.946 131.841 185.994 130.793 185.994 129.5C185.994 128.217 184.946 127.17 183.663 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M183.663 133.615C182.379 133.615 181.331 134.662 181.331 135.945C181.331 137.228 182.379 138.275 183.663 138.275C184.946 138.275 185.994 137.228 185.994 135.945C185.994 134.662 184.946 133.615 183.663 133.615Z"
            fill="#ADADAD"
          />
          <path
            d="M183.663 140.071C182.379 140.071 181.331 141.119 181.331 142.401C181.331 143.684 182.379 144.731 183.663 144.731C184.946 144.731 185.994 143.684 185.994 142.401C185.994 141.119 184.946 140.071 183.663 140.071Z"
            fill="#ADADAD"
          />
          <path
            d="M183.663 172.34C182.379 172.34 181.331 173.388 181.331 174.681C181.331 175.963 182.379 177.011 183.663 177.011C184.946 177.011 185.994 175.963 185.994 174.681C185.994 173.388 184.946 172.34 183.663 172.34Z"
            fill="#ADADAD"
          />
          <path
            d="M183.663 178.786C182.379 178.786 181.331 179.833 181.331 181.116C181.331 182.398 182.379 183.446 183.663 183.446C184.946 183.446 185.994 182.398 185.994 181.116C185.994 179.833 184.946 178.786 183.663 178.786Z"
            fill="#ADADAD"
          />
          <path
            d="M183.663 185.241C182.379 185.241 181.331 186.289 181.331 187.571C181.331 188.865 182.379 189.912 183.663 189.912C184.946 189.912 185.994 188.865 185.994 187.571C185.994 186.289 184.946 185.241 183.663 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M183.663 191.698C182.379 191.698 181.331 192.745 181.331 194.028C181.331 195.31 182.379 196.358 183.663 196.358C184.946 196.358 185.994 195.31 185.994 194.028C185.994 192.745 184.946 191.698 183.663 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M183.663 198.153C182.379 198.153 181.331 199.201 181.331 200.483C181.331 201.766 182.379 202.813 183.663 202.813C184.946 202.813 185.994 201.766 185.994 200.483C185.994 199.201 184.946 198.153 183.663 198.153Z"
            fill="#ADADAD"
          />
          <path
            d="M183.663 204.598C182.379 204.598 181.331 205.646 181.331 206.928C181.331 208.211 182.379 209.258 183.663 209.258C184.946 209.258 185.994 208.211 185.994 206.928C185.994 205.646 184.946 204.598 183.663 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M183.663 211.055C182.379 211.055 181.331 212.102 181.331 213.395C181.331 214.678 182.379 215.725 183.663 215.725C184.946 215.725 185.994 214.678 185.994 213.395C185.994 212.102 184.946 211.055 183.663 211.055Z"
            fill="#ADADAD"
          />
          <path
            d="M183.663 217.51C182.379 217.51 181.331 218.558 181.331 219.84C181.331 221.123 182.379 222.181 183.663 222.181C184.946 222.181 185.994 221.134 185.994 219.84C185.994 218.558 184.946 217.51 183.663 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M183.663 223.966C182.379 223.966 181.331 225.014 181.331 226.297C181.331 227.579 182.379 228.627 183.663 228.627C184.946 228.627 185.994 227.579 185.994 226.297C185.994 225.003 184.946 223.966 183.663 223.966Z"
            fill="#ADADAD"
          />
          <path
            d="M183.663 230.411C182.379 230.411 181.331 231.459 181.331 232.752C181.331 234.035 182.379 235.082 183.663 235.082C184.946 235.082 185.994 234.035 185.994 232.752C185.994 231.459 184.946 230.411 183.663 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M183.663 236.868C182.379 236.868 181.331 237.915 181.331 239.208C181.331 240.491 182.379 241.539 183.663 241.539C184.946 241.539 185.994 240.491 185.994 239.208C185.994 237.915 184.946 236.868 183.663 236.868Z"
            fill="#ADADAD"
          />
          <path
            d="M177.203 94.9011C175.92 94.9011 174.872 95.9486 174.872 97.2312C174.872 98.5138 175.92 99.5613 177.203 99.5613C178.487 99.5613 179.535 98.5138 179.535 97.2312C179.545 95.9486 178.497 94.9011 177.203 94.9011Z"
            fill="#ADADAD"
          />
          <path
            d="M177.203 101.357C175.92 101.357 174.872 102.404 174.872 103.687C174.872 104.969 175.92 106.017 177.203 106.017C178.487 106.017 179.535 104.969 179.535 103.687C179.545 102.404 178.497 101.357 177.203 101.357Z"
            fill="#ADADAD"
          />
          <path
            d="M177.203 112.473C178.491 112.473 179.535 111.43 179.535 110.143C179.535 108.856 178.491 107.813 177.203 107.813C175.916 107.813 174.872 108.856 174.872 110.143C174.872 111.43 175.916 112.473 177.203 112.473Z"
            fill="#ADADAD"
          />
          <path
            d="M177.203 114.258C175.92 114.258 174.872 115.306 174.872 116.599C174.872 117.881 175.92 118.929 177.203 118.929C178.487 118.929 179.535 117.881 179.535 116.599C179.545 115.306 178.497 114.258 177.203 114.258Z"
            fill="#ADADAD"
          />
          <path
            d="M177.203 127.17C175.92 127.17 174.872 128.217 174.872 129.5C174.872 130.793 175.92 131.841 177.203 131.841C178.487 131.841 179.535 130.793 179.535 129.5C179.545 128.217 178.497 127.17 177.203 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M177.203 133.615C175.92 133.615 174.872 134.662 174.872 135.945C174.872 137.228 175.92 138.275 177.203 138.275C178.487 138.275 179.535 137.228 179.535 135.945C179.545 134.662 178.497 133.615 177.203 133.615Z"
            fill="#ADADAD"
          />
          <path
            d="M177.203 140.071C175.92 140.071 174.872 141.119 174.872 142.401C174.872 143.684 175.92 144.731 177.203 144.731C178.487 144.731 179.535 143.684 179.535 142.401C179.545 141.119 178.497 140.071 177.203 140.071Z"
            fill="#ADADAD"
          />
          <path
            d="M177.203 165.884C175.92 165.884 174.872 166.932 174.872 168.215C174.872 169.497 175.92 170.545 177.203 170.545C178.487 170.545 179.535 169.497 179.535 168.215C179.545 166.932 178.497 165.884 177.203 165.884Z"
            fill="#ADADAD"
          />
          <path
            d="M177.203 172.34C175.92 172.34 174.872 173.388 174.872 174.681C174.872 175.963 175.92 177.011 177.203 177.011C178.487 177.011 179.535 175.963 179.535 174.681C179.545 173.388 178.497 172.34 177.203 172.34Z"
            fill="#ADADAD"
          />
          <path
            d="M177.203 178.786C175.92 178.786 174.872 179.833 174.872 181.116C174.872 182.398 175.92 183.446 177.203 183.446C178.487 183.446 179.535 182.398 179.535 181.116C179.545 179.833 178.497 178.786 177.203 178.786Z"
            fill="#ADADAD"
          />
          <path
            d="M177.203 185.241C175.92 185.241 174.872 186.289 174.872 187.571C174.872 188.865 175.92 189.912 177.203 189.912C178.487 189.912 179.535 188.865 179.535 187.571C179.545 186.289 178.497 185.241 177.203 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M177.203 191.698C175.92 191.698 174.872 192.745 174.872 194.028C174.872 195.31 175.92 196.358 177.203 196.358C178.487 196.358 179.535 195.31 179.535 194.028C179.545 192.745 178.497 191.698 177.203 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M177.203 198.153C175.92 198.153 174.872 199.201 174.872 200.483C174.872 201.766 175.92 202.813 177.203 202.813C178.487 202.813 179.535 201.766 179.535 200.483C179.545 199.201 178.497 198.153 177.203 198.153Z"
            fill="#ADADAD"
          />
          <path
            d="M177.203 204.598C175.92 204.598 174.872 205.646 174.872 206.928C174.872 208.211 175.92 209.258 177.203 209.258C178.487 209.258 179.535 208.211 179.535 206.928C179.545 205.646 178.497 204.598 177.203 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M177.203 211.055C175.92 211.055 174.872 212.102 174.872 213.395C174.872 214.678 175.92 215.725 177.203 215.725C178.487 215.725 179.535 214.678 179.535 213.395C179.545 212.102 178.497 211.055 177.203 211.055Z"
            fill="#ADADAD"
          />
          <path
            d="M177.203 217.51C175.92 217.51 174.872 218.558 174.872 219.84C174.872 221.123 175.92 222.181 177.203 222.181C178.487 222.181 179.535 221.134 179.535 219.84C179.545 218.558 178.497 217.51 177.203 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M177.203 223.966C175.92 223.966 174.872 225.014 174.872 226.297C174.872 227.579 175.92 228.627 177.203 228.627C178.487 228.627 179.535 227.579 179.535 226.297C179.545 225.003 178.497 223.966 177.203 223.966Z"
            fill="#ADADAD"
          />
          <path
            d="M177.203 230.411C175.92 230.411 174.872 231.459 174.872 232.752C174.872 234.035 175.92 235.082 177.203 235.082C178.487 235.082 179.535 234.035 179.535 232.752C179.545 231.459 178.497 230.411 177.203 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M177.203 236.868C175.92 236.868 174.872 237.915 174.872 239.208C174.872 240.491 175.92 241.539 177.203 241.539C178.487 241.539 179.535 240.491 179.535 239.208C179.545 237.915 178.497 236.868 177.203 236.868Z"
            fill="#ADADAD"
          />
          <path
            d="M170.744 94.9011C169.461 94.9011 168.413 95.9486 168.413 97.2312C168.413 98.5138 169.461 99.5613 170.744 99.5613C172.027 99.5613 173.075 98.5138 173.075 97.2312C173.086 95.9486 172.038 94.9011 170.744 94.9011Z"
            fill="#ADADAD"
          />
          <path
            d="M170.744 107.813C169.461 107.813 168.413 108.86 168.413 110.143C168.413 111.426 169.461 112.473 170.744 112.473C172.027 112.473 173.075 111.426 173.075 110.143C173.086 108.86 172.038 107.813 170.744 107.813Z"
            fill="#ADADAD"
          />
          <path
            d="M170.744 120.714C169.461 120.714 168.413 121.762 168.413 123.055C168.413 124.338 169.461 125.385 170.744 125.385C172.027 125.385 173.075 124.338 173.075 123.055C173.086 121.762 172.038 120.714 170.744 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M170.744 127.17C169.461 127.17 168.413 128.217 168.413 129.5C168.413 130.793 169.461 131.841 170.744 131.841C172.027 131.841 173.075 130.793 173.075 129.5C173.086 128.217 172.038 127.17 170.744 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M170.744 133.615C169.461 133.615 168.413 134.662 168.413 135.945C168.413 137.228 169.461 138.275 170.744 138.275C172.027 138.275 173.075 137.228 173.075 135.945C173.086 134.662 172.038 133.615 170.744 133.615Z"
            fill="#ADADAD"
          />
          <path
            d="M170.744 140.071C169.461 140.071 168.413 141.119 168.413 142.401C168.413 143.684 169.461 144.731 170.744 144.731C172.027 144.731 173.075 143.684 173.075 142.401C173.086 141.119 172.038 140.071 170.744 140.071Z"
            fill="#ADADAD"
          />
          <path
            d="M170.744 146.527C169.461 146.527 168.413 147.574 168.413 148.857C168.413 150.14 169.461 151.187 170.744 151.187C172.027 151.187 173.075 150.14 173.075 148.857C173.075 147.574 172.038 146.527 170.744 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M170.744 159.428C169.461 159.428 168.413 160.476 168.413 161.758C168.413 163.052 169.461 164.099 170.744 164.099C172.027 164.099 173.075 163.052 173.075 161.758C173.086 160.476 172.038 159.428 170.744 159.428Z"
            fill="#ADADAD"
          />
          <path
            d="M170.744 165.884C169.461 165.884 168.413 166.932 168.413 168.215C168.413 169.497 169.461 170.545 170.744 170.545C172.027 170.545 173.075 169.497 173.075 168.215C173.086 166.932 172.038 165.884 170.744 165.884Z"
            fill="#ADADAD"
          />
          <path
            d="M170.744 172.34C169.461 172.34 168.413 173.388 168.413 174.681C168.413 175.963 169.461 177.011 170.744 177.011C172.027 177.011 173.075 175.963 173.075 174.681C173.086 173.388 172.038 172.34 170.744 172.34Z"
            fill="#ADADAD"
          />
          <path
            d="M170.744 178.786C169.461 178.786 168.413 179.833 168.413 181.116C168.413 182.398 169.461 183.446 170.744 183.446C172.027 183.446 173.075 182.398 173.075 181.116C173.086 179.833 172.038 178.786 170.744 178.786Z"
            fill="#ADADAD"
          />
          <path
            d="M170.744 185.241C169.461 185.241 168.413 186.289 168.413 187.571C168.413 188.865 169.461 189.912 170.744 189.912C172.027 189.912 173.075 188.865 173.075 187.571C173.086 186.289 172.038 185.241 170.744 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M170.744 191.698C169.461 191.698 168.413 192.745 168.413 194.028C168.413 195.31 169.461 196.358 170.744 196.358C172.027 196.358 173.075 195.31 173.075 194.028C173.086 192.745 172.038 191.698 170.744 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M170.744 198.153C169.461 198.153 168.413 199.201 168.413 200.483C168.413 201.766 169.461 202.813 170.744 202.813C172.027 202.813 173.075 201.766 173.075 200.483C173.086 199.201 172.038 198.153 170.744 198.153Z"
            fill="#ADADAD"
          />
          <path
            d="M170.744 204.598C169.461 204.598 168.413 205.646 168.413 206.928C168.413 208.211 169.461 209.258 170.744 209.258C172.027 209.258 173.075 208.211 173.075 206.928C173.086 205.646 172.038 204.598 170.744 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M170.744 211.055C169.461 211.055 168.413 212.102 168.413 213.395C168.413 214.678 169.461 215.725 170.744 215.725C172.027 215.725 173.075 214.678 173.075 213.395C173.086 212.102 172.038 211.055 170.744 211.055Z"
            fill="#ADADAD"
          />
          <path
            d="M170.744 217.51C169.461 217.51 168.413 218.558 168.413 219.84C168.413 221.123 169.461 222.181 170.744 222.181C172.027 222.181 173.075 221.134 173.075 219.84C173.086 218.558 172.038 217.51 170.744 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M170.744 223.966C169.461 223.966 168.413 225.014 168.413 226.297C168.413 227.579 169.461 228.627 170.744 228.627C172.027 228.627 173.075 227.579 173.075 226.297C173.086 225.003 172.038 223.966 170.744 223.966Z"
            fill="#ADADAD"
          />
          <path
            d="M170.744 230.411C169.461 230.411 168.413 231.459 168.413 232.752C168.413 234.035 169.461 235.082 170.744 235.082C172.027 235.082 173.075 234.035 173.075 232.752C173.086 231.459 172.038 230.411 170.744 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M170.744 236.868C169.461 236.868 168.413 237.915 168.413 239.208C168.413 240.491 169.461 241.539 170.744 241.539C172.027 241.539 173.075 240.491 173.075 239.208C173.086 237.915 172.038 236.868 170.744 236.868Z"
            fill="#ADADAD"
          />
          <path
            d="M164.294 88.4446C163.01 88.4446 161.962 89.4921 161.962 90.7747C161.962 92.0574 163.01 93.1048 164.294 93.1048C165.588 93.1048 166.636 92.0574 166.636 90.7747C166.625 89.4921 165.577 88.4446 164.294 88.4446Z"
            fill="#ADADAD"
          />
          <path
            d="M164.294 107.813C163.01 107.813 161.962 108.861 161.962 110.143C161.962 111.426 163.01 112.473 164.294 112.473C165.588 112.473 166.636 111.426 166.636 110.143C166.625 108.861 165.577 107.813 164.294 107.813Z"
            fill="#ADADAD"
          />
          <path
            d="M164.294 114.257C163.01 114.257 161.962 115.305 161.962 116.598C161.962 117.881 163.01 118.928 164.294 118.928C165.588 118.928 166.636 117.881 166.636 116.598C166.625 115.305 165.577 114.257 164.294 114.257Z"
            fill="#ADADAD"
          />
          <path
            d="M164.294 120.714C163.01 120.714 161.962 121.761 161.962 123.054C161.962 124.337 163.01 125.385 164.294 125.385C165.588 125.385 166.636 124.337 166.636 123.054C166.625 121.761 165.577 120.714 164.294 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M164.294 127.17C163.01 127.17 161.962 128.217 161.962 129.5C161.962 130.793 163.01 131.841 164.294 131.841C165.588 131.841 166.636 130.793 166.636 129.5C166.625 128.217 165.577 127.17 164.294 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M164.294 133.616C163.01 133.616 161.962 134.663 161.962 135.946C161.962 137.228 163.01 138.276 164.294 138.276C165.588 138.276 166.636 137.228 166.636 135.946C166.625 134.663 165.577 133.616 164.294 133.616Z"
            fill="#ADADAD"
          />
          <path
            d="M164.294 140.072C163.01 140.072 161.962 141.119 161.962 142.402C161.962 143.685 163.01 144.732 164.294 144.732C165.588 144.732 166.636 143.685 166.636 142.402C166.625 141.119 165.577 140.072 164.294 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M164.294 146.527C163.01 146.527 161.962 147.574 161.962 148.857C161.962 150.139 163.01 151.187 164.294 151.187C165.588 151.187 166.636 150.139 166.636 148.857C166.636 147.574 165.577 146.527 164.294 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M164.294 152.983C163.01 152.983 161.962 154.03 161.962 155.313C161.962 156.596 163.01 157.654 164.294 157.654C165.588 157.654 166.636 156.606 166.636 155.313C166.625 154.03 165.577 152.983 164.294 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M164.294 159.429C163.01 159.429 161.962 160.476 161.962 161.759C161.962 163.052 163.01 164.099 164.294 164.099C165.588 164.099 166.636 163.052 166.636 161.759C166.625 160.476 165.577 159.429 164.294 159.429Z"
            fill="#ADADAD"
          />
          <path
            d="M164.294 165.885C163.01 165.885 161.962 166.932 161.962 168.215C161.962 169.497 163.01 170.545 164.294 170.545C165.588 170.545 166.636 169.497 166.636 168.215C166.625 166.932 165.577 165.885 164.294 165.885Z"
            fill="#ADADAD"
          />
          <path
            d="M164.294 172.339C163.01 172.339 161.962 173.387 161.962 174.68C161.962 175.963 163.01 177.01 164.294 177.01C165.588 177.01 166.636 175.963 166.636 174.68C166.625 173.387 165.577 172.339 164.294 172.339Z"
            fill="#ADADAD"
          />
          <path
            d="M164.294 178.785C163.01 178.785 161.962 179.833 161.962 181.115C161.962 182.398 163.01 183.445 164.294 183.445C165.588 183.445 166.636 182.398 166.636 181.115C166.625 179.833 165.577 178.785 164.294 178.785Z"
            fill="#ADADAD"
          />
          <path
            d="M164.294 185.241C163.01 185.241 161.962 186.289 161.962 187.572C161.962 188.865 163.01 189.912 164.294 189.912C165.588 189.912 166.636 188.865 166.636 187.572C166.625 186.289 165.577 185.241 164.294 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M164.294 191.698C163.01 191.698 161.962 192.745 161.962 194.028C161.962 195.31 163.01 196.358 164.294 196.358C165.588 196.358 166.636 195.31 166.636 194.028C166.625 192.745 165.577 191.698 164.294 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M164.294 198.154C163.01 198.154 161.962 199.201 161.962 200.484C161.962 201.767 163.01 202.814 164.294 202.814C165.588 202.814 166.636 201.767 166.636 200.484C166.625 199.201 165.577 198.154 164.294 198.154Z"
            fill="#ADADAD"
          />
          <path
            d="M164.294 204.598C163.01 204.598 161.962 205.646 161.962 206.928C161.962 208.211 163.01 209.258 164.294 209.258C165.588 209.258 166.636 208.211 166.636 206.928C166.625 205.646 165.577 204.598 164.294 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M164.294 211.054C163.01 211.054 161.962 212.102 161.962 213.395C161.962 214.678 163.01 215.725 164.294 215.725C165.588 215.725 166.636 214.678 166.636 213.395C166.625 212.102 165.577 211.054 164.294 211.054Z"
            fill="#ADADAD"
          />
          <path
            d="M164.294 217.51C163.01 217.51 161.962 218.558 161.962 219.841C161.962 221.123 163.01 222.181 164.294 222.181C165.588 222.181 166.636 221.134 166.636 219.841C166.625 218.558 165.577 217.51 164.294 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M164.294 223.967C163.01 223.967 161.962 225.014 161.962 226.297C161.962 227.579 163.01 228.627 164.294 228.627C165.588 228.627 166.636 227.579 166.636 226.297C166.625 225.004 165.577 223.967 164.294 223.967Z"
            fill="#ADADAD"
          />
          <path
            d="M164.294 230.411C163.01 230.411 161.962 231.458 161.962 232.752C161.962 234.034 163.01 235.082 164.294 235.082C165.588 235.082 166.636 234.034 166.636 232.752C166.625 231.458 165.577 230.411 164.294 230.411Z"
            fill="#ADADAD"
          />
          <path
            d="M164.294 236.867C163.01 236.867 161.962 237.915 161.962 239.208C161.962 240.491 163.01 241.538 164.294 241.538C165.588 241.538 166.636 240.491 166.636 239.208C166.625 237.915 165.577 236.867 164.294 236.867Z"
            fill="#ADADAD"
          />
          <path
            d="M157.834 82.0003C156.551 82.0003 155.503 83.0478 155.503 84.3411C155.503 85.6237 156.551 86.6712 157.834 86.6712C159.118 86.6712 160.166 85.6237 160.166 84.3411C160.166 83.0478 159.128 82.0003 157.834 82.0003Z"
            fill="#ADADAD"
          />
          <path
            d="M157.834 94.9008C156.551 94.9008 155.503 95.9483 155.503 97.2309C155.503 98.5135 156.551 99.561 157.834 99.561C159.118 99.561 160.166 98.5135 160.166 97.2309C160.166 95.9483 159.128 94.9008 157.834 94.9008Z"
            fill="#ADADAD"
          />
          <path
            d="M157.834 107.813C156.551 107.813 155.503 108.861 155.503 110.143C155.503 111.426 156.551 112.473 157.834 112.473C159.118 112.473 160.166 111.426 160.166 110.143C160.166 108.861 159.128 107.813 157.834 107.813Z"
            fill="#ADADAD"
          />
          <path
            d="M157.834 114.257C156.551 114.257 155.503 115.305 155.503 116.598C155.503 117.881 156.551 118.928 157.834 118.928C159.118 118.928 160.166 117.881 160.166 116.598C160.166 115.305 159.128 114.257 157.834 114.257Z"
            fill="#ADADAD"
          />
          <path
            d="M157.834 120.714C156.551 120.714 155.503 121.761 155.503 123.054C155.503 124.337 156.551 125.385 157.834 125.385C159.118 125.385 160.166 124.337 160.166 123.054C160.166 121.761 159.128 120.714 157.834 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M157.834 127.17C156.551 127.17 155.503 128.217 155.503 129.5C155.503 130.793 156.551 131.841 157.834 131.841C159.118 131.841 160.166 130.793 160.166 129.5C160.166 128.217 159.128 127.17 157.834 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M157.834 133.616C156.551 133.616 155.503 134.663 155.503 135.946C155.503 137.228 156.551 138.276 157.834 138.276C159.118 138.276 160.166 137.228 160.166 135.946C160.166 134.663 159.128 133.616 157.834 133.616Z"
            fill="#ADADAD"
          />
          <path
            d="M157.834 140.072C156.551 140.072 155.503 141.119 155.503 142.402C155.503 143.685 156.551 144.732 157.834 144.732C159.118 144.732 160.166 143.685 160.166 142.402C160.166 141.119 159.128 140.072 157.834 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M157.834 146.527C156.551 146.527 155.503 147.574 155.503 148.857C155.503 150.139 156.551 151.187 157.834 151.187C159.118 151.187 160.166 150.139 160.166 148.857C160.166 147.574 159.128 146.527 157.834 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M157.834 152.983C156.551 152.983 155.503 154.03 155.503 155.313C155.503 156.596 156.551 157.654 157.834 157.654C159.118 157.654 160.166 156.606 160.166 155.313C160.166 154.03 159.128 152.983 157.834 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M157.834 159.429C156.551 159.429 155.503 160.476 155.503 161.759C155.503 163.052 156.551 164.099 157.834 164.099C159.118 164.099 160.166 163.052 160.166 161.759C160.166 160.476 159.128 159.429 157.834 159.429Z"
            fill="#ADADAD"
          />
          <path
            d="M157.834 165.885C156.551 165.885 155.503 166.932 155.503 168.215C155.503 169.497 156.551 170.545 157.834 170.545C159.118 170.545 160.166 169.497 160.166 168.215C160.166 166.932 159.128 165.885 157.834 165.885Z"
            fill="#ADADAD"
          />
          <path
            d="M157.834 172.339C156.551 172.339 155.503 173.387 155.503 174.68C155.503 175.963 156.551 177.01 157.834 177.01C159.118 177.01 160.166 175.963 160.166 174.68C160.166 173.387 159.128 172.339 157.834 172.339Z"
            fill="#ADADAD"
          />
          <path
            d="M157.834 178.785C156.551 178.785 155.503 179.833 155.503 181.115C155.503 182.398 156.551 183.445 157.834 183.445C159.118 183.445 160.166 182.398 160.166 181.115C160.166 179.833 159.128 178.785 157.834 178.785Z"
            fill="#ADADAD"
          />
          <path
            d="M157.834 185.241C156.551 185.241 155.503 186.289 155.503 187.572C155.503 188.865 156.551 189.912 157.834 189.912C159.118 189.912 160.166 188.865 160.166 187.572C160.166 186.289 159.128 185.241 157.834 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M157.834 191.698C156.551 191.698 155.503 192.745 155.503 194.028C155.503 195.31 156.551 196.358 157.834 196.358C159.118 196.358 160.166 195.31 160.166 194.028C160.166 192.745 159.128 191.698 157.834 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M157.834 198.154C156.551 198.154 155.503 199.201 155.503 200.484C155.503 201.767 156.551 202.814 157.834 202.814C159.118 202.814 160.166 201.767 160.166 200.484C160.166 199.201 159.128 198.154 157.834 198.154Z"
            fill="#ADADAD"
          />
          <path
            d="M157.834 204.598C156.551 204.598 155.503 205.646 155.503 206.928C155.503 208.211 156.551 209.258 157.834 209.258C159.118 209.258 160.166 208.211 160.166 206.928C160.166 205.646 159.128 204.598 157.834 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M157.834 211.054C156.551 211.054 155.503 212.102 155.503 213.395C155.503 214.678 156.551 215.725 157.834 215.725C159.118 215.725 160.166 214.678 160.166 213.395C160.166 212.102 159.128 211.054 157.834 211.054Z"
            fill="#ADADAD"
          />
          <path
            d="M157.834 217.51C156.551 217.51 155.503 218.558 155.503 219.841C155.503 221.123 156.551 222.181 157.834 222.181C159.118 222.181 160.166 221.134 160.166 219.841C160.166 218.558 159.128 217.51 157.834 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M157.834 223.967C156.551 223.967 155.503 225.014 155.503 226.297C155.503 227.579 156.551 228.627 157.834 228.627C159.118 228.627 160.166 227.579 160.166 226.297C160.166 225.004 159.128 223.967 157.834 223.967Z"
            fill="#ADADAD"
          />
          <path
            d="M151.375 75.5436C150.092 75.5436 149.043 76.591 149.043 77.8844C149.043 79.167 150.092 80.2145 151.375 80.2145C152.658 80.2145 153.706 79.167 153.706 77.8844C153.717 76.591 152.669 75.5436 151.375 75.5436Z"
            fill="#ADADAD"
          />
          <path
            d="M151.375 81.9998C150.092 81.9998 149.043 83.0473 149.043 84.3406C149.043 85.6232 150.092 86.6707 151.375 86.6707C152.658 86.6707 153.706 85.6232 153.706 84.3406C153.717 83.0473 152.669 81.9998 151.375 81.9998Z"
            fill="#ADADAD"
          />
          <path
            d="M151.375 94.9011C150.092 94.9011 149.043 95.9486 149.043 97.2312C149.043 98.5138 150.092 99.5613 151.375 99.5613C152.658 99.5613 153.706 98.5138 153.706 97.2312C153.717 95.9486 152.669 94.9011 151.375 94.9011Z"
            fill="#ADADAD"
          />
          <path
            d="M151.375 107.813C150.092 107.813 149.043 108.86 149.043 110.143C149.043 111.426 150.092 112.473 151.375 112.473C152.658 112.473 153.706 111.426 153.706 110.143C153.717 108.86 152.669 107.813 151.375 107.813Z"
            fill="#ADADAD"
          />
          <path
            d="M151.375 120.714C150.092 120.714 149.043 121.762 149.043 123.055C149.043 124.338 150.092 125.385 151.375 125.385C152.658 125.385 153.706 124.338 153.706 123.055C153.717 121.762 152.669 120.714 151.375 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M151.375 127.17C150.092 127.17 149.043 128.217 149.043 129.5C149.043 130.793 150.092 131.841 151.375 131.841C152.658 131.841 153.706 130.793 153.706 129.5C153.717 128.217 152.669 127.17 151.375 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M151.375 133.615C150.092 133.615 149.043 134.662 149.043 135.945C149.043 137.228 150.092 138.275 151.375 138.275C152.658 138.275 153.706 137.228 153.706 135.945C153.717 134.662 152.669 133.615 151.375 133.615Z"
            fill="#ADADAD"
          />
          <path
            d="M151.375 140.071C150.092 140.071 149.043 141.119 149.043 142.401C149.043 143.684 150.092 144.731 151.375 144.731C152.658 144.731 153.706 143.684 153.706 142.401C153.717 141.119 152.669 140.071 151.375 140.071Z"
            fill="#ADADAD"
          />
          <path
            d="M151.375 146.527C150.092 146.527 149.043 147.574 149.043 148.857C149.043 150.14 150.092 151.187 151.375 151.187C152.658 151.187 153.706 150.14 153.706 148.857C153.706 147.574 152.669 146.527 151.375 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M151.375 152.983C150.092 152.983 149.043 154.031 149.043 155.313C149.043 156.596 150.092 157.654 151.375 157.654C152.658 157.654 153.706 156.607 153.706 155.313C153.717 154.031 152.669 152.983 151.375 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M151.375 159.428C150.092 159.428 149.043 160.476 149.043 161.758C149.043 163.052 150.092 164.099 151.375 164.099C152.658 164.099 153.706 163.052 153.706 161.758C153.717 160.476 152.669 159.428 151.375 159.428Z"
            fill="#ADADAD"
          />
          <path
            d="M151.375 165.884C150.092 165.884 149.043 166.932 149.043 168.215C149.043 169.497 150.092 170.545 151.375 170.545C152.658 170.545 153.706 169.497 153.706 168.215C153.717 166.932 152.669 165.884 151.375 165.884Z"
            fill="#ADADAD"
          />
          <path
            d="M151.375 172.34C150.092 172.34 149.043 173.388 149.043 174.681C149.043 175.963 150.092 177.011 151.375 177.011C152.658 177.011 153.706 175.963 153.706 174.681C153.717 173.388 152.669 172.34 151.375 172.34Z"
            fill="#ADADAD"
          />
          <path
            d="M151.375 178.786C150.092 178.786 149.043 179.833 149.043 181.116C149.043 182.398 150.092 183.446 151.375 183.446C152.658 183.446 153.706 182.398 153.706 181.116C153.717 179.833 152.669 178.786 151.375 178.786Z"
            fill="#ADADAD"
          />
          <path
            d="M151.375 185.241C150.092 185.241 149.043 186.289 149.043 187.571C149.043 188.865 150.092 189.912 151.375 189.912C152.658 189.912 153.706 188.865 153.706 187.571C153.717 186.289 152.669 185.241 151.375 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M151.375 191.698C150.092 191.698 149.043 192.745 149.043 194.028C149.043 195.31 150.092 196.358 151.375 196.358C152.658 196.358 153.706 195.31 153.706 194.028C153.717 192.745 152.669 191.698 151.375 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M151.375 198.153C150.092 198.153 149.043 199.201 149.043 200.483C149.043 201.766 150.092 202.813 151.375 202.813C152.658 202.813 153.706 201.766 153.706 200.483C153.717 199.201 152.669 198.153 151.375 198.153Z"
            fill="#ADADAD"
          />
          <path
            d="M151.375 204.598C150.092 204.598 149.043 205.646 149.043 206.928C149.043 208.211 150.092 209.258 151.375 209.258C152.658 209.258 153.706 208.211 153.706 206.928C153.717 205.646 152.669 204.598 151.375 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M151.375 211.055C150.092 211.055 149.043 212.102 149.043 213.395C149.043 214.678 150.092 215.725 151.375 215.725C152.658 215.725 153.706 214.678 153.706 213.395C153.717 212.102 152.669 211.055 151.375 211.055Z"
            fill="#ADADAD"
          />
          <path
            d="M151.375 217.51C150.092 217.51 149.043 218.558 149.043 219.84C149.043 221.123 150.092 222.181 151.375 222.181C152.658 222.181 153.706 221.134 153.706 219.84C153.717 218.558 152.669 217.51 151.375 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M144.927 81.9998C143.643 81.9998 142.595 83.0473 142.595 84.3406C142.595 85.6232 143.643 86.6707 144.927 86.6707C146.21 86.6707 147.258 85.6232 147.258 84.3406C147.258 83.0473 146.21 81.9998 144.927 81.9998Z"
            fill="#ADADAD"
          />
          <path
            d="M144.927 88.4448C143.643 88.4448 142.595 89.4923 142.595 90.775C142.595 92.0576 143.643 93.1051 144.927 93.1051C146.21 93.1051 147.258 92.0576 147.258 90.775C147.258 89.4923 146.21 88.4448 144.927 88.4448Z"
            fill="#ADADAD"
          />
          <path
            d="M144.927 94.9011C143.643 94.9011 142.595 95.9486 142.595 97.2312C142.595 98.5138 143.643 99.5613 144.927 99.5613C146.21 99.5613 147.258 98.5138 147.258 97.2312C147.258 95.9486 146.21 94.9011 144.927 94.9011Z"
            fill="#ADADAD"
          />
          <path
            d="M144.927 107.813C143.643 107.813 142.595 108.86 142.595 110.143C142.595 111.426 143.643 112.473 144.927 112.473C146.21 112.473 147.258 111.426 147.258 110.143C147.258 108.86 146.21 107.813 144.927 107.813Z"
            fill="#ADADAD"
          />
          <path
            d="M144.927 114.258C143.643 114.258 142.595 115.306 142.595 116.599C142.595 117.881 143.643 118.929 144.927 118.929C146.21 118.929 147.258 117.881 147.258 116.599C147.258 115.306 146.21 114.258 144.927 114.258Z"
            fill="#ADADAD"
          />
          <path
            d="M144.927 120.714C143.643 120.714 142.595 121.762 142.595 123.055C142.595 124.338 143.643 125.385 144.927 125.385C146.21 125.385 147.258 124.338 147.258 123.055C147.258 121.762 146.21 120.714 144.927 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M144.927 127.17C143.643 127.17 142.595 128.217 142.595 129.5C142.595 130.793 143.643 131.841 144.927 131.841C146.21 131.841 147.258 130.793 147.258 129.5C147.258 128.217 146.21 127.17 144.927 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M144.927 133.615C143.643 133.615 142.595 134.662 142.595 135.945C142.595 137.228 143.643 138.275 144.927 138.275C146.21 138.275 147.258 137.228 147.258 135.945C147.258 134.662 146.21 133.615 144.927 133.615Z"
            fill="#ADADAD"
          />
          <path
            d="M144.927 140.071C143.643 140.071 142.595 141.119 142.595 142.401C142.595 143.684 143.643 144.731 144.927 144.731C146.21 144.731 147.258 143.684 147.258 142.401C147.258 141.119 146.21 140.071 144.927 140.071Z"
            fill="#ADADAD"
          />
          <path
            d="M144.927 146.527C143.643 146.527 142.595 147.574 142.595 148.857C142.595 150.14 143.643 151.187 144.927 151.187C146.21 151.187 147.258 150.14 147.258 148.857C147.258 147.574 146.21 146.527 144.927 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M144.927 152.983C143.643 152.983 142.595 154.031 142.595 155.313C142.595 156.596 143.643 157.654 144.927 157.654C146.21 157.654 147.258 156.607 147.258 155.313C147.258 154.031 146.21 152.983 144.927 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M144.927 159.428C143.643 159.428 142.595 160.476 142.595 161.758C142.595 163.052 143.643 164.099 144.927 164.099C146.21 164.099 147.258 163.052 147.258 161.758C147.258 160.476 146.21 159.428 144.927 159.428Z"
            fill="#ADADAD"
          />
          <path
            d="M144.927 165.884C143.643 165.884 142.595 166.932 142.595 168.215C142.595 169.497 143.643 170.545 144.927 170.545C146.21 170.545 147.258 169.497 147.258 168.215C147.258 166.932 146.21 165.884 144.927 165.884Z"
            fill="#ADADAD"
          />
          <path
            d="M144.927 172.34C143.643 172.34 142.595 173.388 142.595 174.681C142.595 175.963 143.643 177.011 144.927 177.011C146.21 177.011 147.258 175.963 147.258 174.681C147.258 173.388 146.21 172.34 144.927 172.34Z"
            fill="#ADADAD"
          />
          <path
            d="M144.927 178.786C143.643 178.786 142.595 179.833 142.595 181.116C142.595 182.398 143.643 183.446 144.927 183.446C146.21 183.446 147.258 182.398 147.258 181.116C147.258 179.833 146.21 178.786 144.927 178.786Z"
            fill="#ADADAD"
          />
          <path
            d="M144.927 185.241C143.643 185.241 142.595 186.289 142.595 187.571C142.595 188.865 143.643 189.912 144.927 189.912C146.21 189.912 147.258 188.865 147.258 187.571C147.258 186.289 146.21 185.241 144.927 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M144.927 191.698C143.643 191.698 142.595 192.745 142.595 194.028C142.595 195.31 143.643 196.358 144.927 196.358C146.21 196.358 147.258 195.31 147.258 194.028C147.258 192.745 146.21 191.698 144.927 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M144.927 198.153C143.643 198.153 142.595 199.201 142.595 200.483C142.595 201.766 143.643 202.813 144.927 202.813C146.21 202.813 147.258 201.766 147.258 200.483C147.258 199.201 146.21 198.153 144.927 198.153Z"
            fill="#ADADAD"
          />
          <path
            d="M144.927 204.598C143.643 204.598 142.595 205.646 142.595 206.928C142.595 208.211 143.643 209.258 144.927 209.258C146.21 209.258 147.258 208.211 147.258 206.928C147.258 205.646 146.21 204.598 144.927 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M144.927 211.055C143.643 211.055 142.595 212.102 142.595 213.395C142.595 214.678 143.643 215.725 144.927 215.725C146.21 215.725 147.258 214.678 147.258 213.395C147.258 212.102 146.21 211.055 144.927 211.055Z"
            fill="#ADADAD"
          />
          <path
            d="M144.927 217.51C143.643 217.51 142.595 218.558 142.595 219.84C142.595 221.123 143.643 222.181 144.927 222.181C146.21 222.181 147.258 221.134 147.258 219.84C147.258 218.558 146.21 217.51 144.927 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M138.465 94.9008C137.182 94.9008 136.133 95.9483 136.133 97.2309C136.133 98.5135 137.182 99.561 138.465 99.561C139.748 99.561 140.796 98.5135 140.796 97.2309C140.796 95.9483 139.748 94.9008 138.465 94.9008Z"
            fill="#ADADAD"
          />
          <path
            d="M138.465 107.813C137.182 107.813 136.133 108.861 136.133 110.143C136.133 111.426 137.182 112.473 138.465 112.473C139.748 112.473 140.796 111.426 140.796 110.143C140.796 108.861 139.748 107.813 138.465 107.813Z"
            fill="#ADADAD"
          />
          <path
            d="M138.465 114.257C137.182 114.257 136.133 115.305 136.133 116.598C136.133 117.881 137.182 118.928 138.465 118.928C139.748 118.928 140.796 117.881 140.796 116.598C140.796 115.305 139.748 114.257 138.465 114.257Z"
            fill="#ADADAD"
          />
          <path
            d="M138.465 120.714C137.182 120.714 136.133 121.761 136.133 123.054C136.133 124.337 137.182 125.385 138.465 125.385C139.748 125.385 140.796 124.337 140.796 123.054C140.796 121.761 139.748 120.714 138.465 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M138.465 127.17C137.182 127.17 136.133 128.217 136.133 129.5C136.133 130.793 137.182 131.841 138.465 131.841C139.748 131.841 140.796 130.793 140.796 129.5C140.796 128.217 139.748 127.17 138.465 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M138.465 133.616C137.182 133.616 136.133 134.663 136.133 135.946C136.133 137.228 137.182 138.276 138.465 138.276C139.748 138.276 140.796 137.228 140.796 135.946C140.796 134.663 139.748 133.616 138.465 133.616Z"
            fill="#ADADAD"
          />
          <path
            d="M138.465 140.072C137.182 140.072 136.133 141.119 136.133 142.402C136.133 143.685 137.182 144.732 138.465 144.732C139.748 144.732 140.796 143.685 140.796 142.402C140.796 141.119 139.748 140.072 138.465 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M138.465 146.527C137.182 146.527 136.133 147.574 136.133 148.857C136.133 150.139 137.182 151.187 138.465 151.187C139.748 151.187 140.796 150.139 140.796 148.857C140.796 147.574 139.748 146.527 138.465 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M138.465 152.983C137.182 152.983 136.133 154.03 136.133 155.313C136.133 156.596 137.182 157.654 138.465 157.654C139.748 157.654 140.796 156.606 140.796 155.313C140.796 154.03 139.748 152.983 138.465 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M138.465 159.429C137.182 159.429 136.133 160.476 136.133 161.759C136.133 163.052 137.182 164.099 138.465 164.099C139.748 164.099 140.796 163.052 140.796 161.759C140.796 160.476 139.748 159.429 138.465 159.429Z"
            fill="#ADADAD"
          />
          <path
            d="M138.465 165.885C137.182 165.885 136.133 166.932 136.133 168.215C136.133 169.497 137.182 170.545 138.465 170.545C139.748 170.545 140.796 169.497 140.796 168.215C140.796 166.932 139.748 165.885 138.465 165.885Z"
            fill="#ADADAD"
          />
          <path
            d="M138.465 172.339C137.182 172.339 136.133 173.387 136.133 174.68C136.133 175.963 137.182 177.01 138.465 177.01C139.748 177.01 140.796 175.963 140.796 174.68C140.796 173.387 139.748 172.339 138.465 172.339Z"
            fill="#ADADAD"
          />
          <path
            d="M138.465 178.785C137.182 178.785 136.133 179.833 136.133 181.115C136.133 182.398 137.182 183.445 138.465 183.445C139.748 183.445 140.796 182.398 140.796 181.115C140.796 179.833 139.748 178.785 138.465 178.785Z"
            fill="#ADADAD"
          />
          <path
            d="M138.465 185.241C137.182 185.241 136.133 186.289 136.133 187.572C136.133 188.865 137.182 189.912 138.465 189.912C139.748 189.912 140.796 188.865 140.796 187.572C140.796 186.289 139.748 185.241 138.465 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M138.465 191.698C137.182 191.698 136.133 192.745 136.133 194.028C136.133 195.31 137.182 196.358 138.465 196.358C139.748 196.358 140.796 195.31 140.796 194.028C140.796 192.745 139.748 191.698 138.465 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M138.465 198.154C137.182 198.154 136.133 199.201 136.133 200.484C136.133 201.767 137.182 202.814 138.465 202.814C139.748 202.814 140.796 201.767 140.796 200.484C140.796 199.201 139.748 198.154 138.465 198.154Z"
            fill="#ADADAD"
          />
          <path
            d="M138.465 204.598C137.182 204.598 136.133 205.646 136.133 206.928C136.133 208.211 137.182 209.258 138.465 209.258C139.748 209.258 140.796 208.211 140.796 206.928C140.796 205.646 139.748 204.598 138.465 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M138.465 211.054C137.182 211.054 136.133 212.102 136.133 213.395C136.133 214.678 137.182 215.725 138.465 215.725C139.748 215.725 140.796 214.678 140.796 213.395C140.796 212.102 139.748 211.054 138.465 211.054Z"
            fill="#ADADAD"
          />
          <path
            d="M138.465 217.51C137.182 217.51 136.133 218.558 136.133 219.841C136.133 221.123 137.182 222.181 138.465 222.181C139.748 222.181 140.796 221.134 140.796 219.841C140.796 218.558 139.748 217.51 138.465 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M132.017 82.0003C130.734 82.0003 129.686 83.0478 129.686 84.3411C129.686 85.6237 130.734 86.6712 132.017 86.6712C133.301 86.6712 134.349 85.6237 134.349 84.3411C134.338 83.0478 133.301 82.0003 132.017 82.0003Z"
            fill="#ADADAD"
          />
          <path
            d="M132.017 88.4446C130.734 88.4446 129.686 89.4921 129.686 90.7747C129.686 92.0574 130.734 93.1048 132.017 93.1048C133.301 93.1048 134.349 92.0574 134.349 90.7747C134.338 89.4921 133.301 88.4446 132.017 88.4446Z"
            fill="#ADADAD"
          />
          <path
            d="M132.017 94.9008C130.734 94.9008 129.686 95.9483 129.686 97.2309C129.686 98.5135 130.734 99.561 132.017 99.561C133.301 99.561 134.349 98.5135 134.349 97.2309C134.338 95.9483 133.301 94.9008 132.017 94.9008Z"
            fill="#ADADAD"
          />
          <path
            d="M132.017 114.257C130.734 114.257 129.686 115.305 129.686 116.598C129.686 117.881 130.734 118.928 132.017 118.928C133.301 118.928 134.349 117.881 134.349 116.598C134.338 115.305 133.301 114.257 132.017 114.257Z"
            fill="#ADADAD"
          />
          <path
            d="M132.017 120.714C130.734 120.714 129.686 121.761 129.686 123.054C129.686 124.337 130.734 125.385 132.017 125.385C133.301 125.385 134.349 124.337 134.349 123.054C134.338 121.761 133.301 120.714 132.017 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M132.017 133.616C130.734 133.616 129.686 134.663 129.686 135.946C129.686 137.228 130.734 138.276 132.017 138.276C133.301 138.276 134.349 137.228 134.349 135.946C134.338 134.663 133.301 133.616 132.017 133.616Z"
            fill="#ADADAD"
          />
          <path
            d="M132.017 140.072C130.734 140.072 129.686 141.119 129.686 142.402C129.686 143.685 130.734 144.732 132.017 144.732C133.301 144.732 134.349 143.685 134.349 142.402C134.338 141.119 133.301 140.072 132.017 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M132.017 146.527C130.734 146.527 129.686 147.574 129.686 148.857C129.686 150.139 130.734 151.187 132.017 151.187C133.301 151.187 134.349 150.139 134.349 148.857C134.349 147.574 133.301 146.527 132.017 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M132.017 152.983C130.734 152.983 129.686 154.03 129.686 155.313C129.686 156.596 130.734 157.654 132.017 157.654C133.301 157.654 134.349 156.606 134.349 155.313C134.338 154.03 133.301 152.983 132.017 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M132.017 159.429C130.734 159.429 129.686 160.476 129.686 161.759C129.686 163.052 130.734 164.099 132.017 164.099C133.301 164.099 134.349 163.052 134.349 161.759C134.338 160.476 133.301 159.429 132.017 159.429Z"
            fill="#ADADAD"
          />
          <path
            d="M132.017 165.885C130.734 165.885 129.686 166.932 129.686 168.215C129.686 169.497 130.734 170.545 132.017 170.545C133.301 170.545 134.349 169.497 134.349 168.215C134.338 166.932 133.301 165.885 132.017 165.885Z"
            fill="#ADADAD"
          />
          <path
            d="M132.017 172.339C130.734 172.339 129.686 173.387 129.686 174.68C129.686 175.963 130.734 177.01 132.017 177.01C133.301 177.01 134.349 175.963 134.349 174.68C134.338 173.387 133.301 172.339 132.017 172.339Z"
            fill="#ADADAD"
          />
          <path
            d="M132.017 178.785C130.734 178.785 129.686 179.833 129.686 181.115C129.686 182.398 130.734 183.445 132.017 183.445C133.301 183.445 134.349 182.398 134.349 181.115C134.338 179.833 133.301 178.785 132.017 178.785Z"
            fill="#ADADAD"
          />
          <path
            d="M132.017 185.241C130.734 185.241 129.686 186.289 129.686 187.572C129.686 188.865 130.734 189.912 132.017 189.912C133.301 189.912 134.349 188.865 134.349 187.572C134.338 186.289 133.301 185.241 132.017 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M132.017 191.698C130.734 191.698 129.686 192.745 129.686 194.028C129.686 195.31 130.734 196.358 132.017 196.358C133.301 196.358 134.349 195.31 134.349 194.028C134.338 192.745 133.301 191.698 132.017 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M132.017 198.154C130.734 198.154 129.686 199.201 129.686 200.484C129.686 201.767 130.734 202.814 132.017 202.814C133.301 202.814 134.349 201.767 134.349 200.484C134.338 199.201 133.301 198.154 132.017 198.154Z"
            fill="#ADADAD"
          />
          <path
            d="M132.017 204.598C130.734 204.598 129.686 205.646 129.686 206.928C129.686 208.211 130.734 209.258 132.017 209.258C133.301 209.258 134.349 208.211 134.349 206.928C134.338 205.646 133.301 204.598 132.017 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M132.017 211.054C130.734 211.054 129.686 212.102 129.686 213.395C129.686 214.678 130.734 215.725 132.017 215.725C133.301 215.725 134.349 214.678 134.349 213.395C134.338 212.102 133.301 211.054 132.017 211.054Z"
            fill="#ADADAD"
          />
          <path
            d="M132.017 217.51C130.734 217.51 129.686 218.558 129.686 219.841C129.686 221.123 130.734 222.181 132.017 222.181C133.301 222.181 134.349 221.134 134.349 219.841C134.338 218.558 133.301 217.51 132.017 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M125.549 88.4446C124.266 88.4446 123.218 89.4921 123.218 90.7747C123.218 92.0574 124.266 93.1048 125.549 93.1048C126.843 93.1048 127.891 92.0574 127.891 90.7747C127.891 89.4921 126.843 88.4446 125.549 88.4446Z"
            fill="#ADADAD"
          />
          <path
            d="M125.549 94.9008C124.266 94.9008 123.218 95.9483 123.218 97.2309C123.218 98.5135 124.266 99.561 125.549 99.561C126.843 99.561 127.891 98.5135 127.891 97.2309C127.891 95.9483 126.843 94.9008 125.549 94.9008Z"
            fill="#ADADAD"
          />
          <path
            d="M125.549 101.357C124.266 101.357 123.218 102.404 123.218 103.687C123.218 104.97 124.266 106.017 125.549 106.017C126.843 106.017 127.891 104.97 127.891 103.687C127.891 102.404 126.843 101.357 125.549 101.357Z"
            fill="#ADADAD"
          />
          <path
            d="M125.549 114.257C124.266 114.257 123.218 115.305 123.218 116.598C123.218 117.881 124.266 118.928 125.549 118.928C126.843 118.928 127.891 117.881 127.891 116.598C127.891 115.305 126.843 114.257 125.549 114.257Z"
            fill="#ADADAD"
          />
          <path
            d="M125.549 120.714C124.266 120.714 123.218 121.761 123.218 123.054C123.218 124.337 124.266 125.385 125.549 125.385C126.843 125.385 127.891 124.337 127.891 123.054C127.891 121.761 126.843 120.714 125.549 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M125.549 133.616C124.266 133.616 123.218 134.663 123.218 135.946C123.218 137.228 124.266 138.276 125.549 138.276C126.843 138.276 127.891 137.228 127.891 135.946C127.891 134.663 126.843 133.616 125.549 133.616Z"
            fill="#ADADAD"
          />
          <path
            d="M125.549 140.072C124.266 140.072 123.218 141.119 123.218 142.402C123.218 143.685 124.266 144.732 125.549 144.732C126.843 144.732 127.891 143.685 127.891 142.402C127.891 141.119 126.843 140.072 125.549 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M125.549 146.527C124.266 146.527 123.218 147.574 123.218 148.857C123.218 150.139 124.266 151.187 125.549 151.187C126.843 151.187 127.891 150.139 127.891 148.857C127.891 147.574 126.843 146.527 125.549 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M125.549 152.983C124.266 152.983 123.218 154.03 123.218 155.313C123.218 156.596 124.266 157.654 125.549 157.654C126.843 157.654 127.891 156.606 127.891 155.313C127.891 154.03 126.843 152.983 125.549 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M125.549 159.429C124.266 159.429 123.218 160.476 123.218 161.759C123.218 163.052 124.266 164.099 125.549 164.099C126.843 164.099 127.891 163.052 127.891 161.759C127.891 160.476 126.843 159.429 125.549 159.429Z"
            fill="#ADADAD"
          />
          <path
            d="M125.549 165.885C124.266 165.885 123.218 166.932 123.218 168.215C123.218 169.497 124.266 170.545 125.549 170.545C126.843 170.545 127.891 169.497 127.891 168.215C127.891 166.932 126.843 165.885 125.549 165.885Z"
            fill="#ADADAD"
          />
          <path
            d="M125.549 172.339C124.266 172.339 123.218 173.387 123.218 174.68C123.218 175.963 124.266 177.01 125.549 177.01C126.843 177.01 127.891 175.963 127.891 174.68C127.891 173.387 126.843 172.339 125.549 172.339Z"
            fill="#ADADAD"
          />
          <path
            d="M125.549 178.785C124.266 178.785 123.218 179.833 123.218 181.115C123.218 182.398 124.266 183.445 125.549 183.445C126.843 183.445 127.891 182.398 127.891 181.115C127.891 179.833 126.843 178.785 125.549 178.785Z"
            fill="#ADADAD"
          />
          <path
            d="M125.549 185.241C124.266 185.241 123.218 186.289 123.218 187.572C123.218 188.865 124.266 189.912 125.549 189.912C126.843 189.912 127.891 188.865 127.891 187.572C127.891 186.289 126.843 185.241 125.549 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M125.549 191.698C124.266 191.698 123.218 192.745 123.218 194.028C123.218 195.31 124.266 196.358 125.549 196.358C126.843 196.358 127.891 195.31 127.891 194.028C127.891 192.745 126.843 191.698 125.549 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M125.549 198.154C124.266 198.154 123.218 199.201 123.218 200.484C123.218 201.767 124.266 202.814 125.549 202.814C126.843 202.814 127.891 201.767 127.891 200.484C127.891 199.201 126.843 198.154 125.549 198.154Z"
            fill="#ADADAD"
          />
          <path
            d="M125.549 204.598C124.266 204.598 123.218 205.646 123.218 206.928C123.218 208.211 124.266 209.258 125.549 209.258C126.843 209.258 127.891 208.211 127.891 206.928C127.891 205.646 126.843 204.598 125.549 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M125.549 211.054C124.266 211.054 123.218 212.102 123.218 213.395C123.218 214.678 124.266 215.725 125.549 215.725C126.843 215.725 127.891 214.678 127.891 213.395C127.891 212.102 126.843 211.054 125.549 211.054Z"
            fill="#ADADAD"
          />
          <path
            d="M125.549 217.51C124.266 217.51 123.218 218.558 123.218 219.841C123.218 221.123 124.266 222.181 125.549 222.181C126.843 222.181 127.891 221.134 127.891 219.841C127.891 218.558 126.843 217.51 125.549 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M119.098 88.4448C117.815 88.4448 116.767 89.4923 116.767 90.775C116.767 92.0576 117.815 93.1051 119.098 93.1051C120.382 93.1051 121.43 92.0576 121.43 90.775C121.43 89.4923 120.382 88.4448 119.098 88.4448Z"
            fill="#ADADAD"
          />
          <path
            d="M119.098 94.9011C117.815 94.9011 116.767 95.9486 116.767 97.2312C116.767 98.5138 117.815 99.5613 119.098 99.5613C120.382 99.5613 121.43 98.5138 121.43 97.2312C121.43 95.9486 120.382 94.9011 119.098 94.9011Z"
            fill="#ADADAD"
          />
          <path
            d="M119.098 107.813C117.815 107.813 116.767 108.86 116.767 110.143C116.767 111.426 117.815 112.473 119.098 112.473C120.382 112.473 121.43 111.426 121.43 110.143C121.43 108.86 120.382 107.813 119.098 107.813Z"
            fill="#ADADAD"
          />
          <path
            d="M119.098 114.258C117.815 114.258 116.767 115.306 116.767 116.599C116.767 117.881 117.815 118.929 119.098 118.929C120.382 118.929 121.43 117.881 121.43 116.599C121.43 115.306 120.382 114.258 119.098 114.258Z"
            fill="#ADADAD"
          />
          <path
            d="M119.098 120.714C117.815 120.714 116.767 121.762 116.767 123.055C116.767 124.338 117.815 125.385 119.098 125.385C120.382 125.385 121.43 124.338 121.43 123.055C121.43 121.762 120.382 120.714 119.098 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M119.098 127.17C117.815 127.17 116.767 128.217 116.767 129.5C116.767 130.793 117.815 131.841 119.098 131.841C120.382 131.841 121.43 130.793 121.43 129.5C121.43 128.217 120.382 127.17 119.098 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M119.098 133.615C117.815 133.615 116.767 134.662 116.767 135.945C116.767 137.228 117.815 138.275 119.098 138.275C120.382 138.275 121.43 137.228 121.43 135.945C121.43 134.662 120.382 133.615 119.098 133.615Z"
            fill="#ADADAD"
          />
          <path
            d="M119.098 140.071C117.815 140.071 116.767 141.119 116.767 142.401C116.767 143.684 117.815 144.731 119.098 144.731C120.382 144.731 121.43 143.684 121.43 142.401C121.43 141.119 120.382 140.071 119.098 140.071Z"
            fill="#ADADAD"
          />
          <path
            d="M119.098 146.527C117.815 146.527 116.767 147.574 116.767 148.857C116.767 150.14 117.815 151.187 119.098 151.187C120.382 151.187 121.43 150.14 121.43 148.857C121.43 147.574 120.382 146.527 119.098 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M119.098 152.983C117.815 152.983 116.767 154.031 116.767 155.313C116.767 156.596 117.815 157.654 119.098 157.654C120.382 157.654 121.43 156.607 121.43 155.313C121.43 154.031 120.382 152.983 119.098 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M119.098 159.428C117.815 159.428 116.767 160.476 116.767 161.758C116.767 163.052 117.815 164.099 119.098 164.099C120.382 164.099 121.43 163.052 121.43 161.758C121.43 160.476 120.382 159.428 119.098 159.428Z"
            fill="#ADADAD"
          />
          <path
            d="M119.098 165.884C117.815 165.884 116.767 166.932 116.767 168.215C116.767 169.497 117.815 170.545 119.098 170.545C120.382 170.545 121.43 169.497 121.43 168.215C121.43 166.932 120.382 165.884 119.098 165.884Z"
            fill="#ADADAD"
          />
          <path
            d="M119.098 172.34C117.815 172.34 116.767 173.388 116.767 174.681C116.767 175.963 117.815 177.011 119.098 177.011C120.382 177.011 121.43 175.963 121.43 174.681C121.43 173.388 120.382 172.34 119.098 172.34Z"
            fill="#ADADAD"
          />
          <path
            d="M119.098 178.786C117.815 178.786 116.767 179.833 116.767 181.116C116.767 182.398 117.815 183.446 119.098 183.446C120.382 183.446 121.43 182.398 121.43 181.116C121.43 179.833 120.382 178.786 119.098 178.786Z"
            fill="#ADADAD"
          />
          <path
            d="M119.098 185.241C117.815 185.241 116.767 186.289 116.767 187.571C116.767 188.865 117.815 189.912 119.098 189.912C120.382 189.912 121.43 188.865 121.43 187.571C121.43 186.289 120.382 185.241 119.098 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M119.098 191.698C117.815 191.698 116.767 192.745 116.767 194.028C116.767 195.31 117.815 196.358 119.098 196.358C120.382 196.358 121.43 195.31 121.43 194.028C121.43 192.745 120.382 191.698 119.098 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M119.098 198.153C117.815 198.153 116.767 199.201 116.767 200.483C116.767 201.766 117.815 202.813 119.098 202.813C120.382 202.813 121.43 201.766 121.43 200.483C121.43 199.201 120.382 198.153 119.098 198.153Z"
            fill="#ADADAD"
          />
          <path
            d="M119.098 204.598C117.815 204.598 116.767 205.646 116.767 206.928C116.767 208.211 117.815 209.258 119.098 209.258C120.382 209.258 121.43 208.211 121.43 206.928C121.43 205.646 120.382 204.598 119.098 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M119.098 211.055C117.815 211.055 116.767 212.102 116.767 213.395C116.767 214.678 117.815 215.725 119.098 215.725C120.382 215.725 121.43 214.678 121.43 213.395C121.43 212.102 120.382 211.055 119.098 211.055Z"
            fill="#ADADAD"
          />
          <path
            d="M119.098 217.51C117.815 217.51 116.767 218.558 116.767 219.84C116.767 221.123 117.815 222.181 119.098 222.181C120.382 222.181 121.43 221.134 121.43 219.84C121.43 218.558 120.382 217.51 119.098 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M112.639 88.4448C111.356 88.4448 110.308 89.4923 110.308 90.775C110.308 92.0576 111.356 93.1051 112.639 93.1051C113.923 93.1051 114.971 92.0576 114.971 90.775C114.981 89.4923 113.933 88.4448 112.639 88.4448Z"
            fill="#ADADAD"
          />
          <path
            d="M112.639 94.9011C111.356 94.9011 110.308 95.9486 110.308 97.2312C110.308 98.5138 111.356 99.5613 112.639 99.5613C113.923 99.5613 114.971 98.5138 114.971 97.2312C114.981 95.9486 113.933 94.9011 112.639 94.9011Z"
            fill="#ADADAD"
          />
          <path
            d="M112.639 107.813C111.356 107.813 110.308 108.86 110.308 110.143C110.308 111.426 111.356 112.473 112.639 112.473C113.923 112.473 114.971 111.426 114.971 110.143C114.981 108.86 113.933 107.813 112.639 107.813Z"
            fill="#ADADAD"
          />
          <path
            d="M112.639 114.258C111.356 114.258 110.308 115.306 110.308 116.599C110.308 117.881 111.356 118.929 112.639 118.929C113.923 118.929 114.971 117.881 114.971 116.599C114.981 115.306 113.933 114.258 112.639 114.258Z"
            fill="#ADADAD"
          />
          <path
            d="M112.639 127.17C111.356 127.17 110.308 128.217 110.308 129.5C110.308 130.793 111.356 131.841 112.639 131.841C113.923 131.841 114.971 130.793 114.971 129.5C114.981 128.217 113.933 127.17 112.639 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M112.639 133.615C111.356 133.615 110.308 134.662 110.308 135.945C110.308 137.228 111.356 138.275 112.639 138.275C113.923 138.275 114.971 137.228 114.971 135.945C114.981 134.662 113.933 133.615 112.639 133.615Z"
            fill="#ADADAD"
          />
          <path
            d="M112.639 140.071C111.356 140.071 110.308 141.119 110.308 142.401C110.308 143.684 111.356 144.731 112.639 144.731C113.923 144.731 114.971 143.684 114.971 142.401C114.981 141.119 113.933 140.071 112.639 140.071Z"
            fill="#ADADAD"
          />
          <path
            d="M112.639 146.527C111.356 146.527 110.308 147.574 110.308 148.857C110.308 150.14 111.356 151.187 112.639 151.187C113.923 151.187 114.971 150.14 114.971 148.857C114.971 147.574 113.933 146.527 112.639 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M112.639 152.983C111.356 152.983 110.308 154.031 110.308 155.313C110.308 156.596 111.356 157.654 112.639 157.654C113.923 157.654 114.971 156.607 114.971 155.313C114.981 154.031 113.933 152.983 112.639 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M112.639 159.428C111.356 159.428 110.308 160.476 110.308 161.758C110.308 163.052 111.356 164.099 112.639 164.099C113.923 164.099 114.971 163.052 114.971 161.758C114.981 160.476 113.933 159.428 112.639 159.428Z"
            fill="#ADADAD"
          />
          <path
            d="M112.639 165.884C111.356 165.884 110.308 166.932 110.308 168.215C110.308 169.497 111.356 170.545 112.639 170.545C113.923 170.545 114.971 169.497 114.971 168.215C114.981 166.932 113.933 165.884 112.639 165.884Z"
            fill="#ADADAD"
          />
          <path
            d="M112.639 172.34C111.356 172.34 110.308 173.388 110.308 174.681C110.308 175.963 111.356 177.011 112.639 177.011C113.923 177.011 114.971 175.963 114.971 174.681C114.981 173.388 113.933 172.34 112.639 172.34Z"
            fill="#ADADAD"
          />
          <path
            d="M112.639 178.786C111.356 178.786 110.308 179.833 110.308 181.116C110.308 182.398 111.356 183.446 112.639 183.446C113.923 183.446 114.971 182.398 114.971 181.116C114.981 179.833 113.933 178.786 112.639 178.786Z"
            fill="#ADADAD"
          />
          <path
            d="M112.639 185.241C111.356 185.241 110.308 186.289 110.308 187.571C110.308 188.865 111.356 189.912 112.639 189.912C113.923 189.912 114.971 188.865 114.971 187.571C114.981 186.289 113.933 185.241 112.639 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M112.639 191.698C111.356 191.698 110.308 192.745 110.308 194.028C110.308 195.31 111.356 196.358 112.639 196.358C113.923 196.358 114.971 195.31 114.971 194.028C114.981 192.745 113.933 191.698 112.639 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M112.639 198.153C111.356 198.153 110.308 199.201 110.308 200.483C110.308 201.766 111.356 202.813 112.639 202.813C113.923 202.813 114.971 201.766 114.971 200.483C114.981 199.201 113.933 198.153 112.639 198.153Z"
            fill="#ADADAD"
          />
          <path
            d="M112.639 204.598C111.356 204.598 110.308 205.646 110.308 206.928C110.308 208.211 111.356 209.258 112.639 209.258C113.923 209.258 114.971 208.211 114.971 206.928C114.981 205.646 113.933 204.598 112.639 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M112.639 211.055C111.356 211.055 110.308 212.102 110.308 213.395C110.308 214.678 111.356 215.725 112.639 215.725C113.923 215.725 114.971 214.678 114.971 213.395C114.981 212.102 113.933 211.055 112.639 211.055Z"
            fill="#ADADAD"
          />
          <path
            d="M112.639 217.51C111.356 217.51 110.308 218.558 110.308 219.84C110.308 221.123 111.356 222.181 112.639 222.181C113.923 222.181 114.971 221.134 114.971 219.84C114.981 218.558 113.933 217.51 112.639 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M112.639 223.966C111.356 223.966 110.308 225.014 110.308 226.297C110.308 227.579 111.356 228.627 112.639 228.627C113.923 228.627 114.971 227.579 114.971 226.297C114.981 225.003 113.933 223.966 112.639 223.966Z"
            fill="#ADADAD"
          />
          <path
            d="M106.18 94.9011C104.896 94.9011 103.848 95.9486 103.848 97.2312C103.848 98.5138 104.896 99.5613 106.18 99.5613C107.474 99.5613 108.522 98.5138 108.522 97.2312C108.522 95.9486 107.474 94.9011 106.18 94.9011Z"
            fill="#ADADAD"
          />
          <path
            d="M106.18 101.357C104.896 101.357 103.848 102.404 103.848 103.687C103.848 104.969 104.896 106.017 106.18 106.017C107.474 106.017 108.522 104.969 108.522 103.687C108.522 102.404 107.474 101.357 106.18 101.357Z"
            fill="#ADADAD"
          />
          <path
            d="M106.18 107.813C104.896 107.813 103.848 108.86 103.848 110.143C103.848 111.426 104.896 112.473 106.18 112.473C107.474 112.473 108.522 111.426 108.522 110.143C108.522 108.86 107.474 107.813 106.18 107.813Z"
            fill="#ADADAD"
          />
          <path
            d="M106.18 114.258C104.896 114.258 103.848 115.306 103.848 116.599C103.848 117.881 104.896 118.929 106.18 118.929C107.474 118.929 108.522 117.881 108.522 116.599C108.522 115.306 107.474 114.258 106.18 114.258Z"
            fill="#ADADAD"
          />
          <path
            d="M106.18 120.714C104.896 120.714 103.848 121.762 103.848 123.055C103.848 124.338 104.896 125.385 106.18 125.385C107.474 125.385 108.522 124.338 108.522 123.055C108.522 121.762 107.474 120.714 106.18 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M106.18 127.17C104.896 127.17 103.848 128.217 103.848 129.5C103.848 130.793 104.896 131.841 106.18 131.841C107.474 131.841 108.522 130.793 108.522 129.5C108.522 128.217 107.474 127.17 106.18 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M106.18 133.615C104.896 133.615 103.848 134.662 103.848 135.945C103.848 137.228 104.896 138.275 106.18 138.275C107.474 138.275 108.522 137.228 108.522 135.945C108.522 134.662 107.474 133.615 106.18 133.615Z"
            fill="#ADADAD"
          />
          <path
            d="M106.18 140.071C104.896 140.071 103.848 141.119 103.848 142.401C103.848 143.684 104.896 144.731 106.18 144.731C107.474 144.731 108.522 143.684 108.522 142.401C108.522 141.119 107.474 140.071 106.18 140.071Z"
            fill="#ADADAD"
          />
          <path
            d="M106.18 146.527C104.896 146.527 103.848 147.574 103.848 148.857C103.848 150.14 104.896 151.187 106.18 151.187C107.474 151.187 108.522 150.14 108.522 148.857C108.522 147.574 107.474 146.527 106.18 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M106.18 152.983C104.896 152.983 103.848 154.031 103.848 155.313C103.848 156.596 104.896 157.654 106.18 157.654C107.474 157.654 108.522 156.607 108.522 155.313C108.522 154.031 107.474 152.983 106.18 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M106.18 159.428C104.896 159.428 103.848 160.476 103.848 161.758C103.848 163.052 104.896 164.099 106.18 164.099C107.474 164.099 108.522 163.052 108.522 161.758C108.522 160.476 107.474 159.428 106.18 159.428Z"
            fill="#ADADAD"
          />
          <path
            d="M106.18 165.884C104.896 165.884 103.848 166.932 103.848 168.215C103.848 169.497 104.896 170.545 106.18 170.545C107.474 170.545 108.522 169.497 108.522 168.215C108.522 166.932 107.474 165.884 106.18 165.884Z"
            fill="#ADADAD"
          />
          <path
            d="M106.18 172.34C104.896 172.34 103.848 173.388 103.848 174.681C103.848 175.963 104.896 177.011 106.18 177.011C107.474 177.011 108.522 175.963 108.522 174.681C108.522 173.388 107.474 172.34 106.18 172.34Z"
            fill="#ADADAD"
          />
          <path
            d="M106.18 178.786C104.896 178.786 103.848 179.833 103.848 181.116C103.848 182.398 104.896 183.446 106.18 183.446C107.474 183.446 108.522 182.398 108.522 181.116C108.522 179.833 107.474 178.786 106.18 178.786Z"
            fill="#ADADAD"
          />
          <path
            d="M106.18 185.241C104.896 185.241 103.848 186.289 103.848 187.571C103.848 188.865 104.896 189.912 106.18 189.912C107.474 189.912 108.522 188.865 108.522 187.571C108.522 186.289 107.474 185.241 106.18 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M106.18 191.698C104.896 191.698 103.848 192.745 103.848 194.028C103.848 195.31 104.896 196.358 106.18 196.358C107.474 196.358 108.522 195.31 108.522 194.028C108.522 192.745 107.474 191.698 106.18 191.698Z"
            fill="#ADADAD"
          />
          <path
            d="M106.18 198.153C104.896 198.153 103.848 199.201 103.848 200.483C103.848 201.766 104.896 202.813 106.18 202.813C107.474 202.813 108.522 201.766 108.522 200.483C108.522 199.201 107.474 198.153 106.18 198.153Z"
            fill="#ADADAD"
          />
          <path
            d="M106.18 204.598C104.896 204.598 103.848 205.646 103.848 206.928C103.848 208.211 104.896 209.258 106.18 209.258C107.474 209.258 108.522 208.211 108.522 206.928C108.522 205.646 107.474 204.598 106.18 204.598Z"
            fill="#ADADAD"
          />
          <path
            d="M106.18 211.055C104.896 211.055 103.848 212.102 103.848 213.395C103.848 214.678 104.896 215.725 106.18 215.725C107.474 215.725 108.522 214.678 108.522 213.395C108.522 212.102 107.474 211.055 106.18 211.055Z"
            fill="#ADADAD"
          />
          <path
            d="M106.18 217.51C104.896 217.51 103.848 218.558 103.848 219.84C103.848 221.123 104.896 222.181 106.18 222.181C107.474 222.181 108.522 221.134 108.522 219.84C108.522 218.558 107.474 217.51 106.18 217.51Z"
            fill="#ADADAD"
          />
          <path
            d="M99.7293 107.813C98.446 107.813 97.3979 108.861 97.3979 110.143C97.3979 111.426 98.446 112.473 99.7293 112.473C101.013 112.473 102.061 111.426 102.061 110.143C102.061 108.861 101.013 107.813 99.7293 107.813Z"
            fill="#ADADAD"
          />
          <path
            d="M99.7293 114.257C98.446 114.257 97.3979 115.305 97.3979 116.598C97.3979 117.881 98.446 118.928 99.7293 118.928C101.013 118.928 102.061 117.881 102.061 116.598C102.061 115.305 101.013 114.257 99.7293 114.257Z"
            fill="#ADADAD"
          />
          <path
            d="M99.7293 120.714C98.446 120.714 97.3979 121.761 97.3979 123.054C97.3979 124.337 98.446 125.385 99.7293 125.385C101.013 125.385 102.061 124.337 102.061 123.054C102.061 121.761 101.013 120.714 99.7293 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M99.7293 127.17C98.446 127.17 97.3979 128.217 97.3979 129.5C97.3979 130.793 98.446 131.841 99.7293 131.841C101.013 131.841 102.061 130.793 102.061 129.5C102.061 128.217 101.013 127.17 99.7293 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M99.7293 133.616C98.446 133.616 97.3979 134.663 97.3979 135.946C97.3979 137.228 98.446 138.276 99.7293 138.276C101.013 138.276 102.061 137.228 102.061 135.946C102.061 134.663 101.013 133.616 99.7293 133.616Z"
            fill="#ADADAD"
          />
          <path
            d="M99.7293 140.072C98.446 140.072 97.3979 141.119 97.3979 142.402C97.3979 143.685 98.446 144.732 99.7293 144.732C101.013 144.732 102.061 143.685 102.061 142.402C102.061 141.119 101.013 140.072 99.7293 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M99.7293 146.527C98.446 146.527 97.3979 147.574 97.3979 148.857C97.3979 150.139 98.446 151.187 99.7293 151.187C101.013 151.187 102.061 150.139 102.061 148.857C102.061 147.574 101.013 146.527 99.7293 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M99.7293 152.983C98.446 152.983 97.3979 154.03 97.3979 155.313C97.3979 156.596 98.446 157.654 99.7293 157.654C101.013 157.654 102.061 156.606 102.061 155.313C102.061 154.03 101.013 152.983 99.7293 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M99.7293 159.429C98.446 159.429 97.3979 160.476 97.3979 161.759C97.3979 163.052 98.446 164.099 99.7293 164.099C101.013 164.099 102.061 163.052 102.061 161.759C102.061 160.476 101.013 159.429 99.7293 159.429Z"
            fill="#ADADAD"
          />
          <path
            d="M99.7293 165.885C98.446 165.885 97.3979 166.932 97.3979 168.215C97.3979 169.497 98.446 170.545 99.7293 170.545C101.013 170.545 102.061 169.497 102.061 168.215C102.061 166.932 101.013 165.885 99.7293 165.885Z"
            fill="#ADADAD"
          />
          <path
            d="M99.7293 172.339C98.446 172.339 97.3979 173.387 97.3979 174.68C97.3979 175.963 98.446 177.01 99.7293 177.01C101.013 177.01 102.061 175.963 102.061 174.68C102.061 173.387 101.013 172.339 99.7293 172.339Z"
            fill="#ADADAD"
          />
          <path
            d="M99.7293 178.785C98.446 178.785 97.3979 179.833 97.3979 181.115C97.3979 182.398 98.446 183.445 99.7293 183.445C101.013 183.445 102.061 182.398 102.061 181.115C102.061 179.833 101.013 178.785 99.7293 178.785Z"
            fill="#ADADAD"
          />
          <path
            d="M99.7293 185.241C98.446 185.241 97.3979 186.289 97.3979 187.572C97.3979 188.865 98.446 189.912 99.7293 189.912C101.013 189.912 102.061 188.865 102.061 187.572C102.061 186.289 101.013 185.241 99.7293 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M93.2717 120.714C91.9776 120.714 90.9295 121.761 90.9295 123.054C90.9295 124.337 91.9776 125.385 93.2717 125.385C94.555 125.385 95.6031 124.337 95.6031 123.054C95.6031 121.761 94.555 120.714 93.2717 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M93.2717 127.17C91.9776 127.17 90.9295 128.217 90.9295 129.5C90.9295 130.793 91.9776 131.841 93.2717 131.841C94.555 131.841 95.6031 130.793 95.6031 129.5C95.6031 128.217 94.555 127.17 93.2717 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M93.2717 133.616C91.9776 133.616 90.9295 134.663 90.9295 135.946C90.9295 137.228 91.9776 138.276 93.2717 138.276C94.555 138.276 95.6031 137.228 95.6031 135.946C95.6031 134.663 94.555 133.616 93.2717 133.616Z"
            fill="#ADADAD"
          />
          <path
            d="M93.2717 140.072C91.9776 140.072 90.9295 141.119 90.9295 142.402C90.9295 143.685 91.9776 144.732 93.2717 144.732C94.555 144.732 95.6031 143.685 95.6031 142.402C95.6031 141.119 94.555 140.072 93.2717 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M93.2717 146.527C91.9776 146.527 90.9295 147.574 90.9295 148.857C90.9295 150.139 91.9776 151.187 93.2717 151.187C94.555 151.187 95.6031 150.139 95.6031 148.857C95.6031 147.574 94.555 146.527 93.2717 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M93.2717 152.983C91.9776 152.983 90.9295 154.03 90.9295 155.313C90.9295 156.596 91.9776 157.654 93.2717 157.654C94.555 157.654 95.6031 156.606 95.6031 155.313C95.6031 154.03 94.555 152.983 93.2717 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M93.2717 159.429C91.9776 159.429 90.9295 160.476 90.9295 161.759C90.9295 163.052 91.9776 164.099 93.2717 164.099C94.555 164.099 95.6031 163.052 95.6031 161.759C95.6031 160.476 94.555 159.429 93.2717 159.429Z"
            fill="#ADADAD"
          />
          <path
            d="M93.2717 165.885C91.9776 165.885 90.9295 166.932 90.9295 168.215C90.9295 169.497 91.9776 170.545 93.2717 170.545C94.555 170.545 95.6031 169.497 95.6031 168.215C95.6031 166.932 94.555 165.885 93.2717 165.885Z"
            fill="#ADADAD"
          />
          <path
            d="M93.2717 172.339C91.9776 172.339 90.9295 173.387 90.9295 174.68C90.9295 175.963 91.9776 177.01 93.2717 177.01C94.555 177.01 95.6031 175.963 95.6031 174.68C95.6031 173.387 94.555 172.339 93.2717 172.339Z"
            fill="#ADADAD"
          />
          <path
            d="M93.2717 178.785C91.9776 178.785 90.9295 179.833 90.9295 181.115C90.9295 182.398 91.9776 183.445 93.2717 183.445C94.555 183.445 95.6031 182.398 95.6031 181.115C95.6031 179.833 94.555 178.785 93.2717 178.785Z"
            fill="#ADADAD"
          />
          <path
            d="M93.2717 185.241C91.9776 185.241 90.9295 186.289 90.9295 187.572C90.9295 188.865 91.9776 189.912 93.2717 189.912C94.555 189.912 95.6031 188.865 95.6031 187.572C95.6031 186.289 94.555 185.241 93.2717 185.241Z"
            fill="#ADADAD"
          />
          <path
            d="M86.8123 120.714C85.5182 120.714 84.4701 121.761 84.4701 123.054C84.4701 124.337 85.5182 125.385 86.8123 125.385C88.0956 125.385 89.1437 124.337 89.1437 123.054C89.1544 121.761 88.1063 120.714 86.8123 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M86.8123 127.17C85.5182 127.17 84.4701 128.217 84.4701 129.5C84.4701 130.793 85.5182 131.841 86.8123 131.841C88.0956 131.841 89.1437 130.793 89.1437 129.5C89.1544 128.217 88.1063 127.17 86.8123 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M86.8123 133.616C85.5182 133.616 84.4701 134.663 84.4701 135.946C84.4701 137.228 85.5182 138.276 86.8123 138.276C88.0956 138.276 89.1437 137.228 89.1437 135.946C89.1544 134.663 88.1063 133.616 86.8123 133.616Z"
            fill="#ADADAD"
          />
          <path
            d="M86.8123 140.072C85.5182 140.072 84.4701 141.119 84.4701 142.402C84.4701 143.685 85.5182 144.732 86.8123 144.732C88.0956 144.732 89.1437 143.685 89.1437 142.402C89.1544 141.119 88.1063 140.072 86.8123 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M86.8123 146.527C85.5182 146.527 84.4701 147.574 84.4701 148.857C84.4701 150.139 85.5182 151.187 86.8123 151.187C88.0956 151.187 89.1437 150.139 89.1437 148.857C89.1437 147.574 88.1063 146.527 86.8123 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M86.8123 152.983C85.5182 152.983 84.4701 154.03 84.4701 155.313C84.4701 156.596 85.5182 157.654 86.8123 157.654C88.0956 157.654 89.1437 156.606 89.1437 155.313C89.1544 154.03 88.1063 152.983 86.8123 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M86.8123 159.429C85.5182 159.429 84.4701 160.476 84.4701 161.759C84.4701 163.052 85.5182 164.099 86.8123 164.099C88.0956 164.099 89.1437 163.052 89.1437 161.759C89.1544 160.476 88.1063 159.429 86.8123 159.429Z"
            fill="#ADADAD"
          />
          <path
            d="M86.8123 165.885C85.5182 165.885 84.4701 166.932 84.4701 168.215C84.4701 169.497 85.5182 170.545 86.8123 170.545C88.0956 170.545 89.1437 169.497 89.1437 168.215C89.1544 166.932 88.1063 165.885 86.8123 165.885Z"
            fill="#ADADAD"
          />
          <path
            d="M86.8123 172.339C85.5182 172.339 84.4701 173.387 84.4701 174.68C84.4701 175.963 85.5182 177.01 86.8123 177.01C88.0956 177.01 89.1437 175.963 89.1437 174.68C89.1544 173.387 88.1063 172.339 86.8123 172.339Z"
            fill="#ADADAD"
          />
          <path
            d="M80.3515 127.17C79.0681 127.17 78.02 128.217 78.02 129.5C78.02 130.793 79.0681 131.841 80.3515 131.841C81.6348 131.841 82.6829 130.793 82.6829 129.5C82.6936 128.217 81.6455 127.17 80.3515 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M80.3515 133.615C79.0681 133.615 78.02 134.662 78.02 135.945C78.02 137.228 79.0681 138.275 80.3515 138.275C81.6348 138.275 82.6829 137.228 82.6829 135.945C82.6936 134.662 81.6455 133.615 80.3515 133.615Z"
            fill="#ADADAD"
          />
          <path
            d="M80.3515 140.071C79.0681 140.071 78.02 141.119 78.02 142.401C78.02 143.684 79.0681 144.731 80.3515 144.731C81.6348 144.731 82.6829 143.684 82.6829 142.401C82.6936 141.119 81.6455 140.071 80.3515 140.071Z"
            fill="#ADADAD"
          />
          <path
            d="M80.3515 146.527C79.0681 146.527 78.02 147.574 78.02 148.857C78.02 150.14 79.0681 151.187 80.3515 151.187C81.6348 151.187 82.6829 150.14 82.6829 148.857C82.6829 147.574 81.6455 146.527 80.3515 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M80.3515 152.983C79.0681 152.983 78.02 154.031 78.02 155.313C78.02 156.596 79.0681 157.654 80.3515 157.654C81.6348 157.654 82.6829 156.607 82.6829 155.313C82.6936 154.031 81.6455 152.983 80.3515 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M80.3515 159.428C79.0681 159.428 78.02 160.476 78.02 161.758C78.02 163.052 79.0681 164.099 80.3515 164.099C81.6348 164.099 82.6829 163.052 82.6829 161.758C82.6936 160.476 81.6455 159.428 80.3515 159.428Z"
            fill="#ADADAD"
          />
          <path
            d="M80.3515 165.884C79.0681 165.884 78.02 166.932 78.02 168.215C78.02 169.497 79.0681 170.545 80.3515 170.545C81.6348 170.545 82.6829 169.497 82.6829 168.215C82.6936 166.932 81.6455 165.884 80.3515 165.884Z"
            fill="#ADADAD"
          />
          <path
            d="M80.3515 172.34C79.0681 172.34 78.02 173.388 78.02 174.681C78.02 175.963 79.0681 177.011 80.3515 177.011C81.6348 177.011 82.6829 175.963 82.6829 174.681C82.6936 173.388 81.6455 172.34 80.3515 172.34Z"
            fill="#ADADAD"
          />
          <path
            d="M73.8922 127.17C72.6088 127.17 71.5607 128.217 71.5607 129.5C71.5607 130.793 72.6088 131.841 73.8922 131.841C75.1862 131.841 76.2343 130.793 76.2343 129.5C76.2343 128.217 75.1862 127.17 73.8922 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M73.8922 133.615C72.6088 133.615 71.5607 134.662 71.5607 135.945C71.5607 137.228 72.6088 138.275 73.8922 138.275C75.1862 138.275 76.2343 137.228 76.2343 135.945C76.2343 134.662 75.1862 133.615 73.8922 133.615Z"
            fill="#ADADAD"
          />
          <path
            d="M73.8922 140.071C72.6088 140.071 71.5607 141.119 71.5607 142.401C71.5607 143.684 72.6088 144.731 73.8922 144.731C75.1862 144.731 76.2343 143.684 76.2343 142.401C76.2343 141.119 75.1862 140.071 73.8922 140.071Z"
            fill="#ADADAD"
          />
          <path
            d="M73.8922 146.527C72.6088 146.527 71.5607 147.574 71.5607 148.857C71.5607 150.14 72.6088 151.187 73.8922 151.187C75.1862 151.187 76.2343 150.14 76.2343 148.857C76.2343 147.574 75.1862 146.527 73.8922 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M73.8922 152.983C72.6088 152.983 71.5607 154.031 71.5607 155.313C71.5607 156.596 72.6088 157.654 73.8922 157.654C75.1862 157.654 76.2343 156.607 76.2343 155.313C76.2343 154.031 75.1862 152.983 73.8922 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M73.8922 159.428C72.6088 159.428 71.5607 160.476 71.5607 161.758C71.5607 163.052 72.6088 164.099 73.8922 164.099C75.1862 164.099 76.2343 163.052 76.2343 161.758C76.2343 160.476 75.1862 159.428 73.8922 159.428Z"
            fill="#ADADAD"
          />
          <path
            d="M67.4442 120.714C66.1608 120.714 65.1127 121.761 65.1127 123.054C65.1127 124.337 66.1608 125.385 67.4442 125.385C68.7275 125.385 69.7756 124.337 69.7756 123.054C69.7756 121.761 68.7382 120.714 67.4442 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M67.4442 127.17C66.1608 127.17 65.1127 128.217 65.1127 129.5C65.1127 130.793 66.1608 131.841 67.4442 131.841C68.7275 131.841 69.7756 130.793 69.7756 129.5C69.7756 128.217 68.7382 127.17 67.4442 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M67.4442 133.616C66.1608 133.616 65.1127 134.663 65.1127 135.946C65.1127 137.228 66.1608 138.276 67.4442 138.276C68.7275 138.276 69.7756 137.228 69.7756 135.946C69.7756 134.663 68.7382 133.616 67.4442 133.616Z"
            fill="#ADADAD"
          />
          <path
            d="M67.4442 140.072C66.1608 140.072 65.1127 141.119 65.1127 142.402C65.1127 143.685 66.1608 144.732 67.4442 144.732C68.7275 144.732 69.7756 143.685 69.7756 142.402C69.7756 141.119 68.7382 140.072 67.4442 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M67.4442 146.527C66.1608 146.527 65.1127 147.574 65.1127 148.857C65.1127 150.139 66.1608 151.187 67.4442 151.187C68.7275 151.187 69.7756 150.139 69.7756 148.857C69.7756 147.574 68.7382 146.527 67.4442 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M67.4442 152.983C66.1608 152.983 65.1127 154.03 65.1127 155.313C65.1127 156.596 66.1608 157.654 67.4442 157.654C68.7275 157.654 69.7756 156.606 69.7756 155.313C69.7756 154.03 68.7382 152.983 67.4442 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M60.9848 120.714C59.7014 120.714 58.6533 121.761 58.6533 123.054C58.6533 124.337 59.7014 125.385 60.9848 125.385C62.2681 125.385 63.3162 124.337 63.3162 123.054C63.3269 121.761 62.2788 120.714 60.9848 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M60.9848 127.17C59.7014 127.17 58.6533 128.217 58.6533 129.5C58.6533 130.793 59.7014 131.841 60.9848 131.841C62.2681 131.841 63.3162 130.793 63.3162 129.5C63.3269 128.217 62.2788 127.17 60.9848 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M60.9848 133.616C59.7014 133.616 58.6533 134.663 58.6533 135.946C58.6533 137.228 59.7014 138.276 60.9848 138.276C62.2681 138.276 63.3162 137.228 63.3162 135.946C63.3269 134.663 62.2788 133.616 60.9848 133.616Z"
            fill="#ADADAD"
          />
          <path
            d="M60.9848 140.072C59.7014 140.072 58.6533 141.119 58.6533 142.402C58.6533 143.685 59.7014 144.732 60.9848 144.732C62.2681 144.732 63.3162 143.685 63.3162 142.402C63.3269 141.119 62.2788 140.072 60.9848 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M60.9848 146.527C59.7014 146.527 58.6533 147.574 58.6533 148.857C58.6533 150.139 59.7014 151.187 60.9848 151.187C62.2681 151.187 63.3162 150.139 63.3162 148.857C63.3162 147.574 62.2788 146.527 60.9848 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M60.9848 152.983C59.7014 152.983 58.6533 154.03 58.6533 155.313C58.6533 156.596 59.7014 157.654 60.9848 157.654C62.2681 157.654 63.3162 156.606 63.3162 155.313C63.3269 154.03 62.2788 152.983 60.9848 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M54.5343 120.714C53.2509 120.714 52.2028 121.761 52.2028 123.054C52.2028 124.337 53.2509 125.385 54.5343 125.385C55.8176 125.385 56.8657 124.337 56.8657 123.054C56.8657 121.761 55.8176 120.714 54.5343 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M54.5343 127.17C53.2509 127.17 52.2028 128.217 52.2028 129.5C52.2028 130.793 53.2509 131.841 54.5343 131.841C55.8176 131.841 56.8657 130.793 56.8657 129.5C56.8657 128.217 55.8176 127.17 54.5343 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M54.5343 133.616C53.2509 133.616 52.2028 134.663 52.2028 135.946C52.2028 137.228 53.2509 138.276 54.5343 138.276C55.8176 138.276 56.8657 137.228 56.8657 135.946C56.8657 134.663 55.8176 133.616 54.5343 133.616Z"
            fill="#ADADAD"
          />
          <path
            d="M54.5343 140.072C53.2509 140.072 52.2028 141.119 52.2028 142.402C52.2028 143.685 53.2509 144.732 54.5343 144.732C55.8176 144.732 56.8657 143.685 56.8657 142.402C56.8657 141.119 55.8176 140.072 54.5343 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M54.5343 146.527C53.2509 146.527 52.2028 147.574 52.2028 148.857C52.2028 150.139 53.2509 151.187 54.5343 151.187C55.8176 151.187 56.8657 150.139 56.8657 148.857C56.8657 147.574 55.8176 146.527 54.5343 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M54.5343 152.983C53.2509 152.983 52.2028 154.03 52.2028 155.313C52.2028 156.596 53.2509 157.654 54.5343 157.654C55.8176 157.654 56.8657 156.606 56.8657 155.313C56.8657 154.03 55.8176 152.983 54.5343 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M48.075 120.714C46.7916 120.714 45.7436 121.762 45.7436 123.055C45.7436 124.338 46.7916 125.385 48.075 125.385C49.3584 125.385 50.4064 124.338 50.4064 123.055C50.4064 121.762 49.3584 120.714 48.075 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M48.075 127.17C46.7916 127.17 45.7436 128.217 45.7436 129.5C45.7436 130.793 46.7916 131.841 48.075 131.841C49.3584 131.841 50.4064 130.793 50.4064 129.5C50.4064 128.217 49.3584 127.17 48.075 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M48.075 133.615C46.7916 133.615 45.7436 134.662 45.7436 135.945C45.7436 137.228 46.7916 138.275 48.075 138.275C49.3584 138.275 50.4064 137.228 50.4064 135.945C50.4064 134.662 49.3584 133.615 48.075 133.615Z"
            fill="#ADADAD"
          />
          <path
            d="M48.075 140.071C46.7916 140.071 45.7436 141.119 45.7436 142.401C45.7436 143.684 46.7916 144.731 48.075 144.731C49.3584 144.731 50.4064 143.684 50.4064 142.401C50.4064 141.119 49.3584 140.071 48.075 140.071Z"
            fill="#ADADAD"
          />
          <path
            d="M48.075 151.187C49.3626 151.187 50.4064 150.144 50.4064 148.857C50.4064 147.57 49.3626 146.527 48.075 146.527C46.7874 146.527 45.7436 147.57 45.7436 148.857C45.7436 150.144 46.7874 151.187 48.075 151.187Z"
            fill="#ADADAD"
          />
          <path
            d="M48.075 152.983C46.7916 152.983 45.7436 154.031 45.7436 155.313C45.7436 156.596 46.7916 157.654 48.075 157.654C49.3584 157.654 50.4064 156.607 50.4064 155.313C50.4064 154.031 49.3584 152.983 48.075 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M41.6157 120.714C40.3323 120.714 39.2843 121.762 39.2843 123.055C39.2843 124.338 40.3323 125.385 41.6157 125.385C42.8991 125.385 43.9471 124.338 43.9471 123.055C43.9578 121.762 42.9098 120.714 41.6157 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M41.6157 127.17C40.3323 127.17 39.2843 128.217 39.2843 129.5C39.2843 130.793 40.3323 131.841 41.6157 131.841C42.8991 131.841 43.9471 130.793 43.9471 129.5C43.9578 128.217 42.9098 127.17 41.6157 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M41.6157 133.615C40.3323 133.615 39.2843 134.662 39.2843 135.945C39.2843 137.228 40.3323 138.275 41.6157 138.275C42.8991 138.275 43.9471 137.228 43.9471 135.945C43.9578 134.662 42.9098 133.615 41.6157 133.615Z"
            fill="#ADADAD"
          />
          <path
            d="M41.6157 140.071C40.3323 140.071 39.2843 141.119 39.2843 142.401C39.2843 143.684 40.3323 144.731 41.6157 144.731C42.8991 144.731 43.9471 143.684 43.9471 142.401C43.9578 141.119 42.9098 140.071 41.6157 140.071Z"
            fill="#ADADAD"
          />
          <path
            d="M41.6157 146.527C40.3323 146.527 39.2843 147.574 39.2843 148.857C39.2843 150.14 40.3323 151.187 41.6157 151.187C42.8991 151.187 43.9471 150.14 43.9471 148.857C43.9471 147.574 42.9098 146.527 41.6157 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M41.6157 152.983C40.3323 152.983 39.2843 154.031 39.2843 155.313C39.2843 156.596 40.3323 157.654 41.6157 157.654C42.8991 157.654 43.9471 156.607 43.9471 155.313C43.9578 154.031 42.9098 152.983 41.6157 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M41.6157 159.428C40.3323 159.428 39.2843 160.476 39.2843 161.758C39.2843 163.052 40.3323 164.099 41.6157 164.099C42.8991 164.099 43.9471 163.052 43.9471 161.758C43.9578 160.476 42.9098 159.428 41.6157 159.428Z"
            fill="#ADADAD"
          />
          <path
            d="M35.1564 114.258C33.873 114.258 32.825 115.306 32.825 116.599C32.825 117.881 33.873 118.929 35.1564 118.929C36.4505 118.929 37.4985 117.881 37.4985 116.599C37.4985 115.306 36.4505 114.258 35.1564 114.258Z"
            fill="#ADADAD"
          />
          <path
            d="M35.1564 120.714C33.873 120.714 32.825 121.762 32.825 123.055C32.825 124.338 33.873 125.385 35.1564 125.385C36.4505 125.385 37.4985 124.338 37.4985 123.055C37.4985 121.762 36.4505 120.714 35.1564 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M35.1564 127.17C33.873 127.17 32.825 128.217 32.825 129.5C32.825 130.793 33.873 131.841 35.1564 131.841C36.4505 131.841 37.4985 130.793 37.4985 129.5C37.4985 128.217 36.4505 127.17 35.1564 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M35.1564 133.615C33.873 133.615 32.825 134.662 32.825 135.945C32.825 137.228 33.873 138.275 35.1564 138.275C36.4505 138.275 37.4985 137.228 37.4985 135.945C37.4985 134.662 36.4505 133.615 35.1564 133.615Z"
            fill="#ADADAD"
          />
          <path
            d="M35.1564 140.071C33.873 140.071 32.825 141.119 32.825 142.401C32.825 143.684 33.873 144.731 35.1564 144.731C36.4505 144.731 37.4985 143.684 37.4985 142.401C37.4985 141.119 36.4505 140.071 35.1564 140.071Z"
            fill="#ADADAD"
          />
          <path
            d="M35.1564 146.527C33.873 146.527 32.825 147.574 32.825 148.857C32.825 150.14 33.873 151.187 35.1564 151.187C36.4505 151.187 37.4985 150.14 37.4985 148.857C37.4985 147.574 36.4505 146.527 35.1564 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M35.1564 152.983C33.873 152.983 32.825 154.031 32.825 155.313C32.825 156.596 33.873 157.654 35.1564 157.654C36.4505 157.654 37.4985 156.607 37.4985 155.313C37.4985 154.031 36.4505 152.983 35.1564 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M35.1564 159.428C33.873 159.428 32.825 160.476 32.825 161.758C32.825 163.052 33.873 164.099 35.1564 164.099C36.4505 164.099 37.4985 163.052 37.4985 161.758C37.4985 160.476 36.4505 159.428 35.1564 159.428Z"
            fill="#ADADAD"
          />
          <path
            d="M35.1564 165.884C33.873 165.884 32.825 166.932 32.825 168.215C32.825 169.497 33.873 170.545 35.1564 170.545C36.4505 170.545 37.4985 169.497 37.4985 168.215C37.4985 166.932 36.4505 165.884 35.1564 165.884Z"
            fill="#ADADAD"
          />
          <path
            d="M28.7086 114.257C27.4252 114.257 26.3771 115.305 26.3771 116.598C26.3771 117.881 27.4252 118.928 28.7086 118.928C29.9919 118.928 31.04 117.881 31.04 116.598C31.04 115.305 29.9919 114.257 28.7086 114.257Z"
            fill="#ADADAD"
          />
          <path
            d="M28.7086 120.714C27.4252 120.714 26.3771 121.761 26.3771 123.054C26.3771 124.337 27.4252 125.385 28.7086 125.385C29.9919 125.385 31.04 124.337 31.04 123.054C31.04 121.761 29.9919 120.714 28.7086 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M28.7086 127.17C27.4252 127.17 26.3771 128.217 26.3771 129.5C26.3771 130.793 27.4252 131.841 28.7086 131.841C29.9919 131.841 31.04 130.793 31.04 129.5C31.04 128.217 29.9919 127.17 28.7086 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M28.7086 133.616C27.4252 133.616 26.3771 134.663 26.3771 135.946C26.3771 137.228 27.4252 138.276 28.7086 138.276C29.9919 138.276 31.04 137.228 31.04 135.946C31.04 134.663 29.9919 133.616 28.7086 133.616Z"
            fill="#ADADAD"
          />
          <path
            d="M28.7086 140.072C27.4252 140.072 26.3771 141.119 26.3771 142.402C26.3771 143.685 27.4252 144.732 28.7086 144.732C29.9919 144.732 31.04 143.685 31.04 142.402C31.04 141.119 29.9919 140.072 28.7086 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M28.7086 146.527C27.4252 146.527 26.3771 147.574 26.3771 148.857C26.3771 150.139 27.4252 151.187 28.7086 151.187C29.9919 151.187 31.04 150.139 31.04 148.857C31.04 147.574 29.9919 146.527 28.7086 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M28.7086 152.983C27.4252 152.983 26.3771 154.03 26.3771 155.313C26.3771 156.596 27.4252 157.654 28.7086 157.654C29.9919 157.654 31.04 156.606 31.04 155.313C31.04 154.03 29.9919 152.983 28.7086 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M28.7086 159.429C27.4252 159.429 26.3771 160.476 26.3771 161.759C26.3771 163.052 27.4252 164.099 28.7086 164.099C29.9919 164.099 31.04 163.052 31.04 161.759C31.04 160.476 29.9919 159.429 28.7086 159.429Z"
            fill="#ADADAD"
          />
          <path
            d="M28.7086 165.885C27.4252 165.885 26.3771 166.932 26.3771 168.215C26.3771 169.497 27.4252 170.545 28.7086 170.545C29.9919 170.545 31.04 169.497 31.04 168.215C31.04 166.932 29.9919 165.885 28.7086 165.885Z"
            fill="#ADADAD"
          />
          <path
            d="M22.2473 120.714C20.9532 120.714 19.9052 121.762 19.9052 123.055C19.9052 124.338 20.9532 125.385 22.2473 125.385C23.5307 125.385 24.5787 124.338 24.5787 123.055C24.5787 121.762 23.5414 120.714 22.2473 120.714Z"
            fill="#ADADAD"
          />
          <path
            d="M22.2473 127.17C20.9532 127.17 19.9052 128.217 19.9052 129.5C19.9052 130.793 20.9532 131.841 22.2473 131.841C23.5307 131.841 24.5787 130.793 24.5787 129.5C24.5787 128.217 23.5414 127.17 22.2473 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M22.2473 133.615C20.9532 133.615 19.9052 134.662 19.9052 135.945C19.9052 137.228 20.9532 138.275 22.2473 138.275C23.5307 138.275 24.5787 137.228 24.5787 135.945C24.5787 134.662 23.5414 133.615 22.2473 133.615Z"
            fill="#ADADAD"
          />
          <path
            d="M22.2473 140.071C20.9532 140.071 19.9052 141.119 19.9052 142.401C19.9052 143.684 20.9532 144.731 22.2473 144.731C23.5307 144.731 24.5787 143.684 24.5787 142.401C24.5787 141.119 23.5414 140.071 22.2473 140.071Z"
            fill="#ADADAD"
          />
          <path
            d="M22.2473 146.527C20.9532 146.527 19.9052 147.574 19.9052 148.857C19.9052 150.14 20.9532 151.187 22.2473 151.187C23.5307 151.187 24.5787 150.14 24.5787 148.857C24.5787 147.574 23.5414 146.527 22.2473 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M22.2473 152.983C20.9532 152.983 19.9052 154.031 19.9052 155.313C19.9052 156.596 20.9532 157.654 22.2473 157.654C23.5307 157.654 24.5787 156.607 24.5787 155.313C24.5787 154.031 23.5414 152.983 22.2473 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M22.2473 159.428C20.9532 159.428 19.9052 160.476 19.9052 161.758C19.9052 163.052 20.9532 164.099 22.2473 164.099C23.5307 164.099 24.5787 163.052 24.5787 161.758C24.5787 160.476 23.5414 159.428 22.2473 159.428Z"
            fill="#ADADAD"
          />
          <path
            d="M22.2473 165.884C20.9532 165.884 19.9052 166.932 19.9052 168.215C19.9052 169.497 20.9532 170.545 22.2473 170.545C23.5307 170.545 24.5787 169.497 24.5787 168.215C24.5787 166.932 23.5414 165.884 22.2473 165.884Z"
            fill="#ADADAD"
          />
          <path
            d="M22.2473 172.34C20.9532 172.34 19.9052 173.388 19.9052 174.681C19.9052 175.963 20.9532 177.011 22.2473 177.011C23.5307 177.011 24.5787 175.963 24.5787 174.681C24.5787 173.388 23.5414 172.34 22.2473 172.34Z"
            fill="#ADADAD"
          />
          <path
            d="M15.7897 127.17C14.5064 127.17 13.4583 128.217 13.4583 129.5C13.4583 130.793 14.5064 131.841 15.7897 131.841C17.0731 131.841 18.1212 130.793 18.1212 129.5C18.1319 128.217 17.0838 127.17 15.7897 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M15.7897 133.616C14.5064 133.616 13.4583 134.663 13.4583 135.946C13.4583 137.228 14.5064 138.276 15.7897 138.276C17.0731 138.276 18.1212 137.228 18.1212 135.946C18.1319 134.663 17.0838 133.616 15.7897 133.616Z"
            fill="#ADADAD"
          />
          <path
            d="M15.7897 140.072C14.5064 140.072 13.4583 141.119 13.4583 142.402C13.4583 143.685 14.5064 144.732 15.7897 144.732C17.0731 144.732 18.1212 143.685 18.1212 142.402C18.1319 141.119 17.0838 140.072 15.7897 140.072Z"
            fill="#ADADAD"
          />
          <path
            d="M15.7897 146.527C14.5064 146.527 13.4583 147.574 13.4583 148.857C13.4583 150.139 14.5064 151.187 15.7897 151.187C17.0731 151.187 18.1212 150.139 18.1212 148.857C18.1212 147.574 17.0838 146.527 15.7897 146.527Z"
            fill="#ADADAD"
          />
          <path
            d="M15.7897 152.983C14.5064 152.983 13.4583 154.03 13.4583 155.313C13.4583 156.596 14.5064 157.654 15.7897 157.654C17.0731 157.654 18.1212 156.606 18.1212 155.313C18.1319 154.03 17.0838 152.983 15.7897 152.983Z"
            fill="#ADADAD"
          />
          <path
            d="M15.7897 172.339C14.5064 172.339 13.4583 173.387 13.4583 174.68C13.4583 175.963 14.5064 177.01 15.7897 177.01C17.0731 177.01 18.1212 175.963 18.1212 174.68C18.1319 173.387 17.0838 172.339 15.7897 172.339Z"
            fill="#ADADAD"
          />
          <path
            d="M9.33925 127.17C8.05589 127.17 7.00781 128.217 7.00781 129.5C7.00781 130.793 8.05589 131.841 9.33925 131.841C10.6226 131.841 11.6707 130.793 11.6707 129.5C11.6707 128.217 10.6226 127.17 9.33925 127.17Z"
            fill="#ADADAD"
          />
          <path
            d="M9.33925 133.615C8.05589 133.615 7.00781 134.662 7.00781 135.945C7.00781 137.228 8.05589 138.275 9.33925 138.275C10.6226 138.275 11.6707 137.228 11.6707 135.945C11.6707 134.662 10.6226 133.615 9.33925 133.615Z"
            fill="#ADADAD"
          />
          <path
            d="M9.33925 140.071C8.05589 140.071 7.00781 141.119 7.00781 142.401C7.00781 143.684 8.05589 144.731 9.33925 144.731C10.6226 144.731 11.6707 143.684 11.6707 142.401C11.6707 141.119 10.6226 140.071 9.33925 140.071Z"
            fill="#ADADAD"
          />
          <path
            d="M9.33925 172.34C8.05589 172.34 7.00781 173.388 7.00781 174.681C7.00781 175.963 8.05589 177.011 9.33925 177.011C10.6226 177.011 11.6707 175.963 11.6707 174.681C11.6707 173.388 10.6226 172.34 9.33925 172.34Z"
            fill="#ADADAD"
          />
          <path
            d="M9.33925 178.786C8.05589 178.786 7.00781 179.833 7.00781 181.116C7.00781 182.398 8.05589 183.446 9.33925 183.446C10.6226 183.446 11.6707 182.398 11.6707 181.116C11.6707 179.833 10.6226 178.786 9.33925 178.786Z"
            fill="#ADADAD"
          />
        </g>

        <path
          d="M429.551 196.253C431.21 196.253 432.555 195.132 432.555 193.749C432.555 192.367 431.21 191.246 429.551 191.246C427.892 191.246 426.547 192.367 426.547 193.749C426.547 195.132 427.892 196.253 429.551 196.253Z"
          fill="#3DAE2B"
        />
        <path
          d="M470.925 99.1387L429.16 140.901V194.032"
          stroke="#3DAE2B"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          className="worldwide-map--label"
          onClick={() => onSelectCountry('ro')}
          d="M602.233 88.3047H470.895V109.897H602.233V88.3047"
          fill="#3DAE2B"
        />
        <text
          className="worldwide-map--text"
          x="539"
          y="100"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {t('worldwide_map.country_label.romania', 'Romania')}
        </text>
        <g opacity="0.35">
          <path
            opacity="0.35"
            d="M470.892 109.908C476.858 109.908 481.695 105.072 481.695 99.1063C481.695 93.1407 476.858 88.3047 470.892 88.3047C464.926 88.3047 460.09 93.1407 460.09 99.1063C460.09 105.072 464.926 109.908 470.892 109.908Z"
            fill="#3DAE2B"
          />
        </g>
        <path
          className="worldwide-map--label-half-circle"
          d="M470.895 88.3047V109.897C476.863 109.897 481.697 105.063 481.697 99.0956C481.697 93.128 476.852 88.3047 470.895 88.3047Z"
          fill="#3DAE2B"
        />
        <path
          d="M470.892 105.213C474.265 105.213 476.999 102.479 476.999 99.1066C476.999 95.734 474.265 93 470.892 93C467.519 93 464.785 95.734 464.785 99.1066C464.785 102.479 467.519 105.213 470.892 105.213Z"
          fill="white"
          className="worldwide-map--letter"
        />

        <path
          d="M470.965 54.7344L422.793 102.914L422.633 219.817"
          stroke="#006937"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          className="worldwide-map--label"
          onClick={() => onSelectCountry('gr')}
          d="M602.284 43.9121H470.945V65.5046H602.284V43.9121Z"
          fill="#006937"
        />
        <g opacity="0.35">
          <path
            opacity="0.35"
            d="M470.943 65.5152C476.909 65.5152 481.745 60.6792 481.745 54.7137C481.745 48.7481 476.909 43.9121 470.943 43.9121C464.977 43.9121 460.141 48.7481 460.141 54.7137C460.141 60.6792 464.977 65.5152 470.943 65.5152Z"
            fill="#006937"
          />
        </g>
        <path
          className="worldwide-map--label-half-circle"
          d="M470.945 43.9121V65.5046C476.913 65.5046 481.748 60.6706 481.748 54.703C481.748 48.7354 476.903 43.9121 470.945 43.9121Z"
          fill="#006937"
        />
        <path
          d="M470.943 60.8207C474.316 60.8207 477.05 58.0867 477.05 54.7141C477.05 51.3415 474.316 48.6074 470.943 48.6074C467.57 48.6074 464.836 51.3415 464.836 54.7141C464.836 58.0867 467.57 60.8207 470.943 60.8207Z"
          fill="white"
          className="worldwide-map--letter"
        />

        <path
          d="M422.632 222.598C424.168 222.598 425.413 221.353 425.413 219.818C425.413 218.282 424.168 217.037 422.632 217.037C421.097 217.037 419.852 218.282 419.852 219.818C419.852 221.353 421.097 222.598 422.632 222.598Z"
          fill="#006937"
        />
        <text
          className="worldwide-map--text"
          x="539"
          y="56"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {t('worldwide_map.country_label.greece', 'Greece')}
        </text>

        <path
          d="M470.905 10.834L416.348 65.3766L416.188 206.963"
          stroke="#023E51"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          className="worldwide-map--label"
          onClick={() => onSelectCountry('hr')}
          d="M602.21 0H470.871V21.5924H602.21V0Z"
          fill="#023E51"
        />
        <g opacity="0.35">
          <path
            opacity="0.35"
            d="M470.873 21.6031C476.839 21.6031 481.675 16.7671 481.675 10.8016C481.675 4.83603 476.839 0 470.873 0C464.907 0 460.07 4.83603 460.07 10.8016C460.07 16.7671 464.907 21.6031 470.873 21.6031Z"
            fill="#023E51"
          />
        </g>
        <path
          className="worldwide-map--label-half-circle"
          d="M470.871 0V21.5924C476.839 21.5924 481.673 16.7585 481.673 10.7909C481.673 4.82327 476.839 0 470.871 0Z"
          fill="#023E51"
        />
        <path
          d="M470.873 16.9086C474.246 16.9086 476.98 14.1745 476.98 10.8019C476.98 7.42934 474.246 4.69531 470.873 4.69531C467.5 4.69531 464.766 7.42934 464.766 10.8019C464.766 14.1745 467.5 16.9086 470.873 16.9086Z"
          fill="white"
          className="worldwide-map--letter"
        />
        <path
          d="M416.187 209.743C417.723 209.743 418.968 208.498 418.968 206.962C418.968 205.427 417.723 204.182 416.187 204.182C414.651 204.182 413.406 205.427 413.406 206.962C413.406 208.498 414.651 209.743 416.187 209.743Z"
          fill="#023E51"
        />

        <text
          className="worldwide-map--text"
          x="539"
          y="12"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {t('worldwide_map.country_label.croatia', 'Croatia')}
        </text>

        <path
          d="M325.984 11.0801L396.67 81.7716L396.83 213.487"
          stroke="#93D500"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          className="worldwide-map--label"
          onClick={() => onSelectCountry('it')}
          d="M194.669 21.8457L326.008 21.8457L326.008 0.253246L194.669 0.253246L194.669 21.8457Z"
          fill="#93D500"
        />
        <g opacity="0.35">
          <path
            opacity="0.35"
            d="M325.982 21.8824C331.948 21.8824 336.784 17.0464 336.784 11.0809C336.784 5.11533 331.948 0.279297 325.982 0.279297C320.016 0.279297 315.18 5.11533 315.18 11.0809C315.18 17.0464 320.016 21.8824 325.982 21.8824Z"
            fill="#93D500"
          />
        </g>
        <path
          className="worldwide-map--label-half-circle"
          d="M325.982 21.8835V0.291016C320.014 0.291016 315.18 5.12498 315.18 11.0926C315.18 17.0602 320.014 21.8835 325.982 21.8835Z"
          fill="#93D500"
        />
        <path
          d="M325.982 17.1879C329.355 17.1879 332.089 14.4538 332.089 11.0812C332.089 7.70864 329.355 4.97461 325.982 4.97461C322.609 4.97461 319.875 7.70864 319.875 11.0812C319.875 14.4538 322.609 17.1879 325.982 17.1879Z"
          fill="white"
          className="worldwide-map--letter"
        />
        <path
          d="M396.828 216.266C398.363 216.266 399.608 215.021 399.608 213.486C399.608 211.95 398.363 210.705 396.828 210.705C395.292 210.705 394.047 211.95 394.047 213.486C394.047 215.021 395.292 216.266 396.828 216.266Z"
          fill="#93D500"
        />
        <text
          className="worldwide-map--text"
          x="260"
          y="12"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {t('worldwide_map.country_label.italy', 'Italy')}
        </text>

        <path
          d="M326.477 54.8965L377.376 105.792L377.536 194.023"
          stroke="#3DAE2B"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          className="worldwide-map--label"
          onClick={() => onSelectCountry('fr')}
          d="M195.166 65.668L325.98 65.668L325.98 44.0755L195.166 44.0755L195.166 65.668Z"
          fill="#3DAE2B"
        />
        <g opacity="0.35">
          <path
            opacity="0.35"
            d="M325.982 65.6988C331.948 65.6988 336.784 60.8628 336.784 54.8973C336.784 48.9317 331.948 44.0957 325.982 44.0957C320.016 44.0957 315.18 48.9317 315.18 54.8973C315.18 60.8628 320.016 65.6988 325.982 65.6988Z"
            fill="#3DAE2B"
          />
        </g>
        <path
          className="worldwide-map--label-half-circle"
          d="M325.982 65.6999V44.1074C320.014 44.1074 315.18 48.9414 315.18 54.909C315.18 60.8766 320.014 65.6999 325.982 65.6999Z"
          fill="#3DAE2B"
        />
        <path
          d="M325.982 61.0043C329.355 61.0043 332.089 58.2703 332.089 54.8977C332.089 51.5251 329.355 48.791 325.982 48.791C322.609 48.791 319.875 51.5251 319.875 54.8977C319.875 58.2703 322.609 61.0043 325.982 61.0043Z"
          fill="white"
          className="worldwide-map--letter"
        />
        <path
          d="M377.535 196.803C379.07 196.803 380.316 195.558 380.316 194.023C380.316 192.487 379.07 191.242 377.535 191.242C375.999 191.242 374.754 192.487 374.754 194.023C374.754 195.558 375.999 196.803 377.535 196.803Z"
          fill="#3DAE2B"
        />

        <text
          className="worldwide-map--text"
          x="260"
          y="56"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {t('worldwide_map.country_label.france', 'France')}
        </text>

        <path
          d="M326.375 99.2266L357.969 130.818L358.13 213.392"
          stroke="#006937"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          className="worldwide-map--label"
          onClick={() => onSelectCountry('es')}
          d="M195.062 109.998L325.973 109.998L325.973 88.4056L195.062 88.4056L195.062 109.998Z"
          fill="#006937"
        />
        <g opacity="0.35">
          <path
            opacity="0.35"
            d="M325.982 110.027C331.948 110.027 336.784 105.191 336.784 99.2254C336.784 93.2599 331.948 88.4238 325.982 88.4238C320.016 88.4238 315.18 93.2599 315.18 99.2254C315.18 105.191 320.016 110.027 325.982 110.027Z"
            fill="#006937"
          />
        </g>
        <path
          className="worldwide-map--label-half-circle"
          d="M325.982 110.016V88.4238C320.014 88.4238 315.18 93.2578 315.18 99.2254C315.18 105.193 320.014 110.016 325.982 110.016Z"
          fill="#006937"
        />
        <path
          d="M325.982 105.332C329.355 105.332 332.089 102.598 332.089 99.2258C332.089 95.8532 329.355 93.1191 325.982 93.1191C322.609 93.1191 319.875 95.8532 319.875 99.2258C319.875 102.598 322.609 105.332 325.982 105.332Z"
          fill="white"
          className="worldwide-map--letter"
        />
        <path
          d="M358.132 216.171C359.668 216.171 360.913 214.926 360.913 213.39C360.913 211.854 359.668 210.609 358.132 210.609C356.597 210.609 355.352 211.854 355.352 213.39C355.352 214.926 356.597 216.171 358.132 216.171Z"
          fill="#006937"
        />

        <text
          className="worldwide-map--text"
          x="260"
          y="100"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {t('worldwide_map.country_label.spain', 'Spain')}
        </text>

        <path
          d="M326.504 143.318L351.51 168.322L351.67 213.39"
          stroke="#023E51"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          className="worldwide-map--label"
          onClick={() => onSelectCountry('pt')}
          d="M195.635 154.092L326.418 154.092L326.418 132.499L195.635 132.499L195.635 154.092Z"
          fill="#023E51"
        />
        <g opacity="0.35">
          <path
            opacity="0.35"
            d="M325.982 154.099C331.948 154.099 336.784 149.263 336.784 143.298C336.784 137.332 331.948 132.496 325.982 132.496C320.016 132.496 315.18 137.332 315.18 143.298C315.18 149.263 320.016 154.099 325.982 154.099Z"
            fill="#023E51"
          />
        </g>
        <path
          className="worldwide-map--label-half-circle"
          d="M325.982 154.089V132.496C320.014 132.496 315.18 137.33 315.18 143.298C315.18 149.265 320.014 154.089 325.982 154.089Z"
          fill="#023E51"
        />
        <path
          d="M325.982 149.405C329.355 149.405 332.089 146.671 332.089 143.298C332.089 139.925 329.355 137.191 325.982 137.191C322.609 137.191 319.875 139.925 319.875 143.298C319.875 146.671 322.609 149.405 325.982 149.405Z"
          fill="white"
          className="worldwide-map--letter"
        />
        <path
          d="M351.671 216.171C353.207 216.171 354.452 214.926 354.452 213.39C354.452 211.854 353.207 210.609 351.671 210.609C350.136 210.609 348.891 211.854 348.891 213.39C348.891 214.926 350.136 216.171 351.671 216.171Z"
          fill="#023E51"
        />

        <text
          className="worldwide-map--text"
          x="260"
          y="145"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {t('worldwide_map.country_label.portugal', 'Portugal')}
        </text>

        <path
          d="M131.316 205.305L151.424 225.411L151.584 251.976"
          stroke="#3DAE2B"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          className="worldwide-map--label"
          onClick={() => onSelectCountry('mx')}
          d="M0.000915527 216.078L131.34 216.078L131.34 194.486L0.000915528 194.486L0.000915527 216.078Z"
          fill="#3DAE2B"
        />
        <g opacity="0.35">
          <path
            opacity="0.35"
            d="M131.337 216.105C137.303 216.105 142.14 211.269 142.14 205.304C142.14 199.338 137.303 194.502 131.337 194.502C125.372 194.502 120.535 199.338 120.535 205.304C120.535 211.269 125.372 216.105 131.337 216.105Z"
            fill="#3DAE2B"
          />
        </g>
        <path
          className="worldwide-map--label-half-circle"
          d="M131.337 216.104V194.512C125.369 194.512 120.535 199.346 120.535 205.313C120.535 211.281 125.38 216.104 131.337 216.104Z"
          fill="#3DAE2B"
        />
        <path
          d="M131.338 211.411C134.71 211.411 137.445 208.676 137.445 205.304C137.445 201.931 134.71 199.197 131.338 199.197C127.965 199.197 125.23 201.931 125.23 205.304C125.23 208.676 127.965 211.411 131.338 211.411Z"
          fill="white"
          className="worldwide-map--letter"
        />
        <path
          d="M151.582 254.757C153.117 254.757 154.362 253.512 154.362 251.976C154.362 250.44 153.117 249.195 151.582 249.195C150.046 249.195 148.801 250.44 148.801 251.976C148.801 253.512 150.046 254.757 151.582 254.757Z"
          fill="#3DAE2B"
        />

        <text
          className="worldwide-map--text"
          x="66"
          y="207"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {t('worldwide_map.country_label.mexico', 'Mexico')}
        </text>

        <path
          d="M286.366 279.375L261.361 304.39L261.211 329.629"
          stroke="#006937"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          className="worldwide-map--label"
          onClick={() => onSelectCountry('br')}
          d="M417.687 268.553H286.348V290.145H417.687V268.553Z"
          fill="#006937"
        />
        <g opacity="0.35">
          <path
            opacity="0.35"
            d="M286.345 290.177C292.311 290.177 297.148 285.341 297.148 279.376C297.148 273.41 292.311 268.574 286.345 268.574C280.379 268.574 275.543 273.41 275.543 279.376C275.543 285.341 280.379 290.177 286.345 290.177Z"
            fill="#006937"
          />
        </g>
        <path
          className="worldwide-map--label-half-circle"
          d="M286.344 268.584V290.176C292.312 290.176 297.146 285.342 297.146 279.375C297.146 273.407 292.312 268.584 286.344 268.584Z"
          fill="#006937"
        />
        <path
          d="M286.345 285.483C289.718 285.483 292.452 282.749 292.452 279.376C292.452 276.004 289.718 273.27 286.345 273.27C282.973 273.27 280.238 276.004 280.238 279.376C280.238 282.749 282.973 285.483 286.345 285.483Z"
          fill="white"
          className="worldwide-map--letter"
        />
        <path
          d="M261.21 332.411C262.746 332.411 263.991 331.166 263.991 329.63C263.991 328.094 262.746 326.85 261.21 326.85C259.675 326.85 258.43 328.094 258.43 329.63C258.43 331.166 259.675 332.411 261.21 332.411Z"
          fill="#006937"
        />

        <text
          className="worldwide-map--text"
          x="351"
          y="280"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {t('worldwide_map.country_label.brazil', 'Brazil')}
        </text>

        <path
          d="M471.162 143.127L435.578 178.719V213.487"
          stroke="#93D500"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          className="worldwide-map--label"
          onClick={() => onSelectCountry('bu')}
          d="M602.468 132.305H471.129V153.897H602.468V132.305Z"
          fill="#93D500"
        />
        <g opacity="0.35">
          <path
            opacity="0.35"
            d="M471.127 153.929C477.092 153.929 481.929 149.093 481.929 143.128C481.929 137.162 477.092 132.326 471.127 132.326C465.161 132.326 460.324 137.162 460.324 143.128C460.324 149.093 465.161 153.929 471.127 153.929Z"
            fill="#93D500"
          />
        </g>
        <path
          className="worldwide-map--label-half-circle"
          d="M471.129 132.336V153.928C477.097 153.928 481.931 149.094 481.931 143.127C481.931 137.159 477.097 132.336 471.129 132.336Z"
          fill="#93D500"
        />
        <path
          d="M471.127 149.235C474.499 149.235 477.234 146.501 477.234 143.128C477.234 139.756 474.499 137.021 471.127 137.021C467.754 137.021 465.02 139.756 465.02 143.128C465.02 146.501 467.754 149.235 471.127 149.235Z"
          fill="white"
          className="worldwide-map--letter"
        />
        <path
          d="M435.578 216.268C437.113 216.268 438.358 215.023 438.358 213.488C438.358 211.952 437.113 210.707 435.578 210.707C434.042 210.707 432.797 211.952 432.797 213.488C432.797 215.023 434.042 216.268 435.578 216.268Z"
          fill="#93D500"
        />

        <text
          className="worldwide-map--text"
          x="539"
          y="145"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {t('worldwide_map.country_label.bulgaria', 'Bulgaria')}
        </text>
        {locationIconCoord && (
          <svg
            className={'worldwide-map--location-icon' + (changingCountry ? ' hidden' : '')}
            x={locationIconCoord.x - LOCATION_ICON_WIDTH / 2 + ''}
            y={locationIconCoord.y - LOCATION_ICON_HEIGHT + ''}
            width="23"
            height="31"
            viewBox="0 0 23 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.93361 30.3745C1.55519 17.621 0 16.3121 0 11.625C0 5.20467 4.95683 0 11.0714 0C17.186 0 22.1429 5.20467 22.1429 11.625C22.1429 16.3121 20.5877 17.621 12.2092 30.3745C11.6594 31.2085 10.4834 31.2085 9.93361 30.3745ZM11.0714 16.4687C13.6192 16.4687 15.6845 14.3001 15.6845 11.625C15.6845 8.94985 13.6192 6.78125 11.0714 6.78125C8.52367 6.78125 6.45833 8.94985 6.45833 11.625C6.45833 14.3001 8.52367 16.4687 11.0714 16.4687Z"
              fill="#FC9948"
            />
            <circle cx="11.0709" cy="11.0709" r="5.53571" fill="white" />
          </svg>
        )}
      </svg>
    </>
  );
};
